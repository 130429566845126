import { Tag, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import { STATE_WISE_DASHBOARD_LABEL } from "../../../config";
import { getEditDashboardDetailApi, getEditDashboardDetailAsync, resetEdit, resetModifiedData } from "../../../redux/modification/modificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const TableData = (
  dashboardType,
  setToggleEdit,
  userRole,
  page,
  onHandleSort,
  selectedLabel,
  schemeIdNo,
  
) => {

  const dispatch = useDispatch();
  const { editDashboard } = useSelector((state) => state.modifyCollection)
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));

  const handleSingleEdit = async (record) => {
    const Test = decryptData(sessionStorage.getItem("userInfo"));
    const selectedRow = JSON.parse(sessionStorage.getItem("rowData"));
    const recordExist =
      selectedRow && selectedRow.find((item) => item.key === record.key);

    await dispatch(resetEdit())
    await dispatch(
      getEditDashboardDetailApi({
        schemeId: record?.schemeId,
        userName: Test.userName,
        schemeNo: record?.schemeNo,
      })
    );

    setToggleEdit(true);

    // if (recordExist) {
    //   navigate("/collection/editScheme");
    // } else {
    //   messageApi.open({
    //     type: "warning",
    //     content: "Please select the checkbox !",
    //     duration: 2,
    //   });
    // }
  };
  const getHoldRemarks =(selectedLabel)=> (selectedLabel === 'Hold Schemes')
  ?
  [
    {
      title: "Hold Remarks",
      dataIndex: "holdReason",
      width: "120px",
    }

  ]:[]
  let columnsList = [
    {
      title: (
        <div>
          {/* onClick={() => onHandleSort('schemeNo')} */}
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "145px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      render: (_, item) => {
        let color = " ";
        const dataStatus=item?.holdStatus === 9 ? item?.holdStatus:item.status
        switch (dataStatus) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "#FFAE00"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
         
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerCode",
    },
    {
      title: (
        <div >
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "dealerLocation",
      fixed: "left",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      sorter: true,
      key: "dealerName",
      defaultSortOrder: "ascend",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      sorter: true,
      key:"StateName",
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"TerritoryManager",
    },
    {
      title: "Top Slab",
      width: "150px",
      render: (x, y, index) => y?.topTarget?.toUpperCase(),
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "160px",
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "100px",
      render: (_, item) => item.oldBillingQty || 0,
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "100px",
      render: (_, item) => item.oldInstallation || 0,
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "100px",
      render: (_, item) => item.oldCollection || 0,
    },
    {
      title: (
        <div>
          Opening <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      width: "100px",
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          width: "100px",
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          width: "100px",
        },
      ],
    },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      width: "160px",
      children: [
        {
title: (
              <span>
                Last <span>3<sup>rd</sup></span> Month
              </span>
            ),
          dataIndex: "closingOutStandingMonth_3",
          width: "180px",
        },
        {
          title: (
            <span>
              Last <span>2<sup>nd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          width: "180px",
        },
        {
          title: (
            <span>
            Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
          </span> 
          ),
          dataIndex: "closingOutStandingMonth_1",
          width: "180px",
        },
        // {
        //   title: (
        //     <span>
        //       Last <span>1<sup>st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   width: "160px",
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "160px",
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bGLacsCurrentYear",
      children: [
        {
          title: "Lacs",
          dataIndex: "bGLacsCurrentYear",
          width: "100px",
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "160px",
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      children: [
        {
          title: "B",
          dataIndex: "billing",
          width: "160px",
          render: (_, item) => item.billing || 0,
        },
        {
          title: "I",
          dataIndex: "installation",
          width: "160px",
          render: (_, item) => item.installation || 0,
        },
        {
          title: "C",
          dataIndex: "collection",
          width: "160px",
          render: (_, item) => item.collection || 0,
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      width: "80px",
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "110px",
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T2",
          dataIndex: "t2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T3",
          dataIndex: "t3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T4",
          dataIndex: "t4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T5",
          dataIndex: "t5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T6",
          dataIndex: "t6",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",

      children: [
        {
          title: "R1",
          dataIndex: "r1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R2",
          dataIndex: "r2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R3",
          dataIndex: "r3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R4",
          dataIndex: "r4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R5",
          dataIndex: "r5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R6",
          dataIndex: "r6",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
      ],
    },

    {
      title: "Payout",
      dataIndex: "payout",
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P2",
          dataIndex: "p2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P3",
          dataIndex: "p3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P4",
          dataIndex: "p4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P5",
          dataIndex: "p5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P6",
          dataIndex: "p6",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "volume",
          width: "100px",

        },
        {
          title: "Per Tr.",
          dataIndex: "perTr",
          width: "100px",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          width: "100px",
        },
      ],
    },
    ...getHoldRemarks(selectedLabel),
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "160px",
    },

    {
      title: "Modify",
      key: "action",
      fixed: "right",
      width: "70px",

      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Modify" placement="top" className="infoIcon">
            <EditOutlined
              // type="primary"
              // className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
              // Disable the button based on the condition
              onClick={() => handleSingleEdit(record)}
              style={{ fontSize: "20px" }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  let columnsList1 = [
    {
      title: (
        <div>
          {/* onClick={() => onHandleSort('schemeNo')} */}
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "145px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      render: (_, item) => {
        let color = " ";
        const dataStatus=item?.holdStatus === 9 ? item?.holdStatus:item.status
        switch (dataStatus) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "#FFAE00"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
         
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div onClick={() => onHandleSort("dealerCode")}>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      fixed: "left",
      sorter: true,
      key: "DealerCode",
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div onClick={() => onHandleSort("dealerLocation")}>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "dealerLocation",
      fixed: "left",
      width: "150px",
      sorter: true,
      key: "DealerLocation",
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div onClick={() => onHandleSort("dealerName")}>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      sorter: true,
      key: "DealerName",
      defaultSortOrder: "ascend",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      sorter: true,
      key: "StateName",
    },
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
      Key: "stateHeadName",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
      sorter: true,
      key: "TerritoryManager",

    },
    {
      title: "Top Slab",
      width: "150px",
      render: (x, y, index) => y?.topTarget?.toUpperCase(),
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "160px",
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "100px",
      render: (_, item) => item.oldBillingQty || 0,
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "100px",
      render: (_, item) => item.oldInstallation || 0,
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "100px",
      render: (_, item) => item.oldCollection || 0,
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      width: "100px",
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          width: "100px",
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          width: "100px",
        },
      ],
    },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      width: "160px",
      children: [
        {
title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
          dataIndex: "closingOutStandingMonth_3",
          width: "180px",
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          width: "180px",
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          width: "180px",
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
            </span>
          ),
          dataIndex: "outStandingDays",
          width: "160px",
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bGLacsCurrentYear",
      width: "160px",
      children: [
        {
          title: "Lacs",
          dataIndex: "bGLacsCurrentYear",
          width: "160px",
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "160px",
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      children: [
        {
          title: "B",
          dataIndex: "billing",
          width: "160px",
          render: (_, item) => item.billing || 0,
        },
        {
          title: "I",
          dataIndex: "installation",
          width: "160px",
          render: (_, item) => item.installation || 0,
        },
        {
          title: "C",
          dataIndex: "collection",
          width: "160px",
          render: (_, item) => item.collection || 0,
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      width: "80px",
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "110px",
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T2",
          dataIndex: "t2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T3",
          dataIndex: "t3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T4",
          dataIndex: "t4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T5",
          dataIndex: "t5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T6",
          dataIndex: "t6",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",

      children: [
        {
          title: "R1",
          dataIndex: "r1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R2",
          dataIndex: "r2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R3",
          dataIndex: "r3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R4",
          dataIndex: "r4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R5",
          dataIndex: "r5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "R6",
          dataIndex: "r6",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
      ],
    },

    {
      title: "Payout",
      dataIndex: "payout",
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P2",
          dataIndex: "p2",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P3",
          dataIndex: "p3",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P4",
          dataIndex: "p4",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P5",
          dataIndex: "p5",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
        {
          title: "P6",
          dataIndex: "p6",
          width: "160px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "volume",
          width: "100px",

        },
        {
          title: "Per Tr.",
          dataIndex: "perTr",
          width: "100px",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          width: "100px",
        },
      ],
    },
    ...getHoldRemarks(selectedLabel),
    {
      title: "Remarksss",
      dataIndex: "reason",
      width: "160px",
    },

    {
      title: "Modify",
      key: "action",
      fixed: "right",
      width: "70px",

      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Modify" placement="top" className="infoIcon">
            <EditOutlined
              // type="primary"
              // className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
              // Disable the button based on the condition
              onClick={() => handleSingleEdit(record)}
              style={{ fontSize: "20px" }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return userInfo?.userRole != "SADM" ? columnsList1 : columnsList;
};

export default TableData;
