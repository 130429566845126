import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  message,
  Menu,
  Tooltip
} from "antd";
import React, { useState, useEffect } from "react";
import axiosAPI from "../../../services/axiosApi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  DownOutlined,
  FilterOutlined,
 
} from "@ant-design/icons";
import XLSX from 'xlsx-js-style';
import { getStateListApi } from "../../../redux/stateData/stateDataSlice";
import "../modificationApproval/ModificationInstallation";
import { getModificationTrackAsyncSadm,getModificationTrackAsyncZadm,getModificationTrackAsync,getModificationTrackHoldAsync } from "../../../redux/modification/modificationSlice";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";
import TrackTable from "./tracktable";
import ToastifyShow from "../../../components/ToastifyShow";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
const { RangePicker } = DatePicker;
const InitCollectionData = {
  currentYear: "2024",
  schemeType: 1,
  dealerCode: [],
  month: 0,
  monthEnd: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: 150,
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "TerritoryManager"
};
const TrackModification = ({ }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedLabel, setSelectedLabel] = useState('Schemes');
  const { stateList } = useSelector((state) => state.stateData);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const {
    loadingState,
  } = useSelector((state) => state.modifyCollection);
  
  const [selectedStateId, setSelectedStateId] = useState(0);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const currentFiscalYear = getCurrentFiscalYear1(collectionData?.month||new Date());
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);
  const [stateHeadData, setStateHeadData] = useState([]);
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [selectedAM, setSelectedAM] = useState("");
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [selectedZH, setSelectedZH] = useState("");
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [schemeNo, setSchemeNo] = useState([]);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [schemeId, setSchemeId] = useState([])
  const [dataSource, setDataSource] = useState([])

  const { trackDataSadm, trackDataZadm,trackData,loadingState1 ,trackHoldData } = useSelector((state) => state.modifyCollection);
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3; 
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
    useEffect(() => {
      setDateValue(financialYear);
    }, [financialYear]);
  useEffect(() => {
    if( userRole =="SADM"){
      setDataSource(trackDataSadm.data?.modifiedSchemeList);
    }else if ( userRole =="ZADM"){
      setDataSource(trackDataZadm.data?.modifiedSchemeList)
    }else if (["NSH","COSM","Finance","PMIS"].includes(userRole)){
      setDataSource(trackData.data?.modifiedSchemeList)
    }
  }, [trackDataSadm,trackDataZadm,trackData]);

  useEffect(()=>{
    setDataSource(trackHoldData.data?.trackholdSchemeList)
  },[trackHoldData])
 

  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
      setZonalHeadData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedZH("All Zonal Head");
                setStateHeadData([]);
                setSelectedZHID(0);
                setSelectedSHID(0);
                setSelectedSH("All State Heads");
                setAreaMngrData([]);
                setSelectedAMID(0);
                setSelectedAM("All Area Managers");
              }}
            >
              All Zonal Head
            </span>
          ),
        },
        ...result.data.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span
                onClick={() => {
                  setSelectedZH(item.zonalHeadName);
                  setSelectedZHID(item.id);
                  setSelectedSHID(0);
                  setSelectedSH("All State Heads");
                  getShDropdownData(item.id);
                  setAreaMngrData([]);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                }}
              >
                {item.zonalHeadName}
              </span>
            ),
          };
        }),
      ]);
    });

    if (["SADM"].includes(userRole)) {
      getStateByStateHeadId(userId);
    }
  }, []);

  const getStateByStateHeadId = (SHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };

  const getStateByZonalHeadId = (ZHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;

        setStateListArr(data);
      });
  };
  useEffect(() => {
    if (userRole == "SADM") {
      getStateByStateHeadId(userId);
      return;
    }
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);
  const getZhId = () => {
    if (userRole == "ZADM") {
      return userId;
    }
    if (userRole == "SADM") {
      return getZonalHeadSH?.data?.userId;
    }

    if (userRole == "AMGR" && getZonalHead?.data?.userId) {
      return getZonalHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedZHID;
    }
  };

  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "SADM") {
      return userId;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedSHID;
    }
  };

  const captalizeObjKeys = obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  );

  const getCol = item => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    if ([1, 2].includes(collectionData.schemeType)) {
      return {
        scheme_no: item.schemeNo,
        dealer_code: item.dealerCode,
        dealer_location: item.dealerLocation,
        state: item.stateName,
        state_head: item.stateHeadName,
        territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        industry: `${item.industry}, ${item.modifiedIndustry}`,
        b: item.oldBillingQty,
        i: item.oldInstallation,
        c: item.oldCollection,
        opening_Stock_nos: item.oldOpeningStock,
        opening_stock_days: `${item.openingStockDays}, ${item.modifiedOpeningStockDays}`,
        Closing_OS_IN_LACS_Last_Third_Month: item.closingOutStandingMonth_1,
        Closing_OS_IN_LACS_Last_Two_Month: item.closingOutStandingMonth_2,
        Closing_OS_IN_LACS_Last_One_Month: item.closingOutStandingMonth_3,
        Days: `${item.outStandingDays}, ${item.modifiedOutStandingDays}`,
        BG_Lacs: item.bgLacsCurrentYear,
        Installation_Plan_Nos: `${item.delayPlan}, ${item.modifiedDelayPlan}`,
        Billing_Details_B: `${item.billing}, ${item.modifiedBilling}`,
        Billing_Details_i: `${item.installation}, ${item.modifiedInstallation}`,
        Billing_Details_c: `${item.collection}, ${item.modifiedCollection}`,
        Books_Collection_Target_T1: `${item.t1}, ${item.modifiedT1}`,
        Books_Collection_Target_T2: `${item.t2}, ${item.modifiedT2}`,
        Books_Collection_Target_T2T1: `${item.t2_T1}, ${item.modifiedT2_T1}`,
        Books_Collection_Target_T3: `${item.t3}, ${item.modifiedT3}`,
        Books_Collection_Target_T3T2: `${item.t3_T2}, ${item.modifiedT3_T2}`,
        Payout_Rate_R1: `${item.r1}, ${item.modifiedR1}`,
        Payout_Rate_R2: `${item.r2}, ${item.modifiedR2}`,
        Payout_Rate_R3: `${item.r3}, ${item.modifiedR3}`,
        Payout_P1: `${item.p1}, ${item.modifiedP1}`,
        Payout_P2: `${item.p2}, ${item.modifiedP2}`,
        Payout_P3: `${item.p3}, ${item.modifiedP3}`,
        TEP_Vol: `${item.volume}, ${item.modifiedVolume}`,
        TEP_Per_Tr: `${item.perTr}, ${item.modifiedPerTr}`,
        TEP_Amount: `${item.amount}, ${item.modifiedAmount}`,
        Remarks: `${item.reason}, ${item.modifiedReason}`,
      }
    }
    // Slab based scheme
    if (collectionData.schemeType == 3) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        Dealer_Location: item.dealerLocation,
        State: item.stateName,
        state_head: item.stateHeadName,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Target_T1: `${item.t1}, ${item.historyT1}`,
        Target_T2: `${item.t2}, ${item.historyT2}`,
        Inc_Per_Tractor_R1: `${item.r1}, ${item.historyR1}`,
        Inc_Per_Tractor_R2: `${item.r2}, ${item.historyR2}`,
        Payout_P1: item.p1,
        Payout_P2: item.p2,
        Remarks: `${item.reason}, ${item.modifiedReason}`,
      }
    }
    // Single Target Scheme
    if (collectionData.schemeType == 8) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: `${item.installationTarget}, ${item.historyInstallationTarget}`,
        Inc_Per_Tractor: `${item.incPerTarget}, ${item.historyIncPerTarget}`,
        Payout: item.p1,
        Remarks: `${item.reason}, ${item.modifiedReason}`
      }
    }
    // Single Target With Upper Cap Scheme
    if (collectionData.schemeType == 4) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: `${item.installationTarget}, ${item.historyInstallationTarget}`,
        Upper_Cap: `${item.upperCap}, ${item.historyUpperCap}`,
        Inc_Per_Tractor: `${item.incPerTarget}, ${item.historyIncPerTarget}`,
        Payout: `${item.p1}, ${item.historyP1}`,
        Remarks: `${item.reason}, ${item.modifiedReason}`
      }
    }
    // Unconditional Scheme
    if (collectionData.schemeType == 5) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Expected: `${item.installationTarget}, ${item.historyInstallationTarget}`,
        Inc_Per_Tractor: `${item.incPerTarget}, ${item.historyIncPerTarget}`,
        Payout: item.p1,
        Remarks: `${item.reason}, ${item.historyReason}`
      }
    }
  }

  const handleDownload = () => {
let selectedSchemeType = collectionData.schemeType

    if([1, 2].includes(selectedSchemeType)){

      if (dataSource?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }

 

      let tab_text = `<table>
        <thead>
        <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">Industry</th>
          <th rowspan="2">B</th>
          <th rowspan="2">I</th>
          <th rowspan="2">C</th>
          <th colspan="2">OPENING STOCK</th>
          <th colspan="4">CLOSING OS. (IN LACS)</th>
          <th>BG</th>
          <th>INSTALLATION PLAN</th>
          <th colspan="3">BILLING DETAILS</th>
          <th rowspan="2">CLOSING STOCK</th>
          <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
          <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
          <th colspan="6">PAYOUT RATE</th>
          <th colspan="6">PAYOUT</th>
          <th rowspan="2">REMARKS</th>`;
      
      if (selectedLabel === 'Hold Schemes') {
        tab_text += `<th rowspan="2">HOLD REMARKS</th>`;
      }
      
      tab_text += `
          <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        </tr>
        <tr>
          <th>NOS</th>
          <th>Days</th>
          <th>LAST 3rd MONTH</th>
          <th>LAST 2nd MONTH</th>
          <th>LAST 1st MONTH</th>
          <th>LAST 1st MONTH DAYS</th>
          <th>LACS</th>
          <th>NOS</th>
          <th>B</th>
          <th>I</th>
          <th>C</th>
          <th>T1</th>
          <th>T2</th>
          <th>T2-T1</th>
          <th>T3</th>
          <th>T3-T2</th>
          <th>T4</th>
          <th>T4-T3</th>
          <th>T5</th>
          <th>T5-T4</th>
          <th>T6</th>
          <th>T6-T5</th>
          <th>R1</th>
          <th>R2</th>
          <th>R3</th>
          <th>R4</th>
          <th>R5</th>
          <th>R6</th>
          <th>P1</th>
          <th>P2</th>
          <th>P3</th>
          <th>P4</th>
          <th>P5</th>
          <th>P6</th>  
          <th>Expected Volume</th>
          <th>PER TR.</th>
          <th>AMOUNT</th>  
        </tr>
        </thead>`;
      
   
      if (selectedLabel === 'Hold Schemes'){
        dataSource?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
      <td>${x?.dealerCode}</td>
      <td>${x?.dealerLocation}</td>
      <td>${x?.dealerName}</td>
      <td>${x?.stateName}</td>
      <td>${x?.territoryManager}</td>
      <td>${(x.status === 2) ?(`${x?.historyTopTarget} ,${x.topTarget}`) :(`${x.topTarget} ,${x?.holdTopTarget} `)}</td>
      <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
      <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
      <td>${(x.status === 2) ?(`${x?.historyIndustry} ,${x.industry}`) :(`${x.industry} ,${x?.holdIndustry} `)}</td>
      <td>${x?.oldBillingQty}</td>
      <td>${x?.oldInstallation}</td>
      <td>${x?.oldCollection}</td>
      <td>${x?.oldOpeningStock}</td>
      <td>${x?.openingStockDays}</td>
      <td>${x?.closingOutStandingMonth_3}</td>
      <td>${x?.closingOutStandingMonth_2}</td>
      <td>${x?.closingOutStandingMonth_1}</td>
      <td>${x?.outStandingDays}</td>
      <td>${x?.bGLacsCurrentYear}</td>
      <td>${(x.status === 2) ? (`${x?.historyDelayPlan} , ${x.delayPlan} `) :(`${x.delayPlan} ,${x?.holdDelayPlan} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyBilling} ,${x.billing}`) :(`${x.billing} ,${x?.holdDelayPlan} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyInstallation} ,${x.installation}`) :(`${x.installation} ,${x?.holdInstallation} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyCollection} ,${x.collection}`) :(`${x.collection} ,${x?.holdCollection} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyClosingStock} ,${x.closingStock}`) :(`${x.closingStock} ,${x?.holdclosingStock} `)}</td>
      <td>${(x.status === 2)  ?(`${x?.historyClosingOutstanding} , ${x.closingOutStanding}`):(`${x.closingOutStanding} , ${x?.holdclosingOutStanding} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyT1} ,${x.t1}`) :(`${x.t1} ,${x?.holdT1} `)}</td> 
      <td>${(x.status === 2) ?(`${x?.historyT2} ,${x.t2}`) :(`${x.t2} ,${x?.holdT2} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyT2_T1} ,${x.t2_T1}`) :(`${x.t2_T1} ,${x?.holdT2_T1} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyT3} ,${x.t3}`) :(`${x.t3} ,${x?.holdT3} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyT3_T2} ,${x.t3_T2}`) :(`${x.t3_T2} ,${x?.holdT3_T2} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyT4} ,${x.t4}`)   :(`${x.t4} ,${x?.holdT4} `)}</td>
      <td>${(x.status === 2) ?(`${x?.historyT4_T3} ,${x.t4_T3}`) :(`${x.t4_T3} ,${x?.holdT4_T3} `) }</td>
      <td>${ (x.status === 2) ?(`${x?.historyT5} ,${x.t5}`)  :(`${x.t5} ,${x?.holdT5} `)}</td>
      <td>${ (x.status === 2) ?(`${x?.historyT5_T4} ,${x.t5_T4}`)  :(`${x.t5_T4} ,${x?.holdT5_T4} `)}</td>
      <td>${ (x.status === 2) ?(`${x?.historyT6} ,${x.t6}`)  :(`${x.t6} ,${x?.holdT6} `)}</td>
      <td>${ (x.status === 2) ?(`${x?.historyT6_T5} ,${x.t6_T5}`)  :(`${x.t6_T5} ,${x?.holdT6_T5} `)}</td>
      <td>${ (x.status === 2) ?(`${x?.historyR1} ,${x.r1}`) :(`${x.r1} ,${x?.holdR1} `) }</td>
      <td>${ (x.status === 2) ?(`${x?.historyR2} ,${x.r2}`) :(`${x.r2} ,${x?.holdR2} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyR3} ,${x.r3}`) :(`${x.r3} ,${x?.holdR3} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyR4} ,${x.r4}`) :(`${x.r4} ,${x?.holdR4} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyR5} ,${x.r5}`) :(`${x.r5} ,${x?.holdR5} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyR6} ,${x.r6}`) :(`${x.r6} ,${x?.holdR6} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyP1} ,${x.p1}`) :(`${x.p1} ,${x?.holdP1} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyP2} ,${x.p2}`) :(`${x.p2} ,${x?.holdP2} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyP3} ,${x.p3}`) :(`${x.p3} ,${x?.holdP3} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyP4} ,${x.p4}`) :(`${x.p4} ,${x?.holdP4} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyP5} ,${x.p5}`) :(`${x.p5} ,${x?.holdP5} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyP6} ,${x.p6}`) :(`${x.p6} ,${x?.holdP6} `) }</td>
      <td>
        ${(x.status === 2) 
          ? (
              (x?.historyReason || x?.reason) 
                ? `${x?.historyReason || ""}, ${x?.reason || ""}` 
                : ""
            )
          : (
              (x?.reason || x?.holdRemarks) 
                ? `${x?.reason || ""}, ${x?.holdRemarks || ""}` 
                : ""
            )
        }
      </td>
      <td>${x.holdReason||""}</td>
      <td>${(x.status === 2) ?(`${x?.historyVolume} ,${x.volume}`) :(`${x.volume} ,${x?.holdVolume} `)  }</td>
      <td>${(x.status === 2) ?(`${x?.historyPerTr} ,${x.perTr}`)  :(`${x.perTr} ,${x?.holdPerTr} `) }</td>
      <td>${(x.status === 2) ?(`${x?.historyAmount} ,${x.amount}`)  :(`${x.amount} ,${x?.holdAmount} `) }</td>
      </tr>`;
          });

      }else{
      dataSource?.forEach((x) => {
        console.log(x,"xx")
        tab_text += `<tr ><td>${x?.schemeNo}</td>
    <td>${x?.dealerCode}</td>
    <td>${x?.dealerLocation}</td>
    <td>${x?.dealerName}</td>
    <td>${x?.stateName}</td>
    <td>${x?.territoryManager}</td>
    <td>${(x.status === 2) ?(`${x?.historyTopTarget} ,${x.topTarget}`) : (`${x?.topTarget}, ${x.modifiedTopTarget}`)}</td>
    <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
    <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
    <td>${(x.status === 2) ?(`${x?.historyIndustry} ,${x.industry}`) : (`${x?.industry}, ${x.modifiedIndustry}`)}</td>
    <td>${x?.oldBillingQty}</td>
    <td>${x?.oldInstallation}</td>
    <td>${x?.oldCollection}</td>
    <td>${x?.oldOpeningStock}</td>
    <td>${x?.openingStockDays}</td>
    <td>${x?.closingOutStandingMonth_3}</td>
    <td>${x?.closingOutStandingMonth_2}</td>
    <td>${x?.closingOutStandingMonth_1}</td>
    <td>${x?.outStandingDays}</td>
    <td>${x?.bGLacsCurrentYear}</td>
    <td>${(x.status === 2) ? (`${x?.historyDelayPlan} , ${x.delayPlan} `) : (`${x?.delayPlan} , ${x?.modifiedDelayPlan}`)}</td>
    <td>${(x.status === 2) ? (`${x?.historyBilling} , ${x.billing} `) : (`${(x.billing)} , ${x?.modifiedBilling}`)}</td>
    <td>${(x.status === 2) ?(`${x?.historyInstallation} , ${x.installation}`) :(`${x?.installation}`)}</td>
    <td>${(x.status === 2) ?(`${x?.historyCollection} , ${x.collection}`) :(`${x?.collection}`)}</td>
    <td>${(x.status === 2) ?(`${x?.historyClosingStock} , ${x.closingStock}`) :`${x?.closingStock} , ${x.modifiedClosingStock}`}</td>
    <td>${(x.status === 2)  ?(`${x?.historyClosingOutstanding} ,  ${x.closingOutStanding}`):`${x?.closingOutStanding} , ${x.modifiedClosingOutstanding}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyT1} ,${x.t1}`) :`${x?.t1} , ${x.modifiedT1}`}</td> 
    <td>${(x.status === 2) ?(`${x?.historyT2} ,${x.t2}`) :`${x?.t2} , ${x.modifiedT2}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyT2_T1} ,${x.t2_T1}`) :(`${x.topTarget?.includes("t2") ? x.t2_T1 :0} , ${x.modifiedTopTarget?.includes("t2") ? x.modifiedT2_T1:0}`)}</td>
    <td>${(x.status === 2) ?(`${x?.historyT3} ,${x.t3}`) :`${x?.t3} , ${x?.modifiedT3} `}</td>
    <td>${(x.status === 2) ?(`${x?.historyT3_T2} ,${x.t3_T2}`) :`${x.topTarget?.includes("t3") ? x.t3_T2 :0} , ${x.modifiedTopTarget?.includes("t3") ? x.modifiedT3_T2:0}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyT4} ,${x.t4}`)   : `${x?.t4} , ${x.modifiedT4}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyT4_T3} ,${x.t4_T3}`) : `${x.topTarget?.includes("t4") ? x.t4_T3 :0} , ${x.modifiedTopTarget?.includes("t4") ? x.modifiedT4_T3:0}`}</td>
    <td>${ (x.status === 2) ?(`${x?.historyT5} ,${x.t5}`)  : `${x?.t5} , ${x.modifiedT5}`}</td>
    <td>${ (x.status === 2) ?(`${x?.historyT5_T4} ,${x.t5_T4}`)  :`${x.topTarget?.includes("t5") ? x.t5_T4 :0} , ${x.modifiedTopTarget?.includes("t5") ? x.modifiedT5_T4:0}`}</td>
    <td>${ (x.status === 2) ?(`${x?.historyT6} ,${x.t6}`)  :`${x?.t6} , ${x.modifiedT6}`}</td>
    <td>${ (x.status === 2) ?(`${x?.historyT6_T5} ,${x.t6_T5}`)  :`${x.topTarget?.includes("t6") ? x.t6_T5 :0} , ${x.modifiedTopTarget?.includes("t6") ? x.modifiedT6_T5:0}`}</td>
    <td>${ (x.status === 2) ?(`${x?.historyR1} ,${x.r1}`)  :`${x?.r1} , ${x.modifiedR1}`}</td>
    <td>${ (x.status === 2) ?(`${x?.historyR2} ,${x.r2}`)  :`${x?.r2} , ${x.modifiedR2}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyR3} ,${x.r3}`) :`${x?.r3} , ${x.modifiedR3}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyR4} ,${x.r4}`) :`${x?.r4} , ${x.modifiedR4}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyR5} ,${x.r5}`) :`${x?.r5} , ${x.modifiedR5}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyR6} ,${x.r6}`)  :`${x?.r6} , ${x.modifiedR6}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyP1} ,${x.p1}`)  :`${x?.p1} ,  ${x.modifiedP1}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyP2} ,${x.p2}`)  :`${x?.p2} , ${x.modifiedP2}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyP3} ,${x.p3}`)  :`${x?.p3} , ${x.modifiedP3}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyP4} ,${x.p4}`)  :`${x?.p4} ,  ${x.modifiedP4}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyP5} ,${x.p5}`) :`${x?.p5} , ${x.modifiedP5}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyP6} ,${x.p6}`)  :`${x?.p6} , ${x.modifiedP6}`}</td>
     <td>
        ${(x.status === 2) 
          ? (
              (x?.historyReason || x?.reason) 
                ? `${x?.historyReason || ""}, ${x?.reason || ""}` 
                : ""
            )
          : (
              (x?.reason || x?.holdRemarks) 
                ? `${x?.reason || ""}, ${x?.holdRemarks || ""}` 
                : ""
            )
        }
      </td>
    <td>${(x.status === 2) ?(`${x?.historyVolume} ,${x.volume}`)  :`${x?.volume}  , ${x.modifiedVolume}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyPerTr} ,${x.perTr}`)  :`${x?.perTr} , ${x.modifiedPerTr}`}</td>
    <td>${(x.status === 2) ?(`${x?.historyAmount} ,${x.amount}`)   :`${x?.amount} , ${x.modifiedAmount}`}</td>
    </tr>`;
        });}

  
        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        const ws = wb.Sheets[wb.SheetNames[0]];

        // Ensure all cells are formatted as text
        Object.keys(ws).forEach((cell) => {
            if (cell[0] === '!') return; // Skip metadata like `!ref`
            ws[cell].t = 's'; // Set cell type to string ('s')
        });

        document.body.removeChild(elt);
        let nameFile=selectedSchemeType==1?"With_DeliveryPlan.xlsx":"Without_Delivery_Plan.xlsx"
        // generate file
        XLSX.writeFile(wb, nameFile);
      }else if (selectedSchemeType == 5) {
        let tab_text = `<table class="custom-table">
          <thead>
          <tr>
              <th >SCHEME NO</th>
              <th >DEALER CODE</th>
              <th >DEALER NAME</th>
              <th >STATE</th>
              <th >DEALER LOCATION</th>
              <th >TERRITORY MANAGER</th>
              <th >START MONTH</th>
              <th >END MONTH</th>
              <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
              <th >INSTALLATION EXPECTED</th>
              <th >INC. PER TRACTOR</th>
              <th >PAYOUT</th>
              <th >REMARKS</th>
                <th>HOLD REMARKS</th>
              </tr>
          </thead>`;
  
          if (selectedLabel === 'Hold Schemes') {
            dataSource?.forEach((x) => {
              tab_text += `<tr >
      
              <td>${x?.schemeNo}</td>
              <td>${x?.dealerCode}</td>
              <td>${x?.dealerName}</td>
              <td>${x?.stateName}</td>
              <td>${x?.dealerLocation}</td>
              <td>${x?.territoryManager}</td>
              <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
              <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
              <td>${x?.actual_Installation_Last_Year_Month}</td>
              <td>${x.status === 2 ?(`${x?.historyInstallationTarget} , ${x.installationTarget}`) :(`${x.installationTarget} ,${x?.holdInstallationTarget} `) }</td>
              <td>${x.status === 2 ?( `${x?.historyIncPerTarget} , ${x.incPerTarget}`) :(`${x.incPerTarget} ,${x?.holdIncPerTarget} `) }</td>
              <td>${x.status === 2 ? (`${x?.historyP1} , ${x?.p1}`)  :(`${x.p1} ,${x?.holdP1} `)}</td>
                  <td>${x?.reason||""}</td>
               <td>${x.holdReason||""}</td>
            </tr>`;
            });
          }else{

            dataSource?.forEach((x) => {
              tab_text += `<tr >
      
              <td>${x?.schemeNo}</td>
              <td>${x?.dealerCode}</td>
              <td>${x?.dealerName}</td>
              <td>${x?.stateName}</td>
              <td>${x?.dealerLocation}</td>
              <td>${x?.territoryManager}</td>
              <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
              <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
              <td>${x?.actual_Installation_Last_Year_Month}</td>
              <td>${x.status === 2 ?(`${x?.historyInstallationTarget} , ${x.installationTarget}`) :(`${x?.installationTarget} , ${x.modifiedInstallationTarget}`)}</td>
              <td>${x.status === 2 ?( `${x?.historyIncPerTarget} , ${x.incPerTarget}`) :(`${x?.incPerTarget}  , ${x.modifiedIncPerTarget}`)}</td>
              <td>${x.status === 2 ? (`${x?.historyP1} , ${x?.p1}`)  :(`${x?.p1} , ${x.modifiedP1}`)}</td>
                   <td>${x?.reason||""}</td>
    <td>${x.holdReason||""}</td>
            </tr>`;
            });
          }
        tab_text += "</table>";
  
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // generate file
        XLSX.writeFile(
          wb,
          `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
      } else if (selectedSchemeType == 4) {
        let tab_text = `<table>
        <thead>
        <tr>
            <th >SCHEME NO</th>
            <th >DEALER CODE</th>
            <th >DEALER NAME</th>
            <th >STATE</th>
            <th >DEALER LOCATION</th>
            <th >TERRITORY MANAGER</th>
            <th >START MONTH</th>
            <th >END MONTH</th>
            <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th >INSTALLATION TARGET</th>
            <th>UPPER CAP</th>
            <th >INC. PER TRACTOR</th>
            <th >PAYOUT</th>
            <th >REMARKS</th>
               <th>HOLD REMARKS</th>
            </tr>
        </thead>`;
        if (selectedLabel === 'Hold Schemes'){
          dataSource?.forEach((x) => {
            tab_text += `<tr >
            <td>${x?.schemeNo}</td>
            <td>${x?.dealerCode}</td>
            <td>${x?.dealerName}</td>
            <td>${x?.stateName}</td>
            <td>${x?.dealerLocation}</td>
            <td>${x?.territoryManager}</td>
            <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
            <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
            <td>${x?.actual_Installation_Last_Year_Month}</td>
            <td>${x.status === 2 ?(`${x?.historyInstallationTarget} , ${x.installationTarget}`)  :(`${x?.installationTarget} , ${x.modifiedInstallationTarget}`)}</td>
            <td>${x.status === 2 ?(`${x?.historyUpperCap} , ${x?.upperCap}`)  :(`${x?.upperCap} , ${x.modifiedUpperCap}`)}</td>
         <td>${x.status === 2 ?(`${x?.historyIncPerTarget} , ${x.incPerTarget}`) :(`${x?.incPerTarget}  , ${x.modifiedIncPerTarget}`)}</td>
            <td>${x.status === 2 ? (`${x?.historyP1} , ${x?.p1}`) :(`${x?.p1} , ${x.modifiedP1}`)}</td>
      <td>${x?.reason||""}</td>
    <td>${x.holdReason||""}</td>
        </tr>`;
          });
        }else{

          dataSource?.forEach((x) => {
            tab_text += `<tr >
            <td>${x?.schemeNo}</td>
            <td>${x?.dealerCode}</td>
            <td>${x?.dealerName}</td>
            <td>${x?.stateName}</td>
            <td>${x?.dealerLocation}</td>
            <td>${x?.territoryManager}</td>
            <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
            <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
            <td>${x?.actual_Installation_Last_Year_Month}</td>
            <td>${x.status === 2 ?(`${x?.historyInstallationTarget} , ${x.installationTarget}`) :(`${x?.installationTarget} , ${x.modifiedInstallationTarget}`)}</td>
            <td>${x.status === 2 ?(`${x?.historyUpperCap},${x?.upperCap}`):(`${x?.upperCap} , ${x.modifiedUpperCap}`)}</td>
         <td>${x.status === 2 ?(`${x?.historyIncPerTarget} , ${x.incPerTarget}`)  :(`${x?.incPerTarget}  , ${x.modifiedIncPerTarget}`)}</td>
            <td>${x.status === 2 ? (`${x?.historyP1} , ${x?.p1}`)  :(`${x?.p1} , ${x.modifiedP1}`)}</td>
      <td>${x?.reason||""}</td>
            
        </tr>`;
          });
        }
        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
  
        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_With_Upper_Cap${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        );
      } else if (selectedSchemeType == 8) {
        let tab_text = `<table>
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION TARGET</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
             <th>HOLD REMARKS</th>
          </tr>
      </thead>`;
      if (selectedLabel === 'Hold Schemes'){
        dataSource?.forEach((x) => {
          tab_text += `<tr >
          <td>${x?.schemeNo}</td>
          <td>${x?.dealerCode}</td>
          <td>${x?.dealerName}</td>
          <td>${x?.stateName}</td>
          <td>${x?.dealerLocation}</td>
          <td>${x?.territoryManager}</td>
          <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
          <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
          <td>${x?.actual_Installation_Last_Year_Month}</td>
          <td>${x.status === 2 ?(`${x?.historyInstallationTarget} , ${x.installationTarget}`) :(`${x.installationTarget} , ${x?.holdInstallationTarget} `)}</td>
          <td>${x.status === 2 ?(`${x?.historyIncPerTarget} , ${x.incPerTarget}`) :(`${x.incPerTarget} , ${x?.holdIncPerTarget} `)}</td>
          <td>${x.status === 2 ? (`${x?.historyP1} , ${x?.p1}`) :(`${x.p1} , ${x?.holdP1} `)}</td>
      <td>${x?.reason||""}</td>
         <td>${x.holdReason||""}</td>
    </tr>`;
        });
      }
      else{

        dataSource?.forEach((x) => {
          tab_text += `<tr >
          <td>${x?.schemeNo}</td>
          <td>${x?.dealerCode}</td>
          <td>${x?.dealerName}</td>
          <td>${x?.stateName}</td>
          <td>${x?.dealerLocation}</td>
          <td>${x?.territoryManager}</td>
          <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
          <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
          <td>${x?.actual_Installation_Last_Year_Month}</td>
          <td>${x.status === 2 ?(`${x?.historyInstallationTarget} , ${x.installationTarget}`) :(`${x?.installationTarget} , ${x.modifiedInstallationTarget}`)}</td>
          <td>${x.status === 2 ?(`${x?.historyIncPerTarget} , ${x.incPerTarget}`) :(`${x?.incPerTarget} , ${x.modifiedIncPerTarget}`)}</td>
          <td>${x.status === 2 ? (`${x?.historyP1} , ${x?.p1}`) :(`${x?.p1} , ${x.modifiedP1}`)}</td>
        <td>${x?.reason||""}</td>
          
    </tr>`;
        });
      }
        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
  
        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        );
      } else if (selectedSchemeType == 3) {
        let tab_text = `<table>
        <thead>
        <tr>
            <th rowspan="2">SCHEME NO</th>
            <th rowspan="2">DEALER CODE</th>
            <th rowspan="2">DEALER NAME</th>
            <th rowspan="2">STATE</th>
            <th rowspan="2">DEALER LOCATION</th>
            <th rowspan="2">TERRITORY MANAGER</th>
            <th rowspan="2">TOP SLAB</th>
            <th rowspan="2">START MONTH</th>
            <th rowspan="2">END MONTH</th>
            <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th colspan="3">TARGETS</th>
            <th colspan="3">INC. PER TRACTOR</th>
            <th colspan="3">PAYOUT</th>
            <th rowspan="2">REMARKS</th>
               <th rowspan="2">HOLD REMARKS</th>
            </tr>
        <tr>
           <th>T1</th>
           <th>T2</th>
           <th>T3</th>
           <th>R1</th>
           <th>R2</th>
           <th>R3</th>
           <th>P1</th>
           <th>P2</th>
           <th>P3</th>
          </tr>
        </thead>`;
        if (selectedLabel === 'Hold Schemes'){
          dataSource?.forEach((header) => {
            tab_text += `<tr >
      <td>${header?.schemeNo}</td>
      <td>${header?.dealerCode}</td>
      <td>${header?.dealerName}</td>
      <td>${header?.stateName}</td>
      <td>${header?.dealerLocation}</td>
      <td>${header?.territoryManager}</td>
      <td>${header?.topTarget}</td>
      <td>${moment(header?.startMonth).format("MMM-YY") || ""}</td>
      <td>${moment(header?.endMonth).format("MMM-YY") || ""}</td>
      <td>${header?.actual_Installation_Last_Year_Month}</td>
      <td>${ header.status === 2 ?(`${header?.historyT1} , ${header.t1}`):(`${header.t1} , ${header?.holdT1} `)}</td>
      <td>${ header.status === 2 ?(`${header?.historyT2} , ${header.t2}`):(`${header.t2} , ${header?.holdT2} `)}</td>
      <td>${ header.status === 2 ?(`${header?.historyT3} , ${header.t3}`):(`${header.t3} , ${header?.holdT3} `)}</td>
      <td>${header.status === 2 ? (`${header?.historyR1} , ${header.r1}`):(`${header.r1} , ${header?.holdR1} `)}</td>
      <td>${header.status === 2 ? (`${header?.historyR2} , ${header.r2}`):(`${header.r2} , ${header?.holdR2} `)}</td>
      <td>${header.status === 2 ?(`${header?.historyR3} , ${header.r3}`):(`${header.r3} , ${header?.holdR3} `)}</td>
      <td>${header.status === 2 ?(`${header?.historyP1} , ${header.p1}`):(`${header.p1} , ${header?.holdP1} `)}</td>
      <td>${header.status === 2 ?(`${header?.historyP2} , ${header.p2}`):(`${header.p2} , ${header?.holdP2} `)}</td>
      <td>${header.status === 2 ?(`${header?.historyP3} , ${header.p3}`):(`${header.p3} , ${header?.holdP3} `)}</td>
      <td>${header?.reason||""}</td>
         <td>${header.holdReason||""}</td>
      </tr>`;
          });
        }
        else{

          dataSource?.forEach((header) => {
            tab_text += `<tr >
      <td>${header?.schemeNo}</td>
      <td>${header?.dealerCode}</td>
      <td>${header?.dealerName}</td>
      <td>${header?.stateName}</td>
      <td>${header?.dealerLocation}</td>
      <td>${header?.territoryManager}</td>
      <td>${header?.topTarget}</td>
      <td>${moment(header?.startMonth).format("MMM-YY") || ""}</td>
      <td>${moment(header?.endMonth).format("MMM-YY") || ""}</td>
      <td>${header?.actual_Installation_Last_Year_Month}</td>
      <td>${ header.status === 2 ?(`${header?.historyT1} , ${header.t1}`):(`${header?.t1} , ${header?.modifiedT1}`)}</td>
      <td>${ header.status === 2 ?(`${header?.historyT2} , ${header.t2}`):(`${header?.t2} , ${header?.modifiedT2}`)}</td>
      <td>${ header.status === 2 ?(`${header?.historyT3} , ${header.t3}`):(`${header?.t3} , ${header?.modifiedT3}`)}</td>
      <td>${header.status === 2 ? (`${header?.historyR1} , ${header.r1}`):(`${header?.r1} , ${header?.modifiedR1}`)}</td>
      <td>${header.status === 2 ? (`${header?.historyR2} , ${header.r2}`):(`${header?.r2} , ${header?.modifiedR2}`)}</td>
      <td>${header.status === 2 ?(`${header?.historyR3} , ${header.r3}`):(`${header?.r3} , ${header?.modifiedR3}`)}</td>
      <td>${header.status === 2 ?(`${header?.historyP1} , ${header.p1}`):(`${header?.p1} , ${header?.modifiedP1}`)}</td>
      <td>${header.status === 2 ?(`${header?.historyP2} , ${header.p2}`):(`${header?.p2} , ${header?.modifiedP2}`)}</td>
      <td>${header.status === 2 ?(`${header?.historyP3} , ${header.p3}`):(`${header?.p3} , ${header?.modifiedP3}`)}</td>
      <td>${header?.reason||""}</td>
      
      </tr>`;
          });
        }
  
        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
  
        // generate file
        XLSX.writeFile(
          wb,
          `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
      }
  }

  const handleClearFilter = () => {
    setCollectionData({ ...InitCollectionData });
    setEnforcedMonth(null);
    setSelectedStatus(0);
    setSelectedStateId(0);
    setSelectedMonth(null);
    setSelectedLabel('Regular Scheme')
    setSelectedAM(0);
    setSelectedZH(0);
    setSelectedSH(0);
    document.querySelector(".select_state .ant-select-selector").innerText = "All States";
    if (userRole == 'NSH') {
      getAM(true);
      getSH(true);
      getZH(true);
      return;
    };
    if (userRole == 'ZADM') {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == 'SADM') {
      getAM(true);
      return;
    }

  }

  const items = [
    {
      key: "4",
      label: (<a onClick={handleClearFilter}>Clear Filters</a>),
      disabled: false,
      icon :<FilterOutlined  />
    },
    {
      label: (
        <Menu.Item  disabled={dataSource?.length == 0} onClick={handleDownload}>Download</Menu.Item>
      ),
      icon: <Download />,
      key: "5",
    },
  ];


  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedAMID;
    }
  };
  



  
 let lastTwoDigits = dateValue % 100;
    const payload = {
      month: collectionData?.month
        ? new Date(collectionData?.month)?.getMonth() + 1
        : collectionData?.month,
      monthEnd: collectionData?.monthEnd
        ? new Date(collectionData?.monthEnd)?.getMonth() + 1
        : collectionData?.monthEnd,
      userid: userId,
      stateId: selectedStateId,
       areaManagerId: 0,
       "stateHeadId": getShId(),
       "zonalHeadId": getZhId(),
      enforcedMonth: collectionData?.currentMonth,
      userRole: userRole,
      fyear: "FY" + lastTwoDigits,
      schemeType: collectionData?.schemeType,
      state: selectedStateId,
      dealerLocation: collectionData?.dealerLocation,
      schemeNumber: collectionData?.schemeNumber,
      dealerCode: collectionData?.dealerCode,
      pageSize: collectionData?.pageSize,
      pageNumber: collectionData?.pageNumber,
      "isDraft": 0,
      "status": 0,
      "year": 0,

      "amRequestuserid": getAmId(),
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "TerritoryManager"
    };
  const getApiData = () => {
  
    if (userRole == "SADM") {
      dispatch(getModificationTrackAsyncSadm(payload));
      return
    }  
    if (userRole =="ZADM"){
      dispatch(getModificationTrackAsyncZadm(payload));
      return;
    }  
    if (["NSH","COSM","Finance","PMIS"].includes(userRole)){
      dispatch(getModificationTrackAsync(payload));
    }
   
  };
  const getHoldData =()=>{
    dispatch(getModificationTrackHoldAsync(payload));
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
    if (selectedLabel === 'Hold Schemes') {
      getHoldData();
    } else {
      getApiData();
    }
  },300)
    return () => {
      clearTimeout(timeout);
    };
  }, [
    selectedStateId,
    selectedStatus,
    collectionData,
    selectedSHID,
    selectedAMID,
    getZonalHeadSH,
    getStateHead,
    getZonalHead,
    selectedZHID,
    dateValue
  ]);

 



  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };
  const disabledDate = (current, selectedMonth) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();

    // Calculate the start date of the current financial year (April of the current year)
    const startFinancialYear = new Date(currentYear, 3, 1); // Months are zero-based, so April is 3

    // Calculate the end date of the current financial year (March of the next year)
    const endFinancialYear = new Date(currentYear + 1, 2, 31); // March is 2

    // Convert current date to Date object if it's not already
    const currentDate = current instanceof Date ? current : new Date(current);

    // Disable dates that are outside the current financial year
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const getZH = (isClear = false) => {
    if (isClear) {
      setSelectedZH('');
      setSelectedZHID(0)
      return 'All Zonal Head';
    }
    if (userRole == "ZADM") {
      return userName;
    }
    if (userRole == "SADM" && getZonalHeadSH?.data?.userName) {
      return getZonalHeadSH?.data?.userName;
    }
    if (userRole == "AMGR" && getZonalHead?.data?.userName) {
      return getZonalHead?.data?.userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedZH == "" ? "All Zonal Head" : selectedZH;
    }
    return selectedZH == "All Zonal Head" ? "All Zonal Head" : selectedZH;
  };

  const getSH = (isClear = false) => {
    if (isClear) {
      setSelectedSH('');
      setSelectedSHID(0)
      return 'All State Heads';
    }
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };
  const getAM = (isClear = false) => {
    if (isClear) {
      setSelectedAM('');
      setSelectedAMID(0)
      return 'All Area Managers';
    }
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "SADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "AMGR" && userName) {
      return userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };
  const getSchemeType = (schemeType) => {

    if (schemeType == 1) {
      return "With Delivery";
    }
    if (schemeType == 2) {
      return "Without Delivery";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };
  const subColType = [
    {
      key: "0",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 1,
              pageNumber: 1,
            })
          }
        >
          With Delivery
        </a>
      ),
      disabled: false,
    },

    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 2,
              pageNumber: 1,
            })
          }
        >
          WIthout Delivery
        </a>
      ),
      disabled: false,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 3,
              pageNumber: 1,
            })
          }
        >
          Slab Based Scheme
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 8,
              pageNumber: 1,
            })
          }
        >
          Single Target Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "4",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 4,
              pageNumber: 1,
            })
          }
        >
          Single Target With Upper Cap Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "5",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 5,
              pageNumber: 1,
            })
          }
        >
          Unconditional Scheme
        </a>
      ),
      disabled: false,
    },
  ];



  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        setStateHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedSH("All State Heads");
                  setSelectedSHID(0);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                  setAreaMngrData([]);
                }}
              >
                All State Heads
              </span>
            ),
          },
          ...result?.data?.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedSH(item.stateHeadName);
                    setSelectedSHID(item.id);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    getAmDropdownData(item.id);
                  }}
                >
                  {item.stateHeadName}
                </span>
              ),
            };
          }),
        ]);
      });
  };

  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId }).then((result) => {
      setAreaDropdown(result?.data?.data);
      setAreaMngrData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedAM("All Area Managers");
                setSelectedAMID(0);
              }}
            >
              All Area Managers
            </span>
          ),
        },
        ...result?.data?.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span onClick={() => setSelectedAMID(item.id)}>{item.am}</span>
            ),
          };
        }),
      ]);
    });
  };
  const getDealerDropdownData = (userId, type) => {

    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
        pageNumber: 1,
      }));
    }
  };

  const itemsforstatus = [
    {
      label: <a
      onClick={() => {
        setSelectedLabel('Regular Schemes');
        getApiData();
      
      }}
      >
        Regular Schemes</a>,
      key: "1",
    },
    {
      label: <a 
      onClick={() => {
        setSelectedLabel('Hold Schemes');
        getHoldData();
        
      
      }}
      >Hold Schemes</a>,
      key: "2",
    }
  ]

  return (
    <>
      <>
        <div
          id="table"
          className="trackcollection create_scheme_wrap onemoth-date-selector view-schela news"
        >
          <div
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Col>
            <Dropdown menu={{ items: itemsforstatus}}>
              <Button>
                <Space>
                { selectedLabel == "Schemes" ? "Regular Scheme" : selectedLabel }
                  <DownOutlined />
                </Space>
              </Button>

            </Dropdown>
            </Col>
            <Col>
            <Tooltip title={"Scheme Type"}>
              <Dropdown menu={{ items: subColType }}>
                <Button>
                  <Space>
                    {getSchemeType(collectionData?.schemeType)}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>

            <div className="marginauto">
            <Tooltip title={"Running Month"}>
              <DatePicker
                onChange={(date, formatDate) => {
                  if (formatDate == "") {
                    setCollectionData({
                      ...collectionData,
                      currentMonth: 0,
                      pageNumber: 1,
                    });
                    return;
                  }
                  setCollectionData({
                    ...collectionData,
                    currentMonth: new Date(formatDate).getMonth() + 1,
                    pageNumber: 1,
                  });
                }}
                className="start"
                placeholder="Running Month"
                picker="month"
                value={enforcedMonth}
                allowClear
                disabled={
                  collectionData.month != "" || collectionData.monthEnd != ""
                }
              />
              </Tooltip>
            </div>
            <div className="marginauto">
            <Tooltip title={"Financial Year"}>
            <FinancialYearButton handleDate={(x) => {
              setDateValue(x);
            }} />
            </Tooltip>
          </div>
            <div className="month ">
            <Tooltip title={"Start Month        End Month"}>
            <RangePicker
                mode={["month", "month"]}
                picker="month"
                disabledDate={disabledDate}
                onChange={(date, formatDate) => {
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    month: date && date[0] ? date[0].format() : 0,
                    monthEnd: date && date[1] ? date[1].format() : 0,
                    pageNumber: 1,
                  }));
                }}
                //disabledDate={(current) => disabledDate(current, selectedMonth)}
                // disabled={[!showStartMonth, !showEndMonth]}
                onPanelChange={handlePanelChange}
                disabled={collectionData.currentMonth > 0}
              />
              </Tooltip>
            </div>

            {["NSH"].includes(userRole)&&
            <Col>
                <Tooltip title={"Zonal Head"}>
              <Dropdown
                menu={{ items: zonalHeadData }}
              >
                <Button>
                  <Space>
                    {getZH()}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>

            }
           

            {true && (
              <Col>
                              <Tooltip title={"State Head"}>
                <Dropdown
                  menu={{ items: stateHeadData }}
                  disabled={["SADM"].includes(userRole)}
                >
                  <Button>
                    <Space>
                      {getSH()}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown></Tooltip>
              </Col>
            )}
           

          </div>

          <div
            style={{ marginTop: "0" }}
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >           <Tooltip title={"Select State"}>
            <Select
              showSearch
              className="select_state heightauto"
              placeholder="Select State"
              optionFilterProp="children"
              onSelect={handleStateSelect}
              defaultValue={0}
            >
              <Option key={0} value={0}>
                All States
              </Option>
              {stateListArr?.map((option) => (
                <Option key={option.stateID} value={option.stateName}>
                  {option.stateName}
                </Option>
              ))}
            </Select>
            </Tooltip>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                placeholder="Scheme No."
                value={collectionData.schemeNumber}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    schemeNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "10px" }}
                placeholder="Dealer Code"
                value={collectionData.dealerCode}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "20px" }}
                placeholder="Dealer Location"
                value={collectionData.dealerLocation}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerLocation: e.target.value,
                  })
                }
              />
            </div>
            <Col className="actionbuttons rightmargin">
              <Dropdown menu={{ items: items }}>
                <Button>
                  <Space>
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </div>


          <TrackTable
            selectedSchemeType={collectionData.schemeType}
            dataSource={dataSource}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            setSchemeIdNo={setSchemeIdNo}
            setSchemeNo={setSchemeNo}
            setSchemeId={setSchemeId}
            schemeNo={schemeNo}
            selectedLabel={selectedLabel}
            loading={(loadingState1 == "pending" || loadingState == "pending" || loadingTrack == "pending") ? true : false}
            handleChange={(pagination, filters, sorter) => {
              setCollectionData({
                ...collectionData,
                sortingOrder: sorter?.order,
                sortingType: sorter?.columnKey,
              });
            }}
          />
          <Pagination
            defaultCurrent={1}
            className='paginationDesign'
            current={collectionData.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setCollectionData({ ...collectionData, pageNumber, pageSize })
            }
            total={trackDataSadm?.data?.totalCount}
            showSizeChanger
            pageSize={collectionData.pageSize}
            pageSizeOptions={['150', '250', '350']}
          />

          <div className="All_scheme_status">
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "green" }}
              ></div>
              Approved
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "red" }}
              ></div>
              Rejected
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "yellow" }}
              ></div>
              Work in Progress
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "rgb(255, 0, 255)" }}
              ></div>
              Auto Rejected
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#BCB38D" }}
              ></div>
              Deviation
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#9999ff" }}
              ></div>
              Hold
            </div>
          </div>
        </div>

      </>
      {(loadingState1 == "pending" || loadingState == "pending" || loadingTrack == "pending")&&<FullScreenLoader />}
    </>
  );
};
export default TrackModification;
