import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStateListApi } from "../../redux/stateData/stateDataSlice";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import { getCurrentFiscalYear } from "../../utils/dateFormatter";
import axiosAPI from "../../services/axiosApi";
import XLSX from "xlsx-js-style";
import FinancialYearButton from "../../components/financialYearButton/FinancialYearButton";
import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  Modal,
  message,
  Switch,
  Result,
  Tooltip,
} from "antd";
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import AntdTable from "../../components/tables/AntdTable";
import TableCollectionViewOnly, {
  actionMergeColumnBtn,
} from "../../components/tables/tableCollectionView";
import { tableInstallationViewOnly } from "../../components/tables/tableInstallationView";
import {
  getApprovedScheme,
  getUnholdScheme,
  postSaveHoldSchemeData,
  postUnHoldSchemeApi,
  postUpdateHoldScheme,
} from "../../redux/hold/holdDataSlice";
import TextArea from "antd/es/input/TextArea";
import { useFormik } from "formik";
import HoldModal from "./HoldModal";
import { HoldTableInstallationView } from "../../components/tables/holdTableInstallationView";
import HoldTableCollectionView, {
  validationStatus,
  validationStatus1,
  validationStatus2,
} from "../../components/tables/holdTableCollectionView";
import { captalizeObjKeys } from "../../components/table/Table";
import ToastifyShow from "../../components/ToastifyShow";
import moment from "moment";
import { formatNumberDecimal } from "../../utils/formatter";
import { Filter, SchemaRounded } from "@mui/icons-material";
import {
  getExpiredStatusHoldNSH,
  getExpiredStatusHoldZADM,
  getExpiredStatusNSH,
  getExpiredStatusZADM,
} from "../../components/header/schemeExpiredDetails";
import { LegendHold } from "../../atoms/LegendHold";

const { RangePicker } = DatePicker;

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: 1,
  startMonth: 0,
  endMonth: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: "150",
  pageNumber: 1,
  holdPageSize: "150",
  holdPageNumber: 1,
};

const SchemeHold = () => {
  const dispatch = useDispatch();
  const {
    holdDataList,
    unHoldDataList,
    loadingGetHoldList,
    loadingUnholdList,
  } = useSelector((state) => state.holdData);
  const { Option } = Select;

  const comments = useRef("");
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const [showExpiredWindow, setShowExpiredWindow] = useState(false);
  const [showHideBtn, setShowHideBtn] = useState(true);
  const [showUnHideBtn, setShowUnHideBtn] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdUnhold, setSelectedIdUnhold] = useState(null);
  const selectedIdIndex = useRef(null);
  // const [selectedIdIndex, setSelectedIdIndex] = useState(null)
  const [selectedHold, setSelectedHold] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModalUnhold, setConfirmationModalUnhold] = useState(false);
  const [collectionData, setCollectionData] = useState(InitCollectionData);

  const [startMonth, setStartMonth] = useState(dayjs());
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);
  const [selectedZH, setSelectedZH] = useState("");
  const [selectedAM, setSelectedAM] = useState("");
  const [stateHeadData, setStateHeadData] = useState([]);
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [stateListArr, setStateListArr] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysUnhold, setSelectedRowKeysUnhold] = useState([]);
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear =
    currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  const { userRole, userId, userName } =
    decryptData(sessionStorage.getItem("userInfo")) || {};
  const userData1 = decryptData(sessionStorage.getItem("userInfo")) || {};
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [schemeIdNoUnhold, setSchemeIdNoUnhold] = useState([]);
  const schemeSelected =
    collectionData?.schemeType == 3 ||
    collectionData?.schemeType == 8 ||
    collectionData?.schemeType == 4 ||
    collectionData?.schemeType == 5
      ? true
      : false;
  const subColType = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            resetScheme();
            handleReset();
            setCollectionData({
              ...collectionData,
              schemeType: 1,
              pageNumber: 1,
            });
          }}
        >
          With Delivery Plan
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            resetScheme();
            handleReset();
            setCollectionData({
              ...collectionData,
              schemeType: 2,
              pageNumber: 1,
            });
          }}
        >
          Without Delivery Plan
        </a>
      ),
      disabled: false,
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            resetScheme();
            handleReset();
            setCollectionData({
              ...collectionData,
              schemeType: 3,
              pageNumber: 1,
            });
          }}
        >
          Slab Based
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          onClick={() => {
            resetScheme();
            handleReset();
            setCollectionData({
              ...collectionData,
              schemeType: 8,
              pageNumber: 1,
            });
          }}
        >
          Single Target
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          onClick={() => {
            resetScheme();
            handleReset();
            setCollectionData({
              ...collectionData,
              schemeType: 4,
              pageNumber: 1,
            });
          }}
        >
          Single Target With Upper Cap
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a
          onClick={() => {
            resetScheme();
            handleReset();
            setCollectionData({
              ...collectionData,
              schemeType: 5,
              pageNumber: 1,
            });
          }}
        >
          Unconditional Installation
        </a>
      ),
    },
  ];

  const getApiData = () => {
    let lastTwoDigits = dateValue % 100;

    const payload = {
      month: collectionData?.month
        ? new Date(collectionData?.month)?.getMonth() + 1
        : collectionData?.month,
      monthEnd: collectionData?.monthEnd
        ? new Date(collectionData?.monthEnd)?.getMonth() + 1
        : collectionData?.monthEnd,
      // month: new Date(collectionData?.month)?.getMonth() + 1,
      // monthEnd: new Date(collectionData?.monthEnd)?.getMonth() + 1,
      userid: userId,
      stateId: selectedStateId,
      stateHeadId: getShId(),
      areaManagerId: getAmId(),
      zonalHeadId: getZhId(),
      enforcedMonth: collectionData?.currentMonth || 0,
      userRole: userRole,
      fyear: "FY" + lastTwoDigits,
      schemeType: collectionData?.schemeType,
      state: selectedStateId,
      dealerLocation: collectionData?.dealerLocation,
      schemeNumber: collectionData?.schemeNumber,
      dealerCode: collectionData?.dealerCode,
      pageSize: collectionData?.pageSize,
      pageNumber: collectionData?.pageNumber,
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "TerritoryManager",
    };

    dispatch(getApprovedScheme(payload));
    dispatch(
      getUnholdScheme({
        ...payload,
        pageSize: collectionData?.holdPageSize,
        pageNumber: collectionData?.holdPageNumber,
      })
    );
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      getApiData();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    collectionData,
    selectedZHID,
    selectedAMID,
    selectedSHID,
    selectedStateId,
  ]);

  const [monthlyFilters, setMonthlyFilters] = useState({
    monthStart: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
    enforcedMonth: 0,
  });

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  useEffect(() => {
    if (selectedSHID == 0) {
      dispatch(getStateListApi());
      return;
    }
    if (userRole == "NSH") {
      dispatch(getStateListApi());
    }
  }, [selectedSHID]);

  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);

  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    if (selectedAm?.am) {
      setSelectedAM(selectedAm.am);
    }
    // console.log(selectedAm,"selectedAmselectedAm")
    // getDealerDropdownData(parseInt(event.key), "amID");
    // if (type === "amID") {
    setCollectionData({
      ...collectionData,
      amID: userId,
      pageNumber: 1,
    });
    // }
  };

  const getZhId = () => {
    if (userRole == "ZADM") {
      return userId;
    }
    if (userRole == "NSH") {
      return selectedZHID;
    }
  };

  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "NSH") {
      return selectedSHID;
    }
  };

  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "NSH") {
      return selectedAMID;
    }
  };
  const getZH = () => {
    if (userRole == "ZADM") {
      return userName;
    }
    if (userRole == "NSH") {
      return selectedZH == "" ? "All Zonal Head" : selectedZH;
    }
    return selectedZH == "All Zonal Head" ? "All Zonal Head" : selectedZH;
  };

  const getSH = () => {
    // if(userRole == 'ZADM') {
    //   return userName;
    // };
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "NSH") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };

  const getAM = () => {
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "NSH") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };

  const currentFiscalYear = getCurrentFiscalYear();

  useEffect(() => {
    if (userRole == "NSH") {
      axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
        setZonalHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedZH("All Zonal Head");
                  setStateHeadData([]);
                  setSelectedZHID(0);
                  setSelectedSHID(0);
                  setSelectedSH("All State Heads");
                  setAreaMngrData([]);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                }}
              >
                All Zonal Head
              </span>
            ),
          },
          ...result.data.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedZH(item.zonalHeadName);
                    setSelectedZHID(item.id);
                    setSelectedSHID(0);
                    setSelectedSH("All State Heads");
                    getShDropdownData(item.id);
                    setAreaMngrData([]);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                  }}
                >
                  {item.zonalHeadName}
                </span>
              ),
            };
          }),
        ]);
      });

      if (["SADM"].includes(userRole)) {
        getStateByStateHeadId(userId);
      }
    }
  }, [userRole]);
  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        if (
          Array?.isArray(result?.data?.data) &&
          result?.data?.data?.length != 0
        ) {
          setStateHeadData([
            {
              key: "0",
              label: (
                <span
                  onClick={() => {
                    setSelectedSH("All State Heads");
                    setSelectedSHID(0);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    setAreaMngrData([]);
                    setCollectionData({ ...collectionData, pageNumber: 1 });
                  }}
                >
                  All State Heads
                </span>
              ),
            },
            ...(Array?.isArray(result?.data?.data) &&
              result?.data?.data?.map((item) => {
                return {
                  key: item.id,
                  label: (
                    <span
                      onClick={() => {
                        setSelectedSH(item.stateHeadName);
                        setSelectedSHID(item.id);
                        setSelectedAMID(0);
                        setSelectedAM("All Area Managers");
                        getAmDropdownData(item.id);
                        setCollectionData({ ...collectionData, pageNumber: 1 });
                      }}
                    >
                      {item.stateHeadName || item}
                    </span>
                  ),
                };
              })),
          ]);
        }
      });
  };
  const getStateByStateHeadId = (SHID) => {
    let lastTwoDigits = dateValue % 100;
    const payload = {
      fyear: "FY" + lastTwoDigits,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };
  const getStateByZonalHeadId = (ZHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;
        setStateListArr(data);
      });
  };
  useEffect(() => {
    if (userRole == "ZADM" || userRole == "NSH") {
      getShDropdownData(userId);
      // if (userRole == "SADM") {
      getAmDropdownData(userId);
      // }
    }
  }, [collectionData?.schemeType]);

  useEffect(() => {
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);


  const getAmDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownAM`, { userId: userId })
      .then((result) => {
        if (result?.data?.data) {
          setAreaDropdown(result?.data?.data);
          setAreaMngrData([
            {
              key: "0",
              label: (
                <span
                  onClick={() => {
                    setSelectedAM("All Area Managers");
                    setSelectedAMID(0);
                    setCollectionData({ ...collectionData, pageNumber: 1 });
                  }}
                >
                  All Area Managers
                </span>
              ),
            },
            ...result?.data?.data?.map((item) => {
              return {
                key: item.id,
                label: (
                  <span
                    onClick={() => {
                      setSelectedAMID(item.id);
                      setSelectedAM(item.aM);
                      setCollectionData({ ...collectionData, pageNumber: 1 });
                    }}
                  >
                    {item.aM}
                  </span>
                ),
              };
            }),
          ]);
        }
      });
  };

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };
  const handleBtn = (data, type) => {
    let checkExpiredWindow =
      userRole == "ZADM"
        ? getExpiredStatusHoldZADM()
        : userRole == "NSH"
        ? getExpiredStatusHoldNSH()
        : false;

    if (checkExpiredWindow) {
      setShowExpiredWindow(true);
    } else {
      // setShowExpiredWindow(true)
      setSelectedId(data?.schemeId);

      selectedIdIndex.current = data?.index || 0;
      setConfirmationModal(true);
    }
  };
  const handleBtnUnhold = (data) => {
    setSelectedIdUnhold(data?.schemeId);

    setConfirmationModalUnhold(true);
  };

  // holdDataList,loadingGetHoldList
  const handleConformationModal = (props) => {
    setSelectedId(null);
    selectedIdIndex.current = null;
    // setSelectedIdIndex(null)
    setSelectedIdUnhold(null);

    if (props == "hold") {
      setConfirmationModal(!confirmationModal);
    } else {
      setConfirmationModalUnhold(!confirmationModalUnhold);
    }

    submitData(props);
  };
  const resetScheme = () => {
    setSelectedHold(false);
    setSelectedRowKeys([]);
    setSelectedRowKeysUnhold([]);
    setSelectedId(null);
    setSchemeIdNo([]);
    setSelectedRowKeysUnhold([]);
    setSchemeIdNoUnhold([]);
    setConfirmationModal(false);
    setConfirmationModalUnhold(false);
  };

  const records = { records: [], unhold: [] };
  const formik = useFormik({
    initialValues: records,
  });

  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31);
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const columnsData = useMemo(() => {
    return formik?.setFieldValue(
      "records",
      holdDataList?.result?.approvedSchemeList?.map((data, index) => ({
        ...data,
        comments: "",
        userRole: userRole,
        userId: userId,
        index: index,
        key: index + 1,
      })) || []
    );
  }, [holdDataList?.result?.approvedSchemeList]);

  const column = useMemo(() => {
    return formik?.setFieldValue(
      "unhold",
      unHoldDataList?.modifiedholdSchemeList?.length != 0
        ? unHoldDataList?.modifiedholdSchemeList?.map((data, index) => ({
            ...data,
            comments: "",
            userRole: userRole,
            userId: userId,
            index: index,
            key: index + 1,
          }))
        : []
    );
  }, [unHoldDataList?.modifiedholdSchemeList]);
  const formatData = (x, fieldName) => {
    if (validationStatus(x)) {
      return `${x[fieldName] + ", "}${x[
        `modified${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`
      ]?.toString()}`;
    } else if (validationStatus1(x)) {
      return `${
        x[`history${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`] +  ", " }${x[fieldName]?.toString()}`;
    } else if (validationStatus2(x)) {
      return `${x[fieldName] + ", "}${x[
        `history${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`
      ]?.toString()}`;
    } else {
      return `<div>${x[fieldName] || ""}</div>`;
    }
  };

  const submitData = async (props) => {
    if (props == "hold") {
      const finalData = selectedId
        ? formik?.values?.records?.filter(
            (data, index) => selectedId == data?.schemeId
          )
        : schemeIdNo?.length != 0
        ? schemeIdNo
        : formik?.values?.records;
      const payload = finalData?.map((data) => ({
        SchemeId: data?.schemeId,
        SchemeNo: data?.schemeNo,
        SchemeTypeId: data?.schemeTypeId,
        DealerCode: data?.dealerCode,
        DealerId: data?.dealerId,
        DealerName: data?.dealerName,
        DealerLocation: data?.dealerLocation,
        HoldReason: data?.holdReason || comments.current,
        StartMonth: data?.startMonth,
        EndMonth: data?.endMonth,
        StateName: data?.stateName,
        UserName: userName,
        UserRole: userRole,
        HoldDate: data.holdDate,
        UserId: userId,
        HoldStatus: data?.holdStatus,
        SchemePlanId: data?.schemePlanId,
        DelayPlan: data?.delayPlan,
        Billing: data?.billing,
        Installation: data?.installation,
        Collection: data?.collection,
        UpperCap: data?.upperCap,
        InsPerTarget: data?.insPerTarget,
        InsTarget: data?.insTarget,
        OpeningStockDays: data?.openingStockDays,
        OutStandingDays: data?.outStandingDays,
        IsSap: data?.isSap,
        IsActive: data?.isActive,
        BookCollectionId: data?.bookCollectionId,
        T1: data?.t1,
        T2: data?.t2,
        T3: data?.t3,
        T4: data?.t4,
        T5: data?.t5,
        T6: data?.t6,
        T2t1: data?.t2_T1,
        T3t2: data?.t3_T2,
        T4t3: data?.t4_T3,
        T5t4: data?.t5_T4,
        T6t5: data?.t6_T5,
        TopTarget: data?.topTarget,
        ExpectedPayoutId: data?.expectedPayoutId,
        Volume: data?.volume,
        PerTr: data?.perTr,
        Amount: data?.amount,
        Remarks: data?.remarks,
        LastOneMonthSlabAchieved: data?.lastOneMonthSlabAchieved,
        LastMonthColQty: data?.lastMonthColQty,
        LastTwoMonthSlabAchieved: data?.lastOneMonthSlabAchieved,
        LastTwoMonthColQty: data?.lastMonthColQty,
        ActualInstallationLastYear: data?.actualInstallationLastYear,
        P1: data?.p1,
        P2: data?.p2,
        P3: data?.p3,
        P4: data?.p4,
        P5: data?.p5,
        P6: data?.p6,
        R1: data?.r1,
        R2: data?.r2,
        R3: data?.r3,
        R4: data?.r4,
        R5: data?.r5,
        R6: data?.r6,
        BglacsCurrentYear: data?.bglacsCurrentYear,
        OldBillingQty: data?.oldBillingQty,
        OldInstallation: data?.oldInstallation,
        OldOpeningStock: data?.oldOpeningStock,
        OldCollection: data?.oldCollection,
        ClosingOutStandingMonth1: data?.closingOutStandingMonth1,
        ClosingOutStandingMonth2: data?.closingOutStandingMonth2,
        ClosingOutStandingMonth3: data?.closingOutStandingMonth3,
        Industry: data?.industry,
        ClosingStock: data?.closingStock,
        ClosingOutStanding: data?.closingOutStanding,
        FYear: data?.fyear,
        TerritoryManager: data?.territoryManager,
      }));

      dispatch(postSaveHoldSchemeData(payload)).then((data) => {
        message.success({
          content: "Scheme Holded successfully",
          className: "custom-message",
        });
        getApiData();
        resetScheme();
      });
    } else {
      //t1,modifiedT1,historyT1

      // status:1?"t1:modifiedT1"
      //if hold and approved historyT1:t1
      //if rejected t1:historyT1

      const finalData = selectedIdUnhold
        ? formik?.values?.unhold?.filter(
            (data, index) => selectedIdUnhold == data?.schemeId
          )
        : schemeIdNoUnhold?.length != 0
        ? schemeIdNoUnhold
        : formik?.values?.unhold;

      const payload = finalData
        ?.filter((data) => userData1?.userRole == data?.holdByRole)
        .map((data) => ({
          SchemeId: data?.schemeId,
          SchemeNo: data?.schemeNo,
          SchemeTypeId: data?.schemeTypeId,
          DealerCode: data?.dealerCode,
          DealerId: data?.dealerId,
          DealerName: data?.dealerName,
          DealerLocation: data?.dealerLocation,
          HoldReason: data?.holdReason,
          StartMonth: data?.startMonth,
          EndMonth: data?.endMonth,
          StateName: data?.stateName,
          UserName: userName,
          UserRole: userRole,
          HoldDate: data.holdDate,
          UserId: userId,
          HoldStatus: data?.holdStatus,
          SchemePlanId: data?.schemePlanId,
          DelayPlan: data?.delayPlan,
          Billing: data?.billing,
          Installation: data?.installation,
          Collection: data?.collection,
          UpperCap: data?.upperCap,
          InsPerTarget: data?.insPerTarget,
          InsTarget: data?.insTarget,
          OpeningStockDays: data?.openingStockDays,
          OutStandingDays: data?.outStandingDays,
          IsSap: data?.isSap,
          IsActive: data?.isActive,
          BookCollectionId: data?.bookCollectionId,
          T1: data?.t1,
          T2: data?.t2,
          T3: data?.t3,
          T4: data?.t4,
          T5: data?.t5,
          T6: data?.t6,
          T2t1: data?.t2_T1,
          T3t2: data?.t3_T2,
          T4t3: data?.t4_T3,
          T5t4: data?.t5_T4,
          T6t5: data?.t6_T5,
          TopTarget: data?.topTarget,
          ExpectedPayoutId: data?.expectedPayoutId,
          Volume: data?.volume,
          PerTr: data?.perTr,
          Amount: data?.amount,
          Remarks: data?.remarks,
          LastOneMonthSlabAchieved: data?.lastOneMonthSlabAchieved,
          LastMonthColQty: data?.lastMonthColQty,
          LastTwoMonthSlabAchieved: data?.lastOneMonthSlabAchieved,
          LastTwoMonthColQty: data?.lastMonthColQty,
          ActualInstallationLastYear: data?.actualInstallationLastYear,
          P1: data?.p1,
          P2: data?.p2,
          P3: data?.p3,
          P4: data?.p4,
          P5: data?.p5,
          P6: data?.p6,
          R1: data?.r1,
          R2: data?.r2,
          R3: data?.r3,
          R4: data?.r4,
          R5: data?.r5,
          R6: data?.r6,
          BglacsCurrentYear: data?.bglacsCurrentYear,
          OldBillingQty: data?.oldBillingQty,
          OldInstallation: data?.oldInstallation,
          OldOpeningStock: data?.oldOpeningStock,
          OldCollection: data?.oldCollection,
          ClosingOutStandingMonth1: data?.closingOutStandingMonth1,
          ClosingOutStandingMonth2: data?.closingOutStandingMonth2,
          ClosingOutStandingMonth3: data?.closingOutStandingMonth3,
          Industry: data?.cndustry,
          ClosingStock: data?.closingStock,
          ClosingOutstanding: data?.closingOutstanding,
          FYear: data?.fyear,
          TerritoryManager: data?.territoryManager,
        }));

      if (payload?.length != 0) {
        dispatch(
          postUnHoldSchemeApi(payload.map((data) => data?.SchemeId)?.toString())
        ).then((data) => {
          message.success({
            content: "Scheme Unhold successfully",
            className: "custom-message",
          });
          getApiData();
          resetScheme();
        });
      }
    }
  };
  const handleUnholdDownload = (unholdData) => {
    if (Array?.isArray(unholdData) && unholdData?.length != 0) {
      const TotalStyle = {
        fill: {
          fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
        },
        font: {
          color: { rgb: "212121" }, // Black font color
          bold: true,
          sz: "12",
        },
      };

      if (collectionData?.schemeType == 5) {
        if (unholdData?.length == 0) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >CREATED BY</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          <th >MODIFIED REMARKS</th>
          </tr>
      </thead>`;

        unholdData?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${x?.createdBy}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
    <td>${x?.installationTarget}</td>
        <td>${formatData(x, "incPerTarget")}</td>
        <td>${formatData(x, "payout")}</td>
        <td>${x?.reason||''}</td>
        <td>${x?.modifiedReason || ""}</td>
    </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
        // const url = window.URL.createObjectURL(
        //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", );
        // document.body.appendChild(link);
        // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
      } else if (collectionData?.schemeType == 4) {
        if (unholdData?.length == 0) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >CREATED BY</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th >MODIFIED REASON</th>
        </tr>
    </thead>`;

        unholdData?.forEach((x) => {
          tab_text += `<tr ><tr ><td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${x?.createdBy}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${formatData(x, "installationTarget")}</td>
        <td>${x?.upperCap}</td>
        <td>${formatData(x, "incPerTarget")}</td>
        <td>${formatData(x, "payout")}</td>
        <td>${x?.reason||''}</td>
        <td>${x?.modifiedReason || ""}</td>
  </tr>`;
        });

        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to Total row color

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_With_Upper_Cap${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        );
      } else if (collectionData?.schemeType == 8) {
        if (unholdData?.length == 0) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >CREATED BY</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
                <th >MODIFIED REMARKS</th>
        </tr>
    </thead>`;

        unholdData?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
  <td>${x?.dealerCode}</td>
  <td>${x?.dealerName}</td>
  <td>${x?.stateName}</td>
  <td>${x?.dealerLocation}</td>
  <td>${x?.territoryManager}</td>
  <td>${x?.createdBy || ""}</td>
  <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
  <td>${x?.actual_Installation_Last_Year_Month}</td>
  <td>${formatData(x, "installationTarget")}</td>
  <td>${formatData(x, "incPerTarget")}</td>
  <td>${formatData(x, "payout")}</td>
  <td>${x?.reason||''}</td>
  <td>${x?.modifiedReason || ""}</td>
  </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to total row color

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        ); // const url = window.URL.createObjectURL(
        //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download",`Download_Installation_Single_Target-${new Date().toJSON().slice(0, 10)}.xlsx`);
        // document.body.appendChild(link);
      } else if (collectionData?.schemeType == 3) {
        if (unholdData?.length == 0) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }

        let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">CREATED BY</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
              <th rowspan="2">HOLD REASON</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;

        unholdData?.forEach((header) => {
          tab_text += `<tr ><td>${header?.schemeNo}</td>
  <td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
    <td>${header?.createdBy}</td>
  <td>${header?.topTarget}</td>
  <td>${header?.startMonth}</td>
  <td>${header?.endMonth}</td>
  <td>${header?.actual_Installation_Last_Year_Month}</td>
  <td>${header?.topTarget?.includes("t1") ? formatData(header, "t1") : 0}</td>
  <td>${header?.topTarget?.includes("t2") ? formatData(header, "t2") : 0}</td>
  <td>${header?.topTarget?.includes("t3") ? formatData(header, "t3") : 0}</td>
  <td>${header?.topTarget?.includes("t1") ? formatData(header, "r1") : 0}</td>
  <td>${header?.topTarget?.includes("t2") ? formatData(header, "r2") : 0}</td>
  <td>${header?.topTarget?.includes("t3") ? formatData(header, "r3") : 0}</td>
  <td>${header?.topTarget?.includes("t1") ? formatData(header, "p1") : 0}</td>
  <td>${header?.topTarget?.includes("t2") ? formatData(header, "p2") : 0}</td>
  <td>${header?.topTarget?.includes("t3") ? formatData(header, "p3") : 0}</td>
  <td>${header?.reason||''}</td>
    <td>${header?.modifiedReason}</td>
  </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to Roral row
        const allColumns = unholdData.reduce((columns, item) => {
          Object.keys(item).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key);
            }
          });
          return columns;
        }, []);

        function getAlphabetEquivalent(number) {
          if (number < 1 || number > 26) {
            return "Invalid input";
          }
          return String.fromCharCode(number + 64);
        }

        let excelArr = [];
        for (let index = 0; index < allColumns.length; index++) {
          let ele = `${getAlphabetEquivalent(index + 1)}${(
            unholdData.length + 3
          ).toString()}`;
          excelArr.push(ele);
        }

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
        // const url = window.URL.createObjectURL(
        // new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `Slab_based_scheme.xlsx`);
        // document.body.appendChild(link);
        // link.click();
      } else if ([1, 2].includes(collectionData?.schemeType)) {
        if (unholdData?.length == 0 || !unholdData) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }

        let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
                <th rowspan="2">CREATED BY</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">Industry</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
        <th colspan="6">PAYOUT RATE</th>
             <th colspan="6">PAYOUT</th>
        <th rowspan="2">REMARKS</th>
           <th rowspan="2">HOLD REASON</th>
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        </tr>
    <tr>
       <th>NOS</th>
       <th>Days</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
       
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th> 
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>  
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>  
      </tr>
    </thead>`;

        // <td>${
        //   x?.topTarget
        //     ?.split(",")
        //     [x?.topTarget?.split(",")?.length - 1].toUpperCase() || "t1".toUpperCase()
        // }</td>
        unholdData?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
  <td>${x?.dealerCode}</td>
  <td>${x?.dealerLocation}</td>
  <td>${x?.dealerName}</td>
  <td>${x?.stateName}</td>
  <td>${x?.territoryManager}</td>
 
    <td>${formatData(x, "topTarget") }</td>
  <td>${x?.createdBy || ""}</td>
  <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
  <td>${formatData(x, "industry")}</td>
  <td>${x?.oldBillingQty}</td>
  <td>${x?.oldInstallation}</td>
  <td>${x?.oldCollection || 0}</td>
  <td>${x?.oldOpeningStock}</td>
  <td>${x?.openingStockDays}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_3)}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_2)}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_1)}</td>
  <td>${x?.outStandingDays}</td>
  <td>${x?.bGLacsCurrentYear || 0}</td>
  <td>${x?.delayPlan}</td>
  <td>${formatData(x, "billing")}</td>
  <td>${formatData(x, "installation") || ""}</td>
  <td>${formatData(x, "collection") || ""}</td>
  <td>${formatData(x, "closingStock") || ""}</td>
  <td>${formatData(x, "closingOutStanding") || ""}</td>
  <td>${formatData(x, "t1") || 0}</td>
  <td>${formatData(x, "t2") || 0}</td>
  <td>${x?.topTarget?.includes("t2") ? formatData(x, "t2_T1") : 0}</td>
  <td>${formatData(x, "t3") || 0}</td>
  <td>${x?.topTarget?.includes("t3") ? formatData(x, "t3_T2") : 0}</td>
 
  <td>${ formatData(x, "t4") || 0}</td>
  <td>${x?.topTarget?.includes("t4") ? formatData(x, "t4_T3") : 0}</td>
  <td>${ formatData(x, "t5") || 0}</td>
  <td>${x?.topTarget?.includes("t5") ? formatData(x, "t5_T4") : 0}</td>
  <td>${ formatData(x, "t6") || 0}</td>
  <td>${x?.topTarget?.includes("t6") ? formatData(x, "t6_T5") : 0}</td>
  <td>${formatData(x, "r1")}</td>
  <td>${ formatData(x, "r2") ||  0}</td>
  <td>${ formatData(x, "r3") || 0}</td>
  <td>${ formatData(x, "r4") || 0}</td>
  <td>${formatData(x, "r5") || 0}</td>
  <td>${formatData(x, "r6") || 0}</td>
  <td>${formatData(x, "p1")}</td>
  <td>${ formatData(x, "p2") || 0}</td>
  <td>${ formatData(x, "p3") || 0}</td>
  <td>${formatData(x, "p4") || 0}</td>
  <td>${ formatData(x, "p5") || 0}</td>
  <td>${ formatData(x, "p6") || 0}</td>
  <td>${x?.reason||''}</td>
  <td>${x?.modifiedReason || ""}</td>
  <td>${formatData(x, "volume")}</td>
  <td>${formatData(x, "perTr")}</td>
  <td>${formatData(x, "amount")}</td>

  </tr>`;
        });

        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        

        // Add style to total row color
        const allColumns = unholdData.reduce((columns, item) => {
          Object.keys(item).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key);
            }
          });
          return columns;
        }, []);

        function getAlphabetEquivalent(number) {
          if (number < 1) {
            return "Invalid input";
          } else if (number <= 26) {
            return String.fromCharCode(number + 64);
          } else {
            let firstChar = String.fromCharCode((number - 1) / 26 + 64);
            let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
            return firstChar + secondChar;
          }
        }

        let excelArr = [];
        for (let index = 0; index < allColumns.length; index++) {
          let ele = `${getAlphabetEquivalent(index + 1)}${(
            unholdData.length + 3
          ).toString()}`;
          excelArr.push(ele);
        }
        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });
        let nameFile =
          collectionData?.schemeType == 1
            ? "With_DeliveryPlan.xlsx"
            : "Without_Delivery_Plan.xlsx";
        // generate file
        XLSX.writeFile(wb, nameFile);
      }
    }
  };
  const handleHoldDownload = (holdData) => {
    if (Array?.isArray(holdData) && holdData?.length != 0) {
      const TotalStyle = {
        fill: {
          fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
        },
        font: {
          color: { rgb: "212121" }, // Black font color
          bold: true,
          sz: "12",
        },
      };

      if (collectionData?.schemeType == 5) {
        if (holdData?.length == 0 || !holdData) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >CREATED BY</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          <th >MODIFIED REMARKS</th>
          </tr>
      </thead>`;

        holdData?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${x?.createdBy}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
    <td>${x?.installationTarget}</td>
        <td>${x?.incPerTarget}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason||''}</td>
        <td>${x?.modifiedReason || ""}</td>
    </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
        // const url = window.URL.createObjectURL(
        //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", );
        // document.body.appendChild(link);
        // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
      } else if (collectionData?.schemeType == 4) {
        if (holdData?.length == 0 || !holdData) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >CREATED BY</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th >MODIFIED REASON</th>
        </tr>
    </thead>`;

        holdData?.forEach((x) => {
          tab_text += `<tr ><tr ><td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${x?.createdBy}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.upperCap}</td>
        <td>${x?.incPerTarget}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason||''}</td>
        <td>${x?.modifiedReason || ""}</td>
  </tr>`;
        });

        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to Total row color

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_With_Upper_Cap${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        );
      } else if (collectionData?.schemeType == 8) {
        if (holdData?.length == 0 || !holdData) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >CREATED BY</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
                <th >MODIFIED REMARKS</th>
        </tr>
    </thead>`;

        holdData?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
  <td>${x?.dealerCode}</td>
  <td>${x?.dealerName}</td>
  <td>${x?.stateName}</td>
  <td>${x?.dealerLocation}</td>
  <td>${x?.territoryManager}</td>
  <td>${x?.createdBy || ""}</td>
  <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
  <td>${x?.actual_Installation_Last_Year_Month}</td>
  <td>${x?.installationTarget}</td>
  <td>${x?.incPerTarget}</td>
  <td>${x?.payout}</td>
  <td>${x?.reason||''}</td>
  <td>${x?.modifiedReason || ""}</td>
  </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to total row color

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        ); // const url = window.URL.createObjectURL(
        //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download",`Download_Installation_Single_Target-${new Date().toJSON().slice(0, 10)}.xlsx`);
        // document.body.appendChild(link);
      } else if (collectionData?.schemeType == 3) {
        if (holdData?.length == 0 || !holdData) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }

        let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">CREATED BY</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
             
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;

        holdData?.forEach((header) => {
          tab_text += `<tr ><td>${header?.schemeNo}</td>
  <td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
    <td>${header?.createdBy}</td>
    <td>${header?.topTarget }</td>
  <td>${header?.startMonth}</td>
  <td>${header?.endMonth}</td>
  <td>${header?.actual_Installation_Last_Year_Month}</td>
  <td>${header?.topTarget?.includes("t1") ? header?.t1 : 0}</td>
  <td>${header?.topTarget?.includes("t2") ? header?.t2 : 0}</td>
  <td>${header?.topTarget?.includes("t3") ? header?.t3 : 0}</td>
  <td>${header?.topTarget?.includes("t1") ? header?.r1 : 0}</td>
  <td>${header?.topTarget?.includes("t2") ? header?.r2 : 0}</td>
  <td>${header?.topTarget?.includes("t3") ? header?.r3 : 0}</td>
  <td>${header?.topTarget?.includes("t1") ? header?.p1 : 0}</td>
  <td>${header?.topTarget?.includes("t2") ? header?.p2 : 0}</td>
  <td>${header?.topTarget?.includes("t3") ? header?.p3 : 0}</td>
  <td>${header?.reason || ""}</td>
   
  </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to Roral row
        const allColumns = holdData.reduce((columns, item) => {
          Object.keys(item).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key);
            }
          });
          return columns;
        }, []);

        function getAlphabetEquivalent(number) {
          if (number < 1 || number > 26) {
            return "Invalid input";
          }
          return String.fromCharCode(number + 64);
        }

        let excelArr = [];
        for (let index = 0; index < allColumns.length; index++) {
          let ele = `${getAlphabetEquivalent(index + 1)}${(
            holdData.length + 3
          ).toString()}`;
          excelArr.push(ele);
        }

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
        // const url = window.URL.createObjectURL(
        // new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `Slab_based_scheme.xlsx`);
        // document.body.appendChild(link);
        // link.click();
      } else if ([1, 2].includes(collectionData?.schemeType)) {

        if (holdData?.length == 0 || !holdData) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }

        let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
                <th rowspan="2">CREATED BY</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">Industry</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
        <th colspan="6">PAYOUT RATE</th>
             <th colspan="6">PAYOUT</th>
        <th rowspan="2">REMARKS</th>
       
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        </tr>
    <tr>
       <th>NOS</th>
       <th>Days</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
       
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th> 
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>  
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>  
      </tr>
    </thead>`;

        // <td>${
        //   x?.topTarget
        //     ?.split(",")
        //     [x?.topTarget?.split(",")?.length - 1].toUpperCase() || "t1".toUpperCase()
        // }</td>
        holdData?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
  <td>${x?.dealerCode}</td>
  <td>${x?.dealerLocation}</td>
  <td>${x?.dealerName}</td>
  <td>${x?.stateName}</td>
  <td>${x?.territoryManager}</td>
 
  <td>${x?.topTarget }</td>
  <td>${x?.createdBy || ""}</td>
  <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
  <td>${x?.industry}</td>
  <td>${x?.oldBillingQty}</td>
  <td>${x?.oldInstallation}</td>
  <td>${x?.oldCollection || 0}</td>
  <td>${x?.oldOpeningStock}</td>
  <td>${x?.openingStockDays}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_3)}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_2)}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_1)}</td>
  <td>${x?.outStandingDays}</td>
  <td>${x?.bGLacsCurrentYear || 0}</td>
  <td>${x?.delayPlan}</td>
  <td>${x?.billing}</td>
  <td>${x?.installation || ""}</td>
  <td>${x?.collection || ""}</td>
  <td>${x?.closingStock || ""}</td>
  <td>${x?.closingOutStanding || ""}</td>
  <td>${x?.t1}</td>
  <td>${x?.t2}</td>
  <td>${x?.topTarget?.includes("t2") ? x?.t2_T1 : 0}</td>
  <td>${x?.topTarget?.includes("t2") ? x?.t3 : 0}</td>
  <td>${x?.topTarget?.includes("t3") ? x?.t3_T2 : 0}</td>

  <td>${x?.topTarget?.includes("t4") ? x?.t4 : 0}</td>
  <td>${x?.topTarget?.includes("t4") ? x?.t4_T3 : 0}</td>
  <td>${x?.topTarget?.includes("t5") ? x?.t5 : 0}</td>
  <td>${x?.topTarget?.includes("t5") ? x?.t5_T4 : 0}</td>
  <td>${x?.topTarget?.includes("t6") ? x?.t6 : 0}</td>
  <td>${x?.topTarget?.includes("t6") ? x?.t6_T5 : 0}</td>
  <td>${x?.r1}</td>
  <td>${x?.topTarget?.includes("t2") ? x?.r2 : 0}</td>
  <td>${x?.topTarget?.includes("t3") ? x?.r3 : 0}</td>
  <td>${x?.topTarget?.includes("t4") ? x?.r4 : 0}</td>
  <td>${x?.topTarget?.includes("t5") ? x?.r5 : 0}</td>
  <td>${x?.topTarget?.includes("t6") ? x?.r6 : 0}</td>
  <td>${x?.p1}</td>
  <td>${x?.topTarget?.includes("t2") ? x?.p2 : 0}</td>
  <td>${x?.topTarget?.includes("t3") ? x?.p3 : 0}</td>
  <td>${x?.topTarget?.includes("t4") ? x?.p4 : 0}</td>
  <td>${x?.topTarget?.includes("t5") ? x?.p5 : 0}</td>
  <td>${x?.topTarget?.includes("t6") ? x?.p6 : 0}</td>
  <td>${x?.reason||''}</td>

  <td>${x?.volume}</td>
  <td>${x?.perTr}</td>
  <td>${x?.amount}</td>
  </tr>`;
        });

        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);

        // Add style to total row color
        const allColumns = holdData.reduce((columns, item) => {
          Object.keys(item).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key);
            }
          });
          return columns;
        }, []);

        function getAlphabetEquivalent(number) {
          if (number < 1) {
            return "Invalid input";
          } else if (number <= 26) {
            return String.fromCharCode(number + 64);
          } else {
            let firstChar = String.fromCharCode((number - 1) / 26 + 64);
            let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
            return firstChar + secondChar;
          }
        }

        let excelArr = [];
        for (let index = 0; index < allColumns.length; index++) {
          let ele = `${getAlphabetEquivalent(index + 1)}${(
            holdData.length + 3
          ).toString()}`;
          excelArr.push(ele);
        }
        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

          // wb.Sheets[wb.SheetNames[0]]["!cols"] = [
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 },
          //   { wch: 15 }, 
          //   { wch: 15 },
          //   { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 25 }, { wch: 15 }, { wch: 15 }, { wch: 15 },
          // ];
      
        let nameFile =
          collectionData?.schemeType == 1
            ? "With_DeliveryPlan.xlsx"
            : "Without_Delivery_Plan.xlsx";
        // generate file
        XLSX.writeFile(wb, nameFile);
      }
    }
  };

  const handleReset = () => {
    setCollectionData({
      ...collectionData,
      currentMonth: "",
      startMonth: 0,
      endMonth: 0,
      schemeNumber: "",
      dealerLocation: "",
      dealerCode: "",
    });

    setStateHeadData([]);
    if (userRole == "NSH") {
      setSelectedZH("All Zonal Head");
      setSelectedZHID(0);
    }
    resetScheme();
    setSelectedStateId(0);
    setSelectedSHID(0);
    setSelectedSH("All State Heads");
    setAreaMngrData([]);
    setSelectedAMID(0);
    setSelectedAM("All Area Managers");
    setStartMonth(dayjs());
    setEnforcedMonth();
    if (userRole == "ZADM" || userRole == "NSH") {
      getShDropdownData(userId);
      // if (userRole == "SADM") {
      getAmDropdownData(userId);
      // }
    }
  };

  const items = [
    {
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => {
            const checkExpiredWindow =
              userRole == "ZADM"
                ? getExpiredStatusHoldZADM()
                : userRole == "NSH"
                ? getExpiredStatusHoldNSH()
                : false;
                 if(selectedRowKeys?.length==0||!selectedRowKeys){
                  ToastifyShow("Please check the checkbox","error")
                  return
                }
            if (checkExpiredWindow) {
              setShowExpiredWindow(true);
            } else {
              setSelectedHold(true);
              setConfirmationModal(true);
            }
          }}
        >
          {"Hold Selected Scheme"}
        </Button>
      ),
      key: "1",
      icon: <SchemaRounded />,
    },
    {
      label: (
        <Button className="link" type="link" onClick={() => handleReset()}>
          Reset Filters
        </Button>
      ),
      key: "2",
      icon: <Filter />,
    },
  ];

  const titleTextUnhold = useMemo(
    () =>
      selectedHold
        ? "Do you want to Unhold the selected scheme?"
        : "Do you want to Unhold this scheme?",
    [selectedHold]
  );

  return (
    <div>
      <div
        style={{ marginTop: "18px" }}
        id="space"
        className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header flex-wrap "
      >
        <Col className="pb-3">
        <Tooltip title={"Scheme Type"}>
          <Dropdown menu={{ items: subColType }}>
            <Button>
              <Space>
                {getSchemeType(collectionData?.schemeType)}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          </Tooltip>
        </Col>
        <div className="marginauto pb-3">
        <Tooltip title={"Financial Year"}>
          <FinancialYearButton
            handleDate={(x) => {
              setDateValue(x);
            }}
          />
          </Tooltip>
        </div>
        <div className="marginauto pb-3">
        <Tooltip title={"Running Month"}>
          <DatePicker
            // onChange={(date) => handleInputChange('enforcedMonth', date)}
            onChange={(date, formatDate) => {
              setEnforcedMonth(date);
              if (formatDate == "") {
                setCollectionData({
                  ...collectionData,
                  currentMonth: 0,
                  pageNumber: 1,
                });
                return;
              }
              setCollectionData({
                ...collectionData,
                currentMonth: new Date(formatDate).getMonth() + 1,
                pageNumber: 1,
              });
            }}
            className="start"
            placeholder="Running Month"
            picker="month"
            value={enforcedMonth}
            allowClear
            disabled={
              collectionData.startMonth != "" || collectionData.endMonth != ""
            }
            // disabledDate={disabledDate}
          />
          </Tooltip>
        </div>  
        <div>
        <Tooltip title={"Start Month     End Month"}>
          <RangePicker
            mode={["month", "month"]}
            dateValue={dateValue}
            picker="month"
            disabledDate={disabledDate}
            onChange={(date) => {
              setCollectionData((collectionData) => ({
                ...collectionData,
                startMonth: date && date[0] ? date[0].format() : 0,
                endMonth: date && date[1] ? date[1].format() : 0,
                pageNumber: 1,
              }));
              setStartMonth(date);
            }}
            onPanelChange={handlePanelChange}
            value={startMonth}
            style={{ width: 235, height: 44 }}
            disabled={
              collectionData.currentMonth != "" 
            }
            //   disabledDate={disabledDate}
          />
          </Tooltip>
        </div>
        <Col className=" mx-1 pb-3">
        <Tooltip title={"Zonal Head"}>
          <Dropdown
            menu={{ items: zonalHeadData }}
            disabled={["ZADM", "SADM", "AMGR"].includes(userRole)}
          >
            <Button>
              <Space>
                {getZH()}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          </Tooltip>
        </Col>
        {/* <div onClick={handleDownload}>
      <Button disabled={dealersList?.data?.collectionList?.length == 0} ><DownloadOutlined /></Button>
    </div> */}

        {true && (
          <Col className="pb-3">
                    <Tooltip title={"State Head"}>
            <Dropdown
              menu={{ items: stateHeadData }}
              disabled={["SADM", "AMGR"].includes(userRole)}
            >
              <Button>
                <Space>
                  {getSH()}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown></Tooltip>
          </Col>
        )}

        {true && (
          <Col className="pb-3">
                      <Tooltip title={"Area Manager"}>
            <Dropdown
              menu={{ items: areaMngrData, onClick: handleAMClick }}
              disabled={["AMGR"].includes(userRole)}
            >
              <Button>
                <Space>
                  {getAM()}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown></Tooltip>
          </Col>
        )}

<Tooltip title={"Select State"}>
        <Select
          showSearch
          className="select_state heightauto pb-3"
          placeholder="Select State"
          optionFilterProp="children"
          onSelect={handleStateSelect}
          value={selectedStateId === 0 
            ? 0 : stateListArr.find(option => option.stateID === selectedStateId)?.stateName}
        >
          <Option key={0} value={0}>
            All States
          </Option>
          {stateListArr?.map((option) => (
            <Option key={option.stateID} value={option.stateName}>
              {option.stateName}
            </Option>
          ))}
        </Select>
        </Tooltip>
        <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search   pb-3">
          <Input
            placeholder="Scheme No."
            value={collectionData.schemeNumber}
            className="h-42 "
            onChange={(e) =>
              setCollectionData({
                ...collectionData,
                schemeNumber: e.target.value,
                pageNumber: 1,
              })
            }
          />
        </div>
        <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
          <Input
            placeholder="Dealer Code"
            value={collectionData.dealerCode}
            className="h-42 "
            onChange={(e) =>
              setCollectionData({
                ...collectionData,
                dealerCode: e.target.value,
                pageNumber: 1,
              })
            }
          />
        </div>
        <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
          <Input
            placeholder="Dealer Location"
            value={collectionData.dealerLocation}
            className="h-42 "
            onChange={(e) =>
              setCollectionData({
                ...collectionData,
                dealerLocation: e.target.value,
                pageNumber: 1,
              })
            }
          />
        </div>
        <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
          <Dropdown menu={{ items: items }}>
            <Button className="h-45">
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center "></div>
      </div>

      <div className="pt-3">
        <div className="d-flex justify-content-between">
          <h3 className="pt-3">Hold Schemes</h3>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center ">
              <Switch
                checked={showHideBtn}
                onChange={() => setShowHideBtn(!showHideBtn)}
                checkedChildren="Hide Hold Details"
                unCheckedChildren="Show Hold Details"
              />
            </div>
            <Button
              type="primary"
              onClick={() => handleHoldDownload(formik?.values?.records)}
              className="w-270 ml-2"
            >
              Download Schemes
            </Button>
          </div>
        </div>
        {showHideBtn && (
          <AntdTable
            columns={
              schemeSelected
                ? [
                    ...tableInstallationViewOnly({
                      showCreated: false,
                      showRemark: true,
                      showCreatedBy: true,
                      selectedSchemeType: collectionData?.schemeType,
                    }),
                    ...actionMergeColumnBtn("Hold", handleBtn),
                  ]
                : [
                    ...TableCollectionViewOnly({
                      showCreated: false,
                      showRemark: true,
                      showCreatedBy: true,
                    }),
                    ...actionMergeColumnBtn("Hold", handleBtn),
                  ]
            }
           
            dataSource={formik?.values?.records || []}
            loading={loadingGetHoldList == "pending" ? true : false}
            showPagination={true}
            currentPage={collectionData?.pageNumber}
            handlePaginationChange={(pageNumber, pageSize) => {
              setCollectionData({
                ...collectionData,
                pageNumber: pageNumber,
                pageSize: pageSize,
              });
            }}
            schemeType={collectionData?.schemeType}
            tableStyle={"px-4 py-3 bg-white h-100 shadow-lg mt-3 overflow"}
            tableSortingChange={(pageNumber, pageSize, sorter) => {
              setCollectionData({
                ...collectionData,
                sortingOrder: sorter?.order === "ascend" ? "ascend" : "descend",
                sortingType: sorter?.columnKey || "TerritoryManager",
              });
            }}
            hideBtn={true}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys,
              onChange: (newSelectedRowKeys, selectedRow) => {
                setSelectedId(null);
                selectedIdIndex.current = null;
                setSelectedRowKeys(newSelectedRowKeys);
                setSchemeIdNo(selectedRow);
              },
            }}
            footerVisible={true}
            totalPaginationPage={holdDataList?.result?.totalCount}
            pageSize={collectionData?.pageSize}
            showSchemeStatus={false}
            colorBgContainer
            showFloatButton={true}
            // selectedRowKeys={selectedRowKeys}
            // setSelectedHold={() => {
            //   setSelectedHold(true);
            //   setConfirmationModal(true);
            // }}
          />
        )}

        <HoldModal
          handleCancel={() => {
            setConfirmationModal(false);
            setSelectedId(null);
            selectedIdIndex.current = null;
            resetScheme();
          }}
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          handleConformationModal={(value) => {
            const dataIndex =
              selectedIdIndex.current || selectedIdIndex.current == 0
                ? true
                : false;

            comments.current = value;
            handleConformationModal("hold");

            // if (selectedHold) {
            //   if (selectedRowKeys?.length != 0) {
            //     formik.setFieldValue(
            //       "records",
            //       formik?.values?.records?.map((data) => ({
            //         ...data,
            //         comments: value,
            //       }))
            //     );
            //     handleConformationModal("hold");
            //   } else {
            //     formik.setFieldValue(
            //       "records",
            //       formik?.values?.records?.map((data) => ({
            //         ...data,
            //         comments: value,
            //       }))
            //     );
            //     handleConformationModal("hold");
            //   }
            // } else if (dataIndex) {
            //   formik.setFieldValue(
            //     `records.[${selectedIdIndex.current}].comments`,
            //     value
            //   );
            //   console.log(selectedHold, "selectedHold", selectedIdIndex, value,dataIndex,formik?.values?.records[selectedIdIndex.current]);
            //   handleConformationModal("hold");
            // }
          }}
          modalType={"hold"}
          selectedHold={selectedHold}
          selectedId={selectedId}
          formik={formik}
          editIndex={selectedIdIndex.current}
          selectedRowKeys={selectedRowKeys}
          setSelectedId={setSelectedId}
        />
      </div>

      <div className="pt-3">
        <div className="pt-3 d-flex justify-content-between">
          <h3 className="pt-3">Unhold Schemes</h3>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center ">
              <Switch
                checked={showUnHideBtn}
                className="mr-2"
                onChange={() => setShowUnHideBtn(!showUnHideBtn)}
                checkedChildren="Hide UnHold Details"
                unCheckedChildren="Show UnHold Details"
              />
            </div>
            <Button
              type="primary"
              onClick={() => handleUnholdDownload(formik?.values?.unhold)}
              className="w-270"
            >
              Download Schemes
            </Button>
          </div>
        </div>
        {showUnHideBtn && (
          <AntdTable
            schemeType={collectionData?.schemeType}
            // columns={schemeSelected ? [...holdTableInstallationView({ showCreated: false, showRemark: true, showCreatedBy: true }), ...actionMergeColumnBtn("Hold", handleBtn)] : [...HoldTableCollectionView({ showCreated: false, showRemark: true, showCreatedBy: true }), ...actionMergeColumnBtn("Hold", handleBtn)]}
            columns={
              schemeSelected
                ? [
                    ...HoldTableInstallationView({
                      showCreated: false,
                      showRemark: true,
                      showCreatedBy: true,
                      showHoldComment: true,
                      selectedSchemeType: collectionData?.schemeType,
                    }),
                    ...actionMergeColumnBtn("Unhold", handleBtnUnhold),
                  ]
                : [
                    ...HoldTableCollectionView({
                      showCreated: false,
                      showRemark: true,
                      showCreatedBy: true,
                      showHoldComment: true,
                    }),
                    ...actionMergeColumnBtn("Unhold", handleBtnUnhold),
                  ]
            }
            dataSource={formik?.values?.unhold || []}
            loading={loadingUnholdList == "pending" ? true : false}
            showPagination={true}
            currentPage={collectionData?.pageNumber}
            handlePaginationChange={(pageNumber, pageSize) => {
              setCollectionData({
                ...collectionData,
                holdPageNumber: pageNumber,
                holdPageSize: pageSize,
              });
            }}
            tableStyle={"px-4 py-3 bg-white h-100 shadow-lg mt-3 overflow"}
            tableSortingChange={(pageNumber, pageSize, sorter) => {
              setCollectionData({
                ...collectionData,
                sortingOrder: sorter?.order === "ascend" ? "ascend" : "descend",
                sortingType: sorter?.columnKey || "TerritoryManager",
              });
            }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeysUnhold,
              onChange: (newSelectedRowKeys, selectedRow) => {
                setSelectedId(null);

                setSelectedRowKeysUnhold(newSelectedRowKeys);
                setSchemeIdNoUnhold(selectedRow);
              },

              getCheckboxProps: (record) => ({
                disabled: userData1?.userRole != record?.holdByRole,
              }),
            }}
            footerVisible={true}
            totalPaginationPage={unHoldDataList?.totalCount}
            pageSize={collectionData?.pageSize}
            showSchemeStatus={false}
            colorBgContainer
            showFloatButton={true}
            unhold={true}
            footer={()=>
              <LegendHold />
            }
            selectedRowKeys={selectedRowKeysUnhold}
            setSelectedHold={() => {

              setConfirmationModalUnhold(true);
            }}
          />
        )}

        <Modal
          title="Confirmation"
          open={confirmationModalUnhold}
          onCancel={() => handleConformationModal("unhold")}
          onOk={() => {
            setConfirmationModalUnhold(false);
            setSelectedIdUnhold(null);

            resetScheme();
          }}
        cancelText="Yes"
        okText="No"        maskClosable={false}
        destroyOnClose={true}

        >
          <p>{titleTextUnhold}</p>
        </Modal>

        <Modal
          title=""
          open={showExpiredWindow}
          onOk={() => setShowExpiredWindow(false)}
          onCancel={() => setShowExpiredWindow(false)}        maskClosable={false}
          destroyOnClose={true}
        >
          <Result
            status="403"
            title="403"
            subTitle="Sorry, time is up for the hold scheme "
          />
        </Modal>
      </div>
    </div>
  );
};

export default SchemeHold;
