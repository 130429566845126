import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import "./trackcollection.css";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getStateListApi } from "../../../redux/stateData/stateDataSlice";
import TrackCollectionTable from "./trackCollectionTable";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import {
  trackGetAreaManagerApi,
  trackGetStateHeadbyAMIdApi,
  trackGetZonalHeadbyAMIdApi,
  trackZonalHeadbySHIdApi,
} from "../../../redux/schemetrack/schemaTrackSlice";
import { getTrackCollectionDealersListApi } from "../../../redux/trackCollection/trackCollectionSlice";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";

import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import ToastifyShow from "../../../components/ToastifyShow";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import XLSX from "xlsx-js-style";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import MailPreviewModal from "../../../components/modal/alertModal/MailPreviewModal";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
const { RangePicker } = DatePicker;

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: 0,
  dealerCode: [],
  startMonth: 0,
  endMonth: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: "150",
  pageNumber: 1,
};

const TrackCollection = () => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const [collectionData, setCollectionData] = useState(InitCollectionData);

  const [openmailModal, setOpenMailModal] = useState(false);

  const [startMonth, setStartMonth] = useState(dayjs());
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const { loadingScheme } = useSelector((state) => state.trackCollection);
  // const {loadingState1} = useSelector((state) => state?.stateDataReducer)
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [selectedSchemeType, setSelectedSchemeType] = useState(0);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const [stateHeadData, setStateHeadData] = useState([]);
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [backendHtmlString, setBackendHtmlString] = useState('');
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);
  const [selectedZH, setSelectedZH] = useState("");
  const [selectedAM, setSelectedAM] = useState("");
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sendPayload, setSendPayload] = useState({});
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const getCol = (item) => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    return {
      SCHEME_NO: item.schemeNo,
      DEALER_CODE: item.dealerCode,
      DEALER_LOCATION: item.dealerLocation,
      DEALER_NAME: item.dealerName,
      STATE: item.stateName,
      TERRITORY_MANAGER: item.territoryManager,
      CREATED_BY: item.createdBy,
      START_MONTH:
        formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
      END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
      INDUSTRY: item.industry,
      B: item.oldBillingQty ? item.oldBillingQty : 0,
      I: item.oldInstallation || 0,
      C: item.oldCollection ? item.oldCollection : 0,
      OPENING_STOCK_NOS: item.oldOpeningStock,
      OPENING_STOCK_DAYS: item.openingStockDays,
      CLOSING_OS_IN_LAST_THIRD_MONTH: item.closingOutStandingMonth_3,
      CLOSING_OS_IN_LAST_TWO_MONTH: item.closingOutStandingMonth_2,
      CLOSING_OS_IN_LAST_ONE_MONTH: item.closingOutStandingMonth_1,
      CLOSING_OS_DAYS: item.outStandingDays,
      BG_LACS: item.bgLacsCurrentYear || item.bGLacsCurrentYear || 0,
      INSTALLATION_PLAN_NOS: item.delayPlan,
      BILLING_DETAILS_B: item.billing,
      BILLING_DETAILS_I: item.installation,
      BILLING_DETAILS_C: Math.round(item.collection),
      CLOSING_STOCK: item.closingStock,
      CLOSING_OUTSTANDING: item?.closingOutStanding,
      BOOKS_COLLECTION_TARGET_T1: item.t1,
      BOOKS_COLLECTION_TARGET_T2: item.t2,
      BOOKS_COLLECTION_TARGET_T2T1: item.t2_T1,
      BOOKS_COLLECTION_TARGET_T3: item.t3,
      BOOKS_COLLECTION_TARGET_T3T2: item.t3_T2,
      BOOKS_COLLECTION_TARGET_T4: item.t4,
      BOOKS_COLLECTION_TARGET_T4T3: item.t4_T3,
      BOOKS_COLLECTION_TARGET_T5: item.t5,
      BOOKS_COLLECTION_TARGET_T5T4: item.t5_T4,
      BOOKS_COLLECTION_TARGET_T6: item.t6,
      BOOKS_COLLECTION_TARGET_T6T5: item.t6_T5,
      PAYOUT_RATE_R1: item.r1,
      PAYOUT_RATE_R2: item.r2,
      PAYOUT_RATE_R3: item.r3,
      PAYOUT_RATE_R4: item.r4,
      PAYOUT_RATE_R5: item.r5,
      PAYOUT_RATE_R6: item.r6,
      REMARKS: item.reason,
      HOLDREMARKS: item.holdReason,
      PAYOUT_P1: item.p1,
      PAYOUT_P2: item.p2,
      PAYOUT_P3: item.p3,
      PAYOUT_P4: item.p4,
      PAYOUT_P5: item.p5,
      PAYOUT_P6: item.p6,
      TEP_VOL: item.volume,
      TEP_PER_TR: item.perTr,
      TEP_AMOUNT: item.amount,
      TOP_TARGET: item.topTarget,
    };
  };

  const handleMailModal = (record) => {
    setOpenMailModal(!openmailModal);

  };

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, 
      },
      font: {
        color: { rgb: "212121" }, 
        bold: true,
        sz: "12",
      },
    };
    const data =
      dealersList?.data?.collectionList &&
      dealersList?.data?.collectionList.map((item) => {
        return captalizeObjKeys(getCol(item));
      });
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }
    let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">CREATED BY</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">INDUSTRY</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        
        <th colspan="11">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
        <th colspan="6">PAYOUT RATE</th>
        <th colspan="6">PAYOUT</th>
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        <th rowspan="2">REMARKS</th>
        <th rowspan="2">HOLD REMARKS</th>

        </tr>
    <tr>
       <th>NOS</th>
       <th>DAYS</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH	</th>
       <th>LAST 1st MONTH</th>
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>

       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>

       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th>
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>
      </tr>
    </thead>`;

    data?.forEach((header) => {

      tab_text += `<tr ><td>${header?.SCHEME_NO}</td>
  <td>${header?.DEALER_CODE}</td>
  <td>${header?.DEALER_LOCATION}</td>
  <td>${header?.DEALER_NAME}</td>
  <td>${header?.STATE}</td>
  <td>${header?.TERRITORY_MANAGER}</td>
  <td>${header?.TOP_TARGET?.toUpperCase() || ""}</td>
  <td>${header?.CREATED_BY}</td>
  <td>${header?.START_MONTH}</td>
  <td>${header?.END_MONTH}</td>
  <td>${header?.INDUSTRY}</td>
  <td>${header?.B}</td>
  <td>${header?.I}</td>
  <td>${header?.C}</td>
  <td>${header?.OPENING_STOCK_NOS}</td>
  <td>${header?.OPENING_STOCK_DAYS}</td>
  <td>${header?.CLOSING_OS_IN_LAST_THIRD_MONTH}</td>
  <td>${header?.CLOSING_OS_IN_LAST_TWO_MONTH}</td>
  <td>${header?.CLOSING_OS_IN_LAST_ONE_MONTH}</td>
  <td>${header?.CLOSING_OS_DAYS}</td>
  <td>${header?.BG_LACS}</td>
  <td>${header?.INSTALLATION_PLAN_NOS}</td>
  <td>${header?.BILLING_DETAILS_B}</td>
  <td>${header?.BILLING_DETAILS_I}</td>
  <td>${header?.BILLING_DETAILS_C}</td>
  <td>${header?.CLOSING_STOCK}</td>
  <td>${header?.CLOSING_OUTSTANDING}</td>
  <td>${header?.BOOKS_COLLECTION_TARGET_T1}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T2}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T2T1 && header?.TOP_TARGET?.includes("t2") ? (header?.BOOKS_COLLECTION_TARGET_T2T1) : 0}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T3}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T3T2 && header?.TOP_TARGET?.includes("t3") ? (header?.BOOKS_COLLECTION_TARGET_T3T2) : 0}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T4}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T4T3 && header?.TOP_TARGET?.includes("t4") ? (header?.BOOKS_COLLECTION_TARGET_T4T3) : 0}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T5}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T5T4 && header?.TOP_TARGET?.includes("t5") ? (header?.BOOKS_COLLECTION_TARGET_T5T4) : 0}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T6}</td>
<td>${header?.BOOKS_COLLECTION_TARGET_T6T5 && header?.TOP_TARGET?.includes("t6") ? (header?.BOOKS_COLLECTION_TARGET_T6T5) : 0}</td>


  <td>${header?.PAYOUT_RATE_R1}</td>
  <td>${header?.PAYOUT_RATE_R2}</td>
  <td>${header?.PAYOUT_RATE_R3}</td>
  <td>${header?.PAYOUT_RATE_R4}</td>
  <td>${header?.PAYOUT_RATE_R5}</td>
  <td>${header?.PAYOUT_RATE_R6}</td>

  <td>${header?.PAYOUT_P1}</td>
  <td>${header?.PAYOUT_P2}</td>
  <td>${header?.PAYOUT_P3}</td>
  <td>${header?.PAYOUT_P4}</td>
  <td>${header?.PAYOUT_P5}</td>
  <td>${header?.PAYOUT_P6}</td>
  <td>${header?.TEP_VOL}</td>
  <td>${header?.TEP_PER_TR}</td>
  <td>${header?.TEP_AMOUNT}</td>
  <td>${header?.REMARKS}</td>
  <td>${header?.HOLDREMARKS}</td>

  </tr>`;
    });

    let totals = {
      INDUSTRY: 0,
      B: 0,
      I: 0,
      C: 0,
      OPENING_STOCK_NOS: 0,
      OPENING_STOCK_DAYS: 0,
      CLOSING_OS_IN_LAST_THIRD_MONTH: 0,
      CLOSING_OS_IN_LAST_TWO_MONTH: 0,
      CLOSING_OS_IN_LAST_ONE_MONTH: 0,
      CLOSING_OS_DAYS: 0,
      BG_LACS: 0,
      INSTALLATION_PLAN_NOS: 0,
      BILLING_DETAILS_B: 0,
      BILLING_DETAILS_I: 0,
      BILLING_DETAILS_C: 0,
      CLOSING_STOCK: 0,
      CLOSING_OUTSTANDING: 0,
      BOOKS_COLLECTION_TARGET_T1: 0,
      BOOKS_COLLECTION_TARGET_T2: 0,
      BOOKS_COLLECTION_TARGET_T2T1: 0,
      BOOKS_COLLECTION_TARGET_T3: 0,
      BOOKS_COLLECTION_TARGET_T3T2: 0,
      BOOKS_COLLECTION_TARGET_T4: 0,
      BOOKS_COLLECTION_TARGET_T4T3: 0,
      BOOKS_COLLECTION_TARGET_T5: 0,
      BOOKS_COLLECTION_TARGET_T5T4: 0,
      BOOKS_COLLECTION_TARGET_T6: 0,
      BOOKS_COLLECTION_TARGET_T6T5: 0,
      PAYOUT_RATE_R1: 0,
      PAYOUT_RATE_R2: 0,
      PAYOUT_RATE_R3: 0,
      PAYOUT_RATE_R4: 0,
      PAYOUT_RATE_R5: 0,
      PAYOUT_RATE_R6: 0,
      PAYOUT_P1: 0,
      PAYOUT_P2: 0,
      PAYOUT_P3: 0,
      PAYOUT_P4: 0,
      PAYOUT_P5: 0,
      PAYOUT_P6: 0,
      TEP_VOL: 0,
      TEP_PER_TR: 0,
      TEP_AMOUNT: 0,
      reason: "",
      holdReason:"",

    };



    data.forEach((item) => {
      for (let key in totals) {
        if (key === "BOOKS_COLLECTION_TARGET_T2T1" && item?.TOP_TARGET.includes("t2")) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (key === "BOOKS_COLLECTION_TARGET_T3T2" && item?.TOP_TARGET.includes("t3")) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (key === "BOOKS_COLLECTION_TARGET_T4T3" && item?.TOP_TARGET.includes("t4")) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (key === "BOOKS_COLLECTION_TARGET_T5T4" && item?.TOP_TARGET.includes("t5")) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (key === "BOOKS_COLLECTION_TARGET_T6T5" && item?.TOP_TARGET.includes("t6")) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (!["BOOKS_COLLECTION_TARGET_T2T1", "BOOKS_COLLECTION_TARGET_T3T2", "BOOKS_COLLECTION_TARGET_T4T3", "BOOKS_COLLECTION_TARGET_T5T4", "BOOKS_COLLECTION_TARGET_T6T5"].includes(key)) {
          totals[key] += parseInt(item[key]) || 0;
        }
      }
    });
    totals.PAYOUT_RATE_R1 = totals.BOOKS_COLLECTION_TARGET_T1 !== 0 ? parseInt(totals.PAYOUT_P1 / totals.BOOKS_COLLECTION_TARGET_T1) : 0;
    totals.PAYOUT_RATE_R2 = totals.BOOKS_COLLECTION_TARGET_T2 !== 0 ? parseInt(totals.PAYOUT_P2 / totals.BOOKS_COLLECTION_TARGET_T2) : 0;
    totals.PAYOUT_RATE_R3 = totals.BOOKS_COLLECTION_TARGET_T3 !== 0 ? parseInt(totals.PAYOUT_P3 / totals.BOOKS_COLLECTION_TARGET_T3) : 0;
    totals.PAYOUT_RATE_R4 = totals.BOOKS_COLLECTION_TARGET_T4 !== 0 ? parseInt(totals.PAYOUT_P4 / totals.BOOKS_COLLECTION_TARGET_T4) : 0;
    totals.PAYOUT_RATE_R5 = totals.BOOKS_COLLECTION_TARGET_T5 !== 0 ? parseInt(totals.PAYOUT_P5 / totals.BOOKS_COLLECTION_TARGET_T5) : 0;
    totals.PAYOUT_RATE_R6 = totals.BOOKS_COLLECTION_TARGET_T6 !== 0 ? parseInt(totals.PAYOUT_P6 / totals.BOOKS_COLLECTION_TARGET_T6) : 0;
    totals.TEP_PER_TR = totals.TEP_VOL !== 0 ? parseInt(totals.TEP_AMOUNT / totals.TEP_VOL) : 0;

    let totalsRow = "<tr><td colspan='10' >Total</td>";
    for (let key in totals) {
      if (key != 'reason' && key != 'CLOSING_STOCK' && key != 'CLOSING_OUTSTANDING'&& key != 'holdReason') {
        if (key.startsWith('PAYOUT_RATE') || key.startsWith('TEP_PER_TR') ) {
          totalsRow += `<td>${totals[key]}</td>`;
        } else {
          totalsRow += `<td>${totals[key]}</td>`;
        }
      } else {
        totalsRow += `<td></td>`;
      }
    }


    totalsRow += "</tr>";
    tab_text += totalsRow;
    tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);
    // Add style to total row color
    const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {
          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {

      if (number < 1) {
        return "Invalid input";
      } else if (number <= 26) {
        return String.fromCharCode(number + 64);
      } else {
        let firstChar = String.fromCharCode(((number - 1) / 26) + 64);
        let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
        return firstChar + secondChar;
      }
    }

    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 3).toString()}`
      excelArr.push(ele);

    }
    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

        let nameFile =
        data?.[0]?.type == 1
            ? "With_DeliveryPlan"
            : "Without_Delivery_Plan";
    // generate file
    XLSX.writeFile(
      wb,
      `Download_track_${nameFile}_${new Date().toJSON().slice(0, 10)}.xlsx`
    );
  };

  const handleClearFilter = () => {
    setCollectionData({ ...InitCollectionData });
    setEnforcedMonth(null);
    setStartMonth(null);
    setSelectedStatus(0);
    setSelectedStateId(0);
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      getAM(true);
      getSH(true);
      getZH(true);
      return;
    }
    if (userRole == "ZADM") {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "SADM") {
      getAM(true);
      return;
    }
  };
  const { dealersList } = useSelector((state) => state.trackCollection);

  const handlePreviewEmail = (payload) => {

    setFullPageLoader(true);
    handleMailModal();

    const updatePayload = payload.reduce((acc, item) => {
      delete item.key;
      acc[item.id] = item;
      return acc;
    });

    axiosAPI
      .post(`controller/MailToDealers`, updatePayload)
      .then((data) => {
        setBackendHtmlString(data.data.data.result.body);
        setSendPayload(data.data.data.result)
        setFullPageLoader(false);
      })
      .catch((err) => {
        ToastifyShow("Email has not been sent.", "error");
        setFullPageLoader(false);
      });
  };

  const handleSendEmail = () => {


    axiosAPI
      .post(`controller/SendPreviewMailers`, sendPayload)
      .then((data) => {

        ToastifyShow("Email has been sent.", "success");
        setFullPageLoader(false);
      })
      .catch((err) => {
        ToastifyShow("Email has not been sent.", "error");
        setFullPageLoader(false);
      });
    handleMailModal();
  }
  const actionsList = [
    {
      key: "1",
      disabled: dealersList?.data?.collectionList?.length == 0,
      label: <a onClick={handleDownload}>Download</a>,
    },
    {
      key: "2",
      label: <a onClick={()=>handleClearFilter()}>Clear Filters</a>,
      disabled: false,
    },
    {
      key: "3",
      label: <a onClick={() => handlePreviewEmail(selectedRows)}>Send E-mail</a>,
      disabled: selectedRows?.length == 0,
    },
  ];

  const subColType = [
    {
      key: "0",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 0,
              pageNumber: 1,
            })
          }
        >
          All Scheme Sub Type
        </a>
      ),
    },
    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 1,
              pageNumber: 1,
            })
          }
        >
          With Delivery Plan
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 2,
              pageNumber: 1,
            })
          }
        >
          Without Delivery Plan
        </a>
      ),
      disabled: false,
    },
  ];

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };

  useEffect(() => {
    if (selectedSHID == 0) {
      dispatch(getStateListApi());
      return;
    }
    if (userRole == "NSH") {
      dispatch(getStateListApi());
    }
  }, [selectedSHID]);

  const getZhId = () => {
    if (userRole == "ZADM") {
      return userId;
    }
    if (userRole == "SADM") {
      return getZonalHeadSH?.data?.userId;
    }

    if (userRole == "AMGR" && getZonalHead?.data?.userId) {
      return getZonalHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedZHID;
    }
  };

  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "SADM") {
      return userId;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedSHID;
    }
  };

  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedAMID;
    }
  };

  useEffect(() => {
    let lastTwoDigits = dateValue % 100;
    console.log(startMonth,"datedatedate")
    const payload = {
      startMonth: startMonth[0]
        ? dayjs(startMonth[0]).format("MM")
        : 0,
      endMonth: startMonth[1]
      ? dayjs(startMonth[1]).format("MM")
      : 0,
      userid: userId, // 18
      status: selectedStatus || 0,
      stateId: selectedStateId,
      stateHeadId: getShId(),
      areaManagerId: getAmId(),
      zonalHeadId: getZhId(),
      runningMonth: collectionData?.currentMonth,
      userRole: userRole,
      fyear: "FY" + lastTwoDigits,
      schemeType: collectionData?.schemeType,
      state: selectedStateId,
      dealerLocation: collectionData?.dealerLocation,
      schemeNumber: collectionData?.schemeNumber,
      dealerCode: collectionData?.dealerCode,
      pageSize: collectionData?.pageSize,
      pageNumber: collectionData?.pageNumber,
      sortingOrder: collectionData?.sortingOrder == "descend" ? "DESC" : "ASC",
      sortingType: collectionData?.sortingType || "DealerCode",
    };
    const timeout = setTimeout(() => {
    dispatch(getTrackCollectionDealersListApi(payload));
  },300)
  return () => {
    clearTimeout(timeout);
  };
  }, [
    selectedStateId,
    selectedStatus,
    collectionData,
    selectedSHID,
    selectedAMID,
    getZonalHeadSH,
    getStateHead,
    getZonalHead,
    selectedZHID,
    dateValue
  ]);

  useEffect(() => {


    if (userRole != "AMGR") {
      dispatch(trackGetAreaManagerApi({ userid: userId }));
    }
    if (userRole !== "SADM") {
      // dispatch(trackGetStateHeadbyAMIdApi({"userid": userId}))
    }
    if (userRole == "SADM") {
      dispatch(trackZonalHeadbySHIdApi({ UserId: userId }));
    }
    if (userRole == "ZADM") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackZonalHeadbySHIdApi({ userid: userId }));
    }
    if (userRole == "AMGR") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackGetStateHeadbyAMIdApi({ userid: userId }));
      // dispatch(trackZonalHeadbySHIdApi({"userid": userId}))
    }
  }, [selectedStateId, selectedStatus, enforcedMonth]);


  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));

  };

  const currentFiscalYear = getCurrentFiscalYear();

  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        setStateHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedSH("All State Heads");
                  setSelectedSHID(0);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                  setAreaMngrData([]);
                  setCollectionData({ ...collectionData, pageNumber: 1 });
                }}
              >
                All State Heads
              </span>
            ),
          },
          ...result?.data?.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedSH(item.stateHeadName);
                    setSelectedSHID(item.id);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    getAmDropdownData(item.id);
                    setCollectionData({ ...collectionData, pageNumber: 1 });
                  }}
                >
                  {item.stateHeadName}
                </span>
              ),
            };
          }),
        ]);
      });
  };

  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  const handleSelect = (key,name) => {
        if(key==0){
          setSelectedZH("All Zonal Head");
          setStateHeadData([]);
          setSelectedZHID(0);
          setSelectedSHID(0);
          setSelectedSH("All State Heads");
          setAreaMngrData([]);
          setSelectedAMID(0);
          setSelectedAM("All Area Managers");
          setCollectionData({ ...collectionData, pageNumber: 1 });
        }else{
          setSelectedZH(name);
          setSelectedZHID(key);
          setSelectedSHID(0);
          setSelectedSH("All State Heads");
          getShDropdownData(key);
          setAreaMngrData([]);
          setSelectedAMID(0);
          setSelectedAM("All Area Managers");
          setCollectionData({ ...collectionData, pageNumber: 1 });
        }
  };

  useEffect(() => {

    axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
      setZonalHeadData([
        {
          key: 0,
          label: "All Zonal Head",
          onClick:()=>handleSelect("0","")
        },
        ...result.data.data?.map((item) => {
          return {
            key: item.id,
            label: item.zonalHeadName,
            onClick:()=>handleSelect(item?.id,item?.zonalHeadName)
          };
        }),
      ]);
    });

    if (["SADM"].includes(userRole)) {
      getStateByStateHeadId(userId);
    }
  }, []);



  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    if (selectedAm?.am) {
      setSelectedAM(selectedAm.am);
    }
    // console.log(selectedAm,"selectedAmselectedAm")
    // getDealerDropdownData(parseInt(event.key), "amID");
    // if (type === "amID") {
    setCollectionData(({
      ...collectionData,
      amID: userId,
      pageNumber: 1,
    }));
    // }
  };

  const getAmDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownAM`, { userId: userId })
      .then((result) => {
        setAreaDropdown(result?.data?.data);
        setAreaMngrData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedAM("All Area Managers");
                  setSelectedAMID(0);
                  setCollectionData({ ...collectionData, pageNumber: 1 });
                }}
              >
                All Area Managers
              </span>
            ),
          },
          ...result?.data?.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedAMID(item.id);
                    setSelectedAM(item.aM);
                    setCollectionData({ ...collectionData, pageNumber: 1 });
                  }}
                >
                  {item.aM}
                </span>
              ),
            };
          }),
        ]);
      });
  };

  const getZH = () => {
    if (userRole == "ZADM") {
      return userName;
    }
    if (userRole == "SADM" && getZonalHeadSH?.data?.userName) {
      return getZonalHeadSH?.data?.userName;
    }
    if (userRole == "AMGR" && getZonalHead?.data?.userName) {
      return getZonalHead?.data?.userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedZH == "" ? "All Zonal Head" : selectedZH;
    }
    return selectedZH == "All Zonal Head" ? "All Zonal Head" : selectedZH;
  };

  const getSH = () => {
    // if(userRole == 'ZADM') {
    //   return userName;
    // };
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };

  const getAM = () => {
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "SADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "AMGR" && userName) {
      return userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };

  const getStateByStateHeadId = (SHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };

  const getStateByZonalHeadId = (ZHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;
        setStateListArr(data);
      });
  };

  useEffect(() => {
    if (userRole == "SADM") {
      getStateByStateHeadId(userId);
      return;
    }
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);

  useEffect(() => {
    if (["AMGR"].includes(userRole)) {
      getStateByStateHeadId(getStateHead?.data?.userId);
    }
  }, [getStateHead]);


  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31);
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };


  return (
    <div
      id="table"
      className="create_scheme_wrap onemoth-date-selector view-schela news"
    >
      {fullPageLoader ? <FullScreenLoader /> : ""}
      <div>
        <div
          style={{ marginTop: "18px" }}
          id="space"
          className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
        >
          <div
            className="allSchmeStatus select_state heightauto"
            style={{ color: "red" }}
          >
             <Tooltip title={"All Status"}> 
            <Status
              isDisable={
                ["PMIS", "Finance", "COSM", "NSH"].includes(userRole)
                  ? true
                  : false
              }
              className=""
              selectedStatus={
                ["PMIS", "Finance", "COSM", "NSH"].includes(userRole)
                  ? 2
                  : selectedStatus
              }
              handleStatusDropdown={(statusId) => {
                setSelectedStatus(statusId);
                setCollectionData({ ...collectionData, pageNumber: 1 });
              }}
            />
            </Tooltip>
          </div>
          <Col>
          <Tooltip title={"All Scheme Sub Type"}>
            <Dropdown menu={{ items: subColType }}>
              <Button>
                <Space>
                  {collectionData?.schemeType == ""
                    ? "All Scheme Sub Type"
                    : getSchemeType(collectionData?.schemeType)}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Tooltip>
          </Col>
          <div className="marginauto">
            <FinancialYearButton handleDate={(x) => {
              setDateValue(x);
            }} />
          </div>
          <div className="marginauto">
          <Tooltip title={"Running Month"}>
            <DatePicker
              // onChange={(date) => handleInputChange('enforcedMonth', date)}
              onChange={(date, formatDate) => {
                if (formatDate == "") {
                  setCollectionData({
                    ...collectionData,
                    currentMonth: 0,
                    pageNumber: 1,
                  });
                  return;
                }
                setCollectionData({
                  ...collectionData,
                  currentMonth: new Date(formatDate).getMonth() + 1,
                  pageNumber: 1,
                });
              }}
              className="start"
              placeholder="Running Month"
              picker="month"
              value={enforcedMonth}
              allowClear
              disabled={
                collectionData.startMonth != "" || collectionData.endMonth != ""
              }
              disabledDate={disabledDate}
            />
            </Tooltip>
          </div>

          <div className="month ">
          <Tooltip title={"Start Month    End Month"}>
            <RangePicker
              mode={["month", "month"]}
              dateValue={dateValue}
              picker="month"
              onChange={(date) => {
         
                setCollectionData((collectionData) => ({
                  ...collectionData,
                  startMonth: date && date[0] ? date[0].format() : 0,
                  endMonth: date && date[1] ? date[1].format() : 0,
                  pageNumber: 1,
                }));
                setStartMonth(date);
              }}

              value={startMonth}
              disabled={
                collectionData.currentMonth != ""
              }
              disabledDate={disabledDate}
            />
              </Tooltip>
          </div>
          <Col>
          <Tooltip title={"All Zonal Heads"}>
            <Dropdown
              menu={{ items: zonalHeadData }}
              disabled={["ZADM", "SADM", "AMGR"].includes(userRole)}
            >
              <Button>
                <Space>
                  {getZH()}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Tooltip>
          </Col>
          {/* <div onClick={handleDownload}>
            <Button disabled={dealersList?.data?.collectionList?.length == 0} ><DownloadOutlined /></Button>
          </div> */}
        </div>
        <div
          style={{ marginTop: "0" }}
          id="space"
          className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
        >
          {true && (
            <Col>
              <Tooltip title={"All State Heads"}> 
              <Dropdown
                menu={{ items: stateHeadData }}
                disabled={["SADM", "AMGR"].includes(userRole)}
              >
                <Button>
                  <Space>
                    {getSH()}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>
          )}

          {true && (
            <Col>
              <Tooltip title={"All Area Managers"}> 
              <Dropdown
                menu={{ items: areaMngrData, onClick: handleAMClick }}
                disabled={["AMGR"].includes(userRole)}
              >
                <Button>
                  <Space>
                    {getAM()}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>
          )}
                     <Tooltip title={"Select State"}>
          <Select
            showSearch
            className="select_state heightauto"
            placeholder="Select State"
            optionFilterProp="children"
            onSelect={handleStateSelect}
            value={selectedStateId === 0 ? 0 : stateListArr.find(option => option.stateID === selectedStateId)?.stateName}
          >
            <Option key={0} value={0}>
              All States
            </Option>
            {stateListArr?.map((option) => (
              <Option key={option.stateID} value={option.stateName}>
                {option.stateName}
              </Option>
            ))}
          </Select></Tooltip>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Scheme No."
              value={collectionData?.schemeNumber}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  schemeNumber: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Dealer Code"
              value={collectionData?.dealerCode}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  dealerCode: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Dealer Location"
              value={collectionData?.dealerLocation}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  dealerLocation: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
          <Col className="actionbuttons">
            <Dropdown menu={{ items: actionsList }}>
              <Button>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
        </div>
      </div>
      <div className="fullwidths">
        <div
          class="recent-boxes fullwidths"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <h2 style={{ fontSize: "18px", padding: "20px" }}>
            Recent {getSchemeType(selectedSchemeType)}
          </h2> */}
        </div>
        {/* <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span> */}
      </div>

      <TrackCollectionTable
        dataSource={dealersList?.data?.collectionList || []}
        grandTotal={dealersList?.data?.grandTotal}
        selectedStatus={selectedStatus}
        selectedSchemeType={selectedSchemeType}

        selectedStateId={selectedStateId}
        loading={
          loadingState == "pending" ||
            loadingTrack == "pending" ||
            loadingScheme == "pending"
            ? true
            : false
        }
        onSendEmail={handlePreviewEmail}
        onSelectedRows={(rows) => setSelectedRows(rows)}
     
        handleChange={(pagination, filters, sorter) => {
          setCollectionData({
            ...collectionData,
            sortingOrder: sorter?.order,
            sortingType: sorter?.columnKey,
          });
        }}
      />

      <Pagination
        defaultCurrent={1}
        className="paginationDesign"
        current={collectionData?.pageNumber||1}
        onChange={(pageNumber, pageSize) =>
          setCollectionData({ ...collectionData, pageNumber, pageSize })
        }
        total={dealersList?.data?.totalCount}
        showSizeChanger
        pageSize={collectionData?.pageSize||100}
        pageSizeOptions={['150', '250', '350']}
      />

      <MailPreviewModal
        open={openmailModal}
        onOk={() => {
          handleSendEmail();
        }}
        onCancel={handleMailModal}
      >

        <JoditEditor
          value={backendHtmlString}
          // onChange={handleSendEmail}

          config={{
            spellcheck: false,
            // minHeight: 100,
            // defaultMode: '1',
            buttons: ['preview'],
            readonly: true

          }}
          readonly={true}


        />
      </MailPreviewModal>

      <div className="All_scheme_status">
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "green" }}
          ></div>
          Approved
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "red" }}
          ></div>
          Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "yellow" }}
          ></div>
          Work in Progress
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "rgb(255, 0, 255)" }}
          ></div>
          Auto Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#BCB38D" }}
          ></div>
          Deviation
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#9999ff" }}
          ></div>
          Hold
        </div>
      </div>
      {(loadingState == "pending" ||
        loadingTrack == "pending" ||
        loadingScheme == "pending") && <FullScreenLoader />}
    </div>
  );
};
export default TrackCollection;
