import React, { useEffect, useRef, useState } from "react";
import ApprovalSchemesTab from "./ApprovalSchemesTab";
import { Button, Col, DatePicker, Divider, Dropdown, Pagination, Row, Space, Switch, Tabs, Tooltip, message } from "antd";
import "./approvalSchemes.css";
import AllSchemeHeader from "../allSchemes/AllSchemeHeader";
import dayjs from "dayjs";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";
import { CheckOutlined, CloseOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetDropdownAreaManagerAsync, GetDropdownStateHeadAsync, GetDropdownZonalHeadAsync, getAwaitingLockSchemeAsync, getLockedSchemeStateApiAsync, getLockedSchemeTypeAsync } from "../../../redux/stateData/stateDataSlice";
import AwaitingApprovalListCollectionDetails from "./collection/AwaitingApprovalListCollectionDetails";
import AwaitingApprovalListInstallationDetails from "./installation/AwaitingApprovalListInstallationDetails";
import { GetAllSchemeAwaitingCountDataApiAsync, GetHoldSchemesCountDataApiAsync, schemeGenerateListApi, updateApprovalSchemeApi, updateApprovedHoldSchemeApi, updateRejectedHoldSchemeApi, getHoldSchemesApi } from "../../../redux/schemaGenerate/schemaGenerateSlice";
import { ConstructionOutlined, Edit } from "@mui/icons-material";
import AwaitingCollectionEdit from "./collection/AwaitingCollectionEdit.js";
import AwaitingInstallationEdit from "./installation/AwaitingInstallationEdit";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import { Link } from "react-router-dom";
import moment from "moment";
import HeaderBox from "./HeaderBox";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton.js";


const AwaitingApprovalList = ({ selectedStatus, handleStatusDropdown }) => {

  const {loadingModificationState } = useSelector((state) => state.modifyCollection);
  const InitCollectionData = {
    fyear: "",
    schemeType: 1,
    month: 0,
    monthEnd: 0,
    pageSize: 150,
    pageNumber: 1,
    totalPage: 0,
    enforcedMonth: 0,
    sortingOrder: "ascend",
    sortingType: "DealerCode",
    selectId: 0,
  };
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [collectionToggle, setCollectionToggle] = useState(false);
  const [stateHeadData, setStateHeadData] = useState([])
  const [dataSource, setDataSource] = useState([]);

  const [toggleEdit, setToggleEdit] = useState(false)
  const [schemeIdNo, setSchemeIdNo] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectUserName, setSelectUserName] = useState(0)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openAcceptModal, setOpenAcceptModal] = useState(false)
  const { awaitingLockSchemes } = useSelector((state) => state.stateData)
  const { schemeGenerateList, loadingScheme } = useSelector((state) => state?.schemagenerate)
  const { RangePicker } = DatePicker;
  const { userRole, userId, userName } = decryptData(sessionStorage.getItem("userInfo"));
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  const [holdChecked, setHoldChecked] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('Schemes');
  const { loadingHoldSchemeAwaiting, holdSchemeAwaiting } = useSelector(
    (state) => state.schemagenerate
  );
  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);
  const dispatch = useDispatch()
  const childref = useRef();
  const { schemeType } = collectionData



  // const disabledDate = (current, selectedMonth) => {
  //   // lock
  //   const today = new Date();
  //   const currentYear = today.getFullYear()&&today.getMonth()<2?today.getFullYear()-1:today.getFullYear();
  //   const fiscalYearStartMonth = 3; // April (0-indexed)

  //   // Assuming 'current' is a Moment.js object
  //   return current && (
  //     (current.year() < currentYear || (current.year() === currentYear && current.month() < fiscalYearStartMonth)) ||
  //     (current.year() > currentYear + 1 || (current.year() === currentYear + 1 && current.month() >= fiscalYearStartMonth))
  //   );
  // };

  // const disabledDate = (current, selectedMonth) => {
  //   // lock
  //   if (false) return true;
  //   const today = new Date();
  //   const currentYear = today.getFullYear() - 1;
  //   const currentMonth = today.getMonth();
  //   const futureYear = currentYear + 1;

  //   //disableDateRestriction
  //   if (false) {
  //     return null;
  //   }

  //   return (
  //     current &&
  //     (current.year() < currentYear ||
  //       (current.year() === currentYear && current.month() < 3) ||
  //       current.year() > futureYear ||
  //       (current.year() === futureYear && current.month() > 2))
  //   );
  // };

  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31);
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };
  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };
  useEffect(() => {
    if (userRole === "SADM" && awaitingLockSchemes?.[0]) {
      setCollectionData({
        ...collectionData, schemeType:
          awaitingLockSchemes?.[0]?.scheme1 ? 1 : awaitingLockSchemes?.[0]?.scheme2 ? 2 :
            awaitingLockSchemes?.[0]?.scheme6 ? 8 :
              awaitingLockSchemes?.[0]?.scheme4 ? 4 :
                awaitingLockSchemes?.[0]?.scheme3 ? 3 :
                  awaitingLockSchemes?.[0]?.scheme5 ? 5 : 0
      })

    }
  }, [awaitingLockSchemes])



  // const getSchemeType = (schemeType) => {
  //   if (schemeType == 1) {
  //     return 'Collection - With Delivery Gate';
  //   }
  //   if (schemeType == 2) {
  //     return 'Collection - Without Delivery Gate';
  //   }
  //   if (schemeType == 3) {
  //     return 'Installation - Slab Based';
  //   }
  //   if (schemeType == 8) {
  //     return 'Installation - Single Target';
  //   }
  //   if (schemeType == 4) {
  //     return 'Installation - Single Target With Upper Cap';
  //   }
  //   if (schemeType == 5) {
  //     return 'Installation - Unconditional Installation';
  //   }
  // }
  // const subColType = [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         onClick={() =>
  //           setCollectionData({ ...collectionData, schemeType: 1 })
  //         }
  //       >
  //         With Delivery Gate
  //       </a>
  //     ),
  //     disabled : (userRole==="SADM"&&awaitingLockSchemes&&awaitingLockSchemes?.[0]?.scheme1)?false:true
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         onClick={() =>
  //           setCollectionData({ ...collectionData, schemeType: 2 })
  //         }
  //       >
  //         Without Delivery Gate
  //       </a>
  //     ),
  //     disabled : (userRole==="SADM"&&awaitingLockSchemes&&awaitingLockSchemes?.[0]?.scheme2)?false:true
  //   },
  //   {
  //     key: "3",
  //     label: (
  //       <a
  //         onClick={() =>
  //           setCollectionData({ ...collectionData, schemeType: 8 })
  //         }
  //       >
  //         Single Target
  //       </a>
  //     ),
  //     disabled : (userRole==="SADM"&&awaitingLockSchemes&&awaitingLockSchemes?.[0]?.scheme6)?false:true
  //   },
  //   {
  //     key: "4",
  //     label: (
  //       <a
  //         onClick={() =>
  //           setCollectionData({ ...collectionData, schemeType: 4 })
  //         }
  //       >
  //         Single Target With Upper Cap
  //       </a>
  //     ),
  //     disabled : (userRole==="SADM"&&awaitingLockSchemes&&awaitingLockSchemes?.[0]?.scheme4)?false:true
  //   },
  //   {
  //     key: "5",
  //     label: (
  //       <a
  //         onClick={() =>
  //           setCollectionData({ ...collectionData, schemeType: 3 })
  //         }
  //       >
  //         Slab Based
  //       </a>
  //     ),
  //     disabled : (userRole==="SADM"&&awaitingLockSchemes&&awaitingLockSchemes?.[0]?.scheme3)?false:true
  //   },
  //   {
  //     key: "6",
  //     label: (
  //       <a
  //         onClick={() =>
  //           setCollectionData({ ...collectionData, schemeType: 5 })
  //         }
  //       >
  //         Unconditional Installation
  //       </a>
  //     ),
  //     disabled : (userRole==="SADM"&&awaitingLockSchemes&&awaitingLockSchemes?.[0]?.scheme5)?false:true
  //   },
  // ];

  useEffect(() => {
    if (Array?.isArray(holdSchemeAwaiting?.data?.data) && selectedLabel == "Hold Schemes") {
      if (holdSchemeAwaiting?.data?.data?.length != 0) {
        setDataSource(holdSchemeAwaiting?.data?.data?.map((x, index) => ({
          ...x, key: index, bgLacsCurrentYear: x?.bGLacsCurrentYear || 0
        })))
      } else {
        setDataSource([])
      }
    } else {
      if (Array?.isArray(schemeGenerateList?.data?.data) && schemeGenerateList?.data?.data?.length != 0) {
        setDataSource(schemeGenerateList?.data?.data?.map((x, index) => ({
          ...x, key: index, bgLacsCurrentYear: x?.bGLacsCurrentYear || 0
        })))
      } else {
        setDataSource([])
      }
    }


  }, [schemeGenerateList, holdSchemeAwaiting])


  let getApiData = () => {
    if (userRole == 'SADM') {
      dispatch(GetDropdownAreaManagerAsync({ userId: userId })).then((x) => {
        let resultData = x?.payload?.data || []
        if (resultData?.length != 0) {
          setStateHeadData([
            {
              key: '0',
              label: (
                <span onClick={() => {
                  setCollectionToggle(!collectionToggle)
                  setCollectionData({ ...collectionData, selectId: 0 })
                  setSelectUserName("All Area Managers")
                }}>All Area Managers</span>)
            },
            ...resultData?.map((item) => ({
              key: item?.id,
              label: (
                <span
                  onClick={() => {
                    setCollectionToggle(!collectionToggle)
                    setSelectUserName(item?.aM)
                    setCollectionData({ ...collectionData, selectId: item?.id })

                  }}
                >
                  {item?.aM}
                </span>
              )
            }))
          ])
        }
      })
    } else if (userRole == 'ZADM') {
      dispatch(GetDropdownStateHeadAsync({ userId: userId })).then((x) => {
        let resultData = x?.payload?.data || []
        if (resultData?.length != 0) {
          setStateHeadData([
            {
              key: '0',
              label: (
                <span onClick={() => {
                  setCollectionData({ ...collectionData, selectId: 0 })
                  setSelectUserName("All State Heads")
                  setCollectionToggle(!collectionToggle)
                }}>All State Heads</span>)
            },
            ...resultData?.map((item) => ({
              key: item?.id,
              label: (
                <span
                  onClick={() => {
                    setSelectUserName(item?.stateHeadName)
                    setCollectionToggle(!collectionToggle)
                    setCollectionData({ ...collectionData, selectId: item?.id })
                  }}
                >
                  {item?.stateHeadName}
                </span>
              )
            }))
          ])
        }
      })
    }
    else if (userRole == 'NSH' || userRole == 'PMIS') {
      dispatch(GetDropdownZonalHeadAsync({ userId: userId })).then((x) => {
        let resultData = x?.payload?.data || []
        if (resultData?.length != 0) {
          setStateHeadData([
            {
              key: '0',
              label: (
                <span onClick={() => {
                  setCollectionToggle(!collectionToggle)
                  setCollectionData({ ...collectionData, selectId: 0 })
                  setSelectUserName("All Zonal Head")
                }}>All Zonal Head</span>)
            },
            ...resultData?.map((item) => ({
              key: item?.id,
              label: (
                <span
                  onClick={() => {
                    setSelectUserName(item?.zonalHeadName)
                    setCollectionToggle(!collectionToggle)
                    setCollectionData({ ...collectionData, selectId: item?.id })
                  }}
                >
                  {item?.zonalHeadName}
                </span>
              )
            }))
          ])
        }
      })
    }
  }
  let userListData = () => {
    let lastTwoDigits = dateValue % 100;
    if (userRole === "SADM" && collectionData?.schemeType != 0) {
      dispatch(schemeGenerateListApi({
        fyear: "FY" + lastTwoDigits,
        schemeType: collectionData?.schemeType,
        pageSize: collectionData?.pageSize || 150,
        pageNumber: collectionData?.pageNumber || 1,
        state: 0,
        userid: userId,
        status: 1,
        userRole: userRole,
        ...(userRole == "SADM") ? { areaManagerId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : (userRole == "NSH") ? { zonalHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : { stateHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId },
        schemeType: collectionData?.schemeType,
        month: (collectionData?.month == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.month).format("MM")),
        monthEnd: (collectionData?.monthEnd == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.monthEnd).format("MM")),
        enforcedMonth: (collectionData?.enforcedMonth == 0 || collectionData?.enforcedMonth == "") ? 0 : parseInt(collectionData?.enforcedMonth),
        sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
        sortingType: collectionData.sortingType || "DealerCode"
      }))
    } else if (userRole !== "SADM") {

      dispatch(schemeGenerateListApi({
        fyear: "FY" + lastTwoDigits,
        schemeType: collectionData?.schemeType,
        pageSize: collectionData?.pageSize || 150,
        pageNumber: collectionData?.pageNumber || 1,
        state: 0,
        userid: userId,
        status: 1,
        userRole: userRole,
        ...(userRole == "SADM") ? { areaManagerId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : (userRole == "NSH") ? { zonalHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : { stateHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId },
        month: (collectionData?.month == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.month).format("MM")),
        monthEnd: (collectionData?.monthEnd == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.monthEnd).format("MM")),
        enforcedMonth: (collectionData?.enforcedMonth == 0 || collectionData?.enforcedMonth == "") ? 0 : parseInt(moment(collectionData?.enforcedMonth).format("MM")),
        sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
        sortingType: collectionData.sortingType || "DealerCode"
      }))
    }
  }
  let userListDataForHold = () => {
    let lastTwoDigits = dateValue % 100;
    if (userRole === "SADM" && collectionData?.schemeType != 0) {
      dispatch(getHoldSchemesApi({
        fyear: "FY" + lastTwoDigits,
        schemeType: collectionData?.schemeType,
        pageSize: collectionData?.pageSize || 150,
        pageNumber: collectionData?.pageNumber || 1,
        state: 0,
        userid: userId,
        status: 1,
        userRole: userRole,
        ...(userRole == "SADM") ? { areaManagerId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : (userRole == "NSH") ? { zonalHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : { stateHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId },
        schemeType: collectionData?.schemeType,
        month: (collectionData?.month == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.month).format("MM")),
        monthEnd: (collectionData?.monthEnd == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.monthEnd).format("MM")),
        enforcedMonth: (collectionData?.enforcedMonth == 0 || collectionData?.enforcedMonth == "") ? 0 : parseInt(moment(collectionData?.enforcedMonth).format("MM")),
        sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
        sortingType: collectionData.sortingType || "DealerCode"
      }))
    } else if (userRole !== "SADM") {

      dispatch(getHoldSchemesApi({
        fyear: "FY" + lastTwoDigits,
        schemeType: collectionData?.schemeType,
        pageSize: collectionData?.pageSize || 150,
        pageNumber: collectionData?.pageNumber || 1,
        state: 0,
        userid: userId,
        status: 1,
        userRole: userRole,
        ...(userRole == "SADM") ? { areaManagerId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : (userRole == "NSH") ? { zonalHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : { stateHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId },
        month: (collectionData?.month == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.month).format("MM")),
        monthEnd: (collectionData?.monthEnd == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.monthEnd).format("MM")),
        enforcedMonth: (collectionData?.enforcedMonth == 0 || collectionData?.enforcedMonth == "") ? 0 : parseInt(moment(collectionData?.enforcedMonth).format("MM")),
        sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
        sortingType: collectionData.sortingType || "DealerCode"
      }))
    }
  }
  let getData = () => {
    let lastTwoDigits = dateValue % 100;
    if (userRole === "SADM") {
      dispatch(getAwaitingLockSchemeAsync())
    }
    dispatch(GetAllSchemeAwaitingCountDataApiAsync({
      fyear: "FY" + lastTwoDigits,
      pageSize: collectionData?.pageSize || 150,
      pageNumber: collectionData?.pageNumber || 1,
      state: 0,
      userid: userId,
      status: 1,
      userRole: userRole,
      ...(userRole == "SADM") ? { areaManagerId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : (userRole == "NSH") ? { zonalHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : { stateHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId },
      month: (collectionData?.month == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.month).format("MM")),
      monthEnd: (collectionData?.monthEnd == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.monthEnd).format("MM")),
      enforcedMonth: (collectionData?.enforcedMonth == 0 || collectionData?.enforcedMonth == "") ? 0 : parseInt(moment(collectionData?.enforcedMonth).format("MM")),
    }))
  }

  let getHoldData = () => {
    let lastTwoDigits = dateValue % 100;
    if (userRole === "SADM") {
      dispatch(getAwaitingLockSchemeAsync())
    }
    dispatch(GetHoldSchemesCountDataApiAsync({
      fyear: "FY" + lastTwoDigits,
      pageSize: collectionData?.pageSize || 150,
      pageNumber: collectionData?.pageNumber || 1,
      state: 0,
      userid: userId,
      status: 1,
      userRole: userRole,
      ...(userRole == "SADM") ? { areaManagerId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : (userRole == "NSH") ? { zonalHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId } : { stateHeadId: collectionData?.selectId == 0 ? 0 : collectionData?.selectId },
      month: (collectionData?.month == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.month).format("MM")),
      monthEnd: (collectionData?.monthEnd == 0 || collectionData?.monthEnd == "") ? 0 : parseInt(moment(collectionData?.monthEnd).format("MM")),
      enforcedMonth: (collectionData?.enforcedMonth == 0 || collectionData?.enforcedMonth == "") ? 0 : parseInt(moment(collectionData?.enforcedMonth).format("MM")),
    }))
  }
  useEffect(() => {
    getData();
    getHoldData();
    getApiData();

  }, [dateValue])
  useEffect(() => {
    if (collectionData) {
      userListData()
      userListDataForHold()
    }
    // setCollectionToggle(!collectionToggle)
  }, [collectionToggle, dateValue, collectionData])
  const handleRejectModal = () => {
    if (selectedRowKeys.length > 0) {
      setOpenRejectModal(true);
    } else {
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  }
  const handleAcceptModal = () => {
    if (selectedRowKeys.length > 0) {
      setOpenAcceptModal(true)
    } else {
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  }
  const handleEditClick = () => {

    if (selectedRowKeys.length > 0) {
      setToggleEdit(true)
    } else {
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  }
  const items = [
    {
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => handleEditClick(e)}
        >
          Edit
        </Button>
      ),
      key: "1",
      icon: <Edit />,
    },
    {
      label: (
        <div style={{ color: "#51DE4E" }} onClick={() => handleAcceptModal()}>
          Approve
        </div>
      ),
      key: "2",
      icon: <CheckOutlined className="approved-icon" />,
    },
    {
      label: (
        <div style={{ color: "#FF3232" }} onClick={() => handleRejectModal()}>
          Reject
        </div>
      ),
      key: "3",
      icon: <CloseOutlined className="reject-icon" />,
    },
  ];
  // const items1 = [
  //   {
  //     key: '1',
  //     label: 'Filter',
  //     children: ,
  //   }
  // ];
  const itemsforstatus = [
    {
      label: <a
        onClick={() => {
          userListData();
          setSelectedLabel('Regular Schemes');
        }}
      >
        Regular Schemes</a>,

      key: "1",
    },
    {
      label: <a
        onClick={() => {
          userListDataForHold();
          setSelectedLabel('Hold Schemes');
        }}
      >Hold Schemes</a>,
      key: "2",
    }
  ]
  const getStatusType = (selectedStatus) => {
    if (selectedStatus == 0) {
      return "Regular Schemes";
    }
    if (selectedStatus == 2) {
      return "Hold Schemes";
    }
  };

  // const handleChange = (checked) => {
  //   setHoldChecked(checked);
  //   if (checked) {
  //     userListDataForHold();
  //   } else {
  //     userListData();
  //   }
  // };
  return (<div>

    <div className="create_scheme_wrap collection-schme-box">
      <Divider />

      {toggleEdit == false && <>
        <div className="bg-white p-1 w-100 awatingul">

          <HeaderBox setStatusFilter={(x) => { setCollectionToggle(!collectionToggle); setCollectionData({ ...collectionData, schemeType: x }) }}
            statusFilter={collectionData?.schemeType}
            selectedLabel={selectedLabel}
          />

        </div>

        <div className="d-flex flex-wrap pt-3">
          <div className="d-flex bg-white w-100 p-4">
            <div className="status d-flex align-items-center">
              <Col>
              <Tooltip title={"Select Schemes"}> 
                <Dropdown
                  menu={{ items: itemsforstatus }}
                >
                  <Button className="h-45">
                    <Space>
                      {selectedLabel == "Schemes" ? "Regular Scheme" : selectedLabel}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                {/* <Switch
                  checked={holdChecked}

                  onChange={handleChange}
                  checkedChildren="Hold"
                  unCheckedChildren="Regular"
                /> */}
                </Tooltip>
              </Col>
            </div>

            <Col>
              <Dropdown
                menu={{ items: stateHeadData, onClick: getApiData }}
              >
                <Button className="h-45">
                  <Space>
                    {selectUserName != 0 ? selectUserName : userRole == 'SADM' ? "All Area Managers" : userRole == 'ZADM' ? "All State Heads" : "All Zonal Head"}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>

            <div className="marginauto mx-3">
            <Tooltip title={"Financial Year"}>
              <FinancialYearButton handleDate={(x) => {
                setDateValue(x);
              }} />
              </Tooltip>
            </div>

            <div className="mx-3">
            <Tooltip title={"Running Month"}>
              <DatePicker
                // onChange={(date) => handleInputChange('enforcedMonth', date)}
                onChange={(date, formatDate) => {
                  if (formatDate == "") {
                    setCollectionToggle(!collectionToggle)
                    setCollectionData({
                      ...collectionData,
                      enforcedMonth: 0,
                      pageNumber: 1,

                    });
                    return;
                  }
                  setCollectionToggle(!collectionToggle)
                  setCollectionData({
                    ...collectionData,
                    enforcedMonth: new Date(formatDate).getMonth() + 1,
                    pageNumber: 1,
                    month: 0,
                    monthEnd: 0
                  });
                }}
                className="start h-45"
                placeholder="Running Month"
                picker="month"
                // value={collectionData?.enforcedMonth}
                allowClear
                disabledDate={disabledDate}
                disabled={
                  collectionData.month != "" || collectionData.monthEnd != ""
                }
              />
              </Tooltip>
            </div>

            <div className="month ">
            <Tooltip title={"Start Month    End Month"}>
              <RangePicker
                className="h-45"
                mode={["month", "month"]}
                picker="month"
                onChange={(date, formatDate) => {
                  setCollectionToggle(!collectionToggle)
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    month: date && date?.[0] ? date?.[0].format() : 0,
                    monthEnd: date && date?.[1] ? date?.[1].format() : 0,
                    enforcedMonth: 0,
                    pageNumber: 1,
                  }));
                }}
                disabledDate={disabledDate}
                //  disabledDate={(current) => disabledDate(current, selectedMonth)}
                // disabled={[!showStartMonth, !showEndMonth]}
                onPanelChange={handlePanelChange}
                disabled={collectionData.enforcedMonth > 0}
              />
              </Tooltip>
            </div>
            <Col className="actionbuttons rightmargin">
              <Dropdown menu={{ items: items }}>
                <Button className="h-45">
                  <Space>
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
            <Col className="actionbuttons rightmargin">
              <Button className="mx-1 h-45" onClick={() => childref.current.click()} >Download</Button>
            </Col>
          </div>
        </div>
      </>}

      {(!schemeType || schemeType == 0) ? <p className="p-5 bg-white mt-2">All Schemes Are Locked</p> : <>
        {(toggleEdit == false && (schemeType == 1 || schemeType == 2)) ?
          <AwaitingApprovalListCollectionDetails
            dataSource={dataSource || []}
            reference={childref}
            loading={loadingScheme == "pending" ? true : false}
            setSchemeIdNo={setSchemeIdNo}
            setToggleEdit={setToggleEdit}
            selectedRowKeys={selectedRowKeys}
            schemeType={schemeType}
            viewType={selectedLabel == "Hold Schemes" ? "hold" : null}
            handleChange={(pagination, filters, sorter) => {
              setCollectionToggle(!collectionToggle)
              setCollectionData({ ...collectionData, 
                "sortingOrder": sorter?.order, 
                "sortingType": sorter?.columnKey })
            }}

            setSelectedRowKeys={setSelectedRowKeys}
            setOpenAcceptModal={setOpenAcceptModal}
            setOpenRejectModal={setOpenRejectModal} /> :
          toggleEdit == true && (schemeType == 1 || schemeType == 2) ?
            <AwaitingCollectionEdit
              backBtn={async () => {
                await getApiData()
                await userListData()
                await userListDataForHold()
                await setSelectedRowKeys([])
                await setToggleEdit(false)
              }}
              viewType={selectedLabel == "Hold Schemes" ? "hold" : null}
              schemeDetails={schemeIdNo}
              setSchemeIdNo={setSchemeIdNo}
              selectedRowKeys={selectedRowKeys} /> : toggleEdit == true ?
              <AwaitingInstallationEdit
                backBtn={async () => {

                  await getApiData()
                  await userListData()
                  await userListDataForHold()
                  await setSelectedRowKeys([])
                  await setToggleEdit(false)
                }}
                viewType={selectedLabel == "Hold Schemes" ? "hold" : null}
                selectedSchemeType={schemeType}
                schemeDetails={schemeIdNo}
                setSchemeIdNo={setSchemeIdNo}
                selectedRowKeys={selectedRowKeys}

              /> :
              <AwaitingApprovalListInstallationDetails
                selectedSchemeType={schemeType}
                dataSource={dataSource || []}
                loading={loadingScheme == "pending" ? true : false}
                setSchemeIdNo={setSchemeIdNo}
                setToggleEdit={setToggleEdit}
                selectedRowKeys={selectedRowKeys}
                viewType={selectedLabel == "Hold Schemes" ? "hold" : null}
                setSelectedRowKeys={setSelectedRowKeys}
                setOpenAcceptModal={setOpenAcceptModal}
                setOpenRejectModal={setOpenRejectModal}
                reference={childref}
                schemeType={schemeType}
                handleChange={(pagination, filters, sorter) => {
                  setCollectionToggle(!collectionToggle)
                  setCollectionData({ ...collectionData, "sortingOrder": sorter?.order, "sortingType": sorter?.columnKey })
                }}
              />}
        {toggleEdit == false && <Pagination defaultCurrent={1}
          className='paginationDesign'
          showSizeChanger
          current={collectionData?.pageNumber}
          onChange={(pageNumber, pageSize) => {
            setCollectionToggle(!collectionToggle)
            setCollectionData({ ...collectionData, pageNumber, pageSize })
          }
          }
          total={schemeGenerateList?.data?.totalCountScheme || 0}
          pageSize={collectionData?.pageSize}
          pageSizeOptions={['150', '250', '350']}
        />}</>}


      <StatusModal
        open={openAcceptModal}
        onOk={async () => {
          await setOpenAcceptModal(false)
          let data = schemeIdNo?.map((x) => {
            return {
              "schemeId": x?.schemeId,
              "status": 2,
              "remark": x?.remarks,
              "username": userName,
              "userid": userId
            }
          })
          if (selectedLabel == "Hold Schemes")
            await dispatch(updateApprovedHoldSchemeApi(data))
          else
            await dispatch(updateApprovalSchemeApi(data))
          await message.success({
            content: "Scheme Approved Successfully",
            className: "custom-message",
          });
          await getApiData()
          await userListData()
          await userListDataForHold()
          await getData();
          await getHoldData();
          setSelectedRowKeys([])
          setSchemeIdNo([])
        }}
        onCancel={() => {
          setOpenAcceptModal(false)
        }}
      >
        <p>Are you sure you want to accept </p>
      </StatusModal>
      <StatusModal
        open={openRejectModal}
        onOk={async () => {
          await setOpenRejectModal(false)
          let data = schemeIdNo?.map((x) => {
            return {
              "schemeId": x?.schemeId,
              "status": 5,
              "remark": x?.remarks,
              "username": userName,
              "userid": userId
            }
          })
          if (selectedLabel == "Hold Schemes")
            await dispatch(updateRejectedHoldSchemeApi(data))
          else
            await dispatch(updateApprovalSchemeApi(data))
          await message.success({
            content: "Scheme Reject Successfully",
            className: "custom-message",
          });
          await getApiData()
          await userListData()
          await userListDataForHold()
          await getData();
          await getHoldData();
          setSelectedRowKeys([])
          setSchemeIdNo([])
        }}
        onCancel={() => {
          setOpenRejectModal(false)
        }}
      >
        <p>Are you sure you want to Reject</p>
      </StatusModal>
    </div>

    {loadingScheme == "pending"||loadingModificationState=="pending" && <FullScreenLoader />}
  </div>)
}

export default AwaitingApprovalList;