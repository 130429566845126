import React, { Table, Tag, Space, Tooltip, Row, Col, Button } from "antd";
import dayjs from "dayjs";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactComponent as Download } from "../../../../assets/vector/download.svg";
import ToastifyShow from "../../../../components/ToastifyShow";
import { useState } from "react";
import XLSX from 'xlsx-js-style';
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { TagAwaitingHold } from "../collection/AwaitingApprovalListCollectionDetails";
const AwaitingApprovalListInstallationDetails = ({
  selectedSchemeType,
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  setEditSchemes,
  setToggleEdit,
  setSchemeIdNo,
  loading,
  setOpenRejectModal,
  setOpenAcceptModal,
  reference,
  schemeType,
  handleChange,
  viewType
}) => {

  const { userRole, userId, userName } = decryptData(sessionStorage.getItem("userInfo"));
  const [selectedRows, setSelectedRows] = useState([])
  const handleSingleEdit = (record) => {
    setSchemeIdNo([record])
    setToggleEdit(true)
  };

  const getStateHead = () => {
    if (userRole != "SADM" && userRole != "NSH") {
      return [
        {
          title: (
            <div>
              State Head
            </div>
          ),
          dataIndex: "stateHeadName",
          width: "180px",
        },
      ]
    } else {
      return []
    }
  }
  const getCols = (schemeType) => {
    if (schemeType == 1 || schemeType == 2) {
      return [
        {
          title: "Industry",
          dataIndex: "industry",
          width: "100px",
        },
        {
          title: "B",
          dataIndex: "oldBillingQty",
          width: "100px",
        },
        {
          title: "I",
          dataIndex: "oldInstallation",
          width: "100px",
        },
        {
          title: "C",
          dataIndex: "oldCollection",
          width: "100px",
        },
        {
          title: (
            <div>
              Opening <br /> Stock
            </div>
          ),
          dataIndex: "stock",
          width: "100px",
          children: [
            {
              title: "NOS",
              width: "100px",
              dataIndex: "oldOpeningStock",
            },
            {
              title: "Days",
              dataIndex: "openingStockDays",
              width: "100px",
            },
          ],
        },
        {
          title: "Closing Os. (In Lacs)",
          dataIndex: "stock",
          defaultSortOrder: "descend",

          children: [
            {
    title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
              dataIndex: "closingOutStandingMonth_3",
              defaultSortOrder: "descend",
              width: "180px",
            },
            {
              title: (
                <span>
                  Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
                </span>
              ),
              dataIndex: "closingOutStandingMonth_2",
              defaultSortOrder: "descend",
              width: "180px",
            },
            {
              title: (
                <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
              ),
              dataIndex: "closingOutStandingMonth_1",
              defaultSortOrder: "descend",
              width: "180px",
            },

            // {
            //   title: (
            //     <span>
            //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
            //     </span>
            //   ),
            //   dataIndex: "outStandingDays",

            //   width: "90px",
            // },
          ],
        },
        {
          title: "Closing OS Days",
          dataIndex: "outStandingDays",
          children: [
            {
              title: "OS Last Month",
              dataIndex: "outStandingDays",
              width: "90px",
            },
          ],
        },
        {
          title: "BG",
          dataIndex: "outStandingDays",
          children: [
            {
              title: "Lacs",
              dataIndex: "bgLacsCurrentYear",
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Installation <br /> Plan
            </div>
          ),
          dataIndex: "IP",
          children: [
            {
              title: "NOS",
              dataIndex: "delayPlan",
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Billing <br /> Details
            </div>
          ),
          dataIndex: "plan",
          children: [
            {
              title: "B",
              dataIndex: "billing",
              width: "100px",
            },
            {
              title: "I",
              dataIndex: "installation",
              width: "100px",
            },
            {
              title: "C",
              dataIndex: "collection",

              width: "100px",
            },
          ],
        },
        {
          title: "Books Collection Target (No. of Ts.)",
          dataIndex: "BooksCollectionTarget",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.t1;
                  const modified = y?.holdT1;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "T2",
              dataIndex: "t2",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.t2;
                  const modified = y?.holdT2;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t2")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t2") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "T2-T1",
              dataIndex: "t2_T1",
              width: "160px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "T3",
              dataIndex: "t3",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.t3;
                  const modified = y?.holdT3;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t3")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t3") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "T3-T2",
              dataIndex: "t3_T2",
              width: "160px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
        {
          title: (
            <div>
              Payout <br /> Rate
            </div>
          ),
          dataIndex: "pr",

          children: [
            {
              title: "R1",
              dataIndex: "r1",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.r1;
                  const modified = y?.holdR1;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "R2",
              dataIndex: "r2",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.r2;
                  const modified = y?.holdR2;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t2")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t2") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "R3",
              dataIndex: "r3",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.r3;
                  const modified = y?.holdR3;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t3")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t3") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
          ],
        },

        {
          title: "Payout",
          dataIndex: "payout",

          children: [
            {
              title: "P1",
              dataIndex: "p1",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.p1;
                  const modified = y?.holdP1;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },

            },
            {
              title: "P2",
              dataIndex: "p2",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.p2;
                  const modified = y?.holdP2;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t2")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t2") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "P3",
              dataIndex: "p3",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.p3;
                  const modified = y?.holdP3;
                  const topSlabDetails = y?.topTarget?.split(",")?.includes("t3")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t3") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
          ],
        },
        {
          title: "Total Expected Payout",
          dataIndex: "TEP",
          children: [
            {
              title: "Expected Volume",
              dataIndex: "volume",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.volume;
                  const modified = y?.holdVolume;
    
                  return TagAwaitingHold(simple, modified);
                } else {
                  return <div>{x}</div>
                  
                }
              },
              width: "160px",
            },
            {
              title: "Per Tr.",
              dataIndex: "perTr",
              width: "100px",  render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.perTr;
                  const modified = y?.holdPerTr;
    
                  return TagAwaitingHold(simple, modified);
                } else {
                  return <div>{x}</div>
                  
                }
              },
            },
            {
              title: "Amount",
              dataIndex: "amount",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.amount;
                  const modified = y?.holdAmount;
    
                  return TagAwaitingHold(simple, modified);
                } else {
                  return <div>{x}</div>
                  
                }
              },
            },
          ],
        },
      ];
    }
    if (schemeType == 3) {
      return [
        {
          title: "TOP SLAB",
          width: "150px",
          render: (x, y, index) => {
            if (viewType == "hold") {
              const simple = y?.topTarget;
              const modified = y?.holdTopTarget;

              return TagAwaitingHold(simple, modified);
            } else {
             return  y?.topTarget?.toUpperCase()}
            }
        },
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          // sorter: true,
          width: "200px",
        },
        {
          title: "Targets",
          dataIndex: "Target",
          // width: '180px',
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.t1;
                  const modified = y?.holdT1;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "T2",
              dataIndex: "t2",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.t2;
                  const modified = y?.holdT2;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t2")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t2") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
              width: "160px",
            }, {
              title: "T3",
              dataIndex: "t3",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.t3;
                  const modified = y?.holdT3;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t3")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t3") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
              width: "160px",
            },
          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          // width: '180px',
          children: [
            {
              title: "R1",
              dataIndex: "r1",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.r1;
                  const modified = y?.holdR1;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "R2",
              dataIndex: "r2",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.r2;
                  const modified = y?.holdR2;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "R3",
              dataIndex: "r3",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.r3;
                  const modified = y?.holdR3;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          // width: '180px',
          children: [
            {
              title: "P1",
              dataIndex: "p1",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.p1;
                  const modified = y?.holdP1;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t1")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t1") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "P2",
              dataIndex: "p2",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.p2;
                  const modified = y?.holdP2;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t2")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t2") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
            {
              title: "P3",
              dataIndex: "p3",
              width: "160px",
              render: (x, y) => {
                if (viewType == "hold") {
                  const simple = y?.p3;
                  const modified = y?.holdP3;
                  const topSlabDetails = y?.holdTopTarget?.split(",")?.includes("t3")
                    ? false
                    : true;
                  return TagAwaitingHold(simple, modified, topSlabDetails);
                } else {
                  return !y?.topTarget?.split(",")?.includes("t3") ? (
                    <div className="h-24 input-disabled"></div>
                  ) : (
                    <div>{x}</div>
                  );
                }
              },
            },
          ],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
          
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.installationTarget;
              const modified = y?.holdInsTarget;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          width: "120px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.upperCap;
              const modified = y?.holdUpperCap;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.incPerTarget;
              const modified = y?.holdInsPerTarget;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },

        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p1;
              const modified = y?.holdP1;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
      ];
    }
    if (schemeType == 8) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.installationTarget;
              const modified = y?.holdInsTarget;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.incPerTarget;
              const modified = y?.holdInsPerTarget;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p1;
              const modified = y?.holdP1;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Expected",
          dataIndex: "installationTarget",
          width: "200px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.installationTarget;
              const modified = y?.holdInsTarget;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.incPerTarget;
              const modified = y?.holdInsPerTarget;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
          width: "190px",

        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {

              const simple = y?.p1;
              const modified = y?.holdP1;
              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{x}</div>
              
            }
          },
        },
      ];
    }
    return [];
  };
  const getModifiedReason=()=>viewType == "hold"?[{
    title: "MODIFIED REMARKS",
    dataIndex: "holdRemarks",
    key: "holdRemarks",
    align: "center",
    ellipsis: {
      showTitle: false,
    },
                width: '120px',
    shouldCellUpdate: (record, prevRecord) =>
      record.holdRemarks !== prevRecord.holdRemarks,
    render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
  }]:[]
  const getRemarksHold=()=>viewType == "hold"?[{
    title: "HOLD REASON",
    dataIndex: "holdReason",
    key: "holdReason",
    align: "center",
    ellipsis: {
      showTitle: false,
    },
                width: '120px',
    shouldCellUpdate: (record, prevRecord) =>
      record.holdReason !== prevRecord.holdReason,
    render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
  }]:[]
  const commonColumns = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "scheme",
      width: "145px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      render: (_, item) => {
        let color = " ";
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838";
            break;
            // #Inprogress
          case 1:
            color = "#FCE838";
            break;
            // #Approved
          case 2:
            color = "#51DE4E";
            break;
              // #Withdraw
          case 3:
            color = "#FFAE00";
            break;
              // #Cancel
          case 4:
            color = "#FF3232";
            break;
             // #Reject
          case 5:
            color = "#FF3232";
            break;
             //Deviation
          case 7:
              color = "#bcb38d";
              break;
             // #AutoRejected;
         case 8:
              color = "rgb(255, 0, 255)";
              break;
              case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
      fixed: "left",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      fixed: "left",
      dataIndex: "dealerLocation",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "160px",
      key: "DealerName",
      // fixed: "left",
    },
    {
      title: "State",
      dataIndex: "stateName",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "100px",
      key: "StateName",
      // fixed: "left",
    },
    ...getStateHead(),
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "180px",
      key: "TerritoryManager",
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      // sorter: true,
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      // sorter: true,
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    ...getCols(selectedSchemeType),
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "150px",
    },
    ...getModifiedReason(),
    ...getRemarksHold(),
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "180px",

      render: (x, record) => (
        record?.status == 1 ? (<Space size="small">
          <Tooltip title="Edit" placement="top" className="infoIcon">
            <EditOutlined
              // type="primary" // Disable the button based on the condition
              onClick={() => handleSingleEdit(x)}
              style={{ fontSize: "20px" }}
            />
          </Tooltip>
          <Tooltip title="Accept" placement="top" className="infoIcon">
            <CheckOutlined
              style={{ color: "#51DE4E", fontSize: "20px" }}
              onClick={() => {
                setOpenAcceptModal(true)
                setSchemeIdNo([record])
              }}
            />
          </Tooltip>
          <Tooltip title="Reject" placement="top" className="infoIcon">
            <CloseOutlined
              style={{ color: "#FF3232", fontSize: "20px" }}
              onClick={() => {
                setOpenRejectModal(true)
                setSchemeIdNo([record])
              }}
            />
          </Tooltip>
        </Space>
        ) : null),
    },
  ];

  const scroll =
    selectedSchemeType == 1 || selectedSchemeType == 2
      ? {
        x: 4200,
        y: 1000,
      }
      : {
        x: 2000,
        y: 300,
      };


  const getDownloadCols = item => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    let createDate = dayjs(item.createdDate).utc('z').local().tz(dayjs.tz.guess()).format("MMM D, YYYY");
    // Slab based scheme
    if (selectedSchemeType == 3) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Target_T1: item.t1,
        Target_T2: item.t2,
        Inc_Per_Tractor_R1: item.r1,
        Inc_Per_Tractor_R2: item.r2,
        Payout_P1: item.p1,
        Payout_P2: item.p2,
        Remarks: item.reason
      }
    }
    // Single Target Scheme
    if (selectedSchemeType == 8) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason
      }
    }
    // Single Target With Upper Cap Scheme
    if (selectedSchemeType == 4) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Upper_Cap: item.upperCap,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason
      }
    }
    // Unconditional Scheme
    if (selectedSchemeType == 5) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Expected: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason
      }
    }
  }

  const captalizeObjKeys = obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  );

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };
    if (selectedRows?.length == 0) {
      ToastifyShow("Please select from checkbox", "error")
      return false
    }
    const data = dataSource.map(item => {
      return captalizeObjKeys(getDownloadCols(item))
    })
    if (selectedSchemeType == 5) {
      const data = dataSource.filter(item => selectedRows.includes(item.schemeId))
        .map((item) => {
          let formattedStartDate = dayjs(item?.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item?.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            STATEHEADNAME: item.stateHeadName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
            INSTALLATION_EXPECTED: item.installationTarget,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
            REMARKS: item.reason,
          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }

      let tab_text = userRole != "SADM" ? `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
         <th >STATE HEAD</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          </tr>
      </thead>`: `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
         <th >STATE HEAD</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          </tr>
      </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
    <td>${x?.DEALER_CODE}</td>
    <td>${x?.DEALER_NAME}</td>
    <td>${x?.STATE}</td>
    <td>${x?.DEALER_LOCATION}</td>
    ${userRole != "SADM" && `<td>${x?.STATEHEADNAME}</td>`}
    <td>${x?.TERRITORY_MANAGER}</td>
    <td>${x?.START_MONTH}</td>
    <td>${x?.END_MONTH}</td>
    <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
    <td>${x?.INSTALLATION_EXPECTED}</td>

    <td>${x?.INC_PER_TRACTOR}</td>
    <td>${x?.PAYOUT}</td>
    <td>${x?.REMARKS}</td>
    </tr>`;
      });

      let totals = {
        INSTALLATION_EXPECTED: 0,
        INC_PER_TRACTOR: 0,
        PAYOUT:0,
        REMARKS:""
       
    };

data.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0 ;
        }
    });
    totals.INC_PER_TRACTOR =  totals.INSTALLATION_EXPECTED !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_EXPECTED):0;

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if(key != 'REMARKS'){
          if (key.startsWith('INC_PER') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", );
      // document.body.appendChild(link);
      // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
    else if (selectedSchemeType == 4) {
      const data = dataSource.filter(item => selectedRows.includes(item.schemeId))?.map((item) => {
        let formattedStartDate = dayjs(item?.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item?.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          SCHEME_NO: item.schemeNo,
          DEALER_CODE: item.dealerCode,
          DEALER_NAME: item.dealerName,
          STATE: item.stateName,
          STATEHEADNAME: item.stateHeadName,
          DEALER_LOCATION: item.dealerLocation,
          TERRITORY_MANAGER: item.territoryManager,
          START_MONTH:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
          INSTALLATION_TARGET: item.installationTarget,
          UPPER_CAP: item.upperCap,
          INC_PER_TRACTOR: item.incPerTarget,
          PAYOUT: item.p1,
          REMARKS: item.reason,
        });
      });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      let tab_text = userRole != "SADM" ? `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >STATE HEAD</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`: `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  ${userRole != "SADM" && `<td>${x?.STATEHEADNAME}</td>`}
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.UPPER_CAP}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
  </tr>`;
      });
      let totals = {
        INSTALLATION_TARGET: 0,
        UPPER_CAP: 0,
        INC_PER_TRACTOR:0,
        PAYOUT: 0,
        REMARKS:""
       
    };


    data.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0 ;
        }
    });
    totals.INC_PER_TRACTOR =  totals.INSTALLATION_TARGET !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_TARGET):0;

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if(key != 'REMARKS'){
          if (key.startsWith('INC_PER') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Installation_Single_Target_With_Upper_Cap${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `Installation_Single_Target_With_Upper_Cap-${new Date().toJSON().slice(0, 10)}.xlsx`);
      // document.body.appendChild(link);
      // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
    else if (selectedSchemeType == 8) {
      const data = dataSource.filter(item => selectedRows.includes(item.schemeId))?.map((item) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          SCHEME_NO: item.schemeNo,
          DEALER_CODE: item.dealerCode,
          DEALER_NAME: item.dealerName,
          STATE: item.stateName,
          STATEHEADNAME: item.stateHeadName,
          DEALER_LOCATION: item.dealerLocation,
          TERRITORY_MANAGER: item.territoryManager,
          START_MONTH:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
          INSTALLATION_TARGET: item.installationTarget,
          INC_PER_TRACTOR: item.incPerTarget,
          PAYOUT: item.p1,
          REMARKS: item.reason,
        });
      });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      let tab_text = userRole != "SADM" ? `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >STATE HEAD</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`:
        `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  ${userRole != "SADM" && `<td>${x?.STATEHEADNAME}</td>`}
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
  </tr>`;
      });
      let totals = {
        INSTALLATION_TARGET: 0,
        INC_PER_TRACTOR: 0,
        PAYOUT: 0,
        REMARKS:""
       
    };

    data.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0 ;
        }
    });
    totals.INC_PER_TRACTOR =  totals.INSTALLATION_TARGET !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_TARGET):0;

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if(key != 'REMARKS'){
          if (key.startsWith('INC_PER') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Installation_Single_Target_${new Date().toJSON().slice(0, 10)}.xlsx`);      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download",`Download_Installation_Single_Target-${new Date().toJSON().slice(0, 10)}.xlsx`);
      // document.body.appendChild(link);
    }
    else if (selectedSchemeType == 3) {
      const data = dataSource.filter(item => selectedRows.includes(item.schemeId))?.map((item) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Name: item.dealerName,
          State: item.stateName,
          STATEHEADNAME: item.stateHeadName,
          Dealer_Location: item.dealerLocation,
          Territory_Manager: item.territoryManager,
          Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
          Target_T1: item.t1,
          Target_T2: item.t2,
          Target_T3: item.t3,
          Inc_Per_Tractor_R1: item.r1,
          Inc_Per_Tractor_R2: item.r2,
          Inc_Per_Tractor_R3: item.r3,
          PAYOUT_P1: item.p1,
          PAYOUT_P2: item.p2,
          PAYOUT_P3: item.p3,
          Remarks: item.reason,
          TOPTARGET: item.topTarget
        })
      })
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }

      let tab_text = userRole != "SADM" ? `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">STATE HEAD</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`: `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;
      data?.forEach((header) => {
        tab_text += `<tr ><td>${header?.SCHEME_NO}</td>
  <td>${header?.DEALER_CODE}</td>
  <td>${header?.DEALER_NAME}</td>
  <td>${header?.STATE}</td>
  <td>${header?.DEALER_LOCATION}</td>
  ${userRole != "SADM" && `<td>${header?.STATEHEADNAME}</td>`}
  <td>${header?.TERRITORY_MANAGER}</td>
  <td>${header?.TOPTARGET?.toUpperCase()}</td>
  <td>${header?.START_MONTH}</td>
  <td>${header?.END_MONTH}</td>
  <td>${header?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${header?.TARGET_T1}</td>
  <td>${header?.TARGET_T2}</td>
  <td>${header?.TARGET_T3}</td>
  <td>${header?.INC_PER_TRACTOR_R1}</td>
  <td>${header?.INC_PER_TRACTOR_R2}</td>
  <td>${header?.INC_PER_TRACTOR_R3}</td>
  <td>${header?.PAYOUT_P1}</td>
  <td>${header?.PAYOUT_P2}</td>
  <td>${header?.PAYOUT_P3}</td>
  <td>${header?.REMARKS}</td>
  </tr>`;
      });

      
      let totals = {
        TARGET_T1: 0,
        TARGET_T2: 0,
        TARGET_T3:0,
        INC_PER_TRACTOR_R1: 0,
        INC_PER_TRACTOR_R2: 0,
        INC_PER_TRACTOR_R3: 0,
        PAYOUT_P1: 0,
        PAYOUT_P2: 0,
        PAYOUT_P3: 0,
        REMARKS:""
       
    };

    data.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0 ;
        }
    });
    totals.INC_PER_TRACTOR_R1 = totals.TARGET_T1 !== 0 ? parseInt(totals.PAYOUT_P1 / totals.TARGET_T1) : 0;
    totals.INC_PER_TRACTOR_R2 = totals.TARGET_T2 !== 0 ? parseInt(totals.PAYOUT_P2 / totals.TARGET_T2) : 0;
    totals.INC_PER_TRACTOR_R3 = totals.TARGET_T3 !== 0 ? parseInt(totals.PAYOUT_P3 / totals.TARGET_T3) : 0;

      let totalsRow = "<tr><td colspan='10' >Total</td>";
      for (let key in totals) {
        if(key != 'REMARKS'){
          if (key.startsWith('INC_PER_TRACTOR')) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to Roral row
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 3).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });
    

      // generate file
      XLSX.writeFile(wb, `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      // new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `Slab_based_scheme.xlsx`);
      // document.body.appendChild(link);
      // link.click(); 
    }
  }

  const tableHeader = (d) => {
    return (
      <Row className="d-none">
        <Col>
          <Button
            // disabled={selectedRows?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
            ref={reference}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div>
        <Table
          title={tableHeader}
          style={{
            cursor: "default",
            marginTop: "9px",
          }}
          summary={(pageData) => {
            const fieldSum = {
              b: 0,
              i: 0,
              c: 0,
              totalR1: 0,
              totalR2: 0,
              totalR3: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              T2_T1: 0,
              T3_T2: 0,
              vol: 0,
              perTr: 0,
              totalAmount: 0,
              T1: 0,
              T2: 0,
              T3: 0,
              R1: 0,
              R2: 0,
              R3: 0,
              P1: 0,
              P2: 0,
              P3: 0,
              P1Slab: 0,
              P2Slab: 0,
              P1Single: 0,
              P1UC: 0,
              P1U: 0,
              target: 0,
              incPerTarget: 0,
              payout: 0,
              installationTarget: 0,
              installationExpected: 0,
              upperCap: 0,
              holdInsTarget:0,
              holdP1:0,
              holdInsPerTarget:0,
              holdUpperCap:0,
              holdT1:0,
              holdT2:0,
              holdT3:0,
              holdP1Slab:0,
              holdP2Slab:0,
              holdP3Slab:0,
              holdR1:0,
              holdR2:0,
              holdR3:0,
              holdP1:0,
              holdP2:0,
              holdP3:0,
            };

            pageData.forEach(
              ({
                payoutR1,
                payoutR2,
                payoutR3,
                TEPamount,
                BooksCollectionTargetT1,
                BooksCollectionTargetT2,
                BooksCollectionTargetT3,
                BooksCollectionTargetT2T1,
                BooksCollectionTargetT3T2,
                TEPvol,
                t1,
                t2,
                t3,
                r1,
                r2,
                r3,
                p1,
                p2,
                p3,
                Target,
                upperCap,
                incPerTarget,
                Payout,
                TEPpertr,
                installationTarget,
                topTarget,
                holdTopTarget,
                holdInsTarget,
                schemeTypeId,
                holdInsPerTarget,
                holdUpperCap,
                holdT1,
                holdT2,
                holdT3,
                holdR1,
                holdR2,
                holdR3,
                holdP1,
                holdP2,
                holdP3,
              }) => {
                fieldSum.totalR1 += payoutR1 ? parseInt(payoutR1) : 0;
                fieldSum.totalR2 += payoutR2 ? parseInt(payoutR2) : 0;
                fieldSum.totalR3 += payoutR3 ? parseInt(payoutR3) : 0;
                fieldSum.installationTarget += installationTarget ? parseInt(installationTarget) : 0;
                fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                fieldSum.t1 += (BooksCollectionTargetT1 && topTarget?.includes("t1")) ? parseInt(BooksCollectionTargetT1 || 0) : 0;
                fieldSum.t2 += (BooksCollectionTargetT2 && topTarget?.includes("t2")) ? parseInt(BooksCollectionTargetT2 || 0) : 0;
                fieldSum.t3 += (BooksCollectionTargetT3 && topTarget?.includes("t3")) ? parseInt(BooksCollectionTargetT3 || 0) : 0;
                fieldSum.T2_T1 += (BooksCollectionTargetT2T1 && topTarget?.includes("t2")) ? parseInt(BooksCollectionTargetT2T1 || 0) : 0;
                fieldSum.T3_T2 += (BooksCollectionTargetT3T2 && topTarget?.includes("t3")) ? parseInt(BooksCollectionTargetT3T2 || 0) : 0;
                fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
                fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
                
                fieldSum.T1 += (t1 && topTarget?.includes("t1")) ? parseInt(t1 || 0) : 0;
                fieldSum.T2 += (t2 && topTarget?.includes("t2")) ? parseInt(t2 || 0) : 0;
                fieldSum.T3 += (t3 && topTarget?.includes("t3")) ? parseInt(t3 || 0) : 0;
                fieldSum.R1 = r1 ? parseInt(p1) / parseInt(t1) : 0;
                fieldSum.R2 = r2 ? parseInt(p2) / parseInt(t2) : 0;
                fieldSum.R3 = r3 ? parseInt(p3) / parseInt(t3) : 0;
                // fieldSum.R1 += r1 ? parseInt(r1) : 0;
                // fieldSum.R2 += r2 ? parseInt(r2) : 0;
                fieldSum.P1 += p1 ? parseInt(p1 || 0) : 0;
                fieldSum.P2 += (p2 && topTarget?.includes("t2")) ? parseInt(p2 || 0) : 0;
                fieldSum.P3 += (p3 && topTarget?.includes("t3")) ? parseInt(p3 || 0) : 0;
                fieldSum.P1Slab = p1 ? parseInt(fieldSum.T1 * fieldSum.R1) : 0;
                fieldSum.P2Slab = p2 ? parseInt(fieldSum.T2 * fieldSum.R2) : 0;
                fieldSum.P3Slab = p3 ? parseInt(fieldSum.T3 * fieldSum.R3) : 0;

                fieldSum.target += Target ? parseInt(Target) : 0;
                fieldSum.incPerTarget += incPerTarget
                  ? parseInt(incPerTarget)
                  : 0;
                  // holdInsPerTarget
                fieldSum.P1Single = p1
                  ? parseInt(fieldSum.incPerTarget * fieldSum.target)
                  : 0;
                fieldSum.upperCap += upperCap ? parseInt(upperCap) : 0;
                fieldSum.holdUpperCap += holdUpperCap ? parseInt(holdUpperCap) : 0;
                // holdUpperCap
                fieldSum.P1UC = p1
                  ? parseInt(fieldSum.upperCap * fieldSum.incPerTarget)
                  : 0;

                fieldSum.payout += Payout ? parseInt(Payout) : 0;
                fieldSum.installationExpected += Target ? parseInt(Target) : 0;

                fieldSum.P1U = p1
                  ? parseInt(
                    fieldSum.incPerTarget * fieldSum.installationExpected
                  )
                  : 0;
                  if (viewType == "hold") {
                    // holdTopTarget
                    fieldSum.holdT1 += (holdT1 && holdTopTarget?.includes("t1")) ? parseInt(holdT1 || 0) : 0;
                    fieldSum.holdT2 += (holdT2 && holdTopTarget?.includes("t2")) ? parseInt(holdT2 || 0) : 0;
                    fieldSum.holdT3 += (holdT3 && holdTopTarget?.includes("t3")) ? parseInt(holdT3 || 0) : 0;
                    fieldSum.holdR1 = holdR1 ? parseInt(holdP1) / parseInt(holdT1) : 0;
                    fieldSum.holdR2 = holdR2 ? parseInt(holdP2) / parseInt(holdT2) : 0;
                    fieldSum.holdR3 = holdR3 ? parseInt(holdP3) / parseInt(holdT3) : 0;
                    fieldSum.holdP1Slab = parseInt(fieldSum.holdT1 * fieldSum.holdR1)|| 0;
                    fieldSum.holdP2Slab = parseInt(fieldSum.holdT2 * fieldSum.holdR2)|| 0;
                    fieldSum.holdP3Slab = parseInt(fieldSum.holdT3 * fieldSum.holdR3)|| 0;

                    fieldSum.holdInsTarget += holdInsTarget ? parseInt(holdInsTarget) : 0;
                    fieldSum.holdP1 += holdP1 ? parseInt(holdP1) : 0;
                    // holdP1
                    fieldSum.holdInsPerTarget += holdInsPerTarget
                    ? parseInt(holdInsPerTarget)
                    : 0;
              }
            }
            );
            //  return evaluateTotalRow(fieldSum);
            if (selectedSchemeType == 1 || selectedSchemeType == 2) {
              return (
                <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  <Table.Summary.Cell index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                  <Table.Summary.Cell index={15}></Table.Summary.Cell>
                  <Table.Summary.Cell index={16}></Table.Summary.Cell>
                  <Table.Summary.Cell index={17}></Table.Summary.Cell>
                  <Table.Summary.Cell index={18}></Table.Summary.Cell>
                  <Table.Summary.Cell index={19}></Table.Summary.Cell>
                  <Table.Summary.Cell index={20}></Table.Summary.Cell>
                  <Table.Summary.Cell index={21}></Table.Summary.Cell>
                  <Table.Summary.Cell index={22}></Table.Summary.Cell>
                  <Table.Summary.Cell index={23}></Table.Summary.Cell>
                  <Table.Summary.Cell index={24}>
                    {fieldSum.t1 ? fieldSum.t1 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={25}>
                    {fieldSum.t2 ? fieldSum.t2 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={26}>
                    {fieldSum.T2_T1 ? fieldSum.T2_T1 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={27}>
                    {fieldSum.t3 ? fieldSum.t3 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={28}>
                    {fieldSum.T3_T2 ? fieldSum.T3_T2 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={29}></Table.Summary.Cell>
                  <Table.Summary.Cell index={30}> </Table.Summary.Cell>
                  <Table.Summary.Cell index={31}></Table.Summary.Cell>
                  <Table.Summary.Cell index={32}></Table.Summary.Cell>
                  <Table.Summary.Cell index={33}>
                    {fieldSum.totalR1 ? fieldSum.totalR1 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={34}>
                    {fieldSum.totalR2 ? fieldSum.totalR2 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={35}>
                    {fieldSum.totalR3 ? fieldSum.totalR3 : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={36}>
                    {fieldSum.vol ? fieldSum.vol : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={37}>
                    {fieldSum.perTr ? fieldSum.perTr : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={38}>
                    {fieldSum.totalAmount ? fieldSum.totalAmount : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={39}></Table.Summary.Cell>
                  <Table.Summary.Cell index={40}></Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            //Slab Based
            else if (selectedSchemeType == 3) {
              return (
                <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  { userRole != "NSH" && <Table.Summary.Cell index={10}></Table.Summary.Cell>}
                  {userRole != "SADM" && <Table.Summary.Cell index={99}></Table.Summary.Cell>}
                  <Table.Summary.Cell index={11}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.T1, fieldSum?.holdT1):viewType != "hold"&&fieldSum?.T1 || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.T2, fieldSum?.holdT2):viewType != "hold"&&fieldSum?.T2 || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.T3, fieldSum?.holdT3):viewType != "hold"&&fieldSum?.T3 || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14}>

                  {viewType == "hold"?TagAwaitingHold(Math.round(fieldSum.P1Slab / fieldSum.T1), Math.round(fieldSum.holdP1Slab / fieldSum.holdT1)):viewType != "hold"?Math.round(fieldSum.P1Slab / fieldSum.T1) : ""}
                    {/* {fieldSum.T1 && fieldSum.P1Slab ? Math.round(fieldSum.P1Slab / fieldSum.T1) : ""} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={15}>
                  {viewType == "hold"?TagAwaitingHold(Math.round(fieldSum.P2Slab / fieldSum.T2), Math.round(fieldSum.holdP2Slab / fieldSum.holdT2)):viewType != "hold"?Math.round(fieldSum.P2Slab / fieldSum.T2) : ""}
                    {/* {fieldSum.T2 && fieldSum.P2Slab ? Math.round(fieldSum.P2Slab / fieldSum.T2) : ""} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={16}>
                  {viewType == "hold"?TagAwaitingHold(Math.round(fieldSum.P3Slab / fieldSum.T3), Math.round(fieldSum.holdP3Slab / fieldSum.holdT3)):viewType != "hold"?Math.round(fieldSum.P3Slab / fieldSum.T3) : ""}
                    {/* {fieldSum.T3 && fieldSum.P3Slab ? Math.round(fieldSum.P3Slab / fieldSum.T3) : ""} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={17}>

                  {viewType == "hold"?TagAwaitingHold(fieldSum?.P1Slab, fieldSum?.holdP1Slab):viewType != "hold"&&fieldSum?.P1Slab || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={18}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.P2Slab, fieldSum?.holdP2Slab):viewType != "hold"&&fieldSum?.P2Slab || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={19}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.P3Slab, fieldSum?.holdP3Slab):viewType != "hold"&&fieldSum?.P3Slab || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={20}>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={21}>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            //Single Target
            else if (selectedSchemeType == 8) {

              return (
                <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                 { userRole != "NSH" &&<Table.Summary.Cell index={9}></Table.Summary.Cell>}
                  {userRole != "SADM" && <Table.Summary.Cell index={99}></Table.Summary.Cell>}
                  <Table.Summary.Cell index={10}>
                  
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.installationTarget, fieldSum?.holdInsTarget):viewType != "hold"&&fieldSum?.installationTarget || ""}
                    {/* {fieldSum.installationTarget ? fieldSum.installationTarget : ""} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.incPerTarget, fieldSum?.holdInsPerTarget):viewType != "hold"&&fieldSum?.incPerTarget?fieldSum?.incPerTarget: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.P1, fieldSum?.holdP1):viewType != "hold"&&fieldSum?.P1?fieldSum?.P1: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                  <Table.Summary.Cell index={15}></Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }

            else if (selectedSchemeType == 4) {
              return (
                <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                 <Table.Summary.Cell index={8}></Table.Summary.Cell>
              {  userRole === "ZADM"  &&  <Table.Summary.Cell index={8}></Table.Summary.Cell>}
              {  userRole === "ZADM"  &&  <Table.Summary.Cell index={8}></Table.Summary.Cell>}

                 {  userRole === "NSH" || userRole != "SADM"  || userRole === "ZADM"  &&<Table.Summary.Cell index={8}></Table.Summary.Cell>}
                
              
                  {userRole != "SADM"  ||userRole != "NSH"    && <Table.Summary.Cell index={99}></Table.Summary.Cell>}
                  { userRole != "NSH" || userRole != "SADM" && <Table.Summary.Cell index={10}> </Table.Summary.Cell>}
                  <Table.Summary.Cell index={11}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.installationTarget, fieldSum?.holdInsTarget):viewType != "hold"&&fieldSum?.installationTarget?fieldSum?.installationTarget: ""}

                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.upperCap, fieldSum?.holdUpperCap):viewType != "hold"&&fieldSum?.upperCap?fieldSum?.upperCap: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.incPerTarget, fieldSum?.holdInsPerTarget):viewType != "hold"&&fieldSum?.incPerTarget?fieldSum?.incPerTarget: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.P1, fieldSum?.holdP1):viewType != "hold"&&fieldSum?.P1?fieldSum?.P1: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={15}></Table.Summary.Cell>
                  <Table.Summary.Cell index={16}></Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            //Unconditional Installation
            else if (selectedSchemeType == 5) {
              return (
                <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                { userRole != "NSH" && <Table.Summary.Cell index={9}></Table.Summary.Cell>}
                  
                  {userRole != "SADM" && <Table.Summary.Cell index={99}></Table.Summary.Cell>}
                  <Table.Summary.Cell index={10}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.installationTarget, fieldSum?.holdInsTarget):viewType != "hold"&&fieldSum?.installationTarget?fieldSum?.installationTarget: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                    {/* holdP1 */}
                    {viewType == "hold"?TagAwaitingHold(fieldSum?.incPerTarget, fieldSum?.holdInsPerTarget):viewType != "hold"&&fieldSum?.incPerTarget?fieldSum?.incPerTarget: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}>
                  {viewType == "hold"?TagAwaitingHold(fieldSum?.P1, fieldSum?.holdP1):viewType != "hold"&&fieldSum?.P1?fieldSum?.P1: ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
          }}
          columns={commonColumns}
          dataSource={dataSource}
          loading={loading}
          onChange={handleChange}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRow) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow);
              setSelectedRows(selectedRow.map(item => item.schemeId))
            },
            getCheckboxProps: (record) => ({
              // Column configuration not to be checked
              name: record.name,
            }),
          }}
          scroll={scroll}
          pagination={false}
        />
      </div>

    </>
  );
};
export default AwaitingApprovalListInstallationDetails;
