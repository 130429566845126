import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { useLocation } from "react-router-dom";
import tableData from "./modificationInstallationTableData";
import { getEditDashboardDetailApi } from "../../../redux/modification/modificationSlice";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const ModificationInstallationTable = ({ dataSource, loading, selectedSchemeType, setSchemeIdNo, handleModifyData,setSelectedRowKeys,selectedRowKeys , selectedLabel,handleChange}) => {
  const SLAB_BASED = 3;
  const SINGLE_TARGET = 8;
  const SINGLE_TARGET_WITH_UPPER_CAP = 4;
  const UNCONDITIONAL_STATEMENT = 5;
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const [tableDataArr, setTableDataArr] = useState([])
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  useEffect(() => {
    if (dataSource) {
      setTableDataArr(dataSource?.map((x, index) => { return { ...x, "key": index } }))
    }
  }, [dataSource])

  
  const columns = tableData({ selectedSchemeType, handleModifyData ,selectedLabel});
  const { loadingScheme, schemeApprove } = useSelector(
    (state) => state.schemagenerate
  );



  const getValue = (field) => field
  const colsToBeadded = dataSource&&dataSource.map(item => ({
    p1: item?.p1,
    p2: item?.p2,
    p3: item?.p3,
    t1: item?.t1,
    t2: item?.t2,
    t3: item?.t3,
    r1: item?.r1,
    r2: item?.r2,
    r3: item?.r3,
    actual_Installation_Last_Year_Month: item?.actual_Installation_Last_Year_Month,
    installationTarget: item?.installationTarget,
    incPerTractor: item?.incPerTarget,
    payout: item?.upperCap,
    upperCap: item?.upperCap,
    topTarget:item?.topTarget
  }
  ))
  
  const pageTotal = colsToBeadded&&colsToBeadded.reduce((acc, obj) => {
    for (const key in obj) {
      if (acc.hasOwnProperty(key)) {
        acc[key] += obj[key];

      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});

  const onSelectChange = (newSelectedRowKeys, y) => {
    setSchemeIdNo(y)
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
 


  const hasSelected = selectedRowKeys;
  return (
    <Table
      size="small"
      loading={loading ? true : false}
      summary={(pageData) => {

        return (
          <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>

            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell fixed index={1}>Total</Table.Summary.Cell>
            <Table.Summary.Cell index={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={3}></Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}></Table.Summary.Cell>
            <Table.Summary.Cell index={6}></Table.Summary.Cell>
            <Table.Summary.Cell index={7}></Table.Summary.Cell>
            {userRole != "SADM"&&<Table.Summary.Cell index={99}></Table.Summary.Cell>}
            <Table.Summary.Cell index={9}></Table.Summary.Cell>
            <Table.Summary.Cell index={99}></Table.Summary.Cell>
            {
              selectedSchemeType == SLAB_BASED &&
              <>
                <Table.Summary.Cell index={10}/>     
                <Table.Summary.Cell index={11}>{ getValue(pageTotal?.topTarget?.includes('t1')) ? (getValue(pageTotal?.t1)) :""}</Table.Summary.Cell>
                <Table.Summary.Cell index={12}>{ getValue(pageTotal?.topTarget?.includes('t2')) ?(getValue(pageTotal?.t2)) :""}</Table.Summary.Cell>
                <Table.Summary.Cell index={13}>{ getValue(pageTotal?.topTarget?.includes('t3')) ?(getValue(pageTotal?.t3)):""}</Table.Summary.Cell>
                <Table.Summary.Cell index={14}> {(getValue(pageTotal?.t1) !== 0 && getValue(pageTotal?.topTarget?.includes('t1')))  ? Math.round(getValue(pageTotal?.p1 /getValue(pageTotal?.t1))):""}</Table.Summary.Cell>
                <Table.Summary.Cell index={15}> {(getValue(pageTotal?.t2) !== 0  && getValue(pageTotal?.topTarget?.includes('t2'))) ?Math.round(getValue(pageTotal?.p2 /getValue(pageTotal?.t2) )):""}</Table.Summary.Cell>
                <Table.Summary.Cell index={16}> {(getValue(pageTotal?.t3) !== 0  &&  getValue(pageTotal?.topTarget?.includes('t3'))) ? Math.round(getValue(pageTotal?.p3 /getValue(pageTotal?.t3) )):""}</Table.Summary.Cell>
                <Table.Summary.Cell index={17}>{((getValue(pageTotal?.p1) !== 0  &&  getValue(pageTotal?.topTarget?.includes('t1'))) ?getValue(pageTotal?.p1) :"")}</Table.Summary.Cell>
                <Table.Summary.Cell index={18}>{((getValue(pageTotal?.p2) !== 0  &&  getValue(pageTotal?.topTarget?.includes('t2'))) ?getValue(pageTotal?.p2) :"")}</Table.Summary.Cell>
                <Table.Summary.Cell index={19}>{((getValue(pageTotal?.p3) !== 0  &&  getValue(pageTotal?.topTarget?.includes('t3'))) ?getValue(pageTotal?.p3) :"")}</Table.Summary.Cell>
                <Table.Summary.Cell index={20}/>
                <Table.Summary.Cell index={21}/>
              </>
            }

            {
              selectedSchemeType == SINGLE_TARGET &&
              <>
                <Table.Summary.Cell index={10}>{getValue(pageTotal?.installationTarget)}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{ getValue(pageTotal?.installationTarget) ==0 ? 0 :  (Math.round(getValue(pageTotal?.p1) / getValue(pageTotal?.installationTarget)))||""} </Table.Summary.Cell>
                <Table.Summary.Cell index={12}>{getValue(pageTotal?.p1)}</Table.Summary.Cell>
                <Table.Summary.Cell index={13}/>
                <Table.Summary.Cell index={14}/>
              </>
            }

            {
              selectedSchemeType == SINGLE_TARGET_WITH_UPPER_CAP &&
              <>

                <Table.Summary.Cell index={10}>{getValue(pageTotal?.installationTarget)}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{getValue(pageTotal?.upperCap)}</Table.Summary.Cell>
                <Table.Summary.Cell index={12}>{Math.round(getValue(pageTotal?.p1) / getValue(pageTotal?.installationTarget)) ||"" }</Table.Summary.Cell>
                <Table.Summary.Cell index={13}>{getValue(pageTotal?.p1)}</Table.Summary.Cell>
                <Table.Summary.Cell index={14}/>
                <Table.Summary.Cell index={15}/>
              </>
            }

            {
              selectedSchemeType == UNCONDITIONAL_STATEMENT &&
              <>
                <Table.Summary.Cell index={10}>{getValue(pageTotal?.installationTarget)}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{Math.round(getValue(pageTotal?.p1) / getValue(pageTotal?.installationTarget)) ||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={12}>{getValue(pageTotal?.p1)}</Table.Summary.Cell>
                <Table.Summary.Cell index={13}/>
                <Table.Summary.Cell index={14}/>
              </>
            }

    
          </Table.Summary.Row>

        );
      }}

      rowSelection={rowSelection}
      onChange={handleChange}
      columns={columns}
      dataSource={tableDataArr}
      pagination={false}
      scroll={{
        x: 500,
        y: 200,
      }}
    />
  );
};
export default ModificationInstallationTable;
