import React from "react";
import { Table, Space, Row, Col, Button, message, Pagination, Select, Tag } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { draftScheme } from "../../../redux/collection/AllDraftScheme";
import MergeModal from "../../../components/modal/alertModal/ConfirmModal";
import mergeScheme from "../../../utils/mergeUnmerge/mergeScheme";
import unMergeScheme from "../../../utils/mergeUnmerge/unMergeScheme";
import { ReactComponent as MergeIcon } from "../../../assets/vector/merge.svg";
import { ReactComponent as UnmergeIcon } from "../../../assets/vector/unmerge.svg";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import ToastifyShow from "../../../components/ToastifyShow";
import XLSX from "xlsx-js-style";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { formatNumberDecimal } from "../../../utils/formatter";
import { ConstructionOutlined } from "@mui/icons-material";
//import type { ColumnsType } from 'antd/es/table'

const currentFiscalYear = getCurrentFiscalYear();
const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  pageSize: "150",
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "DealerCode",
};

const DraftSchemeTable = ({
  getSelectedRowKeys,
  fetchGeneratedScheme,
  selectedSchemeType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [disable, setDisable] = useState(false);
  const [enable, setEnable] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [openUnMergeModal, setOpenUnMergeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const listData = useSelector((state) => state.draftScheme.data);
  const [keys, setKeys] = useState([]);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [selectedFirstMonth, setSelectedFirstMonth] = useState(new Date());
  const [messageApi, contextHolder] = message.useMessage();
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [selectedRows, setSelectedRows] = useState([]);

  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;
  const userId = userData.userId;
  const userName = userData.userName;


  const handleSingleEdit = (record) => {
    const selectedRow = JSON.parse(sessionStorage.getItem("rowData"));
    const recordExist =
      selectedRow && selectedRow.find((item) => item.key === record.key);

    if (recordExist) {
      navigate("/collection/draftSchemes/edit", { state: schemeIdNo });
    } else {
      messageApi.open({
        type: "warning",
        content: "Please select the checkbox !",
        duration: 2,
      });
    }
  };
  const handleSaveSubmit = () => {
    const payload = listData?.data?.data
      ?.filter((data) => keys.includes(data.schemeId))
      ?.map((obj) => {
        const { status, ...rest } = obj;
        return rest;
      });

    if (payload.length != 0) {
      setOpenSubmitModal(!openSubmitModal);
    } else {
      messageApi.open({
        type: "warning",
        content: "Please select the checkbox !",
        duration: 2,
      });
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({
      ...collectionData,
      sortingOrder: sorter?.order,
      sortingType: sorter?.columnKey,
    });
  };

  const secondPastMonth = new Date(
    selectedFirstMonth.getFullYear(),
    selectedFirstMonth.getMonth() - 2,
    1
  );
  const firstPastMonth = new Date(
    selectedFirstMonth.getFullYear(),
    selectedFirstMonth.getMonth() - 1,
    1
  );
  const thirdPastMonth = new Date(
    selectedFirstMonth.getFullYear(),
    selectedFirstMonth.getMonth() - 3,
    1
  );

  const firstPastMonthString = firstPastMonth.toLocaleString("en-IN", {
    year: "2-digit",
    month: "short",
  });
  const secondPastMonthString = secondPastMonth.toLocaleString("en-IN", {
    year: "2-digit",
    month: "short",
  });
  const thirdPastMonthString = thirdPastMonth.toLocaleString("en-IN", {
    year: "2-digit",
    month: "short",
  });

  const columns = [
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "code",
      fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerCode",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "Location",
      fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerLocation",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "name",
      // fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerName",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: true,
      key: "StateName",
      // fixed: "left",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "Manager",
      width: "190px",
      sorter: true,
      key: "TerritoryManager",
      defaultSortOrder: "ascend",
    },
    {
      title: "Top Slab",
      width: "150px",
      render: (x, y, index) => y?.topTarget?.toUpperCase(),
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "StartMonth",
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "EndMonth",
    },
    {
      title: "Industry",
      dataIndex: "industry",
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
    },
    {
      title: "C",
      dataIndex: "oldCollection",
    },
    // {
    //   title: "Slab Achieved Last Year",
    //   dataIndex: "target",
    // },
    {
      title: (
        <div>
          Opening <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
        },
      ],
    },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      children: [
        {
          title: (
            <span>
              Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_3",
          width: "180px"
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          width: "180px"
        },
        {
          title: (
            <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          width: "180px"
        },
        // {
        //   title: (
        //     <span>
        //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   width: "200px"
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "200px",
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      children: [
        {
          title: "Lacs",
          dataIndex: "bgLacsCurrentYear",
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "190px",
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      children: [
        {
          title: "B",
          dataIndex: "billing",
        },
        {
          title: "I",
          dataIndex: "installation",
        },
        {
          title: "C",
          dataIndex: "collection",
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      width: "80px",
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "110px",
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      children: [
        {
          title: "T1",
          dataIndex: "BooksCollectionTargetT1",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T2",
          dataIndex: "BooksCollectionTargetT2",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T2-T1",
          dataIndex: "BooksCollectionTargetT2T1",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T3",
          dataIndex: "BooksCollectionTargetT3",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T3-T2",
          dataIndex: "BooksCollectionTargetT3T2",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T4",
          dataIndex: "BooksCollectionTargetT4",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T4-T3",
          dataIndex: "BooksCollectionTargetT4T3",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T5",
          dataIndex: "BooksCollectionTargetT5",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T5-T4",
          dataIndex: "BooksCollectionTargetT5T4",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T6",
          dataIndex: "BooksCollectionTargetT6",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T6-T5",
          dataIndex: "BooksCollectionTargetT6T5",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",

      children: [
        {
          title: "R1",
          dataIndex: "PayoutRatep1",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R2",
          dataIndex: "PayoutRatep2",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R3",
          dataIndex: "PayoutRatep3",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R4",
          dataIndex: "PayoutRatep4",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R5",
          dataIndex: "PayoutRatep5",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R6",
          dataIndex: "PayoutRatep6",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
    },
    {
      title: "Payout",
      dataIndex: "payout",

      children: [
        {
          title: "P1",
          dataIndex: "payoutR1",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "P2",
          dataIndex: "payoutR2",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "P3",
          dataIndex: "payoutR3",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "P4",
          dataIndex: "payoutR4",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "P5",
          dataIndex: "payoutR5",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "P6",
          dataIndex: "payoutR6",
          render: (x, y) => {
            return (
              !y?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "TEPvol",
          width: "150px",
        },
        {
          title: "Per Tr.",
          dataIndex: "TEPpertr",
          width: "150px",

        },
        {
          title: "Amount",
          dataIndex: "TEPamount",
          width: "150px",
        },
      ],
    },
    // {
    //   title: "Slab achieve",
    //   dataIndex: "slab",
    //   width:"150px",
    // },
    // {
    //   title: "",
    //   dataIndex: "",
    //   children: [
    //     {
    //       title: "Last 1 Month Slab Achieved",
    //       dataIndex: "LMSA",
    //     },
    //     {
    //       title: "Last 1 Month Col Qty",
    //       dataIndex: "LCQ",
    //     },
    //     {
    //       title: "Last 2 Month Slab Achieved",
    //       dataIndex: "LMSA1",
    //     },
    //     {
    //       title: "Last 2 Month Col Qty",
    //       dataIndex: "LCQ1",
    //     },
    //     ],
    //   },
    {
      title: "Action",
      key: "action",
      fixed: "right",

      render: (_, record) => (
        <Space size="small">
          <Button
            disabled={schemeIdNo.length > 1}
            onClick={() => handleSingleEdit(record)}
          >
            {" "}
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    // const userRole = userData.userRole;
    const demoParams = {
      fyear: currentFiscalYear,
      schemeType: selectedSchemeType,
      userid: userId,
      pageNumber: collectionData.pageNumber,
      pageSize: collectionData.pageSize,
      sortingOrder: collectionData.sortingOrder == "descend" ? "DESC" : "ASC",
      sortingType: collectionData.sortingType || "DealerCode",
      // userrole: userRole
    };
    // fetchSchemeList(userId);
    const fetchGeneratedScheme = async () => {
      let result = await axiosAPI.post("SchemeGenerate/GetDraftedScheme", {
        ...demoParams,
        PageNumber: parseInt(collectionData?.pageNumber),
        PageSize: parseInt(collectionData?.pageSize),
      });
      dispatch(draftScheme(result.data));
      if (result?.data?.data?.data && result?.data?.data?.data.length === 0)
        setDataSource([]);
    };
    // fetchSchemeList(userId);

    fetchGeneratedScheme(demoParams);
  }, [collectionData, selectedSchemeType]);

  useEffect(() => {
    const data = [];
    listData?.data?.data &&
      listData?.data?.data.map((item, index) => {
        for (let i = 0; i < 1; i++) {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          setSelectedFirstMonth(new Date(item.startMonth));
          data.push({
            status: item.status,
            key: item.schemeId,
            scheme: { ...item, topTarget: item.topTarget || "t1,t2,t3" },
            code: item.dealerCode,
            name: item.dealerName,
            Location: item.dealerLocation,
            state: item.stateName,
            Manager: item.territoryManager,
            TopTarget: item.TopTarget,
            StartMonth: formattedStartDate,
            EndMonth: formattedEndDate,
            industry: 102,
            // b: 52,
            // i: 23,
            // c: Math.round(item.collection),
            target: "",
            // nos: 50,
            // days: 0,
            nov21: null,
            dec21: null,
            // lac: 66,
            BooksCollectionTargetT1: item.t1,
            BooksCollectionTargetT2: item.t2,
            BooksCollectionTargetT2T1: item.t2_T1,
            BooksCollectionTargetT3: item.t3,
            BooksCollectionTargetT3T2: item.t3_T2,
            BooksCollectionTargetT4: item.t4,
            BooksCollectionTargetT4T3: item.t4_T3,
            BooksCollectionTargetT5: item.t5,
            BooksCollectionTargetT5T4: item.t5_T4,
            BooksCollectionTargetT6: item.t6,
            BooksCollectionTargetT6T5: item.t6_T5,
            PayoutRatep1: item.r1,
            PayoutRatep2: item.r2,
            PayoutRatep3: item.r3,
            PayoutRatep4: item.r4,
            PayoutRatep5: item.r5,
            PayoutRatep6: item.r6,
            payoutR1: item.p1,
            payoutR2: item.p2,
            payoutR3: item.p3,
            payoutR4: item.p4,
            payoutR5: item.p5,
            payoutR6: item.p6,
            reason: item.reason,
            TEPvol: item.volume,
            TEPpertr: item.perTr,
            TEPamount: item.amount,
            oldBillingQty: item.oldBillingQty,
            oldInstallation: item.oldInstallation,
            oldCollection: Math.round(item.oldCollection),
            oldOpeningStock: item.oldOpeningStock,
            closingOutStandingMonth_1: item.closingOutStandingMonth_1,
            closingOutStandingMonth_2: item.closingOutStandingMonth_2,
            closingOutStandingMonth_3: item.closingOutStandingMonth_3,
            bgLacsCurrentYear:
              item.bGLacsCurrentYear || item.bgLacsCurrentYear || 0,
            industry: item.industry,
            openingStockDays: item.openingStockDays,
            outStandingDays: item.outStandingDays,
            delayPlan: item.delayPlan,
            billing: item.billing,
            installation: item.installation,
            collection: Math.round(item.collection),
            slab: item.achievedSlab,
            topTarget: item.topTarget || "t1,t2,t3",
            closingStock: item.closingStock,
            closingOutStanding: item.closingOutStanding,
            billingFactorCOS: item.billingFactorCOS
            // LMSA: 'T2',
            // LCQ: 55,
            // LMSA1: 'T1',
            // LCQ1: 18000
          });
        }
        setDataSource(data);
        if (data && data.length === 0) setDataSource([]);
      });
  }, [listData, selectedSchemeType]);

  const handleUnMergeModal = () => {
    if (schemeIdNo.length === 0) {
      return message.warning("Select the merged scheme first");
    } else {
      setOpenUnMergeModal(!openUnMergeModal);
    }
  };
  useEffect(() => {
    const value = schemeIdNo.map((item) => item.scheme.isMerge);
    const schemeId = schemeIdNo.map((id) => id.scheme.schemeId);
    const editedRow = schemeIdNo.find((item) => item.scheme.isEdit);
    const isEdit = editedRow && editedRow.scheme.isEdit;
    setKeys(schemeId);
    // const status = schemeIdNo.some(item => item.scheme.status === 3 )
    setDisable(
      value.includes(true) && !value.includes(false)
        ? true
        : value.includes(true)
    );
    setEnable(
      !value.includes(true) && value.includes(false)
        ? true
        : value.includes(false)
    );
  }, [schemeIdNo]);

  const handleOk = async () => {
    await mergeScheme(schemeIdNo, userName, userId);
    setLoading(false);
    setOpenMergeModal(false);
  };

  const checkDate = async () => {
    let startMonth = "";
    let endMonth = "";
    for (let i = 0; i <= schemeIdNo.length - 1; i++) {
      for (let j = i + 1; j <= schemeIdNo.length - 1; j++) {
        if (
          schemeIdNo[i].StartMonth === schemeIdNo[j].StartMonth &&
          schemeIdNo[i].EndMonth === schemeIdNo[j].EndMonth
        ) {
          const monthStart = schemeIdNo[i].StartMonth.split("'")[0];
          const startYear = schemeIdNo[i].StartMonth.split("'")[1];
          const monthEnd = schemeIdNo[i].EndMonth.split("'")[0];
          const endYear = schemeIdNo[i].EndMonth.split("'")[1];
          startMonth = dayjs(`${monthStart} 20${startYear}`).format();
          endMonth = dayjs(`${monthEnd} 20${endYear}`).format();
        }
      }
    }
    if (startMonth === "") {
      return {
        error: "Scheme can't be merged due to different start  and end month",
      };
      // return message.warning("Scheme can't be merged due to different start  and end month")
    }
    return {
      error: "",
    };
  };
  const handleMerge = async (e, allData) => {
    try {
      if (schemeIdNo.length === 0) {
        return message.warning("Select Schemes to be merged");
      }
      const errorMessage = await checkDate();

      if (!!errorMessage?.error) {
        throw new Error(errorMessage.error);
      }
      if (schemeIdNo.length <= 4) {
        setOpenMergeModal(!openMergeModal);
      } else {
        message.warning("More than 4 Schemes can't be merged");
      }
    } catch (e) {
      message.warning(e.message);
    }
  };
  const handleUnMerge = async (e, allData) => {
    await unMergeScheme(schemeIdNo, userName, userId);
    setLoading(false);
    setOpenUnMergeModal(!openUnMergeModal);
    // handleUnMergeModal()
  };
  const fetchGenerateScheme = async (demoParams) => {
    let result = await axiosAPI.post(
      `SchemeGenerate/GetDraftedScheme`,
      demoParams
    );
    dispatch(draftScheme(result.data.data));
  };

  const handleSaveScheme = async () => {
    // e.preventDefault()
    const payload = listData?.data?.data
      ?.filter((data) => keys.includes(data.schemeId))
      ?.map((obj) => {
        const { status, ...rest } = obj;
        return rest;
      });

    await axiosAPI
      .post(`UpdateScheme/DraftToSchemeGenerate`, payload)
      .then((result) => {
        message.success({
          content: "Scheme successfully generated",
          className: "custom-message",
        });
        // setOpenSuccessModal(!openSuccessModal);
        const userData = decryptData(sessionStorage.getItem("userInfo"));
        const userId = userData.userId;
        const demoParams = {
          fyear: currentFiscalYear,
          schemeType: 6,
          userid: userId,
        };
        // fetchGenerateScheme(demoParams);
      });
    setLoading(false);
    setOpenUnMergeModal(false);
    navigate("/collection/allSchemes");
    // window.location.replace('/collection/allSchemes')
  };
  const handleCancel = () => {
    setOpenSubmitModal(!openSubmitModal);
  };

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };


    const data = dataSource
      .map((rec) => rec.scheme)
      .filter((item) => selectedRows.includes(item.schemeId))
      .map((item) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

        return captalizeObjKeys({
          // Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Location: item.dealerLocation,
          Dealer_Name: item.dealerName,
          Created_Date: item.createDate,
          State: item.stateName,
          Territory_Manager: item.territoryManager,
          Start_Month:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Industry: item.industry,
          B: item.oldBillingQty ? item.oldBillingQty : 0,
          I: Math.round(item.oldCollection),
          C: item.oldInstallation ? item.oldInstallation : 0,
          Opening_Stock_Nos: item.oldOpeningStock,
          Opening_Stock_Days: item.openingStockDays,
          FIRSTMONTH: item.closingOutStandingMonth_1,
          SECONDMONTH: item.closingOutStandingMonth_2,
          THIRDMONTH: item.closingOutStandingMonth_3,
          Lacs: "",
          Days: item.outStandingDays,
          BG_Lacs: item.bGLacsCurrentYear || item.bgLacsCurrentYear || 0,
          Installation_Plan_Nos: item.delayPlan,
          Billing_Details_B: item.billing,
          Billing_Details_i: item.installation,
          Billing_Details_c: Math.round(item.collection),
          Books_Collection_Target_T1: item.t1,
          Books_Collection_Target_T2: item.t2,
          Books_Collection_Target_T2T1: item.t2_T1,
          Books_Collection_Target_T3: item.t3,
          Books_Collection_Target_T3T2: item.t3_T2,
          Books_Collection_Target_T4: item.t4,
          Books_Collection_Target_T4T3: item.t4_T4,
          Books_Collection_Target_T5: item.t5,
          Books_Collection_Target_T5T4: item.t5_T4,
          Books_Collection_Target_T6: item.t6,
          Books_Collection_Target_T6T5: item.t6_T5,
          Payout_Rate_R1: item.r1,
          Payout_Rate_R2: item.r2,
          Payout_Rate_R3: item.r3,
          Payout_Rate_R4: item.r4,
          Payout_Rate_R5: item.r5,
          Payout_Rate_R6: item.r6,
          Remarks: item.reason,
          Payout_P1: item.p1,
          Payout_P2: item.p2,
          Payout_P3: item.p3,
          Payout_P4: item.p4,
          Payout_P5: item.p5,
          Payout_P6: item.p6,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
          TOPTARGET:item.topTarget,
          CLOSING_STOCK:item.closingStock,
          CLOSING_OUTSTANDING:item?.closingOutStanding,
        });
      });
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }

    let tab_text = `<table class="custom-table">
    <thead >
    <tr >
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">INDUSTRY</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
        <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS. (IN LACS)</th>
      <th>BG</th>
      <th>INSTALLATION PLAN</th>
 <th colspan="3">BILLING DETAILS</th>
 <th rowspan="2">CLOSING STOCK</th>
 <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
 <th colspan="11">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
 <th colspan="6">PAYOUT RATE</th>
 <th colspan="6">PAYOUT</th>
 <th rowspan="2">REMARKS</th>
 <th colspan="3">TOTAL EXPECTED PAYOUT</th>
    </tr>
    <tr>
       <th>NOS</th>
       <th>DAYS</th>
       <th>${thirdPastMonthString}</th>
       <th>${secondPastMonthString}</th>
       <th>${firstPastMonthString}</th>
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th>
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>

       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>
       </tr>
    </thead>`;

    data?.forEach((x) => {
      tab_text += `<tr >
<td>${x?.DEALER_CODE}</td>
<td>${x?.DEALER_LOCATION}</td>
<td>${x?.DEALER_NAME}</td>
<td>${x?.STATE}</td>
<td>${x?.TERRITORY_MANAGER}</td>
<td>${x?.TOPTARGET || ""}</td>
<td>${x?.START_MONTH}</td>
<td>${x?.END_MONTH}</td>
<td>${x?.INDUSTRY}</td>
<td>${x?.B}</td>
<td>${x?.I}</td>
<td>${x?.C}</td>
<td>${x?.OPENING_STOCK_NOS}</td>
<td>${x?.OPENING_STOCK_DAYS}</td>
<td>${x?.THIRDMONTH}</td>
<td>${x?.SECONDMONTH}</td>
<td>${x?.FIRSTMONTH}</td>
<td>${x?.DAYS}</td>
<td>${x?.BG_LACS}</td>
<td>${x?.INSTALLATION_PLAN_NOS}</td>
<td>${x?.BILLING_DETAILS_B}</td>
<td>${x?.BILLING_DETAILS_I}</td>
<td>${x?.BILLING_DETAILS_C}</td>
<td>${x?.CLOSING_STOCK}</td>
<td>${x?.CLOSING_OUTSTANDING}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T1}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T2}</td>
<td>${ x?.BOOKS_COLLECTION_TARGET_T2T1 && x?.TOPTARGET?.includes("t2")?(x?.BOOKS_COLLECTION_TARGET_T2T1) :0}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T3}</td>
<td>${ x?.BOOKS_COLLECTION_TARGET_T3T2 && x?.TOPTARGET?.includes("t3")?(x?.BOOKS_COLLECTION_TARGET_T3T2) :0}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T4}</td>
<td>${ x?.BOOKS_COLLECTION_TARGET_T4T3 && x?.TOPTARGET?.includes("t4")?(x?.BOOKS_COLLECTION_TARGET_T4T3) :0}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T5}</td>
<td>${ x?.BOOKS_COLLECTION_TARGET_T5T4 && x?.TOPTARGET?.includes("t5")?(x?.BOOKS_COLLECTION_TARGET_T5T4) :0}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T6}</td>
<td>${ x?.BOOKS_COLLECTION_TARGET_T6T5 && x?.TOPTARGET?.includes("t6")?(x?.BOOKS_COLLECTION_TARGET_T6T5) :0}</td>
<td>${x?.PAYOUT_RATE_R1}</td>
<td>${x?.PAYOUT_RATE_R2}</td>
<td>${x?.PAYOUT_RATE_R3}</td>
<td>${x?.PAYOUT_RATE_R4}</td>
<td>${x?.PAYOUT_RATE_R5}</td>
<td>${x?.PAYOUT_RATE_R6}</td>
<td>${x?.PAYOUT_P1}</td>
<td>${x?.PAYOUT_P2}</td>
<td>${x?.PAYOUT_P3}</td>
<td>${x?.PAYOUT_P4}</td>
<td>${x?.PAYOUT_P5}</td>
<td>${x?.PAYOUT_P6}</td>
<td>${x?.REMARKS}</td>
<td>${x?.TEPVOL}</td>
<td>${x?.TEPPERTR}</td>
<td>${x?.TEPAMOUNT}</td>
</tr>`;
    });


 
  let totals = {
    CLOSING_STOCK:0,
    CLOSING_OUTSTANDING:0,
    BOOKS_COLLECTION_TARGET_T1:0,
    BOOKS_COLLECTION_TARGET_T2:0,
    BOOKS_COLLECTION_TARGET_T2T1:0,
    BOOKS_COLLECTION_TARGET_T3:0,
    BOOKS_COLLECTION_TARGET_T3T2:0,
    BOOKS_COLLECTION_TARGET_T4:0,
    BOOKS_COLLECTION_TARGET_T4T3:0,
    BOOKS_COLLECTION_TARGET_T5:0,
    BOOKS_COLLECTION_TARGET_T5T4:0,
    BOOKS_COLLECTION_TARGET_T6:0,
    BOOKS_COLLECTION_TARGET_T6T5:0,
    PAYOUT_RATE_R1:0,
    PAYOUT_RATE_R2:0,
    PAYOUT_RATE_R3:0,
    PAYOUT_RATE_R4:0,
    PAYOUT_RATE_R5:0,
    PAYOUT_RATE_R6:0,
    PAYOUT_P1:0,
    PAYOUT_P2:0,
    PAYOUT_P3:0,
    PAYOUT_P4:0,
    PAYOUT_P5:0,
    PAYOUT_P6:0,
    REMARKS:" ",
    TEPVOL:0,
    TEPPERTR:0,
    TEPAMOUNT:0,
    
   
   
};

data.forEach((item) => {
  for (let key in totals) {
    if (key === "BOOKS_COLLECTION_TARGET_T2T1" && item?.TOPTARGET.includes("t2")) {
      totals[key] += parseInt(item[key]) || 0;
    } else if (key === "BOOKS_COLLECTION_TARGET_T3T2" && item?.TOPTARGET.includes("t3")) {
      totals[key] += parseInt(item[key]) || 0;
    } else if (key === "BOOKS_COLLECTION_TARGET_T4T3" && item?.TOPTARGET.includes("t4")) {
      totals[key] += parseInt(item[key]) || 0;
    } else if (key === "BOOKS_COLLECTION_TARGET_T5T4" && item?.TOPTARGET.includes("t5")) {
      totals[key] += parseInt(item[key]) || 0;
    } else if (key === "BOOKS_COLLECTION_TARGET_T6T5" && item?.TOPTARGET.includes("t6")) {
      totals[key] += parseInt(item[key]) || 0;
    } else if (!["BOOKS_COLLECTION_TARGET_T2T1", "BOOKS_COLLECTION_TARGET_T3T2", "BOOKS_COLLECTION_TARGET_T4T3", "BOOKS_COLLECTION_TARGET_T5T4", "BOOKS_COLLECTION_TARGET_T6T5"].includes(key)) {
      totals[key] += parseInt(item[key]) || 0;
    }
  }
});
    totals.PAYOUT_RATE_R1 = totals.BOOKS_COLLECTION_TARGET_T1 !== 0 ?  Math.round(totals.PAYOUT_P1 / totals.BOOKS_COLLECTION_TARGET_T1) : 0;
    totals.PAYOUT_RATE_R2 = totals.BOOKS_COLLECTION_TARGET_T2 !== 0 ?  Math.round(totals.PAYOUT_P2 / totals.BOOKS_COLLECTION_TARGET_T2) : 0;
    totals.PAYOUT_RATE_R3 = totals.BOOKS_COLLECTION_TARGET_T3 !== 0 ?  Math.round(totals.PAYOUT_P3 / totals.BOOKS_COLLECTION_TARGET_T3) : 0;
    totals.PAYOUT_RATE_R4 = totals.BOOKS_COLLECTION_TARGET_T4 !== 0 ?  Math.round(totals.PAYOUT_P4 / totals.BOOKS_COLLECTION_TARGET_T4) : 0;
    totals.PAYOUT_RATE_R5 = totals.BOOKS_COLLECTION_TARGET_T5 !== 0 ?  Math.round(totals.PAYOUT_P5 / totals.BOOKS_COLLECTION_TARGET_T5) : 0;
    totals.PAYOUT_RATE_R6 = totals.BOOKS_COLLECTION_TARGET_T6 !== 0 ?  Math.round(totals.PAYOUT_P6 / totals.BOOKS_COLLECTION_TARGET_T6) : 0;
    totals.TEPPERTR = totals.TEPVOL !== 0 ?  Math.round(totals.TEPAMOUNT / totals.TEPVOL) : 0;

    let totalsRow = "<tr><td colspan='23' >Total</td>";
    for (let key in totals) {
      if(key != 'REMARKS'  && key != 'CLOSING_STOCK' && key !='CLOSING_OUTSTANDING'){
        if (key.startsWith('PAYOUT_R') || key === 'TEPPERTR') {

          totalsRow += `<td>${totals[key]}</td>`;
        } else {
          totalsRow += `<td>${totals[key]}</td>`;
        }
      } else {
        totalsRow += `<td></td>`;
      }
    }


    totalsRow += "</tr>";
    tab_text += totalsRow;
    tab_text += "</table>";
    // const csv = `${tab_text}\n${rows.join("\n")}`;

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);
    // Add style to total row color
    const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {
          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {

      if (number < 1) {
        return "Invalid input";
      } else if (number <= 26) {
        return String.fromCharCode(number + 64);
      } else {
        let firstChar = String.fromCharCode(((number - 1) / 26) + 64);
        let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
        return firstChar + secondChar;
      }

    }

    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 3).toString()}`
      excelArr.push(ele);

    }
    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

    // generate file
    XLSX.writeFile(
      wb,
      `Collection_Draft(with delivery plan and without delivery plan)_${new Date()
        .toJSON()
        .slice(0, 10)}.xlsx`
    );

  };

  const tableHeader = (d) => {
    return (
      <Row className="table_sub_buttons">
        <Col>
          {/* <Button
            disabled={disable}
            icon={<MergeIcon />}
            type="text"
            onClick={(e) => handleMerge(e, d)}
          >
            <span>Merge</span>
          </Button> */}
        </Col>
        {/* <Col>
          <Button
            disabled={enable}
            icon={<UnmergeIcon />}
            type="text"
            onClick={(e) => handleUnMergeModal()}
          >
            Unmerge
          </Button>
        </Col> */}
        <Col>
          <Button
            disabled={selectedRows?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div>
        <Table
          title={tableHeader}
          size="medium"
          className="all_schemeTable_pagination"
          // rowClassName={(record, index) => record.scheme.isMerge == true ? 'table-row-dark' :  'table-row-light'}
          style={{
            cursor: "default",
          }}
          summary={(pageData) => {

            const fieldSum = {
              b: 0,
              i: 0,
              c: 0,
              totalR1: 0,
              totalR2: 0,
              totalR3: 0,
              totalR4: 0,
              totalR5: 0,
              totalR6: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              T2_T1: 0,
              T3_T2: 0,
              T4_T3: 0,
              T5_T4: 0,
              T6_T5: 0,
              vol: 0,
              perTr: 0,
              totalAmount: 0,
            }
            pageData.forEach(({
              payoutR1,
              payoutR2,
              payoutR3,
              payoutR4,
              payoutR5,
              payoutR6,
              TEPamount,
              BooksCollectionTargetT1,
              BooksCollectionTargetT2,
              BooksCollectionTargetT3,
              BooksCollectionTargetT4,
              BooksCollectionTargetT5,
              BooksCollectionTargetT6,
              BooksCollectionTargetT2T1,
              BooksCollectionTargetT3T2,
              BooksCollectionTargetT4T3,
              BooksCollectionTargetT5T4,
              BooksCollectionTargetT6T5,
              TEPvol,
              TEPpertr,
              topTarget
            }) => {
              fieldSum.totalR1 += (payoutR1 && topTarget?.includes("t1")) ? parseInt(payoutR1 || 0) : 0;
              fieldSum.totalR2 += (payoutR2 && topTarget?.includes("t2")) ? parseInt(payoutR2 || 0) : 0;
              fieldSum.totalR3 += (payoutR3 && topTarget?.includes("t3")) ? parseInt(payoutR3 || 0) : 0;
              fieldSum.totalR4 += (payoutR4 && topTarget?.includes("t4")) ? parseInt(payoutR4 || 0) : 0;
              fieldSum.totalR5 += (payoutR5 && topTarget?.includes("t5")) ? parseInt(payoutR5 || 0) : 0;
              fieldSum.totalR6 += (payoutR6 && topTarget?.includes("t6")) ? parseInt(payoutR6 || 0) : 0;
              fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
              fieldSum.t1 += (BooksCollectionTargetT1 && topTarget?.includes("t1")) ? parseInt(BooksCollectionTargetT1 || 0) : 0;
              fieldSum.t2 += (BooksCollectionTargetT2 && topTarget?.includes("t2")) ? parseInt(BooksCollectionTargetT2 || 0) : 0;
              fieldSum.t3 += (BooksCollectionTargetT3 && topTarget?.includes("t3")) ? parseInt(BooksCollectionTargetT3 || 0) : 0;
              fieldSum.t4 += (BooksCollectionTargetT4 && topTarget?.includes("t4")) ? parseInt(BooksCollectionTargetT4 || 0) : 0;
              fieldSum.t5 += (BooksCollectionTargetT5 && topTarget?.includes("t5")) ? parseInt(BooksCollectionTargetT5 || 0) : 0;
              fieldSum.t6 += (BooksCollectionTargetT6 && topTarget?.includes("t6")) ? parseInt(BooksCollectionTargetT6 || 0) : 0;
              fieldSum.T2_T1 += (BooksCollectionTargetT2T1 && topTarget?.includes("t2")) ? parseInt(BooksCollectionTargetT2T1 || 0) : 0;
              fieldSum.T3_T2 += (BooksCollectionTargetT3T2 && topTarget?.includes("t3")) ? parseInt(BooksCollectionTargetT3T2 || 0) : 0;
              fieldSum.T4_T3 += (BooksCollectionTargetT4T3 && topTarget?.includes("t4")) ? parseInt(BooksCollectionTargetT4T3 || 0) : 0;
              fieldSum.T5_T4 += (BooksCollectionTargetT5T4 && topTarget?.includes("t5")) ? parseInt(BooksCollectionTargetT5T4 || 0) : 0;
              fieldSum.T6_T5 += (BooksCollectionTargetT6T5 && topTarget?.includes("t6")) ? parseInt(BooksCollectionTargetT6T5 || 0) : 0;
              fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
              fieldSum.perTr = formatNumberDecimal(fieldSum.totalAmount / fieldSum.vol)
            });
            return (
              <Table.Summary.Row style={{ fontWeight: '500', fontSize: '18px' }}>
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={15}></Table.Summary.Cell>
                <Table.Summary.Cell index={16}></Table.Summary.Cell>
                <Table.Summary.Cell index={17}></Table.Summary.Cell>
                <Table.Summary.Cell index={18}></Table.Summary.Cell>
                <Table.Summary.Cell index={19}></Table.Summary.Cell>
                <Table.Summary.Cell index={20}></Table.Summary.Cell>
                <Table.Summary.Cell index={21}></Table.Summary.Cell>
                <Table.Summary.Cell index={22}></Table.Summary.Cell>
                <Table.Summary.Cell index={23}></Table.Summary.Cell>
                <Table.Summary.Cell index={24}></Table.Summary.Cell>
                <Table.Summary.Cell index={25}></Table.Summary.Cell>
                <Table.Summary.Cell index={26}>
                  {fieldSum.t1 ? fieldSum.t1 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={27}>
                  {fieldSum.t2 ? fieldSum.t2 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={28}>
                  {fieldSum.T2_T1 ? fieldSum.T2_T1 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={29}>
                  {fieldSum.t3 ? fieldSum.t3 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={30}>
                  {fieldSum.T3_T2 ? fieldSum.T3_T2 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={31}>
                  {fieldSum.t4 ? fieldSum.t4 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={32}>
                  {fieldSum.T4_T3 ? fieldSum.T4_T3 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={33}>
                  {fieldSum.t5 ? fieldSum.t5 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={34}>
                  {fieldSum.T5_T4 ? fieldSum.T5_T4 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={35}>
                  {fieldSum.t6 ? fieldSum.t6 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={36}>
                  {fieldSum.T6_T5 ? fieldSum.T6_T5 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={37}>{fieldSum.t1 && fieldSum.totalR1 ? Math.round(fieldSum.totalR1 / fieldSum.t1) : ""}</Table.Summary.Cell>
                <Table.Summary.Cell index={38}>{fieldSum.t2 && fieldSum.totalR2 ? Math.round(fieldSum.totalR2 / fieldSum.t2) : ""}</Table.Summary.Cell>
                <Table.Summary.Cell index={39}>{fieldSum.t3 && fieldSum.totalR3 ? Math.round(fieldSum.totalR3 / fieldSum.t3) : ""}</Table.Summary.Cell>
                <Table.Summary.Cell index={40}>{fieldSum.t4 && fieldSum.totalR4 ? Math.round(fieldSum.totalR4 / fieldSum.t4) : ""}</Table.Summary.Cell>
                <Table.Summary.Cell index={41}>{fieldSum.t5 && fieldSum.totalR5 ? Math.round(fieldSum.totalR5 / fieldSum.t5) : ""}</Table.Summary.Cell>
                <Table.Summary.Cell index={42}>{fieldSum.t6 && fieldSum.totalR6 ? Math.round(fieldSum.totalR6 / fieldSum.t6) : ""}</Table.Summary.Cell>
                <Table.Summary.Cell index={43}>

                </Table.Summary.Cell>
                <Table.Summary.Cell index={44}>
                  {fieldSum.totalR1 ? fieldSum.totalR1 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={45}>
                  {fieldSum.totalR2 ? fieldSum.totalR2 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={46}>
                  {fieldSum.totalR3 ? fieldSum.totalR3 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={47}>
                  {fieldSum.totalR4 ? fieldSum.totalR4 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={48}>
                  {fieldSum.totalR5 ? fieldSum.totalR5 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={49}>
                  {fieldSum.totalR6 ? fieldSum.totalR6 : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={50}>
                  {fieldSum.vol ? fieldSum.vol : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={51}>
                  {fieldSum.perTr ? Math.round(fieldSum.perTr || 0) : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={52}>
                  {fieldSum.totalAmount ? fieldSum.totalAmount : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={53}>

                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRow) => {
              getSelectedRowKeys(newSelectedRowKeys);
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow)
              setSelectedRows(selectedRow.map((item) => item.scheme.schemeId));
              sessionStorage.setItem("rowData", JSON.stringify(selectedRow));
              // setEdit(!selectedRow.scheme.isMerge)
              sessionStorage.setItem('rowData', JSON.stringify(selectedRow))
            },
            getCheckboxProps: (record) => ({ disabled: (record.status == 2 && record.ismerged == "false") || record.status == 3 || record.status == 7 || record.status == 8 })
          }}
          onChange={handleChange}
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          bordered
          scroll={{
            x: 6000,
            y: 1800,
          }}
        />
        <Row
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Col>
            <Button
              type="default"
              shape="round"
              className="schemi-btn-all"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                color: "#fff !important",
              }}
              size="large"
              onClick={(e) => handleSaveSubmit()}
            >
              <span style={{ color: "#fff" }}> Save & Submit</span>
            </Button>
          </Col>
        </Row>
        <Pagination
          defaultCurrent={1}
          className="paginationDesign"
          current={collectionData.pageNumber}
          onChange={(pageNumber, pageSize) =>
            setCollectionData({ ...collectionData, pageSize, pageNumber })
          }
          total={listData?.data?.totalCountScheme}
          showSizeChanger
          pageSize={collectionData.pageSize}
          pageSizeOptions={['150', '250', '350']}
        />
        <MergeModal
          open={openMergeModal}
          handleCancel={handleMerge}
          handleOk={handleOk}
          confirmLoading={loading}
          modalText={"Do you want to merge?"}
        />
        <MergeModal
          open={openUnMergeModal}
          handleCancel={handleUnMergeModal}
          handleOk={handleUnMerge}
          confirmLoading={loading}
          modalText={"Do you want to Unmerge?"}
        />
        <MergeModal
          open={openSubmitModal}
          handleCancel={handleCancel}
          handleOk={handleSaveScheme}
          confirmLoading={loading}
          modalText={"Are you sure want to submit?"}
        />
      </div>
      {contextHolder}
    </>
  );
};
export default DraftSchemeTable;
