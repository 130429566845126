import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Col, Row, Form, message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import axiosAPI from "../../../../services/axiosApi";
import { useFormik } from "formik";
import { schemeList } from "../../../../redux/collection/SchemeList";
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import { getSaveModifiedDashboardApi, resetSaveModification } from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { getLockedZonalHeadApiAsync } from "../../../../redux/stateData/stateDataSlice";
const currentFiscalYear = getCurrentFiscalYear();

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const EditModificationAwaiting = ({ backBtn, editCheckbox }) => {
  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: [],
  });
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({ t1: false,
    t2: false,
    t3: false, t4: false, t5: false, t6: false })
  // const [collectionData, setCollectionData] = useState(rejectDetails);
  const {userId,userName,userRole} = decryptData(sessionStorage.getItem("userInfo"));
  const { lockedStates } = useSelector((state) => state?.stateData);
  const { editDashboard, loadingState } = useSelector(
    (state) => state.modifyCollection
  );

  const formik = useFormik({
    initialValues: [],
  });
  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : [],
    }));
  };
  useEffect(() => {
 
    if (userRole == "ZADM"||userRole=="NSH") {
      dispatch(getLockedZonalHeadApiAsync({"fyear":"FY24","userid":userId})).then((x) => {
        if (x?.payload?.data?.[0]) {
          setSchemeLockedStatus(x?.payload?.data)
        }
      })   
    }
  }, [formik?.values])
  useEffect(() => {
 
    if (userRole == "ZADM"||userRole=="NSH") {
      dispatch(getLockedZonalHeadApiAsync({"fyear":"FY24","userid":userId})).then((x) => {
        if (x?.payload?.data?.[0]) {
          setSchemeLockedStatus(x?.payload?.data)
        }
      })   
    }
  }, [formik?.values])
  useEffect(() => {
    if (editCheckbox) {
      let data = editCheckbox;

      let data1 = data.map((x) => ({
        dealerCode: x.dealerCode,
        dealerId:x.dealerId,
        dealerName: x.dealerName,
        dealerLocation: x.dealerLocation,
        stateName: x.stateName,
        territoryManager: x.teritoryManager ,

        startMonth: moment(x.startMonth).format("MMM YY"),
        endMonth: moment(x.endMonth).format("MMM YY"),
        reason: x.reason,
        billing: x.billing ? +x.billing : 0,
        installation: x.installation ? +x.installation : 0,
        collection: Math.round(x.collection ? +x.collection : 0),
        delayPlan: x.delayPlan,
        schemeTypeId: x.schemeTypeId,
        bookCollectionId: x.bookCollectionId,
        payoutId:x.payoutId,
        schemeId:x.schemeId,
        schemeNo:x.schemeNo,
        schemePlanId:x.schemePlanId,
        openingStockDays: x.openingStockDays,
        outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        t2_T1: x.t2_T1,
        t3_T2: x.t3_T2,
        p1: x.p1,
        p2: x.p2,
        p3: x.p3,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        volume: x.volume,
        perTr: Math.round(x.perTr),
        amount: x.amount,
        // userrole: userRole,
        // ...(y != "draft" && { Manager: y.Manager }),
        // fyear: currentFiscalYear,
        // userid: +userId,
        // username: userName,
        industry: x.industry ? parseInt(x.industry) : 0,
        oldBillingQty: x.oldBillingQty,
        oldInstallation: x.oldInstallation,
        oldCollection: Math.round(x.oldCollection),
        oldOpeningStock: x.oldOpeningStock,
        closingOutStandingMonth_1: x.closingOutStandingMonth_1,
        closingOutStandingMonth_2: x.closingOutStandingMonth_2,
        closingOutStandingMonth_3: x.closingOutStandingMonth_3,
        bGLacsCurrentYear: x.bGLacsCurrentYear,
      }));
      formik?.setValues(data1);
    }
  }, [editCheckbox]);

  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };

  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
    {
      name: "T4",
      value: "t4",
      disabled: !schemeLockedStatus?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !schemeLockedStatus?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !schemeLockedStatus?.t6 ? true : false,
    },
  ];
  const getDropdown = () =>
  schemeLockedStatus?.t1
    ? [
      {
        title: "Top Slab",
        width: "150px",
        render: (x, y, index) => {
          const handleChange = (value) => {
            let dropdownValues = formik?.values?.[index]?.dropdownscheme;
            let checkValue1 = value?.includes("t1");
            let checkValue2 = value?.includes("t2");
            let checkValue3 = value?.includes("t3");
            let checkValue4 = value?.includes("t4");
            let checkValue5 = value?.includes("t5");
            let checkValue6 = value?.includes("t6");
            const handleDropDownScheme = () => {
              return checkValue6? ['t1','t2','t3','t4','t5','t6']
                : checkValue5? ['t1','t2','t3','t4','t5']:
                    checkValue4? ['t1','t2','t3','t4']
                      : checkValue3? ['t1','t2','t3']
                        : checkValue2? ['t1','t2']
                          : checkValue1? ['t1']:[];
            };
            const handleDropdownSchemes = handleDropDownScheme()

            formik.setFieldValue(index, {
              ...x,
              dropdownscheme: handleDropDownScheme() || null,
              ...(handleDropdownSchemes.includes("t5") ? {
                BooksCollectionTargetT6T5: null,
                PayoutRatep6: null,
                BooksCollectionTargetT6: null
              } :
                handleDropdownSchemes.includes("t4") ? {
                  PayoutRatep5: null, BooksCollectionTargetT5: null,
                  PayoutRatep6: null, BooksCollectionTargetT6: null,
                  BooksCollectionTargetT5T4: 0
                } : handleDropdownSchemes.includes("t3") ? {
                  PayoutRatep4: null, BooksCollectionTargetT4: null,
                  PayoutRatep5: null, BooksCollectionTargetT5: null,
                  PayoutRatep6: null, BooksCollectionTargetT6: null,
                  BooksCollectionTargetT4T3: 0,
                } : handleDropdownSchemes.includes("t2") ? {
                  PayoutRatep3: null, BooksCollectionTargetT3: null,
                  PayoutRatep4: null, BooksCollectionTargetT4: null,
                  PayoutRatep5: null, BooksCollectionTargetT5: null,
                  PayoutRatep6: null, BooksCollectionTargetT6: null,
                  BooksCollectionTargetT3T2: 0,
                } : handleDropdownSchemes.includes("t1") && {
                  PayoutRatep2: null, BooksCollectionTargetT2: null,
                  PayoutRatep3: null, BooksCollectionTargetT3: null,
                  PayoutRatep4: null, BooksCollectionTargetT4: null,
                  PayoutRatep5: null, BooksCollectionTargetT5: null,
                  PayoutRatep6: null, BooksCollectionTargetT6: null,
                  BooksCollectionTargetT2T1: 0,

                }),

              BooksCollectionTargetT6T5: null,
            });
          };
          return (
            <Select
              onChange={handleChange}
              value={y?.dropdownscheme}
              mode="multiple"
              autoClearSearchValue="multiple"
              className="w-100"
              allowClear
              onDeselect={false}
              showArrow={false} 
              removeIcon={false}
            >
              {topTargetArr
                ?.filter((x) => !x?.disabled)
                ?.map((x) => (
                  <Select.Option value={x?.value}>
                    {x?.name}
                  </Select.Option>
                ))}
            </Select>
          );
        },
      },
    ]
    : [];
  const calculateFormula = (index) => {
    let y = formik?.values[index];
    let { t2, t1, t3, r1, r2, r3, amount, volume, TEPpertr } =
      formik?.values[index];

    const TEPvol1 = volume ? parseInt(volume) : 0;

    let calculateTepAMount =
      TEPvol1 >= t1 && TEPvol1 < t2
        ? parseInt(TEPvol1) * parseInt(r1)
        : TEPvol1 < t1 || volume == 0
        ? 0
        : TEPvol1 >= t2 && TEPvol1 < t3
        ? parseInt(TEPvol1) * parseInt(r2)
        : parseInt(TEPvol1) * parseInt(r3);

    amount == "NaN" && (calculateTepAMount = volume);

    let TEPpertr1Calculate = TEPvol1
      ? Math.round(parseInt(calculateTepAMount) / parseInt(TEPvol1))
      : parseInt(calculateTepAMount) / parseInt(TEPvol1) == "NaN"
      ? volume
      : 0;

    formik?.setFieldValue(index, {
      ...y,
      t2_T1: +(parseInt(t2 || 0) - parseInt(t1 || 0)),
      t3_T2: +(parseInt(t3 || 0) - parseInt(t2 || 0)),
      p1: parseInt(t1 || 0) * parseInt(r1 || 0) || "",
      p2: parseInt(t2 || 0) * parseInt(r2 || 0) || "",
      p3: parseInt(t3 || 0) * parseInt(r3 || 0) || "",
      r1: r1,
      r2: r2,
      r3: r3,
      perTr: Math.round(TEPpertr1Calculate),

      amount: Math.round(calculateTepAMount),
    });
    if (r2 != null && r1 != null && +r2 <= +r1) {
      handleErrorModal("R2", "R1", true);
    }

    if (r3 != null && r2 != null && +r3 <= +r2) {
      handleErrorModal("R3", "R2", true);
    }

    if (t1 != null && t2 != null && parseInt(t2) <= parseInt(t1)) {
      handleErrorModal("T2", "T1", true);
    }

    if (t2 != null && t3 != null && +t3 <= +t2) {
      handleErrorModal("T3", "T2", true);
    }
  };
  const getStateHead=()=>userRole!="SADM"?[
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
    },
  ]:[]
  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
        key:"DealerCode",
        sorter: true,
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
        sorter: true,
        key:"DealerName",
       // defaultSortOrder: "descend",
       // sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
        sorter: true,
        key:"DealerLocation",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
        sorter: true,
        key:"StateName",
      },
      ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "territoryManager",
        width: "100px",
        sorter: true,
        key:"TerritoryManager",
      },
      ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
      },
      {
        title: "Industry",
        dataIndex: "industry",
        width: "120px",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.industry}
            placeholder="Type here"
            value={formik?.values?.[index]?.industry}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target?.value.replace(/[^\d]/g, "");
              formik.setFieldValue(index, {
                ...y,
                industry: value,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "70px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "70px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "70px",
      },
      {
        title: "Opening Stock",
        dataIndex: "stock",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "100px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "100px",
            // render: (x, y, index) => (
            //   <input
            //     name={formik?.values?.[index]?.openingStockDays}
            //     placeholder="Type here"
            //     value={formik?.values?.[index]?.openingStockDays}
            //     onBlur={() => {
            //       calculateFormula(index);
            //     }}
            //     onChange={(e) => {
            //       let value = e.target?.value.replace(/[^\d]/g, "");
            //       formik.setFieldValue(index, {
            //         ...y,
            //         openingStockDays: value,
            //       });
            //     }}
            //     className="input1"
            //     style={{ width: "90%", padding: "5px" }}
            //   />
            // ),
          },
        ],
      },

      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        editable: false,
        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "100px",
          },

          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "outStandingDays",
            width: "100px",
          //  editable: true,
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bGLacsCurrentYear",
            width: "100px",
          },
        ],
      },

      {
        title: "BILLING DETAILS",
        dataIndex: "plan",
        editable: true,
        children: [
          {
            title: "B",
            dataIndex: "billing",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.billing}
                placeholder="Type here"
                value={formik?.values?.[index]?.billing}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    billing: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "I",
            dataIndex: "installation",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.installation}
                placeholder="Type here"
                value={formik?.values?.[index]?.installation}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installation: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "C",
            dataIndex: "collection",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.collection}
                placeholder="Type here"
                value={formik?.values?.[index]?.collection}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    collection: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
        ],
      },
      {
        title: "Installation plan",
        dataIndex: "instaPlan",
        editable: true,
        width: "300px",
        children: [
          {
            title: "Nos",
            dataIndex: "delayPlan",
            width: "200px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.delayPlan}
                placeholder="Type here"
                value={formik?.values?.[index]?.delayPlan}
                // onBlur={()=>{
                //   calculateFormula(index)
                // }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    delayPlan: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            editable: true,
          },
        ],
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        editable: true,
        children: [
          {
            title: "T1",
            dataIndex: "t1",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t1}
                placeholder="Type here"
                value={formik?.values?.[index]?.t1}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t1: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "T2",
            dataIndex: "t2",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t2}
                placeholder="Type here"
                value={formik?.values?.[index]?.t2}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t2: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "T2-T1",
            dataIndex: "t2_T1",
            width: "100px",
          },
          {
            title: "T3",
            dataIndex: "t3",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t3}
                placeholder="Type here"
                value={formik?.values?.[index]?.t3}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t3: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "T3-T2",
            dataIndex: "t3_T2",
            width: "100px",
          },
        ],
      },
      {
        title: "Payout Rate",
        dataIndex: "pr",
        editable: true,

        children: [
          {
            title: "R1",
            dataIndex: "r1",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r1}
                placeholder="Type here"
                value={formik?.values?.[index]?.r1}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r1: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "120px",
          },
          {
            title: "R2",
            dataIndex: "r2",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r2}
                placeholder="Type here"
                value={formik?.values?.[index]?.r2}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r2: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "120px",
          },
          {
            title: "R3",
            dataIndex: "r3",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r3}
                placeholder="Type here"
                value={formik?.values?.[index]?.r3}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r3: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "120px",
          },
        ],
      },
      {
        title: "Payout",
        dataIndex: "payout",
        editable: true,

        children: [
          {
            title: "P1",
            dataIndex: "p1",
            width: "120px",
          },
          {
            title: "P2",
            dataIndex: "p2",
            width: "120px",
          },
          {
            title: "P3",
            dataIndex: "p3",
            width: "120px",
          },
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        render: (x, y, index) => (
          <input
          name={formik?.values?.[index]?.reason}
          placeholder="Type here"
          value={formik?.values?.[index]?.reason}
          onChange={(e) => {
            let value = e.target?.value;
            const sanitizedValue = value
              ?.replace("<script>", "")
              ?.replace("</script>", "")
            //  .replace(/[^a-zA-Z0-9\s]/g, "");
            formik.setFieldValue(index, {
              ...y,
              reason: sanitizedValue,
            });
          }}
          className="input1"
          style={{ width: "90%", padding: "5px" }}
        />
        ),
        width: "150px",
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        editable: true,
        children: [
          {
            title: "Expected Volume",
            dataIndex: "volume",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.volume}
                placeholder="Type here"
                value={formik?.values?.[index]?.volume}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    volume: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "120px",
          },
          {
            title: "Per Tr.",
            dataIndex: "perTr",
            width: "120px",
          },
          {
            title: "Amount",
            dataIndex: "amount",
            width: "120px",
          },
        ],
      },
    ],
    [formik?.values]
  );

  const handleSubmit = (e) => {

    let checkValidation = formik?.values?.filter(
      (x) =>
        !x?.industry ||
        // !x.openingStockDays ||
        // !x.outStandingDays ||
        !x.billing ||
        !x.installation ||
        !x.collection ||
        !x.delayPlan ||
        !x.t1 ||
        !x.t2 ||
        !x.t3 ||
        !x.r1 ||
        !x.r2 ||
        !x.r3 ||
        !x.volume
    );

    let data = formik?.values?.map((x) => ({
      dealerCode: x.dealerCode,
      dealerId: x.dealerId,
      dealerName: x.dealerName,
      dealerLocation: x.dealerLocation,
      stateName: x.stateName,
      territoryManager: x.territoryManager || "",

      startMonth: moment(x.startMonth).format("MMM YY"),
      endMonth: moment(x.endMonth).format("MMM YY"),
      reason: x.reason,
      status: 1,
      // state: x.stateId,
      billing: x.billing ? +x.billing : 0,
      installation: x.installation ? +x.installation : 0,
      collection: Math.round(x.collection ? +x.collection : 0),
      delayPlan: x.delayPlan,
      // isSAP: true,
      // isActive: true,
      // schemePlanStartMonth: startDate,
      // schemePlanEndMonth: endDate,

      schemeTypeId: x.schemeTypeId,
      bookCollectionId: x.bookCollectionId,
      payoutId: x.payoutId,
      schemeId: x.schemeId,
      schemeNo: x.schemeNo,
      schemePlanId: x.schemePlanId,
      openingStockDays: x.openingStockDays,
      outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
      t1: +x.t1,
      t2: +x.t2,
      t3: +x.t3,
      t2_T1: x.t2_T1,
      t3_T2: x.t3_T2,
      p1: x.p1,
      p2: x.p2,
      p3: x.p3,
      r1: +x.r1,
      r2: +x.r2,
      r3: +x.r3,
      topTarget: x.topTarget,
      volume: x.volume,
      perTr: Math.round(x.perTr),
      amount: x.amount,
      userid: +userId,
      username: userName,
      userrole: userRole,
      industry: x.industry ? parseInt(x.industry) : 0,
      oldBillingQty: x.oldBillingQty,
      oldInstallation: x.oldInstallation,
      oldCollection: Math.round(x.oldCollection),
      oldOpeningStock: x.oldOpeningStock,
      closingOutStandingMonth_1: x.closingOutStandingMonth_1,
      closingOutStandingMonth_2: x.closingOutStandingMonth_2,
      closingOutStandingMonth_3: x.closingOutStandingMonth_3,
      bGLacsCurrentYear: x.bGLacsCurrentYear,
    }));

    let errorDetails = Object.entries(
      checkValidation?.[0] ? checkValidation?.[0] : {}
    )
      ?.filter(
        (x) =>
          !x[1] &&
          (x[0] == "industry" ||
            // x[0] == "openingStockDays" ||
            // x[0] == "outStandingDays" ||
            x[0] == "billing" ||
            x[0] == "installation" ||
            x[0] == "collection" ||
            x[0] == "delayPlan" ||
            x[0] == "t1" ||
            x[0] == "t2" ||
            x[0] == "t3" ||
            x[0] == "r1" ||
            x[0] == "r2" ||
            x[0] == "r3" ||
            x[0] == "TEPvol")
      )
      ?.map((x) =>
        x[0]  == "t1"
          ? "T1"
          : x[0] == "t2"
          ? "T2"
          : x[0] == "t3"
          ? "T3"
          : x[0] == "r1"
          ? "R1"
          : x[0] == "r2"
          ? "R2"
          : x[0] == "r3"
          ? "R3"
          : x[0] == "TEPvol"
          ? "Vol"
          : x[0]
      );
    if (Object.keys(errorDetails ? errorDetails : {})?.length != 0) {
      showErrorDialog(errorDetails);
    }

    if (errorDetails?.length == 0) {
      dispatch(getSaveModifiedDashboardApi(data)).then(async (result) => {
        if (result.status == 200) {
          await message.success({
            content: "Scheme Modified successfully ",
            className: "custom-message",
          });
          await backBtn(false);
          await dispatch(resetSaveModification())
        }
      });
    }
  };

  return (
    <div className="create_scheme_wrap general-bscheme">
      <Button
        type="primary"
        icon={<ArrowBackRounded />}
        size="middle"
        onClick={() => {
          backBtn(false);
        }}
      />
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            className="finance"
            loading={loadingState == "pending" ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            pagination={false}
            scroll={{
              x: 1000,
              y: 300,
            }}
            size="middle"
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          <Button
            type="default"
            shape="round"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
              padding: "20px",
            }}
            size="large"
            onClick={handleSubmit}
          >
            Update & Submit
          </Button>
        </div>
      </div>

      <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
    </div>
  );
};
export default EditModificationAwaiting;
