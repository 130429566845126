import React, { Table, Tag, Space, Tooltip } from "antd";

import dayjs from "dayjs";

import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const TrackTable = ({
  selectedSchemeType,
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  setEditSchemes,
  setSchemeIdNo,
  setSchemeNo,
  setSchemeId,
  schemeNo,
  handleRejectModal1,
  handleAcceptModal1,
  selectedLabel,
  loading,
  handleChange
}) => {



  const getCols = (schemeType) => {
    if (schemeType == 1 || schemeType == 2) {
      return [
        {
          title: "Industry",
          dataIndex: "industry",
          render: (x, y) => (
            y.status === 2 ? (<div >
              {(y?.historyIndustry || y?.historyIndustry ==0) && (
                <Tag color={y?.historyIndustry !== y?.industry && "red"}>
                  {y?.historyIndustry}
                </Tag>
              )}
              {(y?.industry || y?.industry ==0 ) && <Tag color={y?.historyIndustry !== y?.industry && "red"}>{y?.industry}</Tag>}
            </div>
            ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y.industry || y.industry == 0 ) && <Tag>{y.industry}</Tag>}
              {(y.holdIndustry || y.holdIndustry ==0) && (
                <Tag
                  color={
                    y.holdIndustry !== y.industry &&
                    "red"
                  }
                >
                  {y.holdIndustry}
                </Tag>
              )}
            </div>:
            <div>
              {(y.industry   || y.industry ==0 )&& <Tag>{y.industry}</Tag>}
              {(y?.modifiedIndustry || y?.modifiedIndustry == 0) && (
                <Tag
                  color={
                    y.modifiedIndustry !== y.industry &&
                    "red"
                  }
                >
                  {y.modifiedIndustry}
                </Tag>
              )}
            </div>
            
          )
          ,
          width: "100px"
        },
        {
          title: "B",
          dataIndex: "oldBillingQty",
          width: "100px",
        },
        {
          title: "I",
          dataIndex: "oldInstallation",
          width: "100px",
        },
        {
          title: "C",
          dataIndex: "oldCollection",
          width: "100px",
        },
        {
          title: (
            <div>
              Opening <br /> Stock
            </div>
          ),
          dataIndex: "stock",
          width: "100px",
          children: [
            {
              title: "NOS",
              width: "100px",
              dataIndex: "oldOpeningStock",
            },
            {
              title: "Days",
              dataIndex: "openingStockDays",
              width: "100px",
            },
          ],
        },
        {
          title: "Closing Os. (In Lacs)",
          dataIndex: "stock",
          defaultSortOrder: "descend",

          children: [
            {
    title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
              dataIndex: "closingOutStandingMonth_3",
              defaultSortOrder: "descend",
              width: "150px",
            },
            {
              title: (
                <span>
                  Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
                </span>
              ),
              dataIndex: "closingOutStandingMonth_2",
              defaultSortOrder: "descend",
              width: "150px",
            },
            {
              title: (
                <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
              ),
              dataIndex: "closingOutStandingMonth_1",
              defaultSortOrder: "descend",
              width: "150px",
            },

            // {
            //   title: (
            //     <span>
            //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
            //     </span>
            //   ),
            //   dataIndex: "outStandingDays",
            //   width: "90px",
            // },
          ],
        },
        {
          title: "Closing OS Days",
          dataIndex: "outStandingDays",
          children: [
            {
              title: "OS Last Month",
              dataIndex: "outStandingDays",
              width: "90px",
            },
          ],
        },
        {
          title: "BG",
          dataIndex: "outStandingDays",
          children: [
            {
              title: "Lacs",
              dataIndex: "bGLacsCurrentYear",
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Installation <br /> Plan
            </div>
          ),
          dataIndex: "IP",
          children: [
            {
              title: "NOS",
              dataIndex: "delayPlan",
              render: (x, y) => (

                y.status === 2 ? (<div>
                  {(y?.historyDelayPlan || y?.historyDelayPlan == 0) && (
                    <Tag >
                      {y?.historyDelayPlan}
                    </Tag>
                  )}
                  {y?.delayPlan && <Tag color={y?.historyDelayPlan !== y?.delayPlan && "red"}>{y?.delayPlan}</Tag>}
                </div>
                ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.delayPlan || y.delayPlan == 0 ) && <Tag>{y.delayPlan}</Tag>}
                  {(y.holdDelayPlan || y.holdDelayPlan ==0) && (
                    <Tag
                      color={
                        y.holdDelayPlan !== y.delayPlan &&
                        "red"
                      }
                    >
                      {y.holdDelayPlan}
                    </Tag>
                  )}
                </div>: (<div>
                  {(y?.delayPlan || y?.delayPlan == 0) && <Tag>{y?.delayPlan}</Tag>}
                  {((y?.modifiedDelayPlan) || (y?.modifiedDelayPlan == 0)) && (
                    <Tag
                      color={
                        ((y?.modifiedDelayPlan !== y?.delayPlan) && "red") || ((y?.delayPlan || 0) !== (y?.delayPlan || 0) && "red")
                      }
                    >
                      {y?.modifiedDelayPlan}
                    </Tag>
                  )}

                </div>)
              ),
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Billing <br /> Details
            </div>
          ),
        
          dataIndex: "plan",
          children: [
            {
              title: "B",
              dataIndex: "billing",

              render: (x, y) => (

                y.status === 2 ? (<div >

                  {y?.historyBilling && (
                    <Tag >
                      {y?.historyBilling}
                    </Tag>
                  )}
                  {y?.historyBilling && <Tag color={y?.historyBilling !== y?.billing && "red"}>{y?.billing}</Tag>}
                </div>
                ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.billing || y.billing == 0 ) && <Tag>{y.billing}</Tag>}
                  {(y.holdBilling || y.holdBilling ==0) && (
                    <Tag
                      color={
                        y.holdBilling !== y.billing &&
                        "red"
                      }
                    >
                      {y.holdBilling}
                    </Tag>
                  )}
                </div>: (<div >
                  {y.billing && <Tag>{y.billing}</Tag>}
                  {y.modifiedBilling && (
                    <Tag color={y.modifiedBilling !== y.billing && "red"}>
                      {y.modifiedBilling}
                    </Tag>
                  )}
                </div>)
              ),
              width: "100px",
            },
            {
              title: "I",
              dataIndex: "installation",
              render: (x, y) => (

                y.status === 2 ? (<div >

                  {y?.historyInstallation && (
                    <Tag >
                      {y?.historyInstallation}
                    </Tag>
                  )}
                  {y?.historyInstallation && <Tag color={y?.historyInstallation !== y?.installation && "red"}>{y?.installation}</Tag>}
                </div>
                ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.installation || y.installation == 0 ) && <Tag>{y.installation}</Tag>}
                  {(y.holdInstallation || y.holdInstallation ==0) && (
                    <Tag
                      color={
                        y.holdInstallation !== y.installation &&
                        "red"
                      }
                    >
                      {y.holdInstallation}
                    </Tag>
                  )}
                </div>: 
                 (<div >
                  {y.installation && <Tag>{y.installation}</Tag>}
                  {y.modifiedInstallation && (
                    <Tag
                      color={y.modifiedInstallation !== y.installation && "red"}
                    >
                      {y.modifiedInstallation}
                    </Tag>
                  )}
                </div>)
              ),
              width: "100px",
            },
            {
              title: "C",
              dataIndex: "collection",
              render: (x, y) => (

                y.status === 2 ? (<div >

                  {y?.historyCollection && (
                    <Tag >
                      {y?.historyCollection}
                    </Tag>
                  )}
                  {y?.historyCollection && <Tag color={y?.historyCollection !== y?.collection && "red"}>{y?.collection}</Tag>}
                </div>
                ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.collection || y.collection == 0 ) && <Tag>{y.collection}</Tag>}
                  {(y.holdCollection || y.holdCollection ==0) && (
                    <Tag
                      color={
                        y.holdCollection !== y.collection &&
                        "red"
                      }
                    >
                      {y.holdCollection}
                    </Tag>
                  )}
                </div>:  (<div >
                  {y.collection && <Tag>{y.collection}</Tag>}
                  {y.modifiedCollection && (
                    <Tag color={y.modifiedCollection !== y.collection && "red"}>
                      {y.modifiedCollection}
                    </Tag>
                  )}
                </div>)
              ),
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Closing <br /> Stock
            </div>
          ),
          dataIndex: "closingStock",
          width: "80px",
          render: (x, y) => (

            y.status === 2 ? (<div>

              {(y?.historyClosingStock || y?.historyClosingStock == 0) && (
                <Tag >
                  {y?.historyClosingStock}
                </Tag>
              )}
              {(y?.closingStock || y?.closingStock == 0) && <Tag color={y?.historyClosingStock !== y?.closingStock && "red"}>{y?.closingStock}</Tag>}
            </div>
            ) :
            (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.closingStock || y.closingStock == 0 ) && <Tag>{y.closingStock}</Tag>}
                  {(y.holdclosingStock || y.holdclosingStock ==0) && (
                    <Tag
                      color={
                        y.holdclosingStock !== y.closingStock &&
                        "red"
                      }
                    >
                      {y.holdclosingStock}
                    </Tag>
                  )}
                </div>:  (<div >
              {(y.closingStock || y.closingStock == 0) && <Tag>{y.closingStock}</Tag>}
              {(y.modifiedClosingStock || y.modifiedClosingStock == 0) && (
                <Tag color={y.modifiedClosingStock !== y.closingStock && "red"}>
                  {y.modifiedClosingStock}
                </Tag>
              )}
            </div>)
          ),
        },
        {
          title: (
            <div>
              Closing <br /> OutStanding (In Lacs)
            </div>
          ),
          dataIndex: "closingOutStanding",
          width: "110px",

          render: (x, y) => (

            y.status === 2 ? (<div >

              {(y?.historyClosingOutstanding  || y?.historyClosingOutstanding  == 0)&& (
                <Tag >
                  {y?.historyClosingOutstanding}
                </Tag>
              )}
              {(y?.closingOutStanding || y?.closingOutStanding == 0) && <Tag color={y?.historyClosingOutstanding !== y?.closingOutStanding && "red"}>{y?.closingOutStanding}</Tag>}
            </div>
            ) : 
             (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y.closingOutStanding || y.closingOutStanding == 0 ) && <Tag>{y.closingOutStanding}</Tag>}
              {(y.holdclosingOutStanding || y.holdclosingOutStanding ==0) && (
                <Tag
                  color={
                    y.holdclosingOutStanding !== y.closingOutStanding &&
                    "red"
                  }
                >
                  {y.holdclosingOutStanding}
                </Tag>
              )}
            </div>: 
            (<div >
              {(y.closingOutStanding || y.closingOutStanding == 0) && <Tag>{y.closingOutStanding}</Tag>}
              {(y.modifiedClosingOutstanding || y.modifiedClosingOutstanding == 0) && (
                <Tag color={y.modifiedClosingOutstanding !== y.closingOutStanding && "red"}>
                  {y.modifiedClosingOutstanding}
                </Tag>
              )}
            </div>)
          )
        },
        
        {
          title: "Books Collection Target (No. of Ts.)",
          dataIndex: "BooksCollectionTarget",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              render: (x, y) => (

                y.status === 2 ? (<div>
                  {(y?.historyT1 || y?.historyT1 ==0) && (
                    <Tag >
                      {y?.historyT1}
                    </Tag>
                  )}
                  {(y?.t1  || y?.t1 ==0)&& <Tag color={y?.historyT1 !== y?.t1 && "red"}>{y?.t1}</Tag>}
                </div>
                ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.t1 || y.t1 == 0 ) && <Tag>{y.t1}</Tag>}
                  {(y.holdT1 || y.holdT1 ==0) && (
                    <Tag
                      color={
                        y.holdT1 !== y.t1 &&
                        "red"
                      }
                    >
                      {y.holdT1}
                    </Tag>
                  )}
                </div>:
                 (<div >
                  {(y?.t1 || y?.t1 == 0) && <Tag>{y?.t1}</Tag>}
                  {((y?.modifiedT1) || (y?.modifiedT1 == 0)) && (
                    <Tag
                      color={
                        ((y?.modifiedT1 !== y?.t1) && "red") || ((y?.modifiedT1 || 0) !== (y?.t1 || 0) && "red")
                      }
                    >
                      {y?.modifiedT1}
                    </Tag>
                  )}

                </div>)
              ),
              width: "150px",
            },
            {
              title: "T2",
              dataIndex: "t2",
              render: (x, y) => (

                y.status === 2 ? (<div >

                  {(y?.historyT2 || y?.historyT2_T1 == 0) && (
                    <Tag >
                      {y?.historyT2}
                    </Tag>
                  )}
                  {(y?.t2 ||  y?.t2 ==0) && <Tag color={y?.historyT2 !== y?.t2 && "red"}>{y?.t2}</Tag>}
                </div>
                ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.t2 || y.t2 == 0 ) && <Tag>{y.t2}</Tag>}
                  {(y.holdT2 || y.holdT2 ==0) && (
                    <Tag
                      color={
                        y.holdT2 !== y.t2 &&
                        "red"
                      }
                    >
                      {y.holdT2}
                    </Tag>
                  )}
                </div>:
                (<div >
                  {(y?.t2 || y?.t2 == 0) && <Tag>{y?.t2}</Tag>}
                  {((y?.modifiedT2) || (y?.modifiedT2 == 0)) && (
                    <Tag
                      color={
                        ((y?.modifiedT2 !== y?.t2) && "red") || ((y?.modifiedT2 || 0) !== (y?.t2 || 0) && "red")
                      }
                    >
                      {y?.modifiedT2}
                    </Tag>
                  )}

                </div>)
              ),
              width: "150px",
            },
           
            {
              title: "T2-T1",
              dataIndex: "t2_T1",
              render: (x, y) => (

                y.status === 2 ? (<div >

                  {(y?.historyT2_T1 || y?.historyT2_T1 ==0) && (
                    <Tag >
                      {y?.historyT2_T1}
                    </Tag>
                  )}
                  {(y?.t2_T1 || y?.t2_T1==0) && <Tag color={y?.historyT2_T1 !== y?.t2_T1 && "red"}>{y?.t2_T1}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y.t2_T1 || y.t2_T1 == 0 ) && <Tag>{y.t2_T1}</Tag>}
                  {(y.holdT2_T1 || y.holdT2_T1 ==0) && (
                    <Tag
                      color={
                        y.holdT2_T1 !== y.t2_T1 &&
                        "red"
                      }
                    >
                      {y.holdT2_T1}
                    </Tag>
                  )}
                </div>:
                 (<div >
                  {(y?.t2_T1 || y?.t2_T1 == 0) && <Tag>{y?.t2_T1}</Tag>}
                  {((y?.modifiedT2_T1) || (y?.modifiedT2_T1 == 0)) && (
                    <Tag
                      color={
                        ((y?.modifiedT2_T1 !== y?.t2_T1) && "red") || ((y?.modifiedT2_T1 || 0) !== (y?.t2_T1 || 0) && "red")
                      }
                    >
                      {y?.modifiedT2_T1}
                    </Tag>
                  )}

                </div>)
              ),
              width: "150px",
            },
            {
              title: "T3",
              dataIndex: "t3",
              render: (x, y, index) => {
                let modifiedValue = y?.modifiedT3
                let withoutModifiedValue = y?.t3

                return (
                  y.status === 2 ? (<div>

                    {(y?.historyT3 || y?.historyT3 ==0 ) && (
                      <Tag>
                        {y?.historyT3}
                      </Tag>
                    )}
                    {(withoutModifiedValue ||  withoutModifiedValue == 0) && <Tag color={y?.historyT3 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(y.t3 || y.t3 == 0 ) && <Tag>{y.t3}</Tag>}
                    {(y.holdT3 || y.holdT3 ==0) && (
                      <Tag
                        color={
                          y.holdT3 !== y.t3 &&
                          "red"
                        }
                      >
                        {y.holdT3}
                      </Tag>
                    )}
                  </div>:
                   <div>

                    {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                    {((modifiedValue) || (modifiedValue == 0)) && (
                      <Tag
                        color={
                          ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                        }
                      >
                        {modifiedValue}
                      </Tag>
                    )
                    }

                  </div>)
              },
              width: "150px",
            },
            {
              title: "T3-T2",
              dataIndex: "t3_T2",
              render: (x, y, index) => {
                let modifiedValue = y?.modifiedT3_T2
                let withoutModifiedValue = y?.t3_T2
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyT3_T2 || y?.historyT3_T2 == 0) && (
                      <Tag color={y?.historyT3_T2 !== withoutModifiedValue && "red"}>
                        {y?.historyT3_T2}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0 )&& <Tag color={y?.historyT3_T2 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdT3_T2 || y.holdT3_T2 ==0) && (
                      <Tag
                        color={
                          y.holdT3_T2 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdT3_T2}
                      </Tag>
                    )}
                  </div>:
                   <div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>)
              },
              width: "150px",
            },
            {
              title: "T4",
              dataIndex: "t4",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.t4
                let modifiedValue = y?.modifiedT4
                return ( y.status === 2 ? (<div >

                  {(y?.historyT4  || y?.historyT4 ==0 )&& (
                    <Tag>
                      {y?.historyT4}
                    </Tag>
                  )}
                  {(withoutModifiedValue || withoutModifiedValue == 0)&& <Tag color={y?.historyT4 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                </div>
                ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                  {(y.holdT4 || y.holdT4 ==0) && (
                    <Tag
                      color={
                        y.holdT4 !== withoutModifiedValue &&
                        "red"
                      }
                    >
                      {y.holdT4}
                    </Tag>
                  )}
                </div>:
                (<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
            {
              title: "T4-T3",
              dataIndex: "t4_T3",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.t4_T3
                let modifiedValue = y?.modifiedT4_T3
                return (y.status === 2 ? (<div>

                  {(y?.historyT4_T3 || y?.historyT4_T3 == 0)&& (
                    <Tag color={y?.historyT4_T3 !== withoutModifiedValue && "red"}>
                      {y?.historyT4_T3}
                    </Tag>
                  )}
                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag color={y?.historyT4_T3 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                  {(y.holdT4_T3 || y.holdT4_T3 ==0) && (
                    <Tag
                      color={
                        y.holdT4_T3 !== withoutModifiedValue &&
                        "red"
                      }
                    >
                      {y.holdT4_T3}
                    </Tag>
                  )}
                </div>:
                (<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>) )
              },
              width: "150px",
            },
            {
              title: "T5",
              dataIndex: "t5",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.t5
                let modifiedValue = y?.modifiedT5
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyT5 ||  y?.historyT5 ==0) && (
                      <Tag color={y?.historyT5 !== withoutModifiedValue && "red"}>
                        {y?.historyT5}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyT5 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) 
                  :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdT5 || y.holdT5 ==0) && (
                      <Tag
                        color={
                          y.holdT5 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdT5}
                      </Tag>
                    )}
                  </div>
                  :(<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>) )
              },
              width: "150px",
            },
            {
              title: "T5-T4",
              dataIndex: "t5_T4",

              render: (x, y, index) => {

                let withoutModifiedValue = y?.t5_T4
                let modifiedValue = y?.modifiedT5_T4
                return (y.status === 2 ? (<div >

                  {(y?.historyT5_T4 ||y?.historyT5_T4 ==0 ) && (
                    <Tag>
                      {y?.historyT5_T4}
                    </Tag>
                  )}
                  {(withoutModifiedValue || withoutModifiedValue ==0)&& <Tag color={y?.historyT5_T4 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                  {(y.holdT5_T4 || y.holdT5_T4 ==0) && (
                    <Tag
                      color={
                        y.holdT5_T4 !== withoutModifiedValue &&
                        "red"
                      }
                    >
                      {y.holdT5_T4}
                    </Tag>
                  )}
                </div>:
                (<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
            {
              title: "T6",
              dataIndex: "t6",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.t6
                let modifiedValue = y?.modifiedT6
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyT6 || y?.historyT6 ==0 )&& (
                      <Tag color={y?.historyT6 !== withoutModifiedValue && "red"}>
                        {y?.historyT6}
                      </Tag>
                    )}
                    {(withoutModifiedValue ||withoutModifiedValue ==0)&& <Tag color={y?.historyT6 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdT6 || y.holdT6 ==0) && (
                      <Tag
                        color={
                          y.holdT6 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdT6}
                      </Tag>
                    )}
                  </div>:
                  ( <div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },
              width: "150px",
            },
            {
              title: "T6-T5",
              dataIndex: "t6_T5",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.t6_T5
                let modifiedValue = y?.modifiedT6_T5
                return ( y.status === 2 ? (<div >

                    {(y?.historyT6_T5 || y?.historyT6_T5 == 0) && (
                      <Tag color={y?.historyT6_T5 !== withoutModifiedValue && "red"}>
                        {y?.historyT6_T5}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag color={y?.historyT6_T5 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdT6_T5 || y.holdT6_T5 ==0) && (
                      <Tag
                        color={
                          y.holdT6_T5 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdT6_T5}
                      </Tag>
                    )}
                  </div>:
                  (<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
          ],
        },
   
        {
          title: (
            <div>
              Payout <br /> Rate
            </div>
          ),
          dataIndex: "pr",
          children: [
            {
              title: "R1",
              dataIndex: "r1",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r1
                let modifiedValue = y?.modifiedR1
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR1  || y?.historyR1 ==0)&& (
                      <Tag >
                        {y?.historyR1}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR1 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :
                  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR1 || y.holdR1 ==0) && (
                      <Tag
                        color={
                          y.holdR1 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR1}
                      </Tag>
                    )}
                  </div>:(<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>)
                )
              },

              width: "150px",
            },
            {
              title: "R2",
              dataIndex: "r2",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r2
                let modifiedValue = y?.modifiedR2
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR2 || y?.historyR2 ==0) && (
                      <Tag>
                        {y?.historyR2}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR2 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR2 || y.holdR2 ==0) && (
                      <Tag
                        color={
                          y.holdR2 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR2}
                      </Tag>
                    )}
                  </div>:
                  (<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
            {
              title: "R3",
              dataIndex: "r3",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r3
                let modifiedValue = y?.modifiedR3
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR3 ||y?.historyR3==0) && (
                      <Tag>
                        {y?.historyR3}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR3 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR3 || y.holdR3 ==0) && (
                      <Tag
                        color={
                          y.holdR3 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR3}
                      </Tag>
                    )}
                  </div>:
                  ( <div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
            {
              title: "R4",
              dataIndex: "r4",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r4
                let modifiedValue = y?.modifiedR4
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR4 || y?.historyR4  == 0) && (
                      <Tag>
                        {y?.historyR4}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR4 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :
                  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR4 || y.holdR4 ==0) && (
                      <Tag
                        color={
                          y.holdR4 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR4}
                      </Tag>
                    )}
                  </div>:<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>)
              },

              width: "150px",
            },
            {
              title: "R5",
              dataIndex: "r5", render: (x, y, index) => {

                let withoutModifiedValue = y?.r5
                let modifiedValue = y?.modifiedR5
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR5 || y?.historyR5  == 0)&& (
                      <Tag>
                        {y?.historyR5}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR5 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR5 || y.holdR5 ==0) && (
                      <Tag
                        color={
                          y.holdR5 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR5}
                      </Tag>
                    )}
                  </div>:
                  <div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>)
              },

              width: "150px",
            },
            {
              title: "R6",
              dataIndex: "r6",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r6
                let modifiedValue = y?.modifiedR6
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR6 || y?.historyR6 == 0) && (
                      <Tag>
                        {y?.historyR6}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR6 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :
                  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR6 || y.holdR6 ==0) && (
                      <Tag
                        color={
                          y.holdR6 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR6}
                      </Tag>
                    )}
                  </div>: (<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
          ],
        },

        {
          title: "Payout",
          dataIndex: "payout",

          children: [
            {
              title: "P1",
              dataIndex: "p1",
              width: "150px",
              render: (x, y, index) => (

                y.status === 2 ? (<div >

                  {(y?.historyP1 || y?.historyP1 == 0) && (
                    <Tag>
                      {y?.historyP1}
                    </Tag>
                  )}
                  {(y?.p1 || y?.p1 == 0 ) && <Tag color={y?.historyP1 !== y?.p1 && "red"}>{y?.p1}</Tag>}
                </div>
                ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p1  || y?.p1  == 0 ) && <Tag>{y?.p1 }</Tag>}
                  {(y.holdP1 || y.holdP1 ==0) && (
                    <Tag
                      color={
                        y.holdP1 !== y?.p1  &&
                        "red"
                      }
                    >
                      {y.holdP1}
                    </Tag>
                  )}
                </div>:( <div >
                 { (y?.p1 ||y?. p1 == 0) && <Tag>{y.p1}</Tag> }
                 { (y?.modifiedP1 || y?. modifiedP1 == 0) && <Tag color={y?.modifiedP1 !== y.p1 && "red"}>
                    {y?.modifiedP1  || 0}
                  </Tag>}
                </div>)
              ),
            },
            {
              title: "P2",
              dataIndex: "p2",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyP2 || y?.historyP2 == 0) && (
                    <Tag>
                      {y?.historyP2}
                    </Tag>
                  )}
                  {(y?.p2 || y?.p2 == 0 ) && <Tag color={y?.historyP2 !== y?.p2 && "red"}>{y?.p2}</Tag>}
                </div>
                ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p2 || y?.p2 == 0 ) && <Tag>{y?.p2}</Tag>}
                  {(y.holdP2 || y.holdP2 ==0) && (
                    <Tag
                      color={
                        y.holdP2 !== y?.p2 &&
                        "red"
                      }
                    >
                      {y.holdP2}
                    </Tag>
                  )}
                </div>: ( <div >
                 {(y?.p2 || y?. p2 == 0)  && <Tag>{y.p2}</Tag> }
                  { (y?.modifiedP2 || y?. modifiedP2 == 0)  &&<Tag color={y?.modifiedP2 !== y.p2 && "red"}>
                    {(y?.modifiedP2) || 0}
                  </Tag>}
                </div>)
              ),
            }, 
            {
              title: "P3",
              dataIndex: "p3",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyP3 || y?.historyP3 == 0) && (
                    <Tag>
                      {y?.historyP3}
                    </Tag>
                  )}
                  {(y?.p3 || y?.p3 == 0 ) && <Tag color={y?.historyP3 !== y?.p3 && "red"}>{y?.p3}</Tag>}
                </div>
                ) :    (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p3 || y?.p3 == 0 ) && <Tag>{y?.p3}</Tag>}
                  {(y.holdP3 || y.holdP3 ==0) && (
                    <Tag
                      color={
                        y.holdP3 !== y?.p3 &&
                        "red"
                      }
                    >
                      {y.holdP3}
                    </Tag>
                  )}
                </div>:( <div> 
                  {(y?.p3 || y?. p3 == 0) &&<Tag>{y.p3}</Tag>}
                 {(y?.modifiedP2 || y?. modifiedP2 == 0) && <Tag color={y?.modifiedP3 !== y.p3 && "red"}>
                    {(y?.modifiedP3) || 0}
                  </Tag>}
                </div>)
              ),
            },
            {
              title: "P4",
              dataIndex: "p4",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                {(y?.historyP4 || y?.historyP4 == 0) && (
                  <Tag >
                    {y?.historyP4}
                  </Tag>
                )}
                {(y?.p4 || y?.p4 == 0 ) && <Tag color={y?.historyP4 !== y?.p4 && "red"}>{y?.p4}</Tag>}
              </div>
              ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
              <div>
                {(y?.p4 || y?.p4 == 0 ) && <Tag>{y?.p4}</Tag>}
                {(y.holdP4 || y.holdP4 ==0) && (
                  <Tag
                    color={
                      y.holdP4 !== y?.p4 &&
                      "red"
                    }
                  >
                    {y.holdP4}
                  </Tag>
                )}
              </div>:<div >
                  { (y?.p4 || y?.p4 == 0) && <Tag>{y.p4}</Tag>}
                  {(y?.modifiedP4 || y?. modifiedP4 == 0) && <Tag color={y?.modifiedP4 !== y.p4 && "red"}>
                    {(y?.modifiedP4) || 0}
                  </Tag>}
                </div>
              ),
            },
            {
              title: "P5",
              dataIndex: "p5",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyP5 || y?.historyP5 == 0) && (
                    <Tag >
                      {y?.historyP5}
                    </Tag>
                  )}
                  {(y?.p5 || y?.p5 == 0 ) && <Tag color={y?.historyP5 !== y?.p5 && "red"}>{y?.p5}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p5 || y?.p5 == 0 ) && <Tag>{y?.p5}</Tag>}
                  {(y.holdP5 || y.holdP5 ==0) && (
                    <Tag
                      color={
                        y.holdP5 !== y?.p5 &&
                        "red"
                      }
                    >
                      {y.holdP5}
                    </Tag>
                  )}
                </div>:  <div >
                {  (y?.p5 || y?.p5 == 0)  && <Tag>{y.p5}</Tag>}
                 { (y?.modifiedP5 || y?. modifiedP5 == 0)  && <Tag color={y?.modifiedP5 !== y.p5 && "red"}>
                    {(y?.modifiedP5) || 0}
                  </Tag>}
                </div>
              ),
            },
            {
              title: "P6",
              dataIndex: "p6",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div>
                {(y?.historyP6 || y?.historyP6 == 0) && (
                  <Tag >
                    {y?.historyP6}
                  </Tag>
                )}
                {(y?.p6 || y?.p6 == 0 ) && <Tag color={y?.historyP6 !== y?.p6 && "red"}>{y?.p6}</Tag>}
              </div>
              ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
              <div>
                {(y?.p6 || y?.p6 == 0 ) && <Tag>{y?.p6}</Tag>}
                {(y.holdP6 || y.holdP6 ==0) && (
                  <Tag
                    color={
                      y.holdP6 !== y?.p6 &&
                      "red"
                    }
                  >
                    {y.holdP6}
                  </Tag>
                )}
              </div>:  
                <div >
                { (y?.p6 || y?.p6 == 0  )&& <Tag>{y.p6}</Tag>}
                 { (y?.modifiedP6 || y?.modifiedP6 == 0 ) &&<Tag color={y?.modifiedP6 !== y.p6 && "red"}>
                    {(y?.modifiedP6) || 0} 
                  </Tag>}
                </div>
              ),
            },
          ],
        },
        {
          title: "Total Expected Payout",
          dataIndex: "TEP",
          children: [
            {
              title: "Expected Volume",
              dataIndex: "volume",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyVolume || y?.historyVolume == 0) && (
                    <Tag >
                      {y?.historyVolume}
                    </Tag>
                  )} 
                  {(y?.volume || y?.volume == 0) && <Tag color={y?.historyVolume !== y?.volume && "red"}>{y?.volume}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.volume || y?.volume == 0 ) && <Tag>{y?.volume}</Tag>}
                  {(y.holdVolume || y.holdVolume ==0) && (
                    <Tag
                      color={
                        y.holdVolume !== y?.volume &&
                        "red"
                      }
                    >
                      {y.holdVolume}
                    </Tag>
                  )}
                </div>:  
                <div >
                  {(y.volume || y.volume == 0) && <Tag>{y.volume}</Tag>}
                  {(y.modifiedVolume || y.modifiedVolume == 0) && (
                    <Tag color={y.modifiedVolume !== y.volume && "red"}>
                      {y.modifiedVolume} 
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "Per Tr.",
              dataIndex: "perTr",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyPerTr ||  y?.historyPerTr == 0) && (
                    <Tag>
                      {y?.historyPerTr}
                    </Tag>
                  )}
                  {(y?.perTr || y?.perTr ==0) && <Tag color={y?.historyPerTr !== y?.perTr && "red"}>{y?.perTr}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.perTr || y?.perTr == 0 ) && <Tag>{y?.perTr}</Tag>}
                  {(y.holdPerTr || y.holdPerTr ==0) && (
                    <Tag
                      color={
                        y.holdPerTr !== y?.perTr &&
                        "red"
                      }
                    >
                      {y.holdPerTr}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y?.perTr || y?.perTr ==0) && <Tag>{y.perTr}</Tag>}
                  {(y.modifiedPerTr || y.modifiedPerTr == 0) && (
                    <Tag color={y.modifiedPerTr !== y.perTr && "red"}>
                      {y.modifiedPerTr}
                    </Tag>
                  )}
                </div>
              ),
            },
            {
              title: "Amount",
              dataIndex: "amount",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyAmount || y?.historyAmount ==0)&& (
                    <Tag>
                      {y?.historyAmount}
                    </Tag>
                  )}
                  {(y?.amount || y?.amount ==0) && <Tag color={y?.historyAmount !== y?.amount && "red"}>{y?.amount}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.amount || y?.amount == 0 ) && <Tag>{y?.amount}</Tag>}
                  {(y.holdAmount || y.holdAmount ==0) && (
                    <Tag
                      color={
                        y.holdAmount !== y?.amount &&
                        "red"
                      }
                    >
                      {y.holdAmount}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.amount || y.amount==0) && <Tag >{y.amount}</Tag>}
                  {(y.modifiedAmount ||y.modifiedAmount ==0) && (
                    <Tag color={y.modifiedAmount !== y.amount && "red"}>
                      {y.modifiedAmount}
                    </Tag>
                  )}
                </div>
              ),
            },
          ],
        },
      ];
    }


    if (schemeType == 3) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          // sorter: true,
          width: "200px",
        },
        {
          title: "Targets",
          dataIndex: "Target",
          children: [
           {
              title: "T1",
              dataIndex: "t1",
              render: (x, y) => (

                y.status === 2 ? (<div>
                  {(y?.historyT1 || y?.historyT1 ==0) && (
                    <Tag >
                      {y?.historyT1}
                    </Tag>
                  )}
                  {(y?.t1  || y?.t1 ==0)&& <Tag color={y?.historyT1 !== y?.t1 && "red"}>{y?.t1}</Tag>}
                </div>
                ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.t1 || y?.t1 == 0 ) && <Tag>{y?.t1}</Tag>}
                  {(y.holdT1 || y.holdT1 ==0) && (
                    <Tag
                      color={
                        y.holdT1 !== y?.t1 &&
                        "red"
                      }
                    >
                      {y.holdT1}
                    </Tag>
                  )}
                </div>:
                 (<div >
                  {(y?.t1 || y?.t1 == 0) && <Tag>{y?.t1}</Tag>}
                  {((y?.modifiedT1) || (y?.modifiedT1 == 0)) && (
                    <Tag
                      color={
                        ((y?.modifiedT1 !== y?.t1) && "red") || ((y?.modifiedT1 || 0) !== (y?.t1 || 0) && "red")
                      }
                    >
                      {y?.modifiedT1}
                    </Tag>
                  )}

                </div>)
              ),
              width: "150px",
            },
            {
              title: "T2",
              dataIndex: "t2",
              render: (x, y) => (

                y.status === 2 ? (<div >

                  {(y?.historyT2 || y?.historyT2_T1 == 0) && (
                    <Tag >
                      {y?.historyT2}
                    </Tag>
                  )}
                  {(y?.t2 ||  y?.t2 ==0) && <Tag color={y?.historyT2 !== y?.t2 && "red"}>{y?.t2}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.t2 || y?.t2 == 0 ) && <Tag>{y?.t2}</Tag>}
                  {(y.holdT2 || y.holdT2 ==0) && (
                    <Tag
                      color={
                        y.holdT2 !== y?.t2 &&
                        "red"
                      }
                    >
                      {y.holdT2}
                    </Tag>
                  )}
                </div>:
                (<div >
                  {(y?.t2 || y?.t2 == 0) && <Tag>{y?.t2}</Tag>}
                  {((y?.modifiedT2) || (y?.modifiedT2 == 0)) && (
                    <Tag
                      color={
                        ((y?.modifiedT2 !== y?.t2) && "red") || ((y?.modifiedT2 || 0) !== (y?.t2 || 0) && "red")
                      }
                    >
                      {y?.modifiedT2}
                    </Tag>
                  )}

                </div>)
              ),
              width: "150px",
            },
             {
              title: "T3",
              dataIndex: "t3",
              render: (x, y, index) => {
                let modifiedValue = y?.modifiedT3
                let withoutModifiedValue = y?.t3

                return (
                  y.status === 2 ? (<div>

                    {(y?.historyT3 || y?.historyT3 ==0 ) && (
                      <Tag>
                        {y?.historyT3}
                      </Tag>
                    )}
                    {(withoutModifiedValue ||  withoutModifiedValue == 0) && <Tag color={y?.historyT3 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) :(y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdT3 || y.holdT3 ==0) && (
                      <Tag
                        color={
                          y.holdT3 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdT3}
                      </Tag>
                    )}
                  </div>: <div>

                    {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                    {((modifiedValue) || (modifiedValue == 0)) && (
                      <Tag
                        color={
                          ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                        }
                      >
                        {modifiedValue}
                      </Tag>
                    )
                    }

                  </div>)
              },
              width: "150px",
            },
          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          // width: '180px',
          children: [
            {
              title: "R1",
              dataIndex: "r1",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r1
                let modifiedValue = y?.modifiedR1
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR1  || y?.historyR1 ==0)&& (
                      <Tag >
                        {y?.historyR1}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR1 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR1 || y.holdR1 ==0) && (
                      <Tag
                        color={
                          y.holdR1 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR1}
                      </Tag>
                    )}
                  </div>:(<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>)
                )
              },

              width: "150px",
            },
            {
              title: "R2",
              dataIndex: "r2",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r2
                let modifiedValue = y?.modifiedR2
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR2 || y?.historyR2 ==0) && (
                      <Tag >
                        {y?.historyR2}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR2 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR2 || y.holdR2 ==0) && (
                      <Tag
                        color={
                          y.holdR2 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR2}
                      </Tag>
                    )}
                  </div>:(<div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
            {
              title: "R3",
              dataIndex: "r3",
              render: (x, y, index) => {

                let withoutModifiedValue = y?.r3
                let modifiedValue = y?.modifiedR3
                return (
                  y.status === 2 ? (<div >

                    {(y?.historyR3 ||y?.historyR3==0) && (
                      <Tag >
                        {y?.historyR3}
                      </Tag>
                    )}
                    {(withoutModifiedValue || withoutModifiedValue ==0) && <Tag color={y?.historyR3 !== withoutModifiedValue && "red"}>{withoutModifiedValue}</Tag>}
                  </div>
                  ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                  <div>
                    {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
                    {(y.holdR3 || y.holdR3 ==0) && (
                      <Tag
                        color={
                          y.holdR3 !== withoutModifiedValue &&
                          "red"
                        }
                      >
                        {y.holdR3}
                      </Tag>
                    )}
                  </div>:( <div>

                  {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue}</Tag>}
                  {((modifiedValue) || (modifiedValue == 0)) && (
                    <Tag
                      color={
                        ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
                      }
                    >
                      {modifiedValue}
                    </Tag>
                  )
                  }

                </div>))
              },

              width: "150px",
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          // width: '180px',
          children: [
            {
              title: "P1",
              dataIndex: "p1",
              width: "150px",
              render: (x, y, index) => (

                y.status === 2 ? (<div >

                  {(y?.historyP1 || y?.historyP1 == 0) && (
                    <Tag>
                      {y?.historyP1}
                    </Tag>
                  )}
                  {(y?.p1 || y?.p1 == 0 ) && <Tag color={y?.historyP1 !== y?.p1 && "red"}>{y?.p1}</Tag>}
                </div>
                ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
                  {(y.holdP1 || y.holdP1 ==0) && (
                    <Tag
                      color={
                        y.holdP1 !== y?.p1 &&
                        "red"
                      }
                    >
                      {y.holdP1}
                    </Tag>
                  )}
                </div>: ( <div >
                 { (y?.p1 ||y?. p1 == 0) && <Tag>{y.p1}</Tag> }
                 { (y?.modifiedP1 || y?. modifiedP1 == 0) && <Tag color={y?.modifiedP1 !== y.p1 && "red"}>
                    {y?.modifiedP1  || 0}
                  </Tag>}
                </div>)
              ),
            },
            {
              title: "P2",
              dataIndex: "p2",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyP2 || y?.historyP2 == 0) && (
                    <Tag>
                      {y?.historyP2}
                    </Tag>
                  )}
                  {(y?.p2 || y?.p2 == 0 ) && <Tag color={y?.historyP2 !== y?.p2 && "red"}>{y?.p2}</Tag>}
                </div>
                ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p2 || y?.p2 == 0 ) && <Tag>{y?.p2}</Tag>}
                  {(y.holdP2 || y.holdP2 ==0) && (
                    <Tag
                      color={
                        y.holdP2 !== y?.p2 &&
                        "red"
                      }
                    >
                      {y.holdP2}
                    </Tag>
                  )}
                </div>: ( <div >
                 {(y?.p2 || y?. p2 == 0)  && <Tag>{y.p2}</Tag> }
                  { (y?.modifiedP2 || y?. modifiedP2 == 0)  &&<Tag color={y?.modifiedP2 !== y.p2 && "red"}>
                    {(y?.modifiedP2) || 0}
                  </Tag>}
                </div>)
              ),
            }, 
            {
              title: "P3",
              dataIndex: "p3",
              width: "150px",
              render: (x, y, index) => (
                y.status === 2 ? (<div >

                  {(y?.historyP3 || y?.historyP3 == 0) && (
                    <Tag>
                      {y?.historyP3}
                    </Tag>
                  )}
                  {(y?.p3 || y?.p3 == 0 ) && <Tag color={y?.historyP3 !== y?.p3 && "red"}>{y?.p3}</Tag>}
                </div>
                ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
                <div>
                  {(y?.p3 || y?.p3 == 0 ) && <Tag>{y?.p3}</Tag>}
                  {(y.holdP3 || y.holdP3 ==0) && (
                    <Tag
                      color={
                        y.holdP3 !== y?.p3 &&
                        "red"
                      }
                    >
                      {y.holdP3}
                    </Tag>
                  )}
                </div>:  ( <div> 
                  {(y?.p3 || y?. p3 == 0) &&<Tag>{y.p3}</Tag>}
                 {(y?.modifiedP2 || y?. modifiedP2 == 0) && <Tag color={y?.modifiedP3 !== y.p3 && "red"}>
                    {(y?.modifiedP3) || 0}
                  </Tag>}
                </div>)
              ),
            },
          ],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => (
            
              y.status === 2 ? (<div>
                {(y?.historyInstallationTarget || y?.historyInstallationTarget ==0) && (
                  <Tag>
                    {y?.historyInstallationTarget}
                  </Tag>
                )}
                {(y?.installationTarget || y?.installationTarget==0) && <Tag color={y?.historyInstallationTarget !== y?.installationTarget && "red"}>{y?.installationTarget}</Tag>}
              </div>
              ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
              <div>
                {(y?.installationTarget || y?.installationTarget == 0 ) && <Tag>{y?.installationTarget}</Tag>}
                {(y.holdInstallationTarget || y.holdInstallationTarget == 0) && (
                  <Tag
                    color={
                      y.holdInstallationTarget !== y?.installationTarget &&
                      "red"
                    }
                  >
                    {y.holdInstallationTarget}
                  </Tag>
                )}
              </div>: (<div>
             {(y?.installationTarget || y?.installationTarget==0) && <Tag>{y?.installationTarget}</Tag>}

              {(y.modifiedInstallationTarget || y.modifiedInstallationTarget ==0 )&&<Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>
                {y?.modifiedInstallationTarget}
              </Tag>}

            </div>)
            )
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          width: "120px",
          render: (x, y) => (
            y.status === 2 ? (<div>
              {(y?.historyUpperCap || y?.historyUpperCap ==0) && (
                <Tag>
                  {y?.historyUpperCap}
                </Tag>
              )}
              {(y?.upperCap || y?.upperCap==0) && <Tag color={y?.historyUpperCap !== y?.upperCap && "red"}>{y?.installationTarget}</Tag>}
            </div>
            ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.upperCap || y?.upperCap == 0 ) && <Tag>{y?.upperCap}</Tag>}
              {(y.holdUpperCap || y.holdUpperCap ==0) && (
                <Tag
                  color={
                    y.holdUpperCap !== y?.upperCap &&
                    "red"
                  }
                >
                  {y.holdUpperCap}
                </Tag>
              )}
            </div>: <div>
              { (y?.upperCap || y?.upperCap==0) &&<Tag>{y?.upperCap}</Tag>
}
             {(y?.modifiedUpperCap || y?.modifiedUpperCap==0)&& <Tag color={y?.modifiedUpperCap !== y?.upperCap && "red"}>
                {y?.modifiedUpperCap}
              </Tag>}

            </div>
          )
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
          render: (x, y) => (
            y.status === 2 ? (<div>
              {(y?.historyIncPerTarget || y?.historyIncPerTarget ==0) && (
                <Tag>
                  {y?.historyIncPerTarget}
                </Tag>
              )}
              {(y?.incPerTarget || y?.incPerTarget==0) && <Tag color={y?.historyIncPerTarget !== y?.incPerTarget && "red"}>{y?.incPerTarget}</Tag>}
            </div>
            ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.incPerTarget || y?.incPerTarget == 0 ) && <Tag>{y?.incPerTarget}</Tag>}
              {(y.holdIncPerTarget || y.holdIncPerTarget ==0) && (
                <Tag
                  color={
                    y.holdIncPerTarget !== y?.incPerTarget &&
                    "red"
                  }
                >
                  {y.holdIncPerTarget}
                </Tag>
              )}
            </div>:( <div>
              {(y?.incPerTarget || y?.incPerTarget==0) && <Tag>{y?.incPerTarget}</Tag>}
              {( y?.modifiedIncPerTarget || y?.modifiedIncPerTarget ==0) &&<Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>
                {y?.modifiedIncPerTarget}
              </Tag>}

            </div>)
          )
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
          render: (x, y) => (


            y.status === 2 ? (<div>
              {y?.historyP1 && (
                <Tag color={y?.historyP1 !== y?.p1 && "red"}>
                  {y?.historyP1}
                </Tag>
              )}
              {y?.historyP1 && <Tag color={y?.historyP1 !== y?.p1 && "red"}>{y?.p1}</Tag>}
            </div>
            ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.p1  || y?.p1  == 0 ) && <Tag>{y?.p1 }</Tag>}
              {(y.holdP1 || y.holdP1 ==0) && (
                <Tag
                  color={
                    y.holdP1 !== y?.p1  &&
                    "red"
                  }
                >
                  {y.holdP1}
                </Tag>
              )}
            </div>:(
              <div>
                <Tag color={y?.modifiedP1 !== y?.incPerTarget && "red"}>{y?.p1}</Tag>{y?.modifiedP1 && (
                  <Tag color={y?.modifiedP1 !== y?.incPerTarget && "red"}>
                    {y?.modifiedP1}
                  </Tag>
                )}
              </div>
            )
          )
        },
      ];
    }
    if (schemeType == 8) { 
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => (
            
            y.status === 2 ? (<div>
              {(y?.historyInstallationTarget || y?.historyInstallationTarget ==0) && (
                <Tag>
                  {y?.historyInstallationTarget}
                </Tag>
              )}
              {(y?.installationTarget || y?.installationTarget==0) && <Tag color={y?.historyInstallationTarget !== y?.installationTarget && "red"}>{y?.installationTarget}</Tag>}
            </div>
            ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.installationTarget || y?.installationTarget == 0 ) && <Tag>{y?.installationTarget}</Tag>}
              {(y.holdInstallationTarget || y.holdInstallationTarget ==0) && (
                <Tag
                  color={
                    y.holdInstallationTarget !== y?.installationTarget &&
                    "red"
                  }
                >
                  {y.holdInstallationTarget}
                </Tag>
              )}
            </div>:(<div>
           {(y?.installationTarget || y?.installationTarget==0) && <Tag>{y?.installationTarget}</Tag>}

            {(y.modifiedInstallationTarget || y.modifiedInstallationTarget ==0 )&&<Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>
              {y?.modifiedInstallationTarget}
            </Tag>}

          </div>)
          )
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
          render: (x, y) => (
            y.status === 2 ? (<div>
              {(y?.historyIncPerTarget || y?.historyIncPerTarget ==0) && (
                <Tag>
                  {y?.historyIncPerTarget}
                </Tag>
              )}
              {(y?.incPerTarget || y?.incPerTarget==0) && <Tag color={y?.historyIncPerTarget !== y?.incPerTarget && "red"}>{y?.incPerTarget}</Tag>}
            </div>
            ) :  (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.incPerTarget || y?.incPerTarget == 0 ) && <Tag>{y?.incPerTarget}</Tag>}
              {(y.holdIncPerTarget || y.holdIncPerTarget ==0) && (
                <Tag
                  color={
                    y.holdIncPerTarget !== y?.incPerTarget &&
                    "red"
                  }
                >
                  {y.holdIncPerTarget}
                </Tag>
              )}
            </div>:( <div>
              {(y?.incPerTarget || y?.incPerTarget==0) && <Tag>{y?.incPerTarget}</Tag>}
              {( y?.modifiedIncPerTarget || y?.modifiedIncPerTarget ==0) &&<Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>
                {y?.modifiedIncPerTarget}
              </Tag>}

            </div>)
          )
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
          render: (x, y) => (


            y.status === 2 ? (<div>
              {(y?.historyP1 || y?.historyP1 ==0 ) && (
                <Tag color={y?.historyP1 !== y?.p1 && "red"}>
                  {y?.historyP1}
                </Tag>
              )}
              {(y?.p1 || y?.p1 == 0) && <Tag color={y?.historyP1 !== y?.p1 && "red"}>{y?.p1}</Tag>}
            </div>
            ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
              {(y.holdP1 || y.holdP1 ==0) && (
                <Tag
                  color={
                    y.holdP1 !== y?.p1 &&
                    "red"
                  }
                >
                  {y.holdP1}
                </Tag>
              )}
            </div>:(
              <div>
                {<Tag >{y?.p1}</Tag>}
                {y?.modifiedP1 && (
                  <Tag color={y?.modifiedP1 !== y?.p1 && "red"}>
                    {y?.modifiedP1}
                  </Tag>
                )}
              </div>
            )
          )
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Expected",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => (
            
            y.status === 2 ? (<div>
              {(y?.historyInstallationTarget || y?.historyInstallationTarget ==0) && (
                <Tag>
                  {y?.historyInstallationTarget}
                </Tag>
              )}
              {(y?.installationTarget || y?.installationTarget==0) && <Tag color={y?.historyInstallationTarget !== y?.installationTarget && "red"}>{y?.installationTarget}</Tag>}
            </div>
            ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.installationTarget || y?.installationTarget == 0 ) && <Tag>{y?.installationTarget}</Tag>}
              {(y.holdInstallationTarget || y.holdInstallationTarget ==0) && (
                <Tag
                  color={
                    y.holdInstallationTarget !== y?.installationTarget &&
                    "red"
                  }
                >
                  {y.holdInstallationTarget}
                </Tag>
              )}
            </div>:(<div>
           {(y?.installationTarget || y?.installationTarget==0) && <Tag>{y?.installationTarget}</Tag>}

            {(y.modifiedInstallationTarget || y.modifiedInstallationTarget ==0 )&&<Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>
              {y?.modifiedInstallationTarget}
            </Tag>}

          </div>)
          )
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "modifiedIncPerTarget",

          width: "190px",
          render: (x, y) => (
            y.status === 2 ? (<div>
              {(y?.historyIncPerTarget || y?.historyIncPerTarget ==0) && (
                <Tag>
                  {y?.historyIncPerTarget}
                </Tag>
              )}
              {(y?.incPerTarget || y?.incPerTarget==0) && <Tag color={y?.historyIncPerTarget !== y?.incPerTarget && "red"}>{y?.incPerTarget}</Tag>}
            </div>
            ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.incPerTarget || y?.incPerTarget == 0 ) && <Tag>{y?.incPerTarget}</Tag>}
              {(y.holdIncPerTarget || y.holdIncPerTarget ==0) && (
                <Tag
                  color={
                    y.holdIncPerTarget !== y?.incPerTarget &&
                    "red"
                  }
                >
                  {y.holdIncPerTarget}
                </Tag>
              )}
            </div>:( <div>
              {(y?.incPerTarget || y?.incPerTarget==0) && <Tag>{y?.incPerTarget}</Tag>}
              {( y?.modifiedIncPerTarget || y?.modifiedIncPerTarget ==0) &&<Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>
                {y?.modifiedIncPerTarget}
              </Tag>}

            </div>)
          )
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
          render: (x, y) => (


            y.status === 2 ? (<div>
              {y?.historyP1 && (
                <Tag color={y?.historyP1 !== y?.p1 && "red"}>
                  {y?.historyP1}
                </Tag>
              )}
              {y?.historyP1 && <Tag color={y?.historyP1 !== y?.p1 && "red"}>{y?.p1}</Tag>}
            </div>
            ) : (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
              {(y.holdP1 || y.holdP1 ==0) && (
                <Tag
                  color={
                    y.holdP1 !== y?.p1 &&
                    "red"
                  }
                >
                  {y.holdP1}
                </Tag>
              )}
            </div>: (
              <div>
                <Tag color={y?.modifiedP1 !== y?.incPerTarget && "red"}>{y?.p1}</Tag>{y?.modifiedP1 && (
                  <Tag color={y?.modifiedP1 !== y?.incPerTarget && "red"}>
                    {y?.modifiedP1}
                  </Tag>
                )}
              </div>
            )
          )
        },
      ];
    }
    return [];
  };


  const topTargetShow = (x) =>
    (x == 1 || x == 2 || x == 3) ? [{
      title: (
        <div>
          TOP SLAB
        </div>
      ),
      dataIndex: "topTarget",
      width: "180px",
      sorter: true,
      render: (x, y, index) => {
        
        let withoutModifiedValue = y?.topTarget?.toLowerCase() || ''
        let modifiedValue = y?.modifiedTopTarget?.trim() != `NaN` ? y?.modifiedTopTarget?.toLowerCase() : ''
        return (
          (y.holdStatus === 9 || y.holdStatus === 10 ) ?
            <div>
              {(withoutModifiedValue || withoutModifiedValue == 0 ) && <Tag>{withoutModifiedValue}</Tag>}
              {(y.holdTopTarget || y.holdTopTarget ==0) && (
                <Tag
                  color={
                    y.holdTopTarget !== withoutModifiedValue &&
                    "red"
                  }
                >
                  {y.holdTopTarget}
                </Tag>
              )}
            </div>:
        <div>

          {(withoutModifiedValue || withoutModifiedValue == 0) && <Tag>{withoutModifiedValue?.toUpperCase() || ""}</Tag>}
          {(modifiedValue && modifiedValue != `"NaN`) && (
            <Tag
              color={
                ((modifiedValue !== withoutModifiedValue) && "red") || ((modifiedValue || 0) !== (withoutModifiedValue || 0) && "red")
              }
            >
              {modifiedValue != "NaN" ? modifiedValue?.toUpperCase() : ""}
            </Tag>
          )
          }

        </div>)
      },
      defaultSortOrder: "ascend",
      key: "topTarget",
    }] : []
    const getHoldRemarks =(selectedLabel)=> (selectedLabel === 'Hold Schemes')
    ?
    [
      {
        title: "Hold Remarks",
        dataIndex: "holdReason",
        width: "120px",
      }
  
    ]:[]
  const commonColumns = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "scheme",
      width: "145px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      render: (_, item) => {
        let color = " ";
        const dataStatus=item?.holdStatus==9?item?.holdStatus:item.status
        switch (item.status) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "#FFAE00"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      // sorter: true,
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      fixed: "left",
      dataIndex: "dealerLocation",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      sorter: true,
      key: "dealerName",
      defaultSortOrder: "ascend",
      // fixed: "left",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      sorter: true,
      key: "StateName",
      defaultSortOrder: "ascend",
      // fixed: "left",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
    },
    ...topTargetShow(selectedSchemeType),
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      // sorter: true,
      width: "140px",
      render: (_, item) => dayjs(item?.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      // sorter: true,
      width: "140px",
      render: (_, item) => dayjs(item?.endMonth).format("MMM'YY"),
    },
    ...getCols(selectedSchemeType),
    {
      title: "Remarks",
      dataIndex: "reason",
      render: (x, y, index) => (
        y.status === 2 ? (
   <div className="">
   {y.historyReason?.toLowerCase() == y.reason?.toLowerCase() ? <Tag>
     <Tooltip title={y.reason}>{y.reason}</Tooltip>
   </Tag> : <Tooltip title={y.historyReason} >
     <Tag >
       {y.historyReason}
     </Tag>
   </Tooltip>}
 </div>) :
 (y.holdStatus === 9 || y.holdStatus === 10 ) ?
         <div className="">
   {y.holdRemarks?.toLowerCase() == y.reason?.toLowerCase() ? <Tag>
     <Tooltip title={y.reason}>{y.reason}</Tooltip>
   </Tag> : <Tooltip title={y.holdRemarks} >
     <Tag >
       {y.holdRemarks}
     </Tag>
   </Tooltip>}
 </div>:  (<div className="">
   {y.modifiedReason?.toLowerCase() == y.reason?.toLowerCase() ? <Tag>
     <Tooltip title={y.reason}>{y.reason}</Tooltip>
   </Tag> : <Tooltip title={y.modifiedReason}>
     <Tag >
       {y.modifiedReason}
     </Tag>
   </Tooltip>}
 </div>)

      ),
      width: "250px",
    },
...getHoldRemarks(selectedLabel)
  ];





  // const scroll =
  //   selectedSchemeType == 1 || selectedSchemeType == 2
  //     ? {
  //       x: 4200,
  //       y: 1000,
  //     }
  //     : {
  //       x: 2000,
  //       y: 300,
  //     };

  const evaluateTotalRow = (fieldSum) => {
    if (selectedSchemeType == 1 || selectedSchemeType == 2) {
      return (
        <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}></Table.Summary.Cell>
          <Table.Summary.Cell index={11}></Table.Summary.Cell>
          <Table.Summary.Cell index={12}></Table.Summary.Cell>
          <Table.Summary.Cell index={13}></Table.Summary.Cell>
          <Table.Summary.Cell index={14}></Table.Summary.Cell>
          <Table.Summary.Cell index={15}></Table.Summary.Cell>
          <Table.Summary.Cell index={16}></Table.Summary.Cell>
          <Table.Summary.Cell index={17}></Table.Summary.Cell>
          <Table.Summary.Cell index={18}></Table.Summary.Cell>
          <Table.Summary.Cell index={19}></Table.Summary.Cell>
          <Table.Summary.Cell index={20}></Table.Summary.Cell>
          <Table.Summary.Cell index={21}></Table.Summary.Cell>
          <Table.Summary.Cell index={22}></Table.Summary.Cell>
          <Table.Summary.Cell index={23}></Table.Summary.Cell>
          <Table.Summary.Cell index={24}>
            {fieldSum.t1 ? fieldSum.t1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={25}>
            {fieldSum.t2 ? fieldSum.t2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={26}>
            {fieldSum.T2_T1 ? fieldSum.T2_T1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={27}>
            {fieldSum.t3 ? fieldSum.t3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={28}>
            {fieldSum.T3_T2 ? fieldSum.T3_T2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={27}>
            {fieldSum.t4 ? fieldSum.t4 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={29}></Table.Summary.Cell>
          <Table.Summary.Cell index={30}> </Table.Summary.Cell>
          <Table.Summary.Cell index={31}></Table.Summary.Cell>
          <Table.Summary.Cell index={32}></Table.Summary.Cell>
          <Table.Summary.Cell index={33}>
            {fieldSum.totalR1 ? fieldSum.totalR1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={34}>
            {fieldSum.totalR2 ? fieldSum.totalR2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={35}>
            {fieldSum.totalR3 ? fieldSum.totalR3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={36}>
            {fieldSum.vol ? fieldSum.vol : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={37}>
            {fieldSum.perTr ? fieldSum.perTr : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={38}>
            {fieldSum.totalAmount ? fieldSum.totalAmount : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={39}></Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 3) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.T1 ? fieldSum.T1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.T2 ? fieldSum.T2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.R1 ? fieldSum.R1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {fieldSum.R2 ? fieldSum.R2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={14}>
            {fieldSum.P1Slab ? fieldSum.P1Slab : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={15}>
            {fieldSum.P2Slab ? fieldSum.P2Slab : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 8) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.target ? fieldSum.target : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.P1Single ? fieldSum.P1Single : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 4) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.target ? fieldSum.target : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.upperCap ? fieldSum.upperCap : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {fieldSum.P1UC ? fieldSum.P1UC : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 5) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.installationExpected ? fieldSum.installationExpected : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.P1U ? fieldSum.P1U : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    }
  };

  return (
    <>
      <div>
        <Table
          style={{
            cursor: "default",
            marginTop: "9px",
          }}
          // summary={(pageData) => {
          //   const fieldSum = {
          //     b: 0,
          //     i: 0,
          //     c: 0,
          //     totalR1: 0,
          //     totalR2: 0,
          //     totalR3: 0,
          //     t1: 0,
          //     t2: 0,
          //     t3: 0,
          //     T2_T1: 0,
          //     T3_T2: 0,
          //     vol: 0,
          //     perTr: 0,
          //     totalAmount: 0,
          //     T1: 0,
          //     T2: 0,
          //     R1: 0,
          //     R2: 0,
          //     P1: 0,
          //     P2: 0,
          //     P1Slab: 0,
          //     P2Slab: 0,
          //     P1Single: 0,
          //     P1UC: 0,
          //     P1U: 0,
          //     target: 0,
          //     incPerTarget: 0,
          //     payout: 0,
          //     installationExpected: 0,
          //     upperCap: 0,
          //   };
          //   pageData.forEach(
          //     ({
          //       payoutR1,
          //       payoutR2,
          //       payoutR3,
          //       TEPamount,
          //       BooksCollectionTargetT1,
          //       BooksCollectionTargetT2,
          //       BooksCollectionTargetT3,
          //       BooksCollectionTargetT2T1,
          //       BooksCollectionTargetT3T2,
          //       TEPvol,
          //       t1,
          //       t2,
          //       r1,
          //       r2,
          //       p1,
          //       p2,
          //       Target,
          //       UC,
          //       incPerTarget,
          //       Payout,
          //       TEPpertr,
          //     }) => {
          //       fieldSum.totalR1 += payoutR1 ? parseInt(payoutR1) : 0;
          //       fieldSum.totalR2 += payoutR2 ? parseInt(payoutR2) : 0;
          //       fieldSum.totalR3 += payoutR3 ? parseInt(payoutR3) : 0;
          //       fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
          //       fieldSum.t1 += BooksCollectionTargetT1
          //         ? parseInt(BooksCollectionTargetT1)
          //         : 0;
          //       fieldSum.t2 += BooksCollectionTargetT2
          //         ? parseInt(BooksCollectionTargetT2)
          //         : 0;
          //       fieldSum.t3 += BooksCollectionTargetT3
          //         ? parseInt(BooksCollectionTargetT3)
          //         : 0;
          //       fieldSum.T2_T1 += BooksCollectionTargetT2T1
          //         ? parseInt(BooksCollectionTargetT2T1)
          //         : 0;
          //       fieldSum.T3_T2 += BooksCollectionTargetT3T2
          //         ? parseInt(BooksCollectionTargetT3T2)
          //         : 0;
          //       fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
          //       fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
          //       fieldSum.T1 += t1 ? parseInt(t1) : 0;
          //       fieldSum.T2 += t2 ? parseInt(t2) : 0;
          //       fieldSum.R1 = r1 ? parseInt(p1) / parseInt(t1) : 0;
          //       fieldSum.R2 = r2 ? parseInt(p2) / parseInt(t2) : 0;
          //       // fieldSum.R1 += r1 ? parseInt(r1) : 0;
          //       // fieldSum.R2 += r2 ? parseInt(r2) : 0;
          //       fieldSum.P1 += p1 ? parseInt(p1) : 0;
          //       fieldSum.P2 += p2 ? parseInt(p2) : 0;
          //       fieldSum.P1Slab = p1 ? parseInt(fieldSum.T1 * fieldSum.R1) : 0;
          //       fieldSum.P2Slab = p2 ? parseInt(fieldSum.T2 * fieldSum.R2) : 0;

          //       fieldSum.target += Target ? parseInt(Target) : 0;
          //       fieldSum.incPerTarget += incPerTarget
          //         ? parseInt(incPerTarget)
          //         : 0;
          //       fieldSum.P1Single = p1
          //         ? parseInt(fieldSum.incPerTarget * fieldSum.target)
          //         : 0;
          //       fieldSum.upperCap += UC ? parseInt(UC) : 0;
          //       fieldSum.P1UC = p1
          //         ? parseInt(fieldSum.upperCap * fieldSum.incPerTarget)
          //         : 0;

          //       fieldSum.payout += Payout ? parseInt(Payout) : 0;
          //       fieldSum.installationExpected += Target ? parseInt(Target) : 0;
          //       fieldSum.P1U = p1
          //         ? parseInt(
          //           fieldSum.incPerTarget * fieldSum.installationExpected
          //         )
          //         : 0;
          //     }
          //   );
          //   return evaluateTotalRow(fieldSum);
          // }}

          columns={commonColumns}
          dataSource={dataSource}
          onChange={handleChange}
          loading={loading}
          scroll={{
            x: 2000,
            y: 300
          }}
          pagination={false}

        />
      </div>

    </>
  );
};
export default TrackTable;
