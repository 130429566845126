import React, { useEffect, useState } from "react";
import  CreateSchemeTable from "../createSchemes/createSchemeTable"
import { getExpiredStatusAMGR, getExpiredStatusSADM, getExpiredStatusZADM } from "../../../components/header/schemeExpiredDetails";
import ExpiredWindowShow from "../../../components/expiredWindow/ExpiredWindowShow";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { useDispatch, useSelector } from "react-redux";
import { dealerTrackCounterApi, getStateWiseDataApi } from "../../../redux/stateData/stateDataSlice";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import SchemeWindowShow from "../../../components/expiredWindow/schemeWindowShow";
import { ENV_TIME } from "../../../config";


const CollectionCreateNewScheme = () => {
  const {userRole} = decryptData(sessionStorage.getItem("userInfo"));
  const dispatch=useDispatch()

  const [schemeData,setSchemeData]=useState([])
  const { loadingDealer}  = useSelector((state) => state.stateData);
  const dayExpired=userRole=="SADM"?getExpiredStatusSADM():userRole=="AMGR"?getExpiredStatusAMGR():getExpiredStatusZADM()
  useEffect(()=>{
    dispatch(dealerTrackCounterApi()).then(async(data)=>{
        // Parse the XML data
        const parser = await new DOMParser();
        const xmlDoc = await parser.parseFromString(data?.payload?.data, "application/xml");

        // Convert the XML data to an array format
        const rows =  await xmlDoc.getElementsByTagName('Row');

        const items = await Array.from(rows).map(row => {
          return {
            DealerCode: row.getElementsByTagName('DealerCode')[0]?.textContent,
            StateName: row.getElementsByTagName('StateName')[0]?.textContent,
            TMName: row.getElementsByTagName('TMName')[0]?.textContent,
            BillingCount: row.getElementsByTagName('BillingCount')[0]?.textContent,
            DeliveryCount: row.getElementsByTagName('DeliveryCount')[0]?.textContent,
            TractorInventory: row.getElementsByTagName('TractorInventory')[0]?.textContent,
            ClosuerDate: row.getElementsByTagName('ClosuerDate')[0]?.textContent,
          };
        });
        setSchemeData(items)
        // console.log(items,"itemsitems")
    })
  },[])

    return(
        <div className="create_scheme_wrap collection-schme-box creating-schema-box">
        
        {loadingDealer=="pending"?<FullScreenLoader />:(schemeData?.length==1||(schemeData?.[0]?.DealerCode=="null"&&schemeData?.[0]?.StateName=="null"&&schemeData?.[0]?.TMName=="null"))?<SchemeWindowShow />:dayExpired ? (
        <ExpiredWindowShow />
      ):<CreateSchemeTable/>}
        </div>
    )
}
export default CollectionCreateNewScheme;
