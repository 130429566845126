import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  message,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import axiosAPI from "../../../services/axiosApi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx-js-style";
import {
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { getStateListApi } from "../../../redux/stateData/stateDataSlice";
import "../modificationApproval/ModificationInstallation";
import {
  getModificationAcceptAsync,
  getModificationAsync,
  getModificationRejectAsync,
  resetModifiedData,
  getModificationAwaitingAsync,
  getModificationRejectHoldAsync,
} from "../../../redux/modification/modificationSlice";
import {
  getCurrentFiscalYear,
  getCurrentFiscalYear1,
} from "../../../utils/dateFormatter";
import { ReactComponent as Edit } from "../../../assets/vector/edit.svg";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import EditModifiedAwaitingInstallation from "./ModifiedAwaiting/EditModifiedAwaitingInstallation";
import EditModifiedAwaitingCollection from "./ModifiedAwaiting/EditModifiedAwaitingCollection";
import AwaitingApprovalTable from "./awaitingApprovalTable";
import ToastifyShow from "../../../components/ToastifyShow";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import { Link } from "react-router-dom";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import { updateApprovedHoldSchemeApi } from "../../../redux/schemaGenerate/schemaGenerateSlice";
import moment from "moment";
import { formatNumberDecimal } from "../../../utils/formatter";

const { RangePicker } = DatePicker;
const InitCollectionData = {
  currentYear: "2024",
  schemeType: 1,
  dealerCode: [],
  month: 0,
  monthEnd: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: 150,
  pageNumber: 1,
};
const ModificationAwaitingApproval = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const {loadingAcceptState,loadingUpdateScheme,loadingModificationState } = useSelector((state) => state.modifyCollection);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal1, setOpenAcceptModal1] = useState(false);
  const [openRejectModal1, setOpenRejectModal1] = useState(false);
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const [selectedSH, setSelectedSH] = useState("");
  const [editSchemes, setEditSchemes] = useState(false);
  const [modificationId, setModificationId] = useState("");
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const [selectedLabel, setSelectedLabel] = useState("Schemes");
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const currentFiscalYear = getCurrentFiscalYear1(
    collectionData?.month || new Date()
  );
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);
  const [stateHeadData, setStateHeadData] = useState([]);
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [selectedAM, setSelectedAM] = useState("");
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [selectedZH, setSelectedZH] = useState("");
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [editCheckbox, setEditCheckbox] = useState("");
  const [tableArr, setTableArr] = useState("");
  const [schemeNo, setSchemeNo] = useState([]);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [schemeId, setSchemeId] = useState([]);
  const {loadingSaveHoldState}=useSelector((state)=>state.holdData)
  const { modifiedData, loadingState1, modifiedHoldData,loadingModificationAwaitingState } = useSelector(
    (state) => state.modifyCollection
  );

  const dataSource =
    selectedLabel === "Hold Schemes"
      ? modifiedHoldData?.data?.trackholdSchemeList
      : modifiedData?.data?.modifiedSchemeList;
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear =
    currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);
  useEffect(() => {
    if (dataSource?.length != 0) {
      setTableArr(dataSource?.map((x, index) => ({ ...x, key: index })));
    } else {
      setTableArr([]);
    }
  }, [dataSource]);

  useEffect(() => {
    dispatch(getStateListApi());
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (selectedLabel === "Hold Schemes") {
        getHoldData();
      } else {
        getApiData();
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [editSchemes]);
  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
      const zonalHeadData = result.data.data;
      setZonalHeadData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedZH("All Zonal Head");
                setStateHeadData([]);
                setSelectedZHID(0);
                setSelectedSHID(0);
                setSelectedSH("All State Heads");
                setAreaMngrData([]);
                setSelectedAMID(0);
                setSelectedAM("All Area Managers");
              }}
            >
              All Zonal Head
            </span>
          ),
        },
        ...zonalHeadData.map((item) => {
          return {
            key: item.id,
            label: (
              <span
                onClick={() => {
                  setSelectedZH(item.zonalHeadName);
                  setSelectedZHID(item.id);
                  setSelectedSHID(0);
                  setSelectedSH("All State Heads");
                  getShDropdownData(item.id);
                  setAreaMngrData([]);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                }}
              >
                {item.zonalHeadName}
              </span>
            ),
          };
        }),
      ]);
    });

    if (["SADM"].includes(userRole)) {
      getStateByStateHeadId(userId);
    }
  }, []);

  const getStateByStateHeadId = (SHID) => {
    let lastTwoDigits = dateValue % 100;
    const payload = {
      fyear: "FY" + lastTwoDigits,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };

  const getStateByZonalHeadId = (ZHID) => {
    let lastTwoDigits = dateValue % 100;
    const payload = {
      fyear: "FY" + lastTwoDigits,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;

        setStateListArr(data);
      });
  };
  useEffect(() => {
    if (userRole == "SADM") {
      getStateByStateHeadId(userId);
      return;
    }
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);
  const getZhId = () => {
    if (userRole == "ZADM") {
      return userId;
    }
    if (userRole == "SADM") {
      return getZonalHeadSH?.data?.userId;
    }

    if (userRole == "AMGR" && getZonalHead?.data?.userId) {
      return getZonalHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedZHID;
    }
  };

  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "SADM") {
      return userId;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedSHID;
    }
  };

  useEffect(() => {
    let editRejectDetails =
      tableArr && tableArr?.filter((x) => selectedRowKeys.includes(x?.key));
    setEditCheckbox(editRejectDetails);
  }, [selectedRowKeys]);

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const getCol = (item) => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

    if ([1, 2].includes(collectionData.schemeType)) {
      return {
        scheme_no: item.schemeNo,
        dealer_code: item.dealerCode,
        dealer_location: item.dealerLocation,
        state: item.stateName,
        state_head: item.stateHeadName,
        territory_Manager: item.territoryManager,
        TOP_TARGET: `${item.topTarget}, ${item.modifiedTopTarget}`,
        top_slab: item.topTarget,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        industry: `${item.industry}, ${
          item.holdStatus === 9 ? item.holdIndustry : item.modifiedIndustry
        }`,
        b: item.oldBillingQty,
        i: item.oldInstallation,
        c: item.oldCollection,
        opening_Stock_nos: item.oldOpeningStock,
        opening_stock_days: `${item.openingStockDays}, ${
          item.holdStatus === 9
            ? item.holdOpeningStockDays
            : item.modifiedOpeningStockDays
        }`,
        Closing_OS_IN_LACS_Last_Third_Month: item.closingOutStandingMonth_1,
        Closing_OS_IN_LACS_Last_Two_Month: item.closingOutStandingMonth_2,
        Closing_OS_IN_LACS_Last_One_Month: item.closingOutStandingMonth_3,
        Days: `${item.outStandingDays}, ${
          item.holdStatus === 9
            ? item.holdOutStandingDays
            : item.modifiedOutStandingDays
        }`,
        BG_Lacs: item.bgLacsCurrentYear,
        Installation_Plan_Nos: `${item.delayPlan}, ${
          item.holdStatus === 9 ? item.holdDelayPlan : item.modifiedDelayPlan
        }`,
        Billing_Details_B: `${item.billing}, ${
          item.holdStatus === 9 ? item.holdBilling : item.modifiedBilling
        }`,
        Billing_Details_i: `${item.installation}, ${
          item.holdStatus === 9
            ? item.holdInstallation
            : item.modifiedInstallation
        }`,
        Billing_Details_c: `${item.collection}, ${
          item.holdStatus === 9 ? item.holdCollection : item.modifiedCollection
        }`,
        CLOSING_STOCK: item.closingStock,
        CLOSING_OUTSTANDING: item?.closingOutStanding,
        Books_Collection_Target_T1: `${item.t1}, ${
          item.holdStatus === 9 ? item.holdT1 : item.modifiedT1
        }`,
        Books_Collection_Target_T2: `${item.t2}, ${
          item.holdStatus === 9 ? item.holdT2 : item.modifiedT2
        }`,
        Books_Collection_Target_T2T1: `${
          item.topTarget?.includes("t2") || item.holdTopTarget?.includes("t2")
            ? item.t2_T1
            : 0
        },
           ${
             item.modifiedTopTarget?.includes("t2")
               ? item.modifiedT2_T1
               : item.holdStatus === 9
               ? item.holdTopTarget?.includes("t2")
                 ? item.holdT2_T1
                 : 0
               : 0
           }`,
        Books_Collection_Target_T3: `${item.t3}, ${
          item.holdStatus === 9 ? item.holdT3 : item.modifiedT3
        }`,
        Books_Collection_Target_T3T2: `${
          item.topTarget?.includes("t3") || item.holdTopTarget?.includes("t3")
            ? item.t3_T2
            : 0
        }, 
        ${
          item.modifiedTopTarget?.includes("t3")
            ? item.modifiedT3_T2
            : item.holdStatus === 9
            ? item.holdTopTarget?.includes("t3")
              ? item.holdT3_T2
              : 0
            : 0
        }`,
        Books_Collection_Target_T4: `${item.t4}, ${
          item.holdStatus === 9 ? item.holdT4 : item.modifiedT4
        }`,
        Books_Collection_Target_T4T3: `${
          item.topTarget?.includes("t4") || item.holdTopTarget?.includes("t4")
            ? item.t4_T3
            : 0
        },
        ${
          item.modifiedTopTarget?.includes("t4")
            ? item.modifiedT4_T3
            : item.holdStatus === 9
            ? item.holdTopTarget?.includes("t4")
              ? item.holdT4_T3
              : 0
            : 0
        }`,
        Books_Collection_Target_T5: `${item.t5}, ${
          item.holdStatus === 9 ? item.holdT5 : item.modifiedT5
        }`,

        Books_Collection_Target_T5T4: `${
          item.topTarget?.includes("t5") || item.holdTopTarget?.includes("t5")
            ? item.t5_T4
            : 0
        }, ${item.modifiedTopTarget?.includes("t5") ? item.modifiedT5_T4 : 0}`,
        Books_Collection_Target_T6: `${item.t6}, ${
          item.holdStatus === 9 ? item.holdT6 : item.modifiedT6
        }`,
        Books_Collection_Target_T6T5: `${
          item.topTarget?.includes("t6") || item.holdTopTarget?.includes("t6")
            ? item.t6_T5
            : 0
        }, ${item.modifiedTopTarget?.includes("t6") ? item.modifiedT6_T5 : 0}`,

        Payout_Rate_R1: `${item.r1}, ${
          item.holdStatus === 9 ? item.holdR1 : item.modifiedR1
        }`,
        Payout_Rate_R2: `${item.r2}, ${
          item.holdStatus === 9 ? item.holdR2 : item.modifiedR2
        }`,
        Payout_Rate_R3: `${item.r3}, ${
          item.holdStatus === 9 ? item.holdR3 : item.modifiedR3
        }`,
        Payout_Rate_R4: `${item.r4}, ${
          item.holdStatus === 9 ? item.holdR4 : item.modifiedR4
        }`,
        Payout_Rate_R5: `${item.r5}, ${
          item.holdStatus === 9 ? item.holdR5 : item.modifiedR5
        }`,
        Payout_Rate_R6: `${item.r6}, ${
          item.holdStatus === 9 ? item.holdR6 : item.modifiedR6
        }`,
        Payout_P1: `${item.p1}, ${
          item.holdStatus === 9 ? item.holdP1 : item.modifiedP1
        }`,
        Payout_P2: `${item.p2}, ${
          item.holdStatus === 9 ? item.holdP2 : item.modifiedP2
        }`,
        Payout_P3: `${item.p3}, ${
          item.holdStatus === 9 ? item.holdP3 : item.modifiedP3
        }`,
        Payout_P4: `${item.p4}, ${
          item.holdStatus === 9 ? item.holdP4 : item.modifiedP4
        }`,
        Payout_P5: `${item.p5}, ${
          item.holdStatus === 9 ? item.holdP5 : item.modifiedP5
        }`,
        Payout_P6: `${item.p6}, ${
          item.holdStatus === 9 ? item.holdP6 : item.modifiedP6
        }`,
        TEP_Vol: `${item.volume}, ${
          item.holdStatus === 9 ? item.holdVolume : item.modifiedVolume
        }`,
        TEP_Per_Tr: `${item.perTr} , ${
          item.holdStatus === 9 ? item.holdPerTr : item.modifiedPerTr
        }`,
        TEP_Amount: `${item.amount},${
          item.holdStatus === 9 ? item.holdAmount : item.modifiedAmount
        }`,
        Remarks: `${item.reason}, ${
          item.holdStatus === 9 ? item.holdReason : item.modifiedReason
        }`,
      };
    }

    // Slab based scheme
    if (collectionData.schemeType == 3) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        Dealer_Location: item.dealerLocation,
        State: item.stateName,
        state_head: item.stateHeadName,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Target_T1: `${item.t1}, ${
          item.holdStatus === 9 ? item.holdT1 : item.modifiedT1
        }`,
        Target_T2: `${item.t2}, ${
          item.holdStatus === 9 ? item.holdT2 : item.modifiedT2
        }`,
        Target_T3: `${item.t3}, ${
          item.holdStatus === 9 ? item.holdT3 : item.modifiedT3
        }`,
        Inc_Per_Tractor_R1: `${item.r1}, ${
          item.holdStatus === 9 ? item.holdR1 : item.modifiedR1
        }`,
        Inc_Per_Tractor_R2: `${item.r2}, ${
          item.holdStatus === 9 ? item.holdR2 : item.modifiedR2
        }`,
        Inc_Per_Tractor_R3: `${item.r3}, ${
          item.holdStatus === 9 ? item.holdR3 : item.modifiedR3
        }`,
        Payout_P1: `${item.p1}, ${
          item.holdStatus === 9 ? item.holdP1 : item.modifiedP1
        }`,
        Payout_P2: `${item.p2}, ${
          item.holdStatus === 9 ? item.holdP2 : item.modifiedP2
        }`,
        Payout_P3: `${item.p3}, ${
          item.holdStatus === 9 ? item.holdP3 : item.modifiedP3
        }`,
        TOP_TARGET: `${item.topTarget}, ${item.modifiedTopTarget}`,
        Remarks: `${item.reason}, ${
          item.holdStatus === 9 ? item.holdReason : item.modifiedReason
        }`,
      };
    }
    // Single Target Scheme
    if (collectionData.schemeType == 8) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: `${item.installationTarget}, ${
          item.holdStatus === 9
            ? item.holdInstallationTarget
            : item.modifiedInstallationTarget
        }`,
        Inc_Per_Tractor: `${item.incPerTarget}, ${
          item.holdStatus === 9
            ? item.holdIncPerTarget
            : item.modifiedIncPerTarget
        }`,
        Payout: `${item.p1}, ${
          item.holdStatus === 9 ? item.holdP1 : item.modifiedP1
        }`,
        Remarks: `${item.reason}, ${
          item.holdStatus === 9 ? item.holdReason : item.modifiedReason
        }`,
      };
    }
    // Single Target With Upper Cap Scheme
    if (collectionData.schemeType == 4) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: `${item.installationTarget}, ${
          item.holdStatus === 9
            ? item.holdInstallationTarget
            : item.modifiedInstallationTarget
        }`,
        Upper_Cap: `${item.upperCap}, ${
          item.holdStatus === 9 ? item.holdUpperCap : item.modifiedUpperCap
        }`,
        Inc_Per_Tractor: `${item.incPerTarget}, ${
          item.holdStatus === 9
            ? item.holdIncPerTarget
            : item.modifiedIncPerTarget
        }`,
        Payout: `${item.p1}, ${
          item.holdStatus === 9 ? item.holdP1 : item.modifiedP1
        }`,
        Remarks: `${item.reason}, ${
          item.holdStatus === 9 ? item.holdReason : item.modifiedReason
        }`,
      };
    }
    // Unconditional Scheme
    if (collectionData.schemeType == 5) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Expected: `${item.installationTarget}, ${
          item.holdStatus === 9
            ? item.holdInstallationTarget
            : item.modifiedInstallationTarget
        }`,
        Inc_Per_Tractor: `${item.incPerTarget}, ${
          item.holdStatus === 9
            ? item.holdIncPerTarget
            : item.modifiedIncPerTarget
        }`,
        Payout: `${item.p1}, ${
          item.holdStatus === 9 ? item.holdP1 : item.modifiedP1
        }`,
        Remarks: `${item.reason}, ${
          item.holdStatus === 9 ? item.holdReason : item.modifiedReason
        }`,
      };
    }
  };

  const handleDownload = () => {
    // const data = tableArr && tableArr?.map(item=> {
    //   return captalizeObjKeys(getCol(item))
    // })

    if (tableArr?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }

    if (Array?.isArray(tableArr) && tableArr?.length != 0) {
      const TotalStyle = {
        fill: {
          fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
        },
        font: {
          color: { rgb: "212121" }, // Black font color
          bold: true,
          sz: "12",
        },
      };

      if (collectionData?.schemeType == 5) {
        let tab_text = `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >CREATED BY</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          </tr>
      </thead>`;

        tableArr?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${x?.createdBy}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
       <td>${
         selectedLabel != "Hold Schemes"
           ? x?.installationTarget + ", " + x?.modifiedInstallationTarget
           : x?.installationTarget + ", " + x?.holdInstallationTarget
       }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? x?.incPerTarget + ", " + x?.modifiedIncPerTarget
            : x?.incPerTarget + ", " + x?.holdIncPerTarget
        }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? x?.p1 + ", " + x?.modifiedP1
            : x?.p1 + ", " + x?.holdP1
        }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? (x?.reason||"") + ", " + (x?.modifiedReason||"")
            : (x?.reason||"") + ", " + (x?.holdReason||"")
        }</td>
    </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
        // const url = window.URL.createObjectURL(
        //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", );
        // document.body.appendChild(link);
        // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
      } else if (collectionData?.schemeType == 4) {
        if (tableArr?.length == 0 || !tableArr) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >CREATED BY</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>

        </tr>
    </thead>`;

        tableArr?.forEach((x) => {
          tab_text += `<tr ><tr ><td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${x?.createdBy}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? x?.installationTarget + ", " + x?.modifiedInstallationTarget
            : x?.installationTarget + ", " + x?.holdInstallationTarget
        }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? x?.upperCap + ", " + x?.modifiedUpperCap
            : x?.upperCap + ", " + x?.holdUpperCap
        }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? x?.incPerTarget + ", " + x?.modifiedIncPerTarget
            : x?.incPerTarget + ", " + x?.holdIncPerTarget
        }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? x?.p1 + ", " + x?.modifiedP1
            : x?.p1 + ", " + x?.holdP1
        }</td>
        <td>${
          selectedLabel != "Hold Schemes"
            ? (x?.reason||"") + ", " + (x?.modifiedReason||"")
            : (x?.reason||"") + ", " + (x?.holdReason||"")
        }</td>
  </tr>`;
        });

        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to Total row color

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_With_Upper_Cap${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        );
      } else if (collectionData?.schemeType == 8) {
        if (tableArr?.length == 0 || !tableArr) {
          ToastifyShow("Something went wrong", "error");
          return false;
        }
        let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >CREATED BY</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>

        </tr>
    </thead>`;

        tableArr?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
  <td>${x?.dealerCode}</td>
  <td>${x?.dealerName}</td>
  <td>${x?.stateName}</td>
  <td>${x?.dealerLocation}</td>
  <td>${x?.territoryManager}</td>
  <td>${x?.createdBy || ""}</td>
  <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
  <td>${x?.actual_Installation_Last_Year_Month}</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.installationTarget + ", " + x?.modifiedInstallationTarget
      : x?.installationTarget + ", " + x?.holdInstallationTarget
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.incPerTarget + ", " + x?.modifiedIncPerTarget
      : x?.incPerTarget + ", " + x?.holdIncPerTarget
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.p1 + ", " + x?.modifiedP1
      : x?.p1 + ", " + x?.holdP1
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (x?.reason||"") + ", " + (x?.modifiedReason||"")
      : (x?.reason||"") + ", " + (x?.holdReason||"")
  }</td>
  </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to total row color

        let excelArr = [];

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Installation_Single_Target_${new Date()
            .toJSON()
            .slice(0, 10)}.xlsx`
        ); // const url = window.URL.createObjectURL(
        //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download",`Download_Installation_Single_Target-${new Date().toJSON().slice(0, 10)}.xlsx`);
        // document.body.appendChild(link);
      } else if (collectionData?.schemeType == 3) {
        let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">STATE HEAD</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">CREATED BY</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
             
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;
        console.log(tableArr, "tableArrtableArr");
        tableArr?.forEach((header) => {
          tab_text += `<tr ><td>${header?.schemeNo}</td>
  <td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.stateHeadName}</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? header?.topTarget + ", " + header?.modifiedTopTarget
      : header?.topTarget
  }</td>
  <td>${header?.startMonth}</td>
  <td>${header?.endMonth}</td>
  <td>${header?.actual_Installation_Last_Year_Month}</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t1") ? header?.t1 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t1")
        ? header?.modifiedT1
        : 0
      : (header?.topTarget?.includes("t1") ? header?.t1 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t1")
      ? header?.holdT1
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t2") ? header?.t2 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t2")
        ? header?.modifiedT2
        : 0
      : (header?.topTarget?.includes("t2") ? header?.t2 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t2")
      ? header?.holdT2
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t3") ? header?.t3 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t3")
        ? header?.modifiedT3
        : 0
      : (header?.topTarget?.includes("t3") ? header?.t3 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t3")
      ? header?.holdT3
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t1") ? header?.r1 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t1")
        ? header?.modifiedR1
        : 0
      : (header?.topTarget?.includes("t1") ? header?.r1 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t1")
      ? header?.holdR1
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t2") ? header?.r2 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t2")
        ? header?.modifiedR2
        : 0
      : (header?.topTarget?.includes("t2") ? header?.r2 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t2")
      ? header?.holdR2
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t3") ? header?.r3 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t3")
        ? header?.modifiedR3
        : 0
      : (header?.topTarget?.includes("t3") ? header?.r3 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t3")
      ? header?.holdR3
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t1") ? header?.p1 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t1")
        ? header?.modifiedP1
        : 0
      : (header?.topTarget?.includes("t1") ? header?.p1 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t1")
      ? header?.holdP1
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t2") ? header?.p2 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t2")
        ? header?.modifiedP2
        : 0
      : (header?.topTarget?.includes("t2") ? header?.p2 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t2")
      ? header?.holdP2
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.topTarget?.includes("t3") ? header?.p3 : 0) +
        ", " +
        header?.modifiedTopTarget?.includes("t3")
        ? header?.modifiedP3
        : 0
      : (header?.topTarget?.includes("t3") ? header?.p3 : 0) +
        ", " +
        header?.holdTopTarget?.includes("t3")
      ? header?.holdP3
      : 0
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (header?.reason || "") + ", " + (header?.modifiedReason || "")
      : (header?.reason||"")
  }</td>
   
  </tr>`;
        });

        tab_text += "</table>";

        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);
        // Add style to Roral row
        const allColumns = tableArr.reduce((columns, item) => {
          Object.keys(item).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key);
            }
          });
          return columns;
        }, []);

        function getAlphabetEquivalent(number) {
          if (number < 1 || number > 26) {
            return "Invalid input";
          }
          return String.fromCharCode(number + 64);
        }

        let excelArr = [];
        for (let index = 0; index < allColumns.length; index++) {
          let ele = `${getAlphabetEquivalent(index + 1)}${(
            tableArr.length + 3
          ).toString()}`;
          excelArr.push(ele);
        }

        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        // generate file
        XLSX.writeFile(
          wb,
          `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`
        );
        // const url = window.URL.createObjectURL(
        // new Blob([tab_text], { type: "application/vnd.ms-excel" })
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `Slab_based_scheme.xlsx`);
        // document.body.appendChild(link);
        // link.click();
      } else if ([1, 2].includes(collectionData?.schemeType)) {
        let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">STATE MANAGER</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">CREATED BY</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">Industry</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
        <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
        <th colspan="6">PAYOUT RATE</th>
             <th colspan="6">PAYOUT</th>
        <th rowspan="2">REMARKS</th>
       
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        </tr>
    <tr>
       <th>NOS</th>
       <th>Days</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
       
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th> 
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>  
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>  
      </tr>
    </thead>`;

        tableArr?.forEach((x) => {
          tab_text += `<tr ><td>${x?.schemeNo}</td>
  <td>${x?.dealerCode}</td>
  <td>${x?.dealerLocation}</td>
  <td>${x?.dealerName}</td>
  <td>${x?.stateName}</td>
  <td>${x?.stateHeadName}</td>
  <td>${x?.territoryManager}</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.topTarget + ", " + x?.modifiedTopTarget
      : x?.topTarget + ", " + x?.holdTopTarget
  }</td>
  <td>${x?.createdBy || ""}</td>
  <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.industry + ", " + x?.modifiedIndustry
      : x?.industry + ", " + x?.holdIndustry
  }</td>
  <td>${x?.oldBillingQty}</td>
  <td>${x?.oldInstallation}</td>
  <td>${x?.oldCollection || 0}</td>
  <td>${x?.oldOpeningStock}</td>
  <td>${x?.openingStockDays}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_3)}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_2)}</td>
  <td>${formatNumberDecimal(x?.closingOutStandingMonth_1)}</td>
  <td>${x?.outStandingDays}</td>
  <td>${x?.bGLacsCurrentYear || 0}</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.delayPlan + ", " + x?.modifiedDelayPlan
      : x?.delayPlan + ", " + x?.holdDelayPlan
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.billing + ", " + x?.modifiedBilling
      : x?.billing + ", " + x?.holdBilling
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.installation + ", " + x?.modifiedInstallation
      : x?.installation + ", " + x?.holdInstallation
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.collection + ", " + x?.modifiedCollection
      : x?.collection + ", " + x?.holdCollection
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.closingStock + ", " + x?.modifiedclosingStock
      : x?.closingStock + ", " + x?.holdclosingStock
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.closingOutStanding + ", " + x?.modifiedclosingOutStanding
      : x?.closingOutStanding + ", " + x?.holdclosingOutStanding
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.t1 + ", " + x?.modifiedT1
      : x?.t1 + ", " + x?.holdT1
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.t2 + ", " + x?.modifiedT2
      : x?.t2 + ", " + x?.holdT2
  }</td>
  <td>${
    (x?.topTarget?.includes("t2") ? x?.t2_T1 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t2")
        ? x?.modifiedT2_T1
        : 0
      : x?.holdTopTarget?.includes("t2")
      ? x?.holdT2_T1
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t3") ? x?.t3 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t3")
        ? x?.modifiedT3
        : 0
      : x?.holdTopTarget?.includes("t3")
      ? x?.holdT3
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t3") ? x?.t3_T2 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t3")
        ? x?.modifiedT3_T2
        : 0
      : x?.holdTopTarget?.includes("t3")
      ? x?.holdT3_T2
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t4") ? x?.t4 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t4")
        ? x?.modifiedT4
        : 0
      : x?.holdTopTarget?.includes("t4")
      ? x?.holdT4
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t4") ? x?.t4_T3 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t4")
        ? x?.modifiedT4_T3
        : 0
      : x?.holdTopTarget?.includes("t4")
      ? x?.holdT4_T3
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t5") ? x?.t5 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t5")
        ? x?.modifiedT5
        : 0
      : x?.holdTopTarget?.includes("t5")
      ? x?.holdT5
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t5") ? x?.t5_T4 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t5")
        ? x?.modifiedT5_T4
        : 0
      : x?.holdTopTarget?.includes("t5")
      ? x?.holdT5_T$
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t6") ? x?.t6 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t6")
        ? x?.modifiedT6
        : 0
      : x?.holdTopTarget?.includes("t6")
      ? x?.holdT6
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t6") ? x?.t6_T5 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t6")
        ? x?.modifiedT6_T5
        : 0
      : x?.holdTopTarget?.includes("t6")
      ? x?.holdT6_T5
      : 0)
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.r1 + ", " + x?.modifiedR1
      : x?.r1 + ", " + x?.holdR1
  }</td>
  <td>${
    (x?.topTarget?.includes("t2") ? x?.r2 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t2")
        ? x?.modifiedR2
        : 0
      : x?.holdTopTarget?.includes("t2")
      ? x?.holdR2
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t3") ? x?.r3 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t3")
        ? x?.modifiedR3
        : 0
      : x?.holdTopTarget?.includes("t3")
      ? x?.holdR3
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t4") ? x?.r4 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t4")
        ? x?.modifiedR4
        : 0
      : x?.holdTopTarget?.includes("t4")
      ? x?.holdR4
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t5") ? x?.r5 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t5")
        ? x?.modifiedR5
        : 0
      : x?.holdTopTarget?.includes("t5")
      ? x?.holdR5
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t6") ? x?.r6 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t6")
        ? x?.modifiedR6
        : 0
      : x?.holdTopTarget?.includes("t6")
      ? x?.holdR6
      : 0)
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.p1 + ", " + x?.modifiedP1
      : x?.p1 + ", " + x?.holdP1
  }</td>
  <td>${
    (x?.topTarget?.includes("t2") ? x?.p2 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t2")
        ? x?.modifiedP2
        : 0
      : x?.holdTopTarget?.includes("t2")
      ? x?.holdP2
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t3") ? x?.p3 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t3")
        ? x?.modifiedP3
        : 0
      : x?.holdTopTarget?.includes("t3")
      ? x?.holdP3
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t4") ? x?.p4 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t4")
        ? x?.modifiedP4
        : 0
      : x?.holdTopTarget?.includes("t4")
      ? x?.holdP4
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t5") ? x?.p5 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t5")
        ? x?.modifiedP5
        : 0
      : x?.holdTopTarget?.includes("t5")
      ? x?.holdP5
      : 0)
  }</td>
  <td>${
    (x?.topTarget?.includes("t6") ? x?.p6 : 0) +
    ", " +
    (selectedLabel != "Hold Schemes"
      ? x?.modifiedTopTarget?.includes("t6")
        ? x?.modifiedP6
        : 0
      : x?.holdTopTarget?.includes("t6")
      ? x?.holdP6
      : 0)
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? (x?.reason || "") + ", " + (x?.modifiedReason || "")
      : (x?.reason || "") + ", " + (x?.holdReason || "")
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.volume + ", " + x?.modifiedVolume
      : x?.volume + ", " + x?.holdVolume
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.perTr + ", " + x?.modifiedPerTr
      : x?.perTr + ", " + x?.holdPerTr
  }</td>
  <td>${
    selectedLabel != "Hold Schemes"
      ? x?.amount + ", " + x?.modifiedAmount
      : x?.amount + ", " + x?.holdAmount
  }</td>

  </tr>`;
        });

        tab_text += "</table>";
        var elt = document.createElement("div");
        elt.innerHTML = tab_text;
        document.body.appendChild(elt);
        var tbl = elt.getElementsByTagName("TABLE")[0];
        var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
        document.body.removeChild(elt);

        // Add style to total row color
        const allColumns = tableArr.reduce((columns, item) => {
          Object.keys(item).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key);
            }
          });
          return columns;
        }, []);

        function getAlphabetEquivalent(number) {
          if (number < 1) {
            return "Invalid input";
          } else if (number <= 26) {
            return String.fromCharCode(number + 64);
          } else {
            let firstChar = String.fromCharCode((number - 1) / 26 + 64);
            let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
            return firstChar + secondChar;
          }
        }

        let excelArr = [];
        for (let index = 0; index < allColumns.length; index++) {
          let ele = `${getAlphabetEquivalent(index + 1)}${(
            tableArr.length + 3
          ).toString()}`;
          excelArr.push(ele);
        }
        Object.keys(wb.Sheets[wb.SheetNames[0]])
          .filter((cell) => excelArr.includes(cell))
          .forEach((cell) => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

        let nameFile =
          collectionData?.schemeType == 1
            ? "With_DeliveryPlan.xlsx"
            : "Without_Delivery_Plan.xlsx";
        // generate file
        XLSX.writeFile(wb, nameFile);
      }
      console.log(tableArr, "datadata", selectedLabel);
      // const headers = Object.keys(data[0]).join("\t");
      // const rows = data?.map((obj) =>
      // Object.values(obj)
      // .map((val) => {
      // if (typeof val === "string") {
      // // Replace line breaks with CHAR(10) function

      // return val.replace(/\n/g, "CHAR(10)");
      // } else {
      // return val;
      // }
      // })
      // .join("\t")
      // );

      // const csv = `${headers}\n${rows.join("\n")}`;
      // const url = window.URL.createObjectURL(
      // new Blob([csv], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `${Date.now()}.xls`);
      // document.body.appendChild(link);
      // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
  };

  const handleEditClick = (e) => {
    if (selectedRowKeys.length != 0) {
      setEditSchemes(true);
      // setToggleEdit("3");
    } else {
      setEditSchemes(false);
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  };

  const handleRejectModal = async () => {
    if (selectedRowKeys.length != 0) {
      await setOpenRejectModal(true);
    } else {
      setEditSchemes(false);
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  };

  const handleRejectModal1 = async () => {
    await setOpenRejectModal1(true);
  };

  const handleAcceptModal = () => {
    if (selectedRowKeys.length != 0) {
      setOpenAcceptModal(!openAcceptModal);
    } else {
      setEditSchemes(false);
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  };

  const handleStatus = async (status) => {
    // setIsLoading(true);

    const userName = userData.userName;
    const userId = userData.userId;

    const payload = schemeIdNo.map((item) => ({
      schemeId: item?.schemeId,
      username: userName,
      userId: userId,
      modificationId: item?.modifiedId,
    }));
    if (selectedLabel === "Hold Schemes") {
      delete payload.modificationId;
    }
    setOpenAcceptModal(false);
    if (selectedLabel === "Regular Schemes" || selectedLabel === "Schemes") {
      await dispatch(getModificationAcceptAsync(payload)).then((data)=>{
        if(data?.payload?.errorMessage){
          message.error({
            content: "Something went wrong with the scheme",
            className: "custom-message",
          })
        }else{
        message.success({
          content: "Scheme Approved Successfully",
          className: "custom-message",
        });
      }
      })
      await getApiData();
      await message.success({
        content: "Scheme Approved Successfully",
        className: "custom-message",
      });
    } else if (selectedLabel === "Hold Schemes") {
      await dispatch(updateApprovedHoldSchemeApi(payload)).then((data)=>{
        if(data?.payload?.errorMessage){
          message.error({
            content: "Something went wrong with the scheme",
            className: "custom-message",
          })
        }else{
        message.success({
          content: "Scheme Approved Successfully",
          className: "custom-message",
        });
      }
      })
      await getHoldData();
    }

    setModificationId("");
    setSchemeId([]);
    setSelectedRowKeys([]);
    setSchemeIdNo([]);
  };
  const handleSingleAccept = async () => {
    // setIsLoading(true);

    const userName = userData.userName;
    const userId = userData.userId;

    const payload = [
      {
        schemeId: schemeId,
        username: userName,
        userId: userId,
        modificationId: modificationId,
      },
    ];
    if (selectedLabel === "Hold Schemes") {
      delete payload.modificationId;
    }

    if (selectedLabel === "Regular Schemes" || selectedLabel === "Schemes") {
      await dispatch(getModificationAcceptAsync(payload)).then((data)=>{
        if(data?.payload?.errorMessage){
          message.error({
            content: "Something went wrong with the scheme",
            className: "custom-message",
          })
        }else{
        message.success({
          content: "Scheme Approved Successfully",
          className: "custom-message",
        });
      }
      })
      await getApiData();
    } else if (selectedLabel === "Hold Schemes") {
      await dispatch(updateApprovedHoldSchemeApi(payload)).then((data)=>{
        if(data?.payload?.errorMessage){
          message.error({
            content: "Something went wrong with the scheme",
            className: "custom-message",
          })
        }else{
        message.success({
          content: "Scheme Approved Successfully",
          className: "custom-message",
        });
      }
      })
      getHoldData();
    }
    setOpenAcceptModal(false);
    setModificationId("");
    setSchemeId([]);
    setSelectedRowKeys([]);
    setSchemeIdNo([]);
  };

  const handleAcceptModal1 = () => {
    setOpenAcceptModal1(!openAcceptModal1);
  };
  const handleClearFilter = () => {
    setCollectionData({ ...InitCollectionData });
    setEnforcedMonth(null);
    setSelectedStatus(0);
    setSelectedStateId(0);
    setSelectedRowKeys([]);
    setSelectedLabel("Regular Scheme");
    setSchemeIdNo([]);
    document.querySelector(".select_state .ant-select-selector").innerText =
      "All States";
    if (userRole == "NSH") {
      getAM(true);
      getSH(true);
      getZH(true);
      return;
    }
    if (userRole == "ZADM") {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "SADM") {
      getAM(true);
      return;
    }
  };

  const items = [
    {
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => handleEditClick(e)}
        >
          Edit
        </Button>
      ),
      key: "1",
      icon: <Edit />,
    },
    {
      label: (
        <div style={{ color: "#51DE4E" }} onClick={() => handleAcceptModal()}>
          Approve
        </div>
      ),
      key: "2",
      icon: <CheckOutlined className="approved-icon" />,
    },
    {
      label: (
        <div style={{ color: "#FF3232" }} onClick={() => handleRejectModal()}>
          Reject
        </div>
      ),
      key: "3",
      icon: <CloseOutlined className="reject-icon" />,
    },
    {
      label: (
        <Link
          className="link"
          disabled={tableArr?.length == 0}
          onClick={handleDownload}
        >
          Download
        </Link>
      ),
      icon: <Download />,
      key: "5",
    },
    {
      key: "4",
      label: <a onClick={handleClearFilter}>Clear Filters</a>,
      disabled: false,
      icon: <FilterOutlined />,
    },
  ];

  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedAMID;
    }
  };

  let lastTwoDigits = dateValue % 100;
  const payload = {
    month: collectionData?.month
      ? new Date(collectionData?.month)?.getMonth() + 1
      : collectionData?.month,
    monthEnd: collectionData?.monthEnd
      ? new Date(collectionData?.monthEnd)?.getMonth() + 1
      : collectionData?.monthEnd,
    userid: userId,
    stateId: selectedStateId,
    areaManagerId: getAmId(),
    StateHeadId: getShId(),
    // status:1,
    enforcedMonth: collectionData?.currentMonth,
    userRole: userRole,
    fyear: "FY" + lastTwoDigits,
    schemeType: collectionData?.schemeType,
    state: selectedStateId,
    dealerLocation: collectionData?.dealerLocation,
    schemeNumber: collectionData?.schemeNumber,
    dealerCode: collectionData?.dealerCode,
    pageSize: collectionData?.pageSize,
    pageNumber: collectionData?.pageNumber,
    // ...(userRole=="NSH")?{"zonalHeadEmpId":getZhId()}:{
    ZonalHeadId: getZhId(),
    // },
    sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
    sortingType: collectionData.sortingType || "DealerCode",
  };

  const getApiData = () => {
    dispatch(getModificationAsync(payload));
    dispatch(resetModifiedData());
  };

  const getHoldData = () => {
    dispatch(getModificationAwaitingAsync(payload));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (selectedLabel === "Hold Schemes") {
        getHoldData();
      } else {
        getApiData();
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    selectedStateId,
    selectedStatus,
    collectionData,
    selectedSHID,
    selectedAMID,
    getZonalHeadSH,
    getStateHead,
    getZonalHead,
    selectedZHID,
    dateValue,
  ]);

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };
  // const disabledDate = (current, selectedMonth) => {
  //   // lock
  //   if (false) return true;
  //   const today = new Date();
  //   const currentYear = today.getFullYear()-1;
  //   const currentMonth = today.getMonth();
  //   const futureYear = currentYear + 1;

  //   //disableDateRestriction
  //   if (false) {
  //     return null;
  //   }

  //   return (
  //     current &&
  //     (current.year() < currentYear ||
  //       (current.year() === currentYear && current.month() < 3) ||
  //       current.year() > futureYear ||
  //       (current.year() === futureYear && current.month() > 2))
  //   );
  // };

  const getZH = (isClear = false) => {
    if (isClear) {
      setSelectedZH("");
      setSelectedZHID(0);
      return "All Zonal Head";
    }
    if (userRole == "ZADM") {
      return userName;
    }
    if (userRole == "SADM" && getZonalHeadSH?.data?.userName) {
      return getZonalHeadSH?.data?.userName;
    }
    if (userRole == "AMGR" && getZonalHead?.data?.userName) {
      return getZonalHead?.data?.userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedZH == "" ? "All Zonal Head" : selectedZH;
    }
    return selectedZH == "All Zonal Head" ? "All Zonal Head" : selectedZH;
  };

  const getSH = (isClear = false) => {
    if (isClear) {
      setSelectedSH("");
      setSelectedSHID(0);
      return "All State Heads";
    }
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };
  const getAM = (isClear = false) => {
    if (isClear) {
      setSelectedAM("");
      setSelectedAMID(0);
      return "All Area Managers";
    }
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "SADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "AMGR" && userName) {
      return userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };
  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery";
    }
    if (schemeType == 2) {
      return "Without Delivery";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };
  const subColType = [
    {
      key: "0",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 1,
              pageNumber: 1,
            })
          }
        >
          With Delivery
        </a>
      ),
      disabled: false,
    },

    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 2,
              pageNumber: 1,
            })
          }
        >
          WIthout Delivery
        </a>
      ),
      disabled: false,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 3,
              pageNumber: 1,
            })
          }
        >
          Slab Based Scheme
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 8,
              pageNumber: 1,
            })
          }
        >
          Single Target Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "4",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 4,
              pageNumber: 1,
            })
          }
        >
          Single Target With Upper Cap Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "5",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 5,
              pageNumber: 1,
            })
          }
        >
          Unconditional Scheme
        </a>
      ),
      disabled: false,
    },
  ];

  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        setStateHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedSH("All State Heads");
                  setSelectedSHID(0);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                  setAreaMngrData([]);
                }}
              >
                All State Heads
              </span>
            ),
          },
          ...result?.data?.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedSH(item.stateHeadName);
                    setSelectedSHID(item.id);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    getAmDropdownData(item.id);
                  }}
                >
                  {item.stateHeadName}
                </span>
              ),
            };
          }),
        ]);
      });
  };

  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId }).then((result) => {
      setAreaDropdown(result?.data?.data);
      setAreaMngrData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedAM("All Area Managers");
                setSelectedAMID(0);
              }}
            >
              All Area Managers
            </span>
          ),
        },
        ...result?.data?.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span onClick={() => setSelectedAMID(item.id)}>{item.aM}</span>
            ),
          };
        }),
      ]);
    });
  };
  const getDealerDropdownData = (userId, type) => {
    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
        pageNumber: 1,
      }));
    }
  };
  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    if (selectedAm?.aM) {
      setSelectedAM(selectedAm.aM);
    }
    getDealerDropdownData(parseInt(event.key), "amID");
  };

  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31);
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const itemsforstatus = [
    {
      label: (
        <a
          onClick={() => {
            setSelectedLabel("Regular Schemes");
            getApiData();
          }}
        >
          Regular Schemes
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a
          onClick={() => {
            setSelectedLabel("Hold Schemes");
            getHoldData();
          }}
        >
          Hold Schemes
        </a>
      ),
      key: "2",
    },
  ];

  return (
    <>
      {editSchemes === true &&
      (collectionData.schemeType == 1 || collectionData.schemeType == 2) ? (
        <EditModifiedAwaitingCollection
          backBtn={() => {
            setEditSchemes(false);
          }}
          schemeDetails={schemeIdNo}
          editCheckbox={editCheckbox}
          selectedLabel={selectedLabel}
        />
      ) : editSchemes === true &&
        (collectionData.schemeType == 3 ||
          collectionData.schemeType == 4 ||
          collectionData.schemeType == 5 ||
          collectionData.schemeType == 8) ? (
        <EditModifiedAwaitingInstallation
          selectedLabel={selectedLabel}
          selectedSchemeType={collectionData.schemeType}
          backBtn={() => {
            setEditSchemes(false);
          }}
          schemeDetails={schemeIdNo}
          editCheckbox={editCheckbox}
        />
      ) : (
        <div
          id="table"
          className="trackcollection create_scheme_wrap onemoth-date-selector view-schela news"
        >
          <div
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Col>
              <Dropdown menu={{ items: itemsforstatus }}>
                <Button>
                  <Space>
                    {selectedLabel == "Schemes"
                      ? "Regular Scheme"
                      : selectedLabel}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
            <Col>
              <Tooltip title={"Scheme Type"}>
                <Dropdown
                  menu={{ items: subColType }}
                  onClick={() => {
                    setEditSchemes(false);
                    setSchemeIdNo([]);
                    setSelectedRowKeys([]);
                  }}
                >
                  <Button>
                    <Space>
                      {getSchemeType(collectionData?.schemeType)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Tooltip>
            </Col>

            <div className="marginauto">
              {" "}
              <Tooltip title={"Running Month"}>
                <DatePicker
                  // onChange={(date) => handleInputChange('enforcedMonth', date)}
                  onChange={(date, formatDate) => {
                    if (formatDate == "") {
                      setCollectionData({
                        ...collectionData,
                        currentMonth: 0,
                        pageNumber: 1,
                      });
                      return;
                    }
                    setCollectionData({
                      ...collectionData,
                      currentMonth: new Date(formatDate).getMonth() + 1,
                      pageNumber: 1,
                    });
                  }}
                  className="start"
                  placeholder="Running Month"
                  picker="month"
                  value={enforcedMonth}
                  allowClear
                  disabled={
                    collectionData.month != "" || collectionData.monthEnd != ""
                  }
                  disabledDate={disabledDate}
                />
              </Tooltip>
            </div>
            <div className="marginauto">
              <Tooltip title={"Financial Year"}>
                <FinancialYearButton
                  handleDate={(x) => {
                    setDateValue(x);
                  }}
                />
              </Tooltip>
            </div>
            <div className="month ">
              <Tooltip title={"Start Month      End Month"}>
                <RangePicker
                  // mode={["month", "month"]}
                  picker="month"
                  onChange={(date, formatDate) => {
                    setCollectionData((collectionData) => ({
                      ...collectionData,
                      month: date && date[0] ? date[0].format() : 0,
                      monthEnd: date && date[1] ? date[1].format() : 0,
                      pageNumber: 1,
                    }));
                  }}
                  disabledDate={disabledDate}
                  //disabledDate={(current) => disabledDate(current, selectedMonth)}
                  // disabled={[!showStartMonth, !showEndMonth]}
                  onPanelChange={handlePanelChange}
                  disabled={collectionData.currentMonth > 0}
                />
              </Tooltip>
            </div>

            {["NSH"].includes(userRole) && (
              <Col>
                <Tooltip title={"Zonal Head"}>
                  <Dropdown menu={{ items: zonalHeadData }}>
                    <Button>
                      <Space>
                        {getZH()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}

            {true && (
              <Col>
                <Tooltip title={"State Head"}>
                  <Dropdown
                    menu={{ items: stateHeadData }}
                    disabled={["SADM"].includes(userRole)}
                  >
                    <Button>
                      <Space>
                        {getSH()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}
            {true && (
              <Col>
                <Tooltip title={"Area Manager"}>
                  <Dropdown
                    menu={{ items: areaMngrData, onClick: handleAMClick }}
                    disabled={["AMGR"].includes(userRole)}
                  >
                    <Button>
                      <Space>
                        {getAM()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}
          </div>

          <div
            style={{ marginTop: "0" }}
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Tooltip title={"Select State"}>
              <Select
                showSearch
                className="select_state heightauto"
                placeholder="Select State"
                optionFilterProp="children"
                onSelect={handleStateSelect}
                defaultValue={0}
              >
                <Option key={0} value={0}>
                  All States
                </Option>
                {stateListArr?.map((option) => (
                  <Option key={option.stateID} value={option.stateName}>
                    {option.stateName}
                  </Option>
                ))}
              </Select>
            </Tooltip>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                placeholder="Scheme No."
                value={collectionData.schemeNumber}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    schemeNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "10px" }}
                placeholder="Dealer Code"
                value={collectionData.dealerCode}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "20px" }}
                placeholder="Dealer Location"
                value={collectionData.dealerLocation}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerLocation: e.target.value,
                  })
                }
              />
            </div>
            <Col className="actionbuttons rightmargin">
              <Dropdown menu={{ items: items }}>
                <Button>
                  <Space>
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </div>

          <div className="fullwidths">
            <div
              class="recent-boxes fullwidths"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></div>
          </div>

          <AwaitingApprovalTable
            selectedSchemeType={collectionData.schemeType}
            dataSource={tableArr}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedLabel={selectedLabel}
            setSchemeIdNo={setSchemeIdNo}
            setSchemeNo={setSchemeNo}
            setSchemeId={setSchemeId}
            schemeNo={schemeNo}
            setEditSchemes={setEditSchemes}
            handleAcceptModal1={handleAcceptModal1}
            handleRejectModal1={handleRejectModal1}
            setModificationId={setModificationId}
            loading={
              loadingState1 == "pending" ||
              loadingState == "pending" ||
              loadingTrack == "pending"
                ? true
                : false
            }
          />
          <Pagination
            defaultCurrent={1}
            className="paginationDesign"
            current={collectionData.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setCollectionData({ ...collectionData, pageNumber, pageSize })
            }
            total={modifiedData?.data?.totalCount}
            showSizeChanger
            pageSize={collectionData.pageSize}
            pageSizeOptions={["150", "250", "350"]}
          />
          <StatusModal
            open={openAcceptModal}
            onOk={() => {
              handleStatus(2);

            }}
            onCancel={handleAcceptModal}
          >
            <p>Are you sure you want to accept </p>
          </StatusModal>
          <StatusModal
            open={openAcceptModal1}
            onOk={() => {
              handleSingleAccept();
              setOpenAcceptModal1(false);
              message.success({
                content: "Scheme Approved Successfully",
                className: "custom-message",
              });
            }}
            onCancel={handleAcceptModal1}
          >
            <p>Are you sure you want to accept </p>
          </StatusModal>

          <StatusModal
            open={openRejectModal1}
            onOk={async () => {
              const userId = userData.userId;
              const userName = userData.userName;
              const userRole = userData.userRole;
              let rejectDetails = tableArr
                .filter((x) => schemeNo?.[0] == x?.key)
                .map((x) => {
                  return {
                    schemeId: x?.schemeId,
                    schemeNo: x?.schemeNo,
                    dealerName: x?.dealerName,
                    dealerLocation: x?.dealerLocation,
                    dealerCode: x?.dealerCode,
                    dealerId: x?.dealerId,
                    startMonth: x?.startMonth,
                    endMonth: x?.endMonth,
                    createdDate: x?.createdDate,
                    reason: x?.modifiedReason,
                    schemeTypeId: x?.schemeTypeId,
                    status: 5,
                    delayPlan: x?.modifiedDelayPlan,
                    billing: x?.modifiedBilling,
                    installation: x?.modifiedInstallation,
                    collection: x?.modifiedCollection,
                    openingStockDays: x?.modifiedOpeningStockDays,
                    outStandingDays: x?.modifiedOutStandingDays,
                    incPerTarget: x?.modifiedIncPerTarget,
                    industry: x?.modifiedIndustry,
                    installationTarget: x?.modifiedInstallationTarget,
                    upperCap: x?.modifiedUpperCap || 0,
                    isSAP: x?.isSAP,
                    isActive: x?.isActive,
                    schemePlanId: x?.schemePlanId,
                    schemePlanStartMonth: x?.schemePlanStartMonth,
                    schemePlanEndMonth: x?.schemePlanEndMonth,
                    bookCollectionId: x?.bookCollectionId,
                    t1: x?.modifiedT1,
                    t2: x?.modifiedT2,
                    t3: x?.modifiedT3,
                    t4: x?.modifiedT4,
                    t5: x?.modifiedT5,
                    t6: x?.modifiedT6,
                    t2_T1: x?.modifiedT2_T1,
                    t3_T2: x?.modifiedT3_T2,
                    t4_T3: x?.modifiedT4_T3,
                    t5_T4: x?.modifiedT5_T4,
                    t6_T5: x?.modifiedT6_T5,
                    payoutId: x?.payoutId,
                    p1: x?.p1,
                    p2: x?.p2,
                    p3: x?.p3,
                    p4: x?.p4,
                    p5: x?.p5,
                    p6: x?.p6,
                    r1: x?.modifiedR1,
                    r2: x?.modifiedR2,
                    r3: x?.modifiedR3,
                    r4: x?.modifiedR4,
                    r5: x?.modifiedR5,
                    r6: x?.modifiedR6,
                    volume: x?.modifiedVolume,
                    perTr: x?.perTr,
                    amount: x?.amount,
                    lastMonthSlab: x?.lastMonthSlab,
                    lastMonthColQty: x?.lastMonthColQty,
                    lastTwoMonthSlab: x?.lastTwoMonthSlab,
                    lastTwoMonthColQty: x?.lastTwoMonthColQty,
                    fyear: x?.fyear,
                    territoryManager: x?.territoryManager,
                    stateName: x?.stateName,
                    stateId: x?.stateId,
                    schemeType: x?.schemeType,
                    expectedPayoutId: x?.expectedPayoutId,
                    lastOneMonthSlabAchieved: x?.lastOneMonthSlabAchieved,
                    lastTwoMonthSlabAchieved: x?.lastTwoMonthSlabAchieved,
                    isMerge: x?.isMerge,
                    deviationAmount: x?.deviationAmount,
                    oldBillingQty: x?.oldBillingQty,
                    oldInstallation: x?.oldInstallation,
                    oldOpeningStock: x?.oldOpeningStock,
                    oldCollection: x?.oldCollection,
                    closingOutStandingMonth_1: x?.closingOutStandingMonth_1,
                    closingOutStandingMonth_2: x?.closingOutStandingMonth_2,
                    closingOutStandingMonth_3:
                      x?.isMergclosingOutStandingMonth_3,
                    bgLacsCurrentYear: x?.bgLacsCurrentYear,
                    updatedBy: x?.updatedBy,
                    isEdit: x?.isEdit,
                    actualBillingOty: x?.actualBillingOty,
                    actualBillingAmount: x?.actualBillingAmount,
                    actualCollection: x?.actualCollection,
                    collectionQuantity: x?.collectionQuantity,
                    billingFactor: x?.billingFactor,
                    totalSchemeSubmitted: x?.totalSchemeSubmitted,
                    areaManagerId: x?.areaManagerId,
                    areaManagerName: x?.areaManagerName,
                    stateHeadName: x?.stateHeadName,
                    achievedSlab: x?.achievedSlab,
                    payout: x?.payout,
                    userid: +userId,
                    username: userName,
                    userrole: userRole,
                    key: x?.key,
                  };
                });
              let payload = await tableArr
                .filter((x) => selectedRowKeys.includes(x?.key))
                .map((x) => {
                  return {
                    schemeId: x?.schemeId,
                    userid: +userId,
                    username: userName,
                  };
                });

              if (
                selectedLabel === "Regular Schemes" ||
                selectedLabel === "Schemes"
              ) {
                await dispatch(getModificationRejectAsync(rejectDetails));
                await getApiData();
              } else if (selectedLabel === "Hold Schemes") {
                await dispatch(getModificationRejectHoldAsync(payload));
                getHoldData();
              }

              await setOpenRejectModal1(false);

              await message.success({
                content: "Scheme Reject Successfully",
                className: "custom-message",
              });
            }}
            onCancel={() => setOpenRejectModal1(false)}
          >
            <p>Are you sure you want to Reject </p>
          </StatusModal>

          <StatusModal
            open={openRejectModal}
            onOk={async () => {
              const userId = userData.userId;
              const userName = userData.userName;
              const userRole = userData.userRole;
              let rejectDetails = await tableArr
                .filter((x) => selectedRowKeys.includes(x?.key))
                .map((x) => {
                  return {
                    schemeId: x?.schemeId,
                    schemeNo: x?.schemeNo,
                    dealerName: x?.dealerName,
                    dealerLocation: x?.dealerLocation,
                    dealerCode: x?.dealerCode,
                    dealerId: x?.dealerId,
                    startMonth: x?.startMonth,
                    endMonth: x?.endMonth,
                    createdDate: x?.createdDate,
                    reason: x?.modifiedReason,
                    schemeTypeId: x?.schemeTypeId,
                    status: 5,
                    delayPlan: x?.modifiedDelayPlan,
                    billing: x?.modifiedBilling,
                    installation: x?.modifiedInstallation,
                    collection: x?.modifiedCollection,
                    openingStockDays: x?.modifiedOpeningStockDays,
                    outStandingDays: x?.modifiedOutStandingDays,
                    incPerTarget: x?.modifiedIncPerTarget,
                    industry: x?.modifiedIndustry,
                    installationTarget: x?.modifiedInstallationTarget,
                    upperCap: x?.modifiedUpperCap || 0,
                    isSAP: x?.isSAP,
                    isActive: x?.isActive,
                    schemePlanId: x?.schemePlanId,
                    schemePlanStartMonth: x?.schemePlanStartMonth,
                    schemePlanEndMonth: x?.schemePlanEndMonth,
                    bookCollectionId: x?.bookCollectionId,
                    t1: x?.modifiedT1,
                    t2: x?.modifiedT2,
                    t3: x?.modifiedT3,
                    t4: x?.modifiedT4,
                    t5: x?.modifiedT5,
                    t6: x?.modifiedT6,
                    t2_T1: x?.modifiedT2_T1,
                    t3_T2: x?.modifiedT3_T2,
                    t4_T3: x?.modifiedT4_T3,
                    t5_T4: x?.modifiedT5_T4,
                    t6_T5: x?.modifiedT6_T5,
                    payoutId: x?.payoutId,
                    p1: x?.p1,
                    p2: x?.p2,
                    p3: x?.p3,
                    p4: x?.p4,
                    p5: x?.p5,
                    p6: x?.p6,
                    r1: x?.modifiedR1,
                    r2: x?.modifiedR2,
                    r3: x?.modifiedR3,
                    r4: x?.modifiedR4,
                    r5: x?.modifiedR5,
                    r6: x?.modifiedR6,
                    volume: x?.modifiedVolume,
                    perTr: x?.perTr,
                    amount: x?.amount,
                    lastMonthSlab: x?.lastMonthSlab,
                    lastMonthColQty: x?.lastMonthColQty,
                    lastTwoMonthSlab: x?.lastTwoMonthSlab,
                    lastTwoMonthColQty: x?.lastTwoMonthColQty,
                    fyear: x?.fyear,
                    territoryManager: x?.territoryManager,
                    stateName: x?.stateName,
                    stateId: x?.stateId,
                    schemeType: x?.schemeType,
                    expectedPayoutId: x?.expectedPayoutId,
                    lastOneMonthSlabAchieved: x?.lastOneMonthSlabAchieved,
                    lastTwoMonthSlabAchieved: x?.lastTwoMonthSlabAchieved,
                    isMerge: x?.isMerge,
                    deviationAmount: x?.deviationAmount,
                    oldBillingQty: x?.oldBillingQty,
                    oldInstallation: x?.oldInstallation,
                    oldOpeningStock: x?.oldOpeningStock,
                    oldCollection: x?.oldCollection,
                    closingOutStandingMonth_1: x?.closingOutStandingMonth_1,
                    closingOutStandingMonth_2: x?.closingOutStandingMonth_2,
                    closingOutStandingMonth_3:
                      x?.isMergclosingOutStandingMonth_3,
                    bgLacsCurrentYear: x?.bgLacsCurrentYear,
                    updatedBy: x?.updatedBy,
                    isEdit: x?.isEdit,
                    actualBillingOty: x?.actualBillingOty,
                    actualBillingAmount: x?.actualBillingAmount,
                    actualCollection: x?.actualCollection,
                    collectionQuantity: x?.collectionQuantity,
                    billingFactor: x?.billingFactor,
                    totalSchemeSubmitted: x?.totalSchemeSubmitted,
                    areaManagerId: x?.areaManagerId,
                    areaManagerName: x?.areaManagerName,
                    stateHeadName: x?.stateHeadName,
                    achievedSlab: x?.achievedSlab,
                    payout: x?.payout,
                    userid: +userId,
                    username: userName,
                    userrole: userRole,
                    key: x?.key,
                  };
                });
              let payload = await tableArr
                .filter((x) => selectedRowKeys.includes(x?.key))
                .map((x) => {
                  return {
                    schemeId: x?.schemeId,
                    userid: +userId,
                    username: userName,
                  };
                });
              if (
                selectedLabel === "Regular Schemes" ||
                selectedLabel === "Schemes"
              ) {
                await dispatch(getModificationRejectAsync(rejectDetails));
                await getApiData();
              } else if (selectedLabel === "Hold Schemes") {
                await dispatch(getModificationRejectHoldAsync(payload));
                getHoldData();
              }
              await setOpenRejectModal(false);
              await message.success({
                content: "Scheme Reject Successfully",
                className: "custom-message",
              });
            }}
            onCancel={() => setOpenRejectModal(false)}
          >
            <p>Are you sure you want to Reject </p>
          </StatusModal>
          <div className="All_scheme_status">
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "green" }}
              ></div>
              Approved
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "red" }}
              ></div>
              Rejected
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "yellow" }}
              ></div>
              Work in Progress
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "rgb(255, 0, 255)" }}
              ></div>
              Auto Rejected
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#BCB38D" }}
              ></div>
              Deviation
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#9999ff" }}
              ></div>
              Hold
            </div>
          </div>
        </div>
      )}
      {(loadingAcceptState=="pending"||loadingState1 == "pending" ||
        loadingState == "pending" ||loadingUpdateScheme=="pending"||loadingModificationState=="pending"||
        loadingTrack == "pending"||loadingSaveHoldState=="pending"||loadingModificationAwaitingState=="pending") && <FullScreenLoader />}
    </>
  );
};
export default ModificationAwaitingApproval;
