import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getApprovedSchemeApi,
  getUnholdSchemeApi,
  postSaveHoldSchemeDataApi,
  postUnHoldScheme,
  postUpdateHoldSchemeApi,
} from "./holdDataService";

export const getApprovedScheme = createAsyncThunk(
  "getApprovedSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await getApprovedSchemeApi(data);

      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postSaveHoldSchemeData = createAsyncThunk(
  "postSaveHoldSchemeDataApi",
  async (data, thunkAPI) => {
    try {
      const response = await postSaveHoldSchemeDataApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postUpdateHoldScheme = createAsyncThunk(
  "postUpdateHoldSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await postUpdateHoldSchemeApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postUnHoldSchemeApi = createAsyncThunk(
  "postUnHoldSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await postUnHoldScheme(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUnholdScheme = createAsyncThunk(
  "getUnholdSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await getUnholdSchemeApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const holdDataSlice = createSlice({
  name: "holdDataSlice",
  initialState: {
    holdDataList: {},
    saveHoldDataList: {},
    unHoldDataList: {},
    unholdSchemePost: {},
    loadingUnholdList: "idle",
    loadingGetHoldList: "idle",
    loadingSaveHoldList: "idle",
    loadingSaveHoldState: "idle",
  },
  reducers: {
    // resetState: (state, action) => {},
  },
  extraReducers: (builder) => {
    // Save Modification Dashboard
    builder.addCase(postUpdateHoldScheme.pending, (state) => {
      state.loadingSaveHoldState = "pending";
    });
    builder.addCase(postUpdateHoldScheme.fulfilled, (state, { payload }) => {
      state.saveModification = payload;
      state.loadingSaveHoldState = "succeeded";
      state.error = true;
    });
    builder.addCase(postUpdateHoldScheme.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingSaveHoldState = "succeeded";
      state.error = true;
    });

    builder.addCase(getApprovedScheme.pending, (state) => {
      state.loadingGetHoldList = "pending";
    });
    builder.addCase(getApprovedScheme.fulfilled, (state, action) => {
      state.loadingGetHoldList = "succeeded";
      state.holdDataList = action.payload.data;
      state.error = false;
    });
    builder.addCase(getApprovedScheme.rejected, (state) => {
      state.holdDataList = {};
      state.loadingGetHoldList = "failed";
      state.error = true;
    });

    builder.addCase(postSaveHoldSchemeData.pending, (state) => {
      state.loadingSaveHoldList = "pending";
    });
    builder.addCase(postSaveHoldSchemeData.fulfilled, (state, action) => {
      state.loadingSaveHoldList = "succeeded";
      state.saveHoldDataList = action.payload.data;
      state.error = false;
    });
    builder.addCase(postSaveHoldSchemeData.rejected, (state) => {
      state.unHoldDataList = {};
      state.loadingSaveHoldList = "succeeded";
      state.error = true;
    });
    builder.addCase(getUnholdScheme.pending, (state) => {
      state.loadingUnholdList = "pending";
    });
    builder.addCase(getUnholdScheme.fulfilled, (state, action) => {
      state.loadingUnholdList = "succeeded";
      state.unHoldDataList = action.payload.data;
      state.error = false;
    });
    builder.addCase(getUnholdScheme.rejected, (state) => {
      state.unHoldDataList = {};
      state.loadingUnholdList = "succeeded";
      state.error = true;
    });

    builder.addCase(postUnHoldSchemeApi.pending, (state) => {
      state.loadingUnholdList = "pending";
    });
    builder.addCase(postUnHoldSchemeApi.fulfilled, (state, action) => {
      state.loadingUnholdList = "succeeded";
      state.unholdSchemePost = action.payload.data;
      state.error = false;
    });
    builder.addCase(postUnHoldSchemeApi.rejected, (state) => {
      state.unholdSchemePost = {};
      state.loadingUnholdList = "succeeded";
      state.error = true;
    });
    //postUnHoldSchemeApi
    //getUnholdScheme
  },
});
export const { reset } = holdDataSlice.actions;

export default holdDataSlice.reducer;
