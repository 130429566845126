import { toast } from "react-toastify";

export default function ToastifyShow(x, toastifyType = "info") {
  toast.success(x, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    type: toastifyType ? toastifyType : "info",
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: 0,
  });
}

export const TextLocalString=(data,hideZero)=>{
  if(hideZero){
    if(data){
        return data?.toLocaleString("en-IN")||""
    }else{
      return ""
    }
  }else{
  return data?.toLocaleString("en-IN")||""
  }
}
export const TextLocalStringCheck = (data, hideZero) => {
  if (typeof data === "number") {
    // If the number is an integer, avoid adding ".00"
    data = Number.isInteger(data) ? data : data.toFixed(2);
  }

  if (hideZero) {
    if (data) {
      return Number(data)?.toLocaleString("en-IN") || "";
    } else {
      return "";
    }
  } else {
    return Number(data)?.toLocaleString("en-IN") || "";
  }
};
