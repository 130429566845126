import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const SchemeWindowShow = () => {
    const navigate = useNavigate()
    return (
        <div>
            <Result
                status="403"
                title="403"

                subTitle="Window is not available for current month"
                extra={<Button type="primary"
                    onClick={() => {
                        navigate("/dashboard");
                    }}
                >Back Home</Button>}
            />
        </div>
    )
}
export default SchemeWindowShow