import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEditDashboardDetail,
   getModificationApi, 
   getModificationCollectionApi,
   getModificationTrackApiSadm,
   getModificationTrackApi, 
   getModificationTrackApiZadm,
   getModificationAcceptApi,
    getModificationRejectApi,
     getSaveModifiedDashboard,
     getUpdateModifiedDataDetails,
     getModificationHoldApi,
     getModificationHoldTrackApi,
     getModificationAwaitingHoldApi ,
     getModificationRejectHoldApi
    } from "./modificationService";

export const getModificationCollectionAsync = createAsyncThunk(
  "getModificationCollection",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationCollectionApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getModificationAsync = createAsyncThunk(
  "getModificationData",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getModificationHoldAsync = createAsyncThunk(
  "getModificationHoldData",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationHoldApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSaveModifiedDashboardApi = createAsyncThunk(
  "getSaveModifiedDashboardApi",
  async (data, thunkAPI) => {
    try {
      const response = await getSaveModifiedDashboard(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getModificationAwaitingAsync = createAsyncThunk(
  "getModificationAwaitingData",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationAwaitingHoldApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getEditDashboardDetailApi = createAsyncThunk(
  "getEditDashboardDetailApi",
  async (data, thunkAPI) => {
    try {
      const response = await getEditDashboardDetail(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getModificationAcceptAsync = createAsyncThunk(
  "postModificationAccept",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationAcceptApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getModificationRejectAsync = createAsyncThunk(
  "getModificationReject",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationRejectApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getModificationRejectHoldAsync = createAsyncThunk(
  "getModificationHoldReject",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationRejectHoldApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUpdateModifiedAwaitingDataDetails = createAsyncThunk(
  "getUpdateModifiedAwaitingDataDetails",
  async (data, thunkAPI) => {
    try {
      const response = await getUpdateModifiedDataDetails(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getModificationTrackAsyncSadm = createAsyncThunk(
  "getModificationTrackSadm",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationTrackApiSadm(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getModificationTrackAsyncZadm= createAsyncThunk(
  "getModificationTrackZadm",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationTrackApiZadm(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getModificationTrackHoldAsync= createAsyncThunk(
  "getModificationTrackHold",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationHoldTrackApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getModificationTrackAsync= createAsyncThunk(
  "getModificationTrack",
  async (data, thunkAPI) => {
    try {
      const response = await getModificationTrackApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const modificationCollectionSlice = createSlice({
  name: "modificationCollectionSlice",
  initialState: {
    collection: {}, 
    saveModification: {},
    saveHistory: {},
    RejectHistory: {},
    editDashboard: {},
    modifiedData: {},
    modifiedHoldData:{},
    loadingState: "idle",
    loadingModificationSaveState:"idle",
    loadingModificationAwaitingState:"idle",
    loadingModificationState:"idle",
    trackDataSadm:{},
    trackDataZadm:{},
    trackData:{},
    trackHoldData:{},
    message:null
  },
  reducers: {
    resetCollection: (state, action) => {
      state.collection = {}
    },
    resetSaveModification: (state, action) => {
      state.saveModification = {}
    },
    resetModifiedData:(state, action) => {
      state.modifiedData = {}
    },
    resetEdit:(state,action)=>{
      state.editDashboard= {}
    }
  },
  extraReducers: (builder) => {
    // Modification Collection
    builder.addCase(getModificationCollectionAsync.pending, (state) => {
      state.loadingState1 = "pending";
    });
    builder.addCase(getModificationCollectionAsync.fulfilled, (state, { payload }) => {
      state.collection = payload;
      state.loadingState1 = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationCollectionAsync.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState1 = "succeeded";
      state.error = true;
    });

    // Modification Hold Collection
    builder.addCase(getModificationHoldAsync.pending, (state) => {
      state.loadingState1 = "pending";
    });
    builder.addCase(getModificationHoldAsync.fulfilled, (state, { payload }) => {
      state.collection = payload;
      state.loadingState1 = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationHoldAsync.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState1 = "succeeded";
      state.error = true;
    });
    //  Modified Data
    builder.addCase(getModificationAsync.pending, (state) => {
      state.loadingModificationState = "pending";
    });
    builder.addCase(getModificationAsync.fulfilled, (state, { payload }) => {
      state.modifiedData = payload;
      state.loadingModificationState = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationAsync.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingModificationState = "succeeded";
      state.error = true;
    });
 //  Modified Hold Data
 builder.addCase(getModificationAwaitingAsync.pending, (state) => {
  state.loadingState1 = "pending";
});
builder.addCase(getModificationAwaitingAsync.fulfilled, (state, { payload }) => {
  state.modifiedHoldData = payload;
  state.loadingState1 = "succeeded";
  state.error = true;
});
builder.addCase(getModificationAwaitingAsync.rejected, (state) => {
  state.stateWiseData = {};
  state.loadingState1 = "succeeded";
  state.error = true;
});

    // Save Modification Dashboard
    builder.addCase(getSaveModifiedDashboardApi.pending, (state) => {
      state.loadingModificationSaveState = "pending";
    });
    builder.addCase(getSaveModifiedDashboardApi.fulfilled, (state, { payload }) => {
      state.saveModification = payload;
      state.loadingModificationSaveState = "succeeded";
      state.error = true;
    });
    builder.addCase(getSaveModifiedDashboardApi.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingModificationSaveState = "succeeded";
      state.error = true;
    });

    // Accept Modified  
    builder.addCase(getModificationAcceptAsync.pending, (state) => {
      state.loadingAcceptState = "pending";
    });
    builder.addCase(getModificationAcceptAsync.fulfilled, (state, { payload }) => {
      state.saveHistory = payload;
      state.loadingAcceptState = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationAcceptAsync.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingAcceptState = "succeeded";
      state.error = true;
    });
    // REJECT  Modified  
    builder.addCase(getModificationRejectAsync.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(getModificationRejectAsync.fulfilled, (state, { payload }) => {
      state.saveHistory = payload;
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationRejectAsync.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState = "succeeded";
      state.error = true;
    });
      // REJECT Hold  Modified  
      builder.addCase(getModificationRejectHoldAsync.pending, (state) => {
        state.loadingState = "pending";
      });
      builder.addCase(getModificationRejectHoldAsync.fulfilled, (state, { payload }) => {
        state.saveHistory = payload;
        state.loadingState = "succeeded";
        state.error = true;
      });
      builder.addCase(getModificationRejectHoldAsync.rejected, (state) => {
        state.stateWiseData = {};
        state.loadingState = "succeeded";
        state.error = true;
      });
    builder.addCase(getEditDashboardDetailApi.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(getEditDashboardDetailApi.fulfilled, (state, action, payload) => {
      state.editDashboard = action.payload;
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getEditDashboardDetailApi.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getUpdateModifiedAwaitingDataDetails.pending, (state) => {
      state.loadingModificationAwaitingState = "pending";
    });
    builder.addCase(getUpdateModifiedAwaitingDataDetails.fulfilled, (state, action, payload) => {
      state.editDashboard = action.payload;
      state.loadingModificationAwaitingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getUpdateModifiedAwaitingDataDetails.rejected, (state) => {
      state.editDashboard = {};
      state.loadingModificationAwaitingState = "succeeded";
      state.error = true;
    });
    //getTrackModifiedDataDetailsForSADM

    builder.addCase(getModificationTrackAsyncSadm.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(getModificationTrackAsyncSadm.fulfilled, (state, { payload }) => {
      state.trackDataSadm = payload;
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationTrackAsyncSadm.rejected, (state) => {
      state.trackDataSadm = {};
      state.loadingState = "succeeded";
      state.error = true;
    });

     //getTrackModifiedDataDetailsForZADM
    builder.addCase(getModificationTrackAsyncZadm.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(getModificationTrackAsyncZadm.fulfilled, (state, { payload }) => {
      state.trackDataZadm = payload;
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationTrackAsyncZadm.rejected, (state) => {
      state.trackDataZadm = {};
      state.loadingState = "succeeded";
      state.error = true;
    });
      //getTrackModifiedDataDetailsForHold
      builder.addCase(getModificationTrackHoldAsync.pending, (state) => {
        state.loadingState = "pending";
      });
      builder.addCase(getModificationTrackHoldAsync.fulfilled, (state, { payload }) => {
        state.trackHoldData = payload;
        state.loadingState = "succeeded";
        state.error = true;
      });
      builder.addCase(getModificationTrackHoldAsync.rejected, (state) => {
        state.trackHoldData = {};
        state.loadingState = "succeeded";
        state.error = true;
      });
 //getTrackModifiedDataDetails
    builder.addCase(getModificationTrackAsync.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(getModificationTrackAsync.fulfilled, (state, { payload }) => {
      state.trackData = payload;
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(getModificationTrackAsync.rejected, (state) => {
      state.trackData = {};
      state.loadingState = "succeeded";
      state.error = true;
    });
  },
});



export const { resetCollection,resetSaveModification,resetModifiedData,resetEdit } = modificationCollectionSlice.actions;

export default modificationCollectionSlice.reducer;
